import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import { editOrder, getOrder } from 'src/ducks/orders/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import {
  loadingOrderSelector,
  orderAnyTouchedSelector,
  selectedOrderSelector,
  trackingNumberSelector,
} from 'src/ducks/orders/selectors';
import { userAccessesSelector } from 'src/ducks/user/selectors';

import Loader from 'src/components/common/error-pages/Loader';
import { SaveIcon } from 'src/components/common/Icons';
import { FormProps } from '../viewOrder/deliveryInfo/DeliveryInfo';

import { findMappedDeliveryStatus, formatDate } from 'src/utils/helpers';
import userAccessesConstants from 'src/constants/userAccesses';
import history from 'src/containers/history';

import {
  ColoredDot,
  DescriptionWrapper,
  NoteOrderWrapper,
  StatusWrapper,
  StatusWrapperLabel,
  StyledSwitch,
  TrackingWrapper,
} from '../viewOrder/deliveryInfo/styles';
import {
  ActionButtons,
  ActionButtonsWrapper,
  BackButton,
  OrderTitle,
  SaveButton,
  TotalWrapper,
  ViewOrderPage,
} from '../viewOrder/styles';
import { ViewOrderContainer, CustomerWrapper, InfoTitle } from './styles';
import { CalculationWrapper } from '../viewOrder/productsInfo/styles';

const OrderMobile = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const order = useSelector(selectedOrderSelector);
  const isLoading = useSelector(loadingOrderSelector);
  const trackingNumber = useSelector(trackingNumberSelector);
  const userAccesses = useSelector(userAccessesSelector);
  const currentStatus = findMappedDeliveryStatus(order?.status || '');
  const isAllowEditPage = !userAccesses?.includes(userAccessesConstants.ACCESS_EDITOR);
  const isAnyTouched = useSelector(orderAnyTouchedSelector);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    dispatch(getNewNotifications());
    dispatch(getOrder(id));
  }, [id, dispatch]);

  const handleGoBack = () => {
    history.push('/orders');
  };

  const handleEditOrder = () => {
    if (isAnyTouched || checked) {
      if (order) {
        let orderStatus = 'completed';

        if (order.status === 'paid') {
          orderStatus = 'shipping';
        }

        if (order.trackingNumber) {
          dispatch(editOrder(id, trackingNumber));
        } else {
          if(trackingNumber && order.status === 'shipping') {
            dispatch(editOrder(id, trackingNumber));
          } else {
            dispatch(editOrder(id, trackingNumber, orderStatus));
          }
        }
      }
    } else {
      history.push('/orders');
    }
  };

  const renderOrderNote = () => {
    if (order?.note) {
      return (
        <NoteOrderWrapper style={{ marginTop: 40, flexBasis: 'auto' }}>
          <InfoTitle>Note About Order</InfoTitle>
          <DescriptionWrapper>
            <span>{order?.note}</span>
          </DescriptionWrapper>
        </NoteOrderWrapper>
      );
    }

    return null;
  };

  const handleSwitch = () => setChecked(!checked);

  const renderTrackingBlock = () => {
    let currentDeliveryStatus;
    if (order) {
      currentDeliveryStatus = findMappedDeliveryStatus(order.status);
    }
    const fulfilledStatus = findMappedDeliveryStatus('shipping');
    const deliveredStatus = findMappedDeliveryStatus('completed');
    const isDeliveredStatus = currentDeliveryStatus?.shownStatus === 'Delivered';
    const isFulfilledStatus = currentDeliveryStatus?.shownStatus === 'Fulfilled';
    const isPendingStatus = currentDeliveryStatus?.shownStatus === 'Pending';
    const disabledSwitchCondition = !isAllowEditPage || isDeliveredStatus;

    let status;
    if (checked && isPendingStatus) {
      status = fulfilledStatus?.shownStatus;
    }

    if (checked && isFulfilledStatus) {
      status = 'Delivered';
    }

    if (!checked) {
      status = currentDeliveryStatus?.shownStatus;
    }

    return (
      <TrackingWrapper>
        <StatusWrapper>
          <div>
            <StatusWrapperLabel>{isPendingStatus ? 'Fulfilled' : 'Delivered'}</StatusWrapperLabel>
            <StyledSwitch
              disabled={disabledSwitchCondition}
              checked={isDeliveredStatus || checked}
              onChange={handleSwitch}
              name="checked"
            />
          </div>
          <div>
            <StatusWrapperLabel>Status</StatusWrapperLabel>
            <div>
              <ColoredDot color={checked ? deliveredStatus?.color : currentDeliveryStatus?.color} />
              {status || order?.status}
            </div>
          </div>
        </StatusWrapper>
      </TrackingWrapper>
    );
  };

  return !isLoading && order ? (
    <ViewOrderPage>
      <OrderTitle>
        Order <span>#{order.id}</span>
      </OrderTitle>
      <ViewOrderContainer>
        <CustomerWrapper>
          {renderTrackingBlock()}
          <div>
            <InfoTitle>Delivery Method</InfoTitle>
            <DescriptionWrapper>
              <span>Delivery Needed</span>
            </DescriptionWrapper>
          </div>
          <div>Placed on {formatDate(order.createdAt)}</div>
        </CustomerWrapper>
        {renderOrderNote()}
        <CalculationWrapper>
          <div>
            <div>SUBTOTAL:</div>
            <div>TAX:</div>
          </div>
          <div>
            <div>${order.subtotal.toFixed(2)}</div>
            <div>${order.tax.toFixed(2)}</div>
          </div>
        </CalculationWrapper>
        <ActionButtonsWrapper>
          <ActionButtons>
            <BackButton onClick={handleGoBack}>Back</BackButton>
            {isAllowEditPage && currentStatus?.shownStatus !== 'Delivered' && (
              <SaveButton
                variant="contained"
                color="primary"
                disabled={!`${trackingNumber}`}
                onClick={handleEditOrder}
              >
                <SaveIcon />
                Save
              </SaveButton>
            )}
          </ActionButtons>
          <TotalWrapper style={{ paddingRight: 15 }}>
            <div>Total:</div>
            <div>${order.grandTotal}</div>
          </TotalWrapper>
        </ActionButtonsWrapper>
      </ViewOrderContainer>
    </ViewOrderPage>
  ) : (
    <Loader />
  );
};

export default reduxForm<FormProps, any>({
  form: 'order',
})(OrderMobile);
