import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { TableBody, TableRow } from '@material-ui/core';
import { Head } from '../styles';
import {
  StyledBodyTableRow,
  StyledTableCell,
  StyledTableContainer,
  TableWrapper,
  VariantText,
} from './styles';
import { FormInput } from 'src/components/common/formInput';
import validations, { addUspsRules } from 'src/components/common/inputs/validations';
import { productShippingOptionsSelector, productVersionsFormSelector } from 'src/ducks/products/selectors';
import { isVariousSizesT, TableDataObjectT } from 'src/constants/productsTypes';
import { USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

const tableHeaderRequiredNames = [
  'variant',
  'inventory',
  'price',
];
const tableHeaderOptionalNames = [
  'width: (inches)',
  'height: (inches)',
  'length: (inches)',
];


type resArrT = {
  resArr: string[];
  productType: string;
};

const VersionsTable = ({ resArr, productType }: resArrT) => {
  const productVersionsFormValues: isVariousSizesT = useSelector(productVersionsFormSelector);
  const productShippingOptions = useSelector(productShippingOptionsSelector);
  const isVariousSizes = !!productVersionsFormValues?.isVariousSizes;
  const isServiceOrDigitalProduct = productType === 'digital_product_or_service';
  const isPhysicalProduct = productType === 'physical_product';
  const isUspsPriorityMail = productShippingOptions?.shippingType === USPS_PRIORITY_MAIL;

  const prepareVariantValidaion = (rules: any[]) => {
    return isVariousSizes ? addUspsRules(rules) : undefined
  }

  return resArr.length !== 0 ? (
    <StyledTableContainer>
      <TableWrapper>
        <Head>
          <TableRow>
            {tableHeaderRequiredNames.map((name: string) => (
              <StyledTableCell key={name}>{name}</StyledTableCell>
            ))}
            {(isPhysicalProduct && isUspsPriorityMail) &&
              tableHeaderOptionalNames.map((name: string) => (
                <StyledTableCell key={name}>{name}</StyledTableCell>
              ))}
          </TableRow>
        </Head>
        <TableBody>
          {resArr.map((optionName: string) => (
            <StyledBodyTableRow key={optionName}>
              <StyledTableCell align="left">
                <VariantText>{optionName}</VariantText>
              </StyledTableCell>
              <StyledTableCell align="left">
                <FormInput
                  type="number"
                  name={`${optionName}.inventory`}
                  validate={validations.inventory}
                  disabled={isServiceOrDigitalProduct}
                  customBg="#F8FAFB"
                />
              </StyledTableCell>
              <StyledTableCell align="left">
                <FormInput
                  type="number"
                  name={`${optionName}.price`}
                  validate={validations.price}
                  customBg="#F8FAFB"
                />
              </StyledTableCell>
              {(isPhysicalProduct && isUspsPriorityMail) && (
                <>
                  <StyledTableCell align="left">
                    <FormInput
                      type="number"
                      name={`${optionName}.width`}
                      validate={prepareVariantValidaion(validations.width)}
                      disabled={!isVariousSizes}
                      customBg="#F8FAFB"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormInput
                      type="number"
                      name={`${optionName}.height`}
                      validate={prepareVariantValidaion(validations.height)}
                      disabled={!isVariousSizes}
                      customBg="#F8FAFB"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <FormInput
                      type="number"
                      name={`${optionName}.length`}
                      validate={prepareVariantValidaion(validations.length)}
                      disabled={!isVariousSizes}
                      customBg="#F8FAFB"
                    />
                  </StyledTableCell>
                </>
              )}
            </StyledBodyTableRow>
          ))}
        </TableBody>
      </TableWrapper>
    </StyledTableContainer>
  ) : null;
};

export default reduxForm<TableDataObjectT, resArrT>({
  form: 'tableData',
})(VersionsTable);
