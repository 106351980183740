import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { CashOutType } from 'src/constants/cashOutTypes';
import { SelectWrapper, styles } from './styles';

type CashOutSelectProps = {
    options?: CashOutType[]
    selectedValue: string,
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void,
};

const useStyles = makeStyles(styles);

export const additionCashOutTypeOption = [
    {
        type: 'all_type',
        shownType: 'All Type',
    }
]

const FilterSelect = (props: CashOutSelectProps) => {
    const {
        options = [],
        selectedValue = additionCashOutTypeOption[0].type,
        handleChange,
    } = props;

    const allOptions = [...additionCashOutTypeOption, ...options];
    const classes = useStyles();

    const renderOptions = allOptions?.map(({ type, shownType }: CashOutType) => (
        <MenuItem value={type} key={type}>
            {shownType}
        </MenuItem>
    ));

    return (
        <SelectWrapper>
            <Select
                id="cashoutType"
                value={selectedValue}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                    className: classes.input,
                }}
                MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    elevation: 0,
                    PopoverClasses: { paper: classes.popover },
                  }}
                  variant="outlined"
            >
                {renderOptions}
            </Select>
        </SelectWrapper>
    );
};

export default FilterSelect;
