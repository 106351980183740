import React, { useRef } from 'react';
import { CROP_FILE_FORMAT_ERROR, CROP_LOADING_ERROR } from 'src/constants/formValidations';

const UPLOAD_FILE_LIMIT_MB = 3;
const allowedMimeTypes = ['image/png', 'image/jpeg', 'image/jpg'];

const messages = {
  fileSizeLimit: `File size is too big, please use file not bigger than ${UPLOAD_FILE_LIMIT_MB} MB`,
};

export type ErrorCallback = (error: string) => void;
export type ImageDataAvailableCallback = (imageDataInBase64: string) => void;
export type AfterLoadValidationCallback = (imageDataInBase64: string) => void;

export const useImageUpload = (
  onImageDataAvailable: ImageDataAvailableCallback,
  onError: ErrorCallback,
  afterLoadValidation?: AfterLoadValidationCallback,
) => {
  const ref = useRef<HTMLInputElement>(null);

  const triggerFileUpload = () => {
    ref.current!.click();
  };

  const readBase64ImageData = (file: File) => {
    const reader = new FileReader();

    reader.onload = async (e: any) => {
      const imageData = e.target.result;

      if (afterLoadValidation) { 
        await afterLoadValidation(imageData);
      }
      
      onImageDataAvailable(imageData);
    };

    reader.onabort = reader.onerror = (e: any) => {
      onError(CROP_LOADING_ERROR);
    };

    reader.readAsDataURL(file);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement).files;

    if (!files || files.length !== 1) {
      console.warn('useImageUpload supports only one image, but received', files);
      return;
    }

    const file = files[0];
    if (!allowedMimeTypes.some((mimeType) => file.type === mimeType)) {
      onError(CROP_FILE_FORMAT_ERROR);
      return;
    }

    if (file.size > UPLOAD_FILE_LIMIT_MB * 1024 * 1024) {
      onError(messages.fileSizeLimit);
      return;
    }

    readBase64ImageData(file);
  };

  return {
    triggerFileUpload,
    LoadImageInput: (props: any) => {
      return (
        <input
          style={{
            opacity: 0,
            height: 0,
            width: 0,
          }}
          ref={ref}
          type="file"
          accept="image/*"
          onChange={onChange}
          onClick={(event: React.MouseEvent<HTMLInputElement>) => {
            (event.target as HTMLInputElement).value = '';
          }}
          multiple={false}
          disabled={props.disabled}
        />
      );
    },
  };
};
