import React from 'react';
import { useHistory } from 'react-router-dom';
import { Cashout } from 'src/ducks/cashout/cashout';
import { TableBody, TableRow, Paper, TableContainer, IconButton } from '@material-ui/core';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { formatDate } from 'src/utils/helpers';
import { FormatDate } from 'src/components/common/date';
import cashOutTypes, { CashOutType } from 'src/constants/cashOutTypes';
import cashoutStatus, { CashoutStatus } from 'src/constants/cashoutStatuses';
import { DeleteCashout } from '../deleteCashout';
import {
  TableWrapper,
  Head,
  StyledTableCell,
} from 'src/components/pages/students/studenstList/styles';
import { StyledTableRow } from 'src/components/pages/orders/orderList/styles';
import { DeleteContainer, StyledTableCellInfo, StyledDateCellCashout } from './styles';

const tableColumnHeaders: string[] = [
  'Cash Out Type',
  'Recipient Name',
  'Status',
  'Date Submitted',
  'Date Approved',
  'Amount',
];

type CashoutProps = {
  loadMore: () => void;
  cashoutList: Cashout[];
  handleRemoveCashout: (cashoutId: number, amount: number) => void;
  isTeacher?: boolean;
};

const CashoutList = (props: CashoutProps) => {
  const history = useHistory();
  const { loadMore, cashoutList, handleRemoveCashout, isTeacher } = props;

  useInfiniteScroll({ loadMore });

  const getMappedCashoutTypes = (currentType: string) => {
    return cashOutTypes.find(({ type }: CashOutType) => type === currentType);
  };

  const getMappedCashoutStatus = (currentStatus: string) => {
    return cashoutStatus.find(({ status }: CashoutStatus) => status === currentStatus);
  };

  const handleGoToCashout = (cashoutId: number) => {
    history.push(`/accounting/cashout/${cashoutId}`);
  };

  const getCashoutList = (cashout: Cashout) => {
    const cashoutType = getMappedCashoutTypes(cashout.type);
    const cashoutStatus = getMappedCashoutStatus(cashout.status);
    const pendingStatus = 'Pending';
    const AttentionStatus = 'Attention Needed';
    const isAllowEditCashout =
      isTeacher &&
      (cashoutStatus?.shownStatus === pendingStatus ||
        cashoutStatus?.shownStatus === AttentionStatus);

    return (
      <StyledTableRow key={cashout.id} onClick={() => handleGoToCashout(cashout.id)}>
        <StyledTableCellInfo>{cashoutType?.shownType || cashout.type}</StyledTableCellInfo>
        <StyledTableCellInfo>{cashout.recipientName}</StyledTableCellInfo>
        <StyledTableCellInfo color={cashoutStatus?.color}>
          <span />
          {cashoutStatus?.shownStatus || cashout.status}
        </StyledTableCellInfo>
        <StyledDateCellCashout>{formatDate(cashout.createdAt)}</StyledDateCellCashout>
        <StyledDateCellCashout>
          {cashout.approvedDate ? (
            <FormatDate date={cashout.approvedDate.replace(/-/g, '/')} />
          ) : (
            "Your cash out request hasn't been approved yet"
          )}
        </StyledDateCellCashout>
        <StyledTableCellInfo>
          {cashout.amount &&
            `$${cashout.amount.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}
        </StyledTableCellInfo>
        <DeleteContainer align="left">
          <IconButton disabled={!isAllowEditCashout}>
            <DeleteCashout
              cashoutId={cashout.id}
              handleRemoveCashout={handleRemoveCashout}
              amount={cashout.amount}
            />
          </IconButton>
        </DeleteContainer>
      </StyledTableRow>
    );
  };

  const renderCashoutTable = () => {
    return (
      <TableContainer component={Paper}>
        <TableWrapper>
          <Head>
            <TableRow>
              {tableColumnHeaders.map((columnHeader: string) => {
                return <StyledTableCell key={columnHeader}>{columnHeader}</StyledTableCell>;
              })}
              <StyledTableCell />
            </TableRow>
          </Head>
          <TableBody>{cashoutList.map((cashout: Cashout) => getCashoutList(cashout))}</TableBody>
        </TableWrapper>
      </TableContainer>
    );
  };

  return renderCashoutTable();
};

export default CashoutList;
