import React from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';

type TooltipWrapperProps = {
  position: string;
};

export const Wrapper = styled('div')({
  position: 'relative',
});

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.customPalette.background.header,
    color: theme.customPalette.text.primary,
    padding: '13px 15px 14px',
    borderRadius: '5px',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.1)',
    fontSize: '15px',
    fontWeight: 'normal',
  },
  arrow: {
    color: theme.customPalette.background.header,
  },
  popper: {
    pointerEvents: 'auto',
  },
}))(Tooltip);

export const TooltipWrapper = styled(({ position, ...otherProps }: TooltipWrapperProps) => (
  <div {...otherProps} />
))({
  position: ({ position }: any) => position,
  width: 'min-content',
  bottom: 5,
  left: 9,
  '& svg': {
    fill: '#B7C2CC',
    '&:hover': {
      fill: '#44566C',
    },
  },
});
