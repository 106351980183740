import React, { useState, useEffect } from 'react';
import { MenuItem } from '@material-ui/core';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { ProductChoice } from 'src/ducks/shopProducts/shopProducts';
import { FormShopSelect } from 'src/components/common/selects/shopSelect';
import { StylesTemplate } from 'src/constants/stylesTemplates';
import { SelectorLabel, CounterContainer } from './styles';

export type FormProps = {};

type SmartSelectorProps = {
  setProductAmount: (productAmount: number) => void;
  setProductChoiceId: (productChoiceId: number) => void;
  productChoices: ProductChoice[];
  productId: number;
  isPhysicalProduct?: boolean;
  styles: StylesTemplate,
};

type Props = InjectedFormProps<FormProps, SmartSelectorProps, {}>;

type DefaultState = {
  id?: number;
  category1?: string;
  categoryName1?: string;
  category2?: string;
  categoryName2?: string;
  inventory?: number;
}

const ProductChoices = (props: Props & SmartSelectorProps) => {
  const {
    setProductAmount,
    setProductChoiceId,
    productChoices,
    productId,
    isPhysicalProduct,
    styles,
  } = props;
  const [count, setCount] = useState<number>(1);
  const [selectedProductCombination, setSelectedProductCombination] = useState<DefaultState | null>(null);

  const isProductIncreaseForbid = selectedProductCombination?.inventory === count;

  useEffect(() => {
    const defaultAvailableCombination = productChoices.find((product: ProductChoice) => product.inventory !== 0);

    if (defaultAvailableCombination) {
      setSelectedProductCombination({
        id: defaultAvailableCombination.id,
        category1: defaultAvailableCombination.productOptionValues[0].name.toLocaleLowerCase(),
        categoryName1: defaultAvailableCombination.productOptionValues[0].productOption.name,
        category2: defaultAvailableCombination.productOptionValues[1]?.name.toLocaleLowerCase(),
        categoryName2: defaultAvailableCombination.productOptionValues[1]?.productOption.name,
        inventory: defaultAvailableCombination.inventory,
      });

      setProductChoiceId(defaultAvailableCombination.id);
    }
  }, [productId, setProductChoiceId]);

  useEffect(() => {
    setProductAmount(count);
  }, [count]);

  const handleChangeProductCombination = ({
    target: { value, name },
  }: React.ChangeEvent<{ value: unknown, name?: string; }>) => {
    productChoices.find((product: ProductChoice) => {
      const isColorSelectorChanged = product.productOptionValues[0].name.toLocaleLowerCase() === value &&
        product.productOptionValues[1]?.name.toLocaleLowerCase() === selectedProductCombination?.category2;
      const isSizeSelectorChanged = product.productOptionValues[1]?.name.toLocaleLowerCase() === value &&
        product.productOptionValues[0].name.toLocaleLowerCase() === selectedProductCombination?.category1;

      if (isColorSelectorChanged || isSizeSelectorChanged) {
        setSelectedProductCombination({
          ...selectedProductCombination,
          [name as string]: value,
          inventory:  product.inventory,
          id: product.id,
        });
        setProductChoiceId(product.id);

        if (isPhysicalProduct) {
          if (count > product.inventory) {
            setCount(product.inventory);
          }
        } else {
          setCount(1);
        }
      }
    });
  };

  const renderColorOptions = () => {
    return productChoices.map((product: ProductChoice) => {
      const category1 = product.productOptionValues[0].name;
      let isDisabled = false;

      if (
        product.productOptionValues[1]?.name.toLocaleLowerCase() === selectedProductCombination?.category2
      ) {
        if (product.inventory === 0) {
          isDisabled = true;
        }

        return (
          <MenuItem
            key={category1}
            value={category1.toLowerCase()}
            disabled={isDisabled}
          >
            {category1}
          </MenuItem>
        );
      }
    });
  };

  const renderSizeOptions = () => {
    return productChoices.map((product: ProductChoice) => {
      const category2 = product.productOptionValues[1]?.name;
      let isDisabled = false;

      if (
        product.productOptionValues[0].name.toLocaleLowerCase() === selectedProductCombination?.category1
      ) {
        if (product.inventory === 0) {
          isDisabled = true;
        }

        return (
          <MenuItem
            key={category2}
            value={category2?.toLowerCase()}
            disabled={isDisabled}
          >
            {category2}
          </MenuItem>
        );
      }
    });
  };

  const handleIncreaseAmount = () => {
    if (isPhysicalProduct) {
      if (count < Number(selectedProductCombination?.inventory)) {
        setCount(count + 1);
      }
    } else {
      setCount(count + 1);
    }
  };

  const handleDecreaseAmount = () => {
    if (count !== 1) {
      setCount(count - 1);
    }
  };

  return (
    <>
      {selectedProductCombination?.category2 &&
      <>
        <SelectorLabel>{selectedProductCombination.categoryName2}</SelectorLabel>
        <FormShopSelect
          name="category2"
          selectedValue={selectedProductCombination.category2}
          handleChange={handleChangeProductCombination}
          renderOptions={renderSizeOptions()}
          styles={styles}
        />
      </>
      }
      {selectedProductCombination?.category1 &&
      <>
        <SelectorLabel>{selectedProductCombination.categoryName1}</SelectorLabel>
        <FormShopSelect
          name="category1"
          selectedValue={selectedProductCombination.category1}
          handleChange={handleChangeProductCombination}
          renderOptions={renderColorOptions()}
          styles={styles}
        />
      </>
      }
      <CounterContainer isOutOfStock={isProductIncreaseForbid}>
        <span onClick={handleDecreaseAmount}>&#8722;</span>
        <span>{count}</span>
        <span onClick={handleIncreaseAmount}>+</span>
      </CounterContainer>
    </>
  );
};

export default reduxForm<FormProps, SmartSelectorProps, {}>({
  form: 'shopProductDetails',
})(ProductChoices);
