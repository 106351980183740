import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { StudentContainer } from '../students/styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearProfitsList, getProfitsList } from 'src/ducks/accounting/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { loadingProfitSelector, profitsSelector } from 'src/ducks/accounting/selectors';
import { OrderTitle } from '../orders/viewOrder/styles';
import Loader from 'src/components/common/error-pages/Loader';
import ProfitsList from './ProfitsList';
import { ILoadMoreParams, prepareQueryParams } from '../accounting/report/queryHelper';
import ProfitFilters from './ProfitFilters';

const Profits = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const profitsList = useSelector(profitsSelector);
  const isLoading = useSelector(loadingProfitSelector);
  const currentPage = Number(profitsList?.currentPage);
  const totalPages = profitsList?.totalPages;
  const totalProfit = profitsList?.totalProfit;

  const loadData = (params: any = {}) => {
    dispatch(getProfitsList(params));
  }

  const resetFilter = () => {
    dispatch(clearProfitsList());
  }

  useEffect(() => {
    dispatch(getNewNotifications());
    const queryParams = prepareQueryParams(location.search);
    loadData(queryParams);
    return resetFilter;
  }, []);

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      let queryParams: ILoadMoreParams = prepareQueryParams(location.search);
      queryParams.page = Number(currentPage) + 1;
      loadData(queryParams)
    }
  };

  return (
    <StudentContainer>
      <OrderTitle>
        Total Profit{' '}
        <span>${totalProfit?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</span>
      </OrderTitle>
      <ProfitFilters
        loadData={loadData}
        resetFilter={resetFilter}
      />
      {isLoading && !profitsList ? (
        <Loader />
      ) : profitsList?.list?.length ?
        <ProfitsList
          loadMore={loadMore}
          profitsList={profitsList.list}
        />
        :
        <div>You don't have any profits</div>
      }
    </StudentContainer>
  );
};

export default Profits;
