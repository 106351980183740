import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  AxiosRequestConfig,
  AxiosPromise,
} from 'axios';
import envSettings from 'src/config/envSettings';

class HttpService {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: envSettings.baseUrl,
      transformRequest: [
        (data, headers) => {
          if (data) {
            headers['Content-Type'] = 'application/json';
            return typeof data === 'object' ? JSON.stringify(data) : data;
          }
        },
      ],
    });
  }

  addResponseInterceptor = (onFulfilled: any, onRejected: any): number =>
    this.axiosInstance.interceptors.response.use(onFulfilled, onRejected);

  setAuthHeader = (token: string | undefined): string =>
    (this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`);

  removeAuthHeader = (): boolean => delete axios.defaults.headers.common.Authorization;

  handleResponse = (response: AxiosResponse): AxiosResponse => response;

  handleRejection = (error: AxiosError): AxiosPromise<AxiosError> =>
    Promise.reject(error.response || error);

  request = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.axiosInstance(config).catch(this.handleRejection).then(this.handleResponse);

  public get = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.request({ method: 'get', ...config });

  public post = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.request({ method: 'post', ...config });

  public delete = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.request({ method: 'delete', ...config });

  public put = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.request({ method: 'put', ...config });

  public patch = (config: AxiosRequestConfig): AxiosPromise<AxiosResponse> =>
    this.request({ method: 'patch', ...config });
}

const httpService = new HttpService();

export default httpService;
