import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getOrderList, clearOrderList } from 'src/ducks/orders/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { ordersSelector, loadingOrderSelector } from 'src/ducks/orders/selectors';
import Loader from 'src/components/common/error-pages/Loader';
import { StudentContainer } from 'src/components/pages/students/styles';
import OrderList from './orderList/OrderList';
import { ILoadMoreParams, prepareQueryParams } from '../accounting/report/queryHelper';
import OrderFilters from './OrderFilters';

const Orders = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const orderList = useSelector(ordersSelector);
  const isLoading = useSelector(loadingOrderSelector);
  const currentPage = orderList?.currentPage;
  const totalPages = orderList?.totalPages;
  const totalOrders = orderList?.totalOrders;

  const loadData = (params: any = {}) => {
    dispatch(getOrderList(params));
  }

  const resetFilter = () => {
    dispatch(clearOrderList());
  }

  useEffect(() => {
    dispatch(getNewNotifications());
    const queryParams = prepareQueryParams(location.search);

    loadData(queryParams)
    return resetFilter;
  }, []);

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      let queryParams: ILoadMoreParams = prepareQueryParams(location.search);
      queryParams.page = Number(currentPage) + 1;
      dispatch(getOrderList(queryParams));
    }
  };

  return (
    <StudentContainer>
      {isLoading && !orderList ? (
        <Loader />
      ) : (
        <>
          <OrderFilters
            loadData={loadData}
            resetFilter={resetFilter}
          />
          {orderList?.list?.length ? (
            <OrderList loadMore={loadMore} orderList={orderList.list} totalOrders={totalOrders} />
          ) : (
            <div>You don't have any orders</div>
          )}
        </>
      )
      }
    </StudentContainer>
  );
};

export default Orders;
