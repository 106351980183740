import React from 'react';
import { Link } from 'react-router-dom';
import { SuccessIcon } from 'src/components/common/Icons';
import { Wrapper, SuccessPageWrapper, SuccessText } from './styles';

type SuccessPageProps = {
  text: string;
  backUrl: string;
};

const SuccessPage = ({ text, backUrl }: SuccessPageProps) => {
  return (
    <Wrapper>
      <SuccessPageWrapper>
        <SuccessIcon />
        <SuccessText>{text}</SuccessText>
        <Link to={backUrl}>Back to home page</Link>
      </SuccessPageWrapper>
    </Wrapper>
  );
};

export default SuccessPage;
