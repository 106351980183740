import React, { useEffect, useState, SyntheticEvent } from 'react';
import { change, clearFields, initialize, reduxForm } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';

import { FormInput } from 'src/components/common/formInput';
import FormArea from 'src/components/common/textarea/FormArea';
import { ArrowDownIcon, ArrowUpIcon, SaveIcon } from 'src/components/common/Icons';
import { Container, Title, FormContainer } from '../students/styles';
import { getNewNotifications } from 'src/ducks/user/actions';
import {
  productFormSelector,
  productHasCustomFieldsSelector,
  productShippingOptionsSelector,
  productVersionsChipsFormSelector,
  productVersionsFormSelector,
  productVersionsNamesFormSelector,
  tableFormFormSelector,
} from 'src/ducks/products/selectors';
import {
  addProduct,
  editProduct,
  getProduct,
  addProductWithCustomFields,
  editProductWithCustomFields,
} from 'src/ducks/products/actions';
import PosterImage from './posterImage/PosterImage';
import defaultImg from 'src/assets/images/default.png';
import history from 'src/containers/history';
import validations, { addUspsRules } from 'src/components/common/inputs/validations';
import { errorSelector } from 'src/ducks/user/selectors';
import { errorSelector as errorProductsSelector } from 'src/ducks/products/selectors';
import { SaveButton } from '../orders/viewOrder/styles';
import CustomFields from './customFields';
import { getDataWithCustomField } from 'src/utils/helpers';
import {
  ChipsT,
  FormProps,
  HasCustomFieldsT,
  isVariousSizesT,
  NamesT,
  Props,
  TableDataT,
  TableDataObjectT,
  WebsiteCollection,
} from 'src/constants/productsTypes';
import {
  BackButton,
  ContainerColumn,
  ErrorMessage,
  SmallImage,
  ContainerFlex,
  ButtonsContainer,
  Remove,
  ProductErrorMessage,
  ShowCustomOptionsButton,
  InputsWrapper,
  UploadImgContainer,
  FormTitle,
  ContentContainer,
  ImagesContainer,
  SmTitle,
  SmImgWrapper,
  ProductInfo,
} from './styles';
import CropImageModal from 'src/components/pages/profile/avatarEditor/CropImageModal';
import { ShippingOptionsForm } from './shippingOptions';
import { USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';
import { CollectionAutocomplete } from './collectionAutocomplete';
import { websiteCollections, websiteSelector } from 'src/ducks/website/selectors';
import { getWebsiteCollections } from 'src/ducks/website/actions';

const Product = () => {
  const dispatch = useDispatch();
  const productFormValues: any = useSelector(productFormSelector);
  const productCollection: WebsiteCollection = productFormValues?.websiteCollection;
  const collections: WebsiteCollection[] = useSelector(websiteCollections) || [];
  const websiteId = useSelector(websiteSelector)?.id || null;
  const productShippingOptions = useSelector(productShippingOptionsSelector);
  const productVersionsFormValues: isVariousSizesT = useSelector(productVersionsFormSelector);
  const tableDataFormValues: TableDataT = useSelector(tableFormFormSelector);
  const productHasCustomFields: HasCustomFieldsT = useSelector(productHasCustomFieldsSelector);
  const chips: ChipsT = useSelector(productVersionsChipsFormSelector);
  const names: NamesT = useSelector(productVersionsNamesFormSelector);
  const [isModalOpen, setModalOpen] = useState(false);
  const [uploadedImageData, setUploadedImageData] = useState<File | null>(null);
  const [images, setImageState] = useState([] as any);
  const [currentImage, setCurrentImage] = useState({} as any);
  const [error, setError] = useState('');
  const [isDisabledUpload, setDisabledUpload] = useState(false);
  const [showedCustomOptions, setShowedCustomOptions] = useState(false);
  const [resArr, setResArr] = useState<string[]>([]);
  const [productType, setProductType] = useState<string>('');
  const [collectionName, setCollectionName] = useState<string>(productCollection?.collectionName || '');
  const errors = useSelector(errorSelector);
  const productErrors = useSelector(errorProductsSelector);
  const { id } = useParams<any>();

  const MAXIMUM_NUMBER_OF_IMAGES = 4;
  const isVariousSizes = !!productVersionsFormValues?.isVariousSizes;
  const hasCustomFields = !!productHasCustomFields?.hasCustomFields;
  const isDigitalOrServiceProduct = productType === 'digital_product_or_service';
  const isPhysicalProduct = productType === 'physical_product';
  const isUspsPriorityMail = productShippingOptions?.shippingType === USPS_PRIORITY_MAIL;

  useEffect(() => {
    dispatch(getNewNotifications());
    if (id) {
      dispatch(getProduct(id));
    } else {
      dispatch(
        initialize('ProductVersionsChips', {
          chip1: [],
          chip2: [],
        })
      );
      dispatch(change('hasCustomFields', 'hasCustomFields', false));
    }
  }, [dispatch, id]);

  useEffect(() => {
    setShowedCustomOptions(productHasCustomFields?.hasCustomFields);
  }, [productHasCustomFields]);

  useEffect(() => {
    const fieldsForChange = ['width', 'height', 'length'];
    if (id) {
      if (isVariousSizes) {
        fieldsForChange.forEach((name: string) => {
          dispatch(change('product', name, null));
        });
      } else {
        resArr.forEach((optionName: string) => {
          fieldsForChange.forEach((field: string) => {
            dispatch(change('tableData', `${optionName}.${field}`, null));
          });
        });
      }
    } else {
      dispatch(clearFields('product', false, false, ...fieldsForChange));
      resArr.forEach((optionName: string) => {
        dispatch(clearFields('tableData', false, false, `${optionName}.width`));
        dispatch(clearFields('tableData', false, false, `${optionName}.height`));
        dispatch(clearFields('tableData', false, false, `${optionName}.length`));
      });
    }
  }, [isVariousSizes]);

  useEffect(() => {
    if (id && productFormValues?.imagesGallery) {
        let images: any = [];
        const imageKey = Object.keys(productFormValues.imagesGallery)
        for (let key of imageKey) {
          images.push({
            id: +key,
            url: productFormValues.imagesGallery[key][0].url,
          })
        }
        setImageState(images);
      }
  }, [id, productFormValues && productFormValues.imagesGallery]);

  /**
   * Fetches all collections for the current website the product belongs to
   */
  useEffect(() => {
    if (websiteId) {
      dispatch( getWebsiteCollections(websiteId) )
    }
  }, [websiteId]);

  useEffect(() => {
    const length = images.reduce((acc: number, currentItem: any) => currentItem.isRemoved ? acc : ++acc, 0)

    if (length === MAXIMUM_NUMBER_OF_IMAGES) {
      setDisabledUpload(true);
      return;
    }
    setDisabledUpload(false);
  }, [images]);

  useEffect(() => {
    if (productType === 'digital_product_or_service') {
      const clearedFields = ['width', 'height', 'length', 'inventory'];
      clearedFields.forEach((field: string) => {
        dispatch(clearFields('product', false, false, field));
      });
      resArr.length &&
        resArr.forEach((optionName: string) => {
          clearedFields.forEach((field: string) => {
            dispatch(clearFields('tableData', false, false, `${optionName}.${field}`));
          });
        });
    }
  }, [productType, dispatch]);

  const getProductType = (productType: string) => {
    setProductType(productType);
  };

  const getCustomData = () => getDataWithCustomField(chips, names, tableDataFormValues, resArr);

  const getBodyShippingOptions = () => {
    if (productType === 'physical_product') {
      return {
        isPhysicalProduct: true,
        shippingType: productShippingOptions?.shippingType,
        isLocalPickup: productShippingOptions?.isLocalPickup,
        isLocalDelivery: productShippingOptions?.isLocalDelivery,
        deliveryFee: productShippingOptions?.deliveryFee,
        note: productShippingOptions?.note,
      };
    } else {
      return {
        isPhysicalProduct: false,
        isDigitalProduct: productShippingOptions?.productCategory === 'isDigitalProduct',
        note: productShippingOptions?.note,
      };
    }
  };

  const prepareImagesGallery = () => {
    let newGallery: any = [];
    let busyKeys: any = [];

    images.forEach((item: any) => {
      if (item && item.url && item.isRemoved) {
        newGallery.push({
          image: '',
          key: +item.id,
        })
        return
      }
      if (item && item.url) {
        busyKeys.push(+item.id)
      }
    })

    images.forEach((item: any) => {
      if (item && item.src) {
        const removedImageIndex = newGallery.findIndex((item: any) => item.image === '')

        if (removedImageIndex > -1) {
          newGallery[removedImageIndex].image = item.src
          return;
        }

        for (let freeIndex = 0; freeIndex <= 3; freeIndex++) {
          if (!busyKeys.includes(freeIndex)) {
            newGallery.push({
              image: item.src,
              key: freeIndex,
            })
            busyKeys.push(freeIndex)
            return
          }
        }
      }
    })

    return newGallery
  }

  const submit = (e: SyntheticEvent): void => {
    e.preventDefault();

    const bodyForAdd = {
      ...productFormValues,
      websiteCollection: collectionName ? { collectionName } : null,
      imagesGallery: prepareImagesGallery(),
      shippingOptions: getBodyShippingOptions(),
    };
    
    /** Reuse bodyForAdd object to avoid DRY */
    const bodyForEdit = {
      ...bodyForAdd,
      isVariousSizes,
      id,
    };

    if (id) {
      dispatch(
        hasCustomFields
          ? editProductWithCustomFields({
            ...bodyForEdit,
            ...getCustomData(),
          })
          : editProduct({
            ...bodyForEdit,
            productChoices: {},
            productOptions: {},
          })
      );
    } else {
      dispatch(
        hasCustomFields
          ? addProductWithCustomFields({
            ...bodyForAdd,
            ...getCustomData(),
            isVariousSizes,
          })
          : addProduct({ ...bodyForAdd })
      );
    }
  };

  const goBack = () => {
    history.goBack();
  };

  const handleChangeImage = (uploadImage: File) => {
    setUploadedImageData(uploadImage)
  };

  const handleCropImage = (file: any) => {
    const newImages = [...images, file]
    setImageState(newImages);
  }

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChangeCurrentImage = (currentImage: {}) => {
    setCurrentImage(currentImage);
  };

  const handleCollectionChange = (collectionName: string) => {
    setCollectionName(collectionName ? collectionName.trim() : '');
  };

  const handleChangeErrors = (error: string) => {
    setError(error);
  };

  const handleShowCustomOptions = () => {
    setShowedCustomOptions(!showedCustomOptions);
  };

  const handleRemoveImage = (e: React.MouseEvent<HTMLSpanElement>, removedImage: any) => {
    e.stopPropagation();

    const newImages = [...images];
    const i = images.findIndex((item: any) => item.id === removedImage.id)
    if (i > -1) {
      if (newImages[i].src) { // for new uploading files
        newImages.splice(i, 1)
      } else {
        newImages[i].isRemoved = true; // for files from server image galery
      }
    }
    setImageState(newImages)

    if (removedImage.id === currentImage.id) {
      setCurrentImage({});
    }
  }

  const formValid =
    productFormValues &&
    productFormValues.name &&
    productFormValues.inventory >= 0 &&
    productFormValues.price &&
    productFormValues.description &&
    productFormValues.width &&
    productFormValues.length &&
    productFormValues.height;

  const validFuncForTableData = (data: TableDataT, isVariousSizes: boolean) => {
    const actualArr = Object.entries(data).filter((optionData: [string, TableDataObjectT]) => {
      return resArr.find((optionName: string) => optionData[0] === optionName);
    });
    const validationBoolArr = actualArr.map((optionData: [string, TableDataObjectT]) => {
      const validRuleForDisVariousSizes = !!(optionData[1].price && optionData[1].inventory);
      if (isVariousSizes) {
        const validRuleForVariousSizes = !!(
          optionData[1].price &&
          optionData[1].inventory &&
          optionData[1].width &&
          optionData[1].length &&
          optionData[1].height
        );
        return validRuleForVariousSizes;
      }
      return validRuleForDisVariousSizes;
    });
    return validationBoolArr.length === resArr.length && validationBoolArr.indexOf(false) === -1;
  };

  const notDisabled = () => {
    if (hasCustomFields) {
      if (isVariousSizes) {
        return (
          productFormValues &&
          productFormValues.name &&
          productFormValues.price &&
          productFormValues.description &&
          tableDataFormValues &&
          validFuncForTableData(tableDataFormValues, isVariousSizes)
        );
      }
      return (
        productFormValues &&
        productFormValues.width &&
        productFormValues.length &&
        productFormValues.height &&
        tableDataFormValues &&
        validFuncForTableData(tableDataFormValues, isVariousSizes)
      );
    }
    return formValid;
  };

  const prepareVariantValidaion = (rules: any[]) => {
    return isVariousSizes ? undefined : addUspsRules(rules)
  }

  const renderProductDescription = () => {
    return (
      <FormContainer>
        <FormTitle>Description</FormTitle>
        <form>
          <ProductInfo>
            <FormInput
              placeholder="Product Name"
              type="text"
              name="name"
              label="Product Name: "
              customBg="#F8FAFB"
              validate={validations.productName}
              required
            />
            <InputsWrapper>
              <FormInput
                placeholder="Inventory"
                type="number"
                name="inventory"
                label="Inventory: "
                isNeedHelp
                title={`How much inventory do you have for this product? Use this feature to
                  ensure that customers can't buy products that you don't have in stock`}
                validate={validations.inventory}
                size={'sm'}
                step="any"
                customBg="#F8FAFB"
                disabled={isDigitalOrServiceProduct || hasCustomFields}
                required={!isDigitalOrServiceProduct}
              />
              <FormInput
                placeholder="Price"
                type="number"
                name="price"
                label="Price: "
                validate={validations.price}
                size={'sm'}
                step="any"
                customBg="#F8FAFB"
                required
              />
            </InputsWrapper>
            <FormArea
              label="Product Description: "
              name="description"
              validate={validations.productDescription}
              required
            />
            <CollectionAutocomplete
              defaultCollection={productCollection}
              collections={collections}
              onCollectionChange={handleCollectionChange}
            />
          </ProductInfo>
          {productErrors && Array.isArray(productErrors)
            ? productErrors.map(
              (error, index) =>
                !error.isShipping && (
                  <ProductErrorMessage key={index}>{error.message}</ProductErrorMessage>
                )
            )
            : productErrors && <ProductErrorMessage>{productErrors}</ProductErrorMessage>}
          <ShippingOptionsForm id={id} getProductType={getProductType} />
          {(isPhysicalProduct && isUspsPriorityMail) && (
              <>
                <SmTitle>{`Product Size: *`}</SmTitle>
                <InputsWrapper style={{ flexWrap: 'wrap' }}>
                  <FormInput
                    placeholder="Width"
                    type="number"
                    name="width"
                    label="Width: (inches)"
                    validate={prepareVariantValidaion(validations.width)}
                    size={'sm'}
                    step="any"
                    disabled={isVariousSizes}
                    customBg="#F8FAFB"
                  />
                  <FormInput
                    placeholder="Height"
                    type="number"
                    name="height"
                    label="Height: (inches)"
                    validate={prepareVariantValidaion(validations.height)}
                    size={'sm'}
                    step="any"
                    disabled={isVariousSizes}
                    customBg="#F8FAFB"
                  />
                  <FormInput
                    placeholder="Length"
                    type="number"
                    name="length"
                    label="Length: (inches)"
                    validate={prepareVariantValidaion(validations.length)}
                    size={'sm'}
                    step="any"
                    disabled={isVariousSizes}
                    customBg="#F8FAFB"
                  />
                </InputsWrapper>
              </>
            )}
        </form>
        {renderActionButtons()}
      </FormContainer>
    );
  };

  const renderActionButtons = () => (
    <ButtonsContainer>
      <BackButton onClick={goBack}>Back</BackButton>
      {id ? (
        <SaveButton variant="contained" color="primary" onClick={submit} disabled={!notDisabled}>
          <SaveIcon />
          Save
        </SaveButton>
      ) : (
        <SaveButton variant="contained" color="primary" onClick={submit} disabled={!notDisabled}>
          Create
        </SaveButton>
      )}
    </ButtonsContainer>
  );

  return (
    <Container>
      <CropImageModal
        isOpen={isModalOpen}
        closeModal={closeModal}
        imageData={uploadedImageData}
        handleCropImage={handleCropImage}
        variant={'product'}
      />
      <Title>{id ? 'Edit a product' : 'Add a product'}</Title>
      <ContentContainer>
        <ContainerFlex>
          <ImagesContainer>
            <SmImgWrapper>
              {images.map((image: any, index: number) =>
              (!image.isRemoved &&
                <SmallImage
                  key={index}
                  style={{
                    backgroundImage: `url(${image.url || URL.createObjectURL(image) || defaultImg
                      })`,
                  }}
                  onClick={() => setCurrentImage(image)}
                >
                  <Remove onClick={(e) => handleRemoveImage(e, image)}>
                    <DeleteIcon />
                  </Remove>
                </SmallImage>
              )
              )}
            </SmImgWrapper>
            <ContainerColumn>
              {errors && Array.isArray(errors) ? (
                errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
              ) : (
                <ErrorMessage>{errors}</ErrorMessage>
              )}
              <UploadImgContainer style={{ border: error ? '1px solid red' : '1px solid #EAEDF0' }}>
                <PosterImage
                  currentImage={currentImage}
                  handleChangeImage={handleChangeImage}
                  handleChangeCurrentImage={handleChangeCurrentImage}
                  handleChangeErrors={handleChangeErrors}
                  isDisabled={isDisabledUpload}
                  setModalOpen={setModalOpen}
                />
              </UploadImgContainer>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </ContainerColumn>
          </ImagesContainer>
          {renderProductDescription()}
        </ContainerFlex>
        <ShowCustomOptionsButton onClick={handleShowCustomOptions}>
          <p>ADD VERSIONS OF THIS PRODUCT</p>
          {showedCustomOptions ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </ShowCustomOptionsButton>
        <div style={{ display: !showedCustomOptions ? 'none' : 'block' }}>
          <CustomFields changeData={setResArr} productType={productType} />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default reduxForm<FormProps, Props>({
  form: 'product',
})(Product);
