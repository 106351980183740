import React, { useState, useEffect } from 'react';
import { ApplyButton, ExportReportButton, ResetButton, ReportWrapper, ExportInfoWrapper, ExportErrorWrapper, DownloadReportButton } from '../accounting/report/styles';
import FilterSelect, { additionCashOutTypeOption } from '../accounting/report/FilterSelect';
import { prepareSelectOptions } from 'src/constants/profitsStatuses';
import { DatePickerInput } from 'src/components/common/DatePickerInput';
import { ExportReportIcon } from 'src/components/common/Icons';
import { useHistory, useLocation } from 'react-router';
import { prepareQueryParams, prepareUrl } from '../accounting/report/queryHelper';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { clearCashoutList } from 'src/ducks/cashout/actions';
import { prepareDownloadUrl, STRIPE_MIN_DATE } from 'src/ducks/cashout/urlHelper';
import accountingApi from 'src/api/accountingApi';
import { userStoreId } from 'src/ducks/user/selectors';

type ProfitFiltersProps = {
  loadData: (params: string) => void,
  resetFilter: () => void,
  idFilterByType?: boolean,
};

const ProfitFilters = (props: ProfitFiltersProps) => {
  const {
    loadData,
    resetFilter,
    idFilterByType = true,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState<string>('');
  const [reportUrl, setReportUrl] = useState<string>('');
  const minDate = new Date(STRIPE_MIN_DATE);

  const storeId = useSelector(userStoreId);
  const baseDownloadUrl = `api/order/profit/request-report/${storeId}`;
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [exportError, setExportError] = useState<string | null>(null);
  const [reportId, setReportId] = useState<string | null>(null);
  const [reportStatus, setReportStatus] = useState<string | null>(null);

  const options = prepareSelectOptions();

  const restoreExportButton = () => {
    setIsExporting(false);
    setReportId(null);
    setReportStatus(null);
  }

  useEffect(() => {
    const queryParams = prepareQueryParams(location.search);
    const from = queryParams.from ? parse(queryParams.from, 'MM/dd/yyyy', new Date()) : null;
    const to = queryParams.to ? parse(queryParams.to, 'MM/dd/yyyy', new Date()) : null;
    setStartDate(from);
    setEndDate(to);
    setSelectedType(queryParams.cashoutType);
    replaceDownloadUrl(queryParams);

    restoreExportButton();
  }, [location.search]);

  useEffect(() => {
    let intervalId: number;

    const fetchData = async () => {
      try {
        if (reportId) {
          let res: any = await accountingApi.getProfitReportStatus(storeId as number, reportId);
          if (res.status === 'succeeded') {
            setReportStatus('succeeded');
            clearInterval(intervalId);
          }
        }
      } catch (error) {
        console.log('Error loading report status', error);
      }
    };

    if (reportId) {
      intervalId = setInterval(() => {
        fetchData();
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [reportId]);

  const handleDownloadReport = () => {
    if (reportStatus === 'succeeded') {
      if (reportId) {
        accountingApi.getProfitReport(storeId as number, reportId)
          .then((response: any) => {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;

            const from = startDate !== null ? format(startDate, 'MM_dd_yyyy') : format(new Date(STRIPE_MIN_DATE), 'MM_dd_yyyy');
            const to = endDate !== null ? format(endDate, 'MM_dd_yyyy') : format(new Date(), 'MM_dd_yyyy');

            const filePrefix = 'Profits';
            let fileName = `${filePrefix}.csv`;
            if (startDate) {
              fileName = `${filePrefix} (${from} - ${to}).csv`;
            }
            link.setAttribute('download', fileName);

            document.body.appendChild(link);
            link.click();
          });
      }
    }
  };

  const handleChangeDates = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  let history = useHistory()
  const replaceUrl = (newQueryParams: any) => {
    let newUrl = prepareUrl(location, newQueryParams);
    dispatch(clearCashoutList());
    resetFilter();
    loadData(newQueryParams);
    history.push(newUrl)
  };

  const replaceDownloadUrl = (newQueryParams: any) => {
    const resUrl = prepareDownloadUrl(baseDownloadUrl, newQueryParams, new Date(STRIPE_MIN_DATE));
    setReportUrl(resUrl)
  };

  const handleChangeType = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(value as string);
  };

  const handleApply = () => {
    const newQueryParams = {
      from: startDate !== null ? format(startDate, 'MM/dd/yyyy') : startDate,
      to: endDate !== null ? format(endDate, 'MM/dd/yyyy') : endDate,
      cashoutType: selectedType
    }
    replaceUrl(newQueryParams);
  }

  const handleReset = () => {
    const newQueryParams = {
      from: null,
      to: null,
      cashoutType: additionCashOutTypeOption[0].type,
    }
    replaceUrl(newQueryParams);
  }

  const onExportReport = async () => {
    setIsExporting(true);
    setExportError(null);
    try {
      let reportIdResponse: any = await accountingApi.getProfitReportId(reportUrl);
      setReportId(reportIdResponse.reportId);

    } catch (e) {
      if (e as any && e.data && e.data.error) {
        setIsExporting(false)
        setExportError(e.data!.errors!.message)
      }
    }
  };

  const handleExportReport = () => {
    onExportReport();
  }

  return (
    <>
      <ReportWrapper>
        <DatePickerInput
          selected={startDate}
          onChange={handleChangeDates}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          minDate={minDate}
          maxDate={new Date()}
        />
        {idFilterByType &&
          <FilterSelect
            options={options}
            selectedValue={selectedType}
            handleChange={handleChangeType}
          />
        }
        <ApplyButton variant="contained" color="primary" onClick={handleApply}>
          Apply
        </ApplyButton>
        <ResetButton variant="contained" onClick={handleReset}>
          Reset
        </ResetButton>
        {!isExporting &&
          <ExportReportButton variant="contained" onClick={handleExportReport}>
            <ExportReportIcon />
            Export Report
          </ExportReportButton>
        }
        {(isExporting && reportStatus !== 'succeeded') &&
          <ExportReportButton variant="contained" disabled={true}>
            <ExportReportIcon />
            Report is in progress
          </ExportReportButton>
        }
        {reportStatus === 'succeeded' &&
          <DownloadReportButton variant="contained" color="primary" onClick={handleDownloadReport}>
            Download Report
          </DownloadReportButton>
        }
      </ReportWrapper>
      {(isExporting && reportStatus !== 'succeeded') &&
        <ExportInfoWrapper>Report generation in progress...</ExportInfoWrapper>
      }
      {exportError &&
        <ExportErrorWrapper>{exportError}</ExportErrorWrapper>
      }
    </>
  )
};

export default ProfitFilters;