import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Header } from 'src/containers/header';
import { Sidebar } from 'src/containers/sidebar';
import { Toolbar, Main } from './styles';

type MainLayoutProps = {
  children: React.ReactNode;
  isAuthenticated: boolean;
} & RouteComponentProps;

const MainLayout = ({ children, isAuthenticated }: MainLayoutProps) => {
  if (isAuthenticated) {
    return (
      <>
        <Header />
        <Sidebar />
        <Main>
          <Toolbar />
          {children}
        </Main>
      </>
    );
  }
  return <>{children}</>;
};

export default withRouter(MainLayout);
