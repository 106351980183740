import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';
import { EditOrderPayload } from 'src/ducks/orders/actions';
import { prepareUrlParams } from 'src/ducks/cashout/urlHelper';

export default {
  getOrderList: (
    websiteId: number,
    payload: any,
  ): AxiosPromise<AxiosResponse> => {
    const newUrl = prepareUrlParams(`api/order/list/${websiteId}`, payload);
    return httpService.get({
      url: newUrl,
    })
  },

  getOrder: (orderId: number): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/order/${orderId}`,
    }),

  editOrder: (data: EditOrderPayload): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/order/${data.orderId}`,
      data: {
        trackingNumber: data.trackingNumber,
        status: data.status,
      },
    }),
};
