import { styled } from '@material-ui/core/styles';

export const Wrapper = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const SuccessPageWrapper = styled('div')({
  padding: 50,
  width: 300,
  backgroundColor: '#FFFFFF',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -2px 4px 20px rgba(68, 86, 108, 0.16)',
  borderRadius: 8,
  textAlign: 'center',
});

export const SuccessText = styled('div')({
  marginTop: 30,
});
