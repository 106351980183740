import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableCellProps,
  Button,
  ButtonProps,
} from '@material-ui/core';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const TableWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Table {...otherProps} />)({
    width: '100%',
  })
);

export const StudentContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    padding: 40,
  })
);

export const Head = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <TableHead {...otherProps} />)({
    textTransform: 'uppercase',
    // color: ({ theme }: WithTheme) => theme.customPalette.text.primary
  })
);

export const StyledBodyTableRow = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <TableRow {...otherProps} />)({
    height: 90,
    '&:nth-of-type(odd)': {
      backgroundColor: '#F8FAFB',
    },
  })
);

export const StyledTableCell = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    '& > div': {
      cursor: 'pointer',
    },
    position: 'relative',
  })
);

export const StyledTableCellTitle = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    maxWidth: 450,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  })
);

export const StyledTableCellInfo = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    wordBreak: 'break-word',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    '& > div': {
      cursor: 'pointer',
    },
  })
);

export const ResetPinButton = withTheme(
  styled(({ theme, ...otherProps }: ButtonProps & WithTheme) => <Button {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.blue,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.blue,
    textTransform: 'none',
    padding: '5px 20px',
    width: '100%',
    borderRadius: 5,
    '&:hover': {
      backgroundColor: '#C7EBFF',
    },
    '&:disabled': {
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.secondary.disabledColor,
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.secondary.disabledBg,
    },
  })
);

export const ActionContainer = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    '&  button': {
      height: 54,
      width: 54,
      '&:hover': {
        backgroundColor: '#EAEDF0',
      },
    },
  })
);
