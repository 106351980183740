import { sessionService } from 'src/services';
import interceptors from 'src/redux/store/middlewares/Interceptors';
import SSEventStream from './SSEventStream';
import { APP_INITIALIZER, LOGOUT } from 'src/ducks/auth/constants';

type Action = {
  type: string;
  payload?: any;
};

export default (store: any) => (next: any) => (action: Action) => {
  if (action.type === APP_INITIALIZER) {
    sessionService.initFromLocalStorage();
    interceptors.init(store);
  }

  if (action.type === LOGOUT) {
    sessionService.destroySession();
    return undefined;
  }

  return next(action);
};
