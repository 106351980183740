import {
  GET_PRODUCTS_LIST,
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  GET_PRODUCT,
  SET_IMAGES,
  CLEAR_PRODUCTS_LIST,
  ADD_PRODUCT_WITH_CUSTOM_FIELDS,
  EDIT_PRODUCT_WITH_CUSTOM_FIELDS,
} from './constants';
import {
  GetProductsParams,
  editProductT,
  addProductT,
} from '../../constants/productsTypes';


export function getProductsList(getProductsParams: GetProductsParams) {
  return {
    type: GET_PRODUCTS_LIST,
    payload: getProductsParams,
  };
}

export function addProduct(addProductForm: addProductT) {
  return {
    type: ADD_PRODUCT,
    payload: addProductForm,
  };
}

export function addProductWithCustomFields(customFieldsData: addProductT) {
  return {
    type: ADD_PRODUCT_WITH_CUSTOM_FIELDS,
    payload: customFieldsData,
  };
}

export function removeProduct(id: number) {
  return {
    type: DELETE_PRODUCT,
    payload: id,
  };
}

export function editProduct(product: editProductT) {
  return {
    type: EDIT_PRODUCT,
    payload: product,
  };
}

export function editProductWithCustomFields(customFieldsData: editProductT) {
  return {
    type: EDIT_PRODUCT_WITH_CUSTOM_FIELDS,
    payload: customFieldsData,
  };
}

export function getProduct(id: string) {
  return {
    type: GET_PRODUCT,
    payload: id,
  };
}

export function setImages(images: []) {
  return {
    type: SET_IMAGES,
    payload: images,
  };
}

export function clearProductsList() {
  return { type: CLEAR_PRODUCTS_LIST };
}


