import React, { useEffect, useState } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormSelectField } from 'src/components/common/selects';
import {
  getCashout,
  editCashout,
  createCashout,
  getCashoutList,
  clearCashoutList,
} from 'src/ducks/cashout/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { cashoutSelector, cashoutListSelector, errorSelector } from 'src/ducks/cashout/selectors';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { isUserTeacher } from 'src/ducks/user/selectors';
import { SaveIcon } from 'src/components/common/Icons';
import { cashoutTypes } from 'src/constants/cashOutTypes';
import { CashoutFormsByTypes } from './cashoutFormsByTypes';
import { StudentFormLabel } from 'src/components/pages/students/studentForm/styles';
import { BackButton } from 'src/components/pages/orders/viewOrder/styles';
import {
  MainBlock,
  ActionButtonsContainer,
  Label,
  FormControlWrapper,
  CashoutFormContainer,
  AddCashoutButton,
} from './styles';

export type FormProps = {
  fullName: string;
  amount: number;
  note: string;
  organizationName: string;
  organizationCityAndState: string;
  organizationAddress: string;
  organizationTaxId: number;
  organizationZipCode: number;
};

type CashoutProps = {};

type Props = InjectedFormProps<FormProps, CashoutProps, {}>;

const Cashout = ({ handleSubmit, valid }: Props) => {
  const history = useHistory();
  const { id: cashoutId } = useParams();
  const dispatch = useDispatch();
  const cashout = useSelector(cashoutSelector);
  const cashoutList = useSelector(cashoutListSelector);
  const isTeacher = useSelector(isUserTeacher);
  const errors = useSelector(errorSelector);
  const [selectedCashoutType, setSelectedCashoutType] = useState<string>('');
  const pendingStatus = 'pending';
  const AttentionStatus = 'attention_changes';
  const isAllowEditCashout = isTeacher
    ? cashout && cashoutId
      ? cashout.status === pendingStatus || cashout.status === AttentionStatus
      : !cashoutId
    : false;
  const cashoutType = cashout?.type;

  useEffect(() => {
    dispatch(getNewNotifications());
    if (cashoutId) {
      dispatch(getCashout(cashoutId));
    } else {
      dispatch(getCashoutList());
    }

    if (cashoutType) {
      setSelectedCashoutType(cashoutType);
    } else {
      setSelectedCashoutType(cashoutTypes.classWallet);
    }

    return () => {
      dispatch(clearCashoutList());
    };
  }, [cashoutId, cashoutType]);

  const handleGoBack = () => {
    history.push({
      pathname: '/accounting',
      search: '?tab=2',
      state: { activeTabCashout: true },
    });
  };

  const calculateBlockWidth = () => {
    const smallWidth = 350;
    const smallWidthEditCashout = 720;
    const mediumWidth = 628;
    const mediumWidthEditCashout = 920;
    const largeWidth = 750;
    const largeWidthCashout = 1090;
    const isWidthWithNeedChangesText = cashoutId && cashout?.needChangesText;

    if (selectedCashoutType === cashoutTypes.mySchool) {
      return isWidthWithNeedChangesText ? mediumWidthEditCashout : mediumWidth;
    } else if (selectedCashoutType === cashoutTypes.classWallet) {
      return isWidthWithNeedChangesText ? smallWidthEditCashout : smallWidth;
    } else if (selectedCashoutType === cashoutTypes.organization) {
      return isWidthWithNeedChangesText ? largeWidthCashout : largeWidth;
    }

    return mediumWidth;
  };

  const calculateDataForCashout = (values: FormProps) => {
    switch (selectedCashoutType) {
      case cashoutTypes.mySchool:
        return {
          amount: values.amount,
          note: values.note,
          fullName: values.fullName,
          type: selectedCashoutType,
        };

      case cashoutTypes.classWallet:
        return {
          amount: values.amount,
          note: values.note,
          type: selectedCashoutType,
        };

      case cashoutTypes.organization:
        return {
          recipientName: values.organizationName,
          amount: values.amount,
          note: values.note,
          organizationCityAndState: values.organizationCityAndState,
          organizationAddress: values.organizationAddress,
          organizationTaxId: values.organizationTaxId,
          organizationZipCode: values.organizationZipCode,
          type: selectedCashoutType,
        };

      default:
        return {
          amount: values.amount,
          note: values.note,
          fullName: values.fullName,
          type: selectedCashoutType,
        };
    }
  };

  const handleChangeCashout = (values: FormProps) => {
    const data = calculateDataForCashout(values);

    if (cashoutId) {
      dispatch(editCashout(cashoutId, data));
    } else {
      dispatch(createCashout({ ...data, type: selectedCashoutType }));
    }
  };

  const renderActionButtons = () => {
    return (
      <ActionButtonsContainer>
        <BackButton onClick={handleGoBack}>Back</BackButton>
        {isAllowEditCashout && (
          <AddCashoutButton
            type="submit"
            variant="contained"
            color="primary"
            disabled={!valid}
            isSaveButton={!!cashoutId}
          >
            {cashoutId ? (
              <>
                <SaveIcon />
                Save
              </>
            ) : (
              '+ Create Cash out'
            )}
          </AddCashoutButton>
        )}
      </ActionButtonsContainer>
    );
  };

  const handleChangeCashoutType = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCashoutType(value as string);
  };

  const CashoutSelect = () => {
    const options = [
      {
        name: 'My School',
        value: 'my_school',
      },
      {
        name: 'Organization',
        value: 'organization',
      },
      {
        name: 'ClassWallet',
        value: 'class_wallet',
      },
    ];
    const cashoutFormBlock = document.getElementById('form-block-cashout');
    const widthFormBlock = cashoutFormBlock?.clientWidth;

    return (
      <FormControlWrapper disabled={!isAllowEditCashout} blockWidth={widthFormBlock}>
        <Label>Cash Out Type</Label>
        <FormSelectField
          name="cashoutType"
          options={options}
          selectedValue={selectedCashoutType}
          handleChange={handleChangeCashoutType}
        />
      </FormControlWrapper>
    );
  };

  const calculateTotalCashout = () => {
    const totalFundsCashOut = cashoutId
      ? cashout?.totalFundsCashOut
      : cashoutList?.totalFundsCashOut;

    if (cashoutId && cashout?.amount && totalFundsCashOut !== undefined) {
      return totalFundsCashOut + cashout?.amount;
    }

    return totalFundsCashOut;
  };

  const renderCashoutForm = () => {
    const totalFundsCashOut = calculateTotalCashout();

    if (totalFundsCashOut) {
      return (
        <CashoutFormsByTypes
          needChangesText={cashout && cashoutId ? cashout.needChangesText : null}
          isAllowEditCashout={isAllowEditCashout}
          totalFundsCashOut={totalFundsCashOut}
          selectedCashoutType={selectedCashoutType}
        />
      );
    }

    return null;
  };

  const renderCashoutTitle = () => {
    const addCashout = 'Add A New Cash Out';
    const editCashout = 'Edit Cash Out';
    const viewCashout = 'Cash Out Details';

    if (isTeacher) {
      if (cashoutId) {
        if (isAllowEditCashout) {
          return editCashout;
        } else {
          return viewCashout;
        }
      } else {
        return addCashout;
      }
    }

    return viewCashout;
  };

  return (
    <CashoutFormContainer>
      {cashout || !cashoutId ? (
        <>
          <StudentFormLabel>{renderCashoutTitle()}</StudentFormLabel>
          <MainBlock blockWidth={calculateBlockWidth()}>
            {errors && Array.isArray(errors) ? (
              errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
            ) : (
              <ErrorMessage>{errors}</ErrorMessage>
            )}
            <CashoutSelect />
            <form onSubmit={handleSubmit(handleChangeCashout)}>
              {renderCashoutForm()}
              {renderActionButtons()}
            </form>
          </MainBlock>
        </>
      ) : (
        `Cash out doesn't exist`
      )}
    </CashoutFormContainer>
  );
};

export default reduxForm<FormProps, CashoutProps, {}>({
  form: 'cashout',
})(Cashout);
