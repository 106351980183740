export const appName = 'edCorps';
// export const edCorpsDomain = "https://dev.edcorps.com:8080/" // DEV
export const edCorpsDomain = 'https://127.0.0.1:8080/';
export const regexpPass = /^(?=.*\d)(?=.*[a-zA-z])(?=.*[!@#$%^&*()[\]\-,.?"':{}|<>~+=;_`/\\]).{8,}$/;
export const regexpName = /^[a-zA-Z|\s]{3,}$/;
export const regexpPhone = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/; ///[+][\d]{8,11}$/
export const regexpLastName = /^[A-Z][a-zA-Z]{0,50}\s{0,1}[a-zA-Z]{1,50}$/;
export const regexpPrice = /^(\d{1,5})(\.\d{1,4})?$/;
export const regexpZip = /^[0-9]{5}$/;
export const regexpDescription = /^([\w\s\\.!?_:;,'"<>@%&$#()-]){1,400}$/;
export const regexpTitle = /^([\w\s\\.!?_:;,'"<>@%&$#()-]){1,40}$/;

export const config = {
  headers: {
    'Content-Type': 'application/json',
    //'Authorization' : 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1NDMzOTgwNjksImV4cCI6MTU0MzQ4NDQ2OSwidWlkIjo1MzgsInR5cGUiOiJzaW1wbGUifQ.K3f6N4kUefKf0swIhUWYmjRApMhITUkqkWNYd7hILmg'
  },
  regexpValidatePass: regexpPass,
  regexpName: regexpName,
  regexpPhone: regexpPhone,
  regexpLastName: regexpLastName,
  regexpPrice: regexpPrice,
  regexpZip: regexpZip,
  regexpTitle: regexpTitle,
  regexpDescription: regexpDescription,

  login: `${edCorpsDomain}api/v1/users/login?`,
  signUp: `${edCorpsDomain}api/v1/users`,
  signOut: `${edCorpsDomain}api/v1/users/logout?`,
};
