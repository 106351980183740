export default {
  resetPin: {
    main: 'Are you sure you want to generate a new Pin-code?',
    hintText: 'The old pin-code will no longer work for this user',
    successfulText: 'Your new pin code for student login',
  },
  deleteStudent: {
    mainText: 'Are you sure you want to remove a student?',
  },
  deleteProduct: {
    mainText: 'Are you sure you want to remove a product?',
  },
  viewOrder: {
    mainText: 'Are you sure you want to leave the page?',
    warningText: 'Unsaved changes will be lost',
  },
  deleteCashout: {
    mainText: 'Are you sure you want to remove the cash out request?',
  },
};
