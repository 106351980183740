import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FullOrder } from 'src/ducks/orders/orders';
import { mapOrderBox, prepareNextDeliveryStatus } from 'src/utils/helpers';
import { errorSelector } from 'src/ducks/orders/selectors';
import { FormInputField } from 'src/components/common/inputs';
import validations from 'src/components/common/inputs/validations';
import { findMappedDeliveryStatus, findMappedShippingMethod } from 'src/utils/helpers';
import { QuestionHintIcon } from 'src/components/common/Icons';
import { Hint } from 'src/components/common/hint';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { LabelWrapper } from 'src/components/pages/website/form-website/style';
import {
  TrackingWrapper,
  StatusWrapper,
  StyledSwitch,
  TrackingNumber,
  StatusWrapperLabel,
  ColoredDot,
  ViewOrderInfo,
  NoteOrderWrapper,
  InfoTitle,
  ShippingAddressWrapper,
  DescriptionWrapper,
  SuitableBox,
  DeliveryWrapper,
} from './styles';
import { LOCAL_DELIVERY, LOCAL_PICKUP, NON_USPS_SHIPPING, NOT_APPLICABLE, SHIPPING_NOT_AVAILABLE, USPS_MULTIPLE, USPS_NON_PRIORITY_MAIL, USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

export type FormProps = {
  trackingNumber: string;
};

type DeliveryInfo = {
  order: FullOrder;
  isAllowEditPage: boolean;
  checked?: any;
  setChecked?: any;
};

type Props = InjectedFormProps<FormProps, DeliveryInfo>;

const DeliveryInfo = ({ destroy, order, isAllowEditPage, checked, setChecked }: Props & DeliveryInfo) => {
  const dispatch = useDispatch();
  const inputRef = useRef<any>(null);
  const error = useSelector(errorSelector);

  useEffect(() => {
    if (!checked && order.status !== 'shipping' && order.status !== 'completed') {
      dispatch(destroy());
    }

    if (checked) {
      inputRef.current?.focus();
    }
  }, [checked]);

  const handleSwitch = () => setChecked(!checked);

  const mappedOrderBox = () => {
    const defaultBoxText =
      'You can use any box that you want!';

    if (order.shippingContainer) {
      return mapOrderBox(order.shippingContainer);
    }
    return defaultBoxText;
  };

  const renderOrderNote = () => (
    <NoteOrderWrapper>
      <InfoTitle>Note About Order</InfoTitle>
      <DescriptionWrapper>
        <span>{order.note}</span>
      </DescriptionWrapper>
    </NoteOrderWrapper>
  );

  const renderDeliveryBlock = () => {
    const currentShippingMethod = findMappedShippingMethod(order);
    const titleHint = 'The dimensions of this box are suitable for this order';

    return (
      <DeliveryWrapper>
        <div>
          <InfoTitle>Delivery Method</InfoTitle>
          <DescriptionWrapper>
            <span>{currentShippingMethod}</span>
          </DescriptionWrapper>
        </div>
        <SuitableBox>
          <LabelWrapper>
            <label>Box Size</label>
            <Hint placement="top" text={titleHint}>
              <QuestionHintIcon />
            </Hint>
          </LabelWrapper>
          <DescriptionWrapper>
            <span>{mappedOrderBox()}</span>
          </DescriptionWrapper>
        </SuitableBox>
      </DeliveryWrapper>
    );
  };

  const renderShippingAddressBlock = () => (
    <ShippingAddressWrapper>
      <InfoTitle>Shipping Address</InfoTitle>
      <DescriptionWrapper>
        Address: <span>{order.address}</span>
      </DescriptionWrapper>
      <DescriptionWrapper>
        City: <span>{order.city}</span>
      </DescriptionWrapper>
      <DescriptionWrapper>
        State: <span>{order.state}</span>
      </DescriptionWrapper>
      <DescriptionWrapper>
        Country: <span>United States</span>
      </DescriptionWrapper>
      <DescriptionWrapper>
        Zip Code: <span>{order.zipCode}</span>
      </DescriptionWrapper>
    </ShippingAddressWrapper>
  );

  const renderTrackingBlock = () => {
    const currentDeliveryStatus = findMappedDeliveryStatus(order.status);
    const nextDeliveryStatus = prepareNextDeliveryStatus(order.status);

    const titleHint = 'This will be labeled as (whatever the USPS term is) on your USPS label';
    const isDeliveredStatus = currentDeliveryStatus?.shownStatus === 'Delivered';
    const isFulfilledStatus = currentDeliveryStatus?.shownStatus === 'Fulfilled';
    const isPendingStatus = currentDeliveryStatus?.shownStatus === 'Pending';

    const isDisplayTrackingNumber = (isPendingStatus || isFulfilledStatus || isDeliveredStatus)
      && (order.shippingMethod === USPS_PRIORITY_MAIL || order.shippingMethod === USPS_MULTIPLE);
    const isDisabledTrackingNumber = !isAllowEditPage || isDeliveredStatus
      || (isPendingStatus && !checked);

    const isDisplayFulfilledSwitch =
      isPendingStatus ||
      (isFulfilledStatus &&
        (
          order.shippingMethod === USPS_PRIORITY_MAIL ||
          order.shippingMethod === USPS_MULTIPLE
        )
      );
    const isDisabledFulfilledSwitch =
      !isAllowEditPage ||
      (isFulfilledStatus &&
        (order.shippingMethod === USPS_PRIORITY_MAIL ||
          order.shippingMethod === USPS_MULTIPLE
        ));

    const isDisplayDeliveredSwitch =
      (isFulfilledStatus || isDeliveredStatus) &&
      (order.shippingMethod === USPS_NON_PRIORITY_MAIL ||
        order.shippingMethod === NON_USPS_SHIPPING ||
        order.shippingMethod === LOCAL_PICKUP ||
        order.shippingMethod === LOCAL_DELIVERY ||
        order.shippingMethod === SHIPPING_NOT_AVAILABLE ||
        order.shippingMethod === NOT_APPLICABLE);

    const isDisabledDeliveredSwitch =
      !isAllowEditPage ||
      (isDeliveredStatus &&
        (order.shippingMethod === USPS_NON_PRIORITY_MAIL ||
          order.shippingMethod === NON_USPS_SHIPPING ||
          order.shippingMethod === LOCAL_PICKUP ||
          order.shippingMethod === LOCAL_DELIVERY ||
          order.shippingMethod === SHIPPING_NOT_AVAILABLE ||
          order.shippingMethod === NOT_APPLICABLE));

    return (
      <TrackingWrapper>
        {isDisplayTrackingNumber && (
          <TrackingNumber>
            <LabelWrapper>
              <label>Tracking Number</label>
              <Hint placement="top" text={titleHint}>
                <QuestionHintIcon />
              </Hint>
            </LabelWrapper>
            <FormInputField
              placeholder="Enter the tracking number..."
              name="trackingNumber"
              type="text"
              ref={inputRef}
              disabled={isDisabledTrackingNumber}
              maxLength={inputRestrictions.MAX_TRACKING_NUMBER}
              validate={validations.trackingNumber}
            />
          </TrackingNumber>
        )}
        <ErrorMessage>{error}</ErrorMessage>
        <StatusWrapper>
          {isDisplayFulfilledSwitch && (
            <div>
              <StatusWrapperLabel>
                Fulfilled
              </StatusWrapperLabel>
              <StyledSwitch
                disabled={isDisabledFulfilledSwitch}
                checked={isFulfilledStatus || isDeliveredStatus || checked}
                onChange={handleSwitch}
                name="checked"
              />
            </div>
          )}
          {isDisplayDeliveredSwitch && (
            <div>
              <StatusWrapperLabel>Delivered</StatusWrapperLabel>
              <StyledSwitch
                disabled={isDisabledDeliveredSwitch}
                checked={isDeliveredStatus || checked}
                onChange={handleSwitch}
                name="checked"
              />
            </div>
          )}
          <div>
            <StatusWrapperLabel>Status</StatusWrapperLabel>
            <div>
              <ColoredDot color={checked ? nextDeliveryStatus?.color : currentDeliveryStatus?.color} />
              {(checked && nextDeliveryStatus?.shownStatus) || currentDeliveryStatus?.shownStatus || order.status}
            </div>
          </div>
        </StatusWrapper>
      </TrackingWrapper>
    );
  };

  return (
    <ViewOrderInfo>
      {renderTrackingBlock()}
      {renderShippingAddressBlock()}
      {renderDeliveryBlock()}
      {renderOrderNote()}
    </ViewOrderInfo>
  );
};

export default reduxForm<FormProps, DeliveryInfo>({
  form: 'order',
})(DeliveryInfo);
