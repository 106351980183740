import React from 'react';
import { withTheme, WithTheme, styled } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@material-ui/core';

type RoleButton = {
  onClick: any;
  isActive: boolean;
};

export const ErrorMessage = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.error,
  }),
);

export const FormContainer = styled('div')({
  width: 350,
});

export const ContinueButton = withTheme(
  styled((props: ButtonProps & WithTheme) => <Button {...props} />)({
    width: '100%',
    height: 40,
    boxShadow: '0px 8px 16px rgba(0, 141, 186, 0.19)',
    textTransform: 'capitalize',
    fontSize: 15,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    },
    '&:disabled': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledBg,
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledColor,
    },
  }),
);

export const ContinueButtonContainer = styled('div')({
  width: '100%',
  paddingTop: 22,
  '& svg': {
    paddingRight: 7,
  },
});

export const AdditionalInfo = withTheme(
  styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    height: 15,
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
    fontSize: 13,
    '& p': {
      margin: 0,
    },
    '& > a': {
      color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
      textDecoration: 'none',
      '&:hover': {
        color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  }),
);

export const InputFieldContainer = styled('div')({
  '& > div': {
    padding: 0,
    paddingBottom: 15,
  },
});

export const RoleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 30,
  marginTop: -23,
  width: 225,
  height: 40,
  backgroundColor: '#F8FAFB',
});

export const RoleButton = styled(
  ({ isActive, ...otherProps }: RoleButton) => <Button disableRipple {...otherProps} />,
)({
  width: 109,
  height: 34,
  backgroundColor: ({ isActive }: RoleButton) => isActive ? '#008DBA' : 'transparent',
  borderRadius: ({ isActive }: RoleButton) => isActive ? 5 : 0,
  color: ({ isActive }: RoleButton) => isActive ? '#FFFFFF' : '#44566C',
  fontSize: ({ isActive }: RoleButton) => isActive ? 15 : 13,
  textTransform: 'capitalize',
  fontWeight: 400,
  '&:hover': {
    backgroundColor: ({ isActive }: RoleButton) => isActive ? '#008DBA' : 'transparent',
  },
});
