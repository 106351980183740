import { styled } from '@material-ui/core/styles';

export const TotalBonuses = styled('span')({
  color: '#8697A8',
});

export const HelpIconWrapper = styled('span')({
  position: 'absolute',
  top: 42,
});
