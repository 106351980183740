import React, { useState, useEffect } from 'react';
import { ApplyButton, ExportReportButton, ResetButton, ReportWrapper } from './styles';
import FilterSelect, { additionCashOutTypeOption } from './FilterSelect';
import { DatePickerInput } from 'src/components/common/DatePickerInput';
import { ExportReportIcon } from 'src/components/common/Icons';
import { useHistory, useLocation } from 'react-router';
import { prepareQueryParams, prepareUrl } from './queryHelper';
import { format, parse } from 'date-fns';
import { useDispatch } from 'react-redux';
import { clearCashoutList } from 'src/ducks/cashout/actions';
import { prepareDownloadUrl, STRIPE_MIN_DATE } from 'src/ducks/cashout/urlHelper';
import cashOutTypes from 'src/constants/cashOutTypes';

type CashOutReportProps = {
  loadData: (params: string) => void,
  resetFilter: () => void,
  baseDownloadUrl: string,
  idFilterByType?: boolean,
  onExportReport: (reportUrl: string, startDate: Date | null, endDate: Date | null, type?: string) => void,
};

const CashOutReport = (props: CashOutReportProps) => {
  const {
    loadData,
    resetFilter,
    baseDownloadUrl,
    idFilterByType = true,
    onExportReport
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedType, setSelectedType] = useState<string>('');
  const [reportUrl, setReportUrl] = useState<string>('');

  const minDate = new Date(STRIPE_MIN_DATE);

  useEffect(() => {
    const queryParams = prepareQueryParams(location.search);
    const from = queryParams.from ? parse(queryParams.from, 'MM/dd/yyyy', new Date()) : null;
    const to = queryParams.to ? parse(queryParams.to, 'MM/dd/yyyy', new Date()) : null;
    setStartDate(from);
    setEndDate(to);
    setSelectedType(queryParams.cashoutType);
    replaceDownloadUrl(queryParams);
  }, [location.search]);

  const handleChangeDates = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  let history = useHistory()
  const replaceUrl = (newQueryParams: any) => {
    let newUrl = prepareUrl(location, newQueryParams);
    dispatch(clearCashoutList());
    resetFilter();
    loadData(newQueryParams);
    history.push(newUrl)
  };

  const replaceDownloadUrl = (newQueryParams: any) => {
    const resUrl = prepareDownloadUrl(baseDownloadUrl, newQueryParams);
    setReportUrl(resUrl)
  };

  const handleChangeType = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedType(value as string);
  };

  const handleApply = () => {
    const newQueryParams = {
      from: startDate !== null ? format(startDate, 'MM/dd/yyyy') : startDate,
      to: endDate !== null ? format(endDate, 'MM/dd/yyyy') : endDate,
      cashoutType: selectedType
    }
    replaceUrl(newQueryParams);
  }

  const handleReset = () => {
    const newQueryParams = {
      from: null,
      to: null,
      cashoutType: additionCashOutTypeOption[0].type,
    }
    replaceUrl(newQueryParams);
  }

  const handleExportReport = () => {
    onExportReport(reportUrl, startDate, endDate);
  }

  return (
    <ReportWrapper>
      <DatePickerInput
        selected={startDate}
        onChange={handleChangeDates}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        minDate={minDate}
        maxDate={new Date()}
      />
      {idFilterByType &&
        <FilterSelect
          options={cashOutTypes}
          selectedValue={selectedType}
          handleChange={handleChangeType} />
      }
      <ApplyButton variant="contained" color="primary" onClick={handleApply}>
        Apply
      </ApplyButton>
      <ResetButton variant="contained" onClick={handleReset}>
        Reset
      </ResetButton>
      <ExportReportButton variant="contained" onClick={handleExportReport}>
        <ExportReportIcon />
        Export Report
      </ExportReportButton>
    </ReportWrapper>
  )
};

export default CashOutReport;

