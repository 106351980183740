import { Theme } from '@material-ui/core';

export const styles = (theme: Theme) => ({
  checkboxField: {
    height: 16,
    color: theme.customPalette.checkbox.default,
    padding: '9px 7px',
    borderRadius: 4,
    '&$checked': {
      color: theme.customPalette.checkbox.checked,
    },
    '&:hover': {
      background: 'none',
      color: theme.customPalette.checkbox.checked,
    },
  },
  checked: {},
  label: {
    color: theme.customPalette.checkbox.label,
  },
});
