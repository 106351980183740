import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, Divider } from '@material-ui/core';
import {
  fullNameSelector,
  teacherProfileAvatarImageDataSelector,
  userImagesSelector,
  notifyCount,
} from 'src/ducks/user/selectors';
import { findFirstLetters } from 'src/utils/helpers';
import { UserMenu } from './userMenu';
import {
  AppBarContainer,
  DefaultAvatar,
  Menu,
  UserAvatar,
  NotificationButton,
  BadgeIconWrapper,
} from './styles';
import { BadgeIcon, NotificationsIcon } from 'src/components/common/Icons';
import { toggleMainDrawer } from 'src/ducks/notifications/actions';
import NotificationsDrawer from 'src/components/common/MainDrawer/NotificationsDrawer';
import { drawerSelector } from 'src/ducks/notifications/selectors';

export type FullName = {
  firstName?: string;
  lastName?: string;
  displayName?: string;
};

const Header = () => {
  const userName: FullName | null = useSelector(fullNameSelector);
  const abbreviation = userName && findFirstLetters(userName);
  const fullName = userName?.displayName;
  const avatarImageData = useSelector(teacherProfileAvatarImageDataSelector);
  const userImages = useSelector(userImagesSelector);
  const drawerIsOpen = useSelector(drawerSelector);
  const isNotify = useSelector(notifyCount);
  const userImage = userImages?.length && userImages[0].url;
  let avatar = avatarImageData || userImage;
  let isShowAvatar = !!(avatarImageData || (userImage && avatarImageData !== ''));
  if (!window.location.pathname.includes('profile')) {
    isShowAvatar = !!userImages;
    avatar = Array.isArray(userImages) ? userImage : userImages;
  }

  const dispatch = useDispatch();

  const checkNotifications = () => {
    dispatch(toggleMainDrawer());
  };

  return (
    <>
      <AppBarContainer position="fixed">
        <Menu>
          <NotificationButton onClick={checkNotifications}>
            {isNotify ? (
              <BadgeIconWrapper>
                <BadgeIcon />
              </BadgeIconWrapper>
            ) : null}
            <NotificationsIcon />
          </NotificationButton>
          <Toolbar>
            {avatar && isShowAvatar ? (
              <UserAvatar src={avatar} />
            ) : (
              <DefaultAvatar>{abbreviation}</DefaultAvatar>
            )}
            {fullName && <UserMenu fullName={fullName} />}
          </Toolbar>
        </Menu>
      </AppBarContainer>
      <Divider />
      {drawerIsOpen && <NotificationsDrawer />}
    </>
  );
};

export default Header;
