import React from 'react';
import { Link } from 'react-router-dom';
import { renderFooter } from './Template2';
import { ShopCartTemplate2Icon, SkewLinesIcon } from 'src/components/common/Icons';
import {
  CartContainer,
  SocialNetworksContainer,
  WebsiteContainer,
  WebsiteName,
  WebsiteNameContainer,
} from './styles';

type WebsiteWrapper2 = {
  email: string;
  websitePath: string;
  name: string;
  children: React.ReactNode;
  productsAmount: number;
};

const WebsiteWrapper2 = ({
  email,
  children,
  websitePath,
  name,
  productsAmount,
}: WebsiteWrapper2) => {
  return (
    <WebsiteContainer isCart>
      <WebsiteNameContainer>
        <Link to={`/shop/${websitePath}/cart`}>
          <CartContainer>
            <SkewLinesIcon />
            <ShopCartTemplate2Icon />
            <div>{productsAmount}</div>
          </CartContainer>
        </Link>
        <Link to={`/shop/${websitePath}`}>
          <WebsiteName>{name}</WebsiteName>
        </Link>
        <SocialNetworksContainer />
      </WebsiteNameContainer>
      {children}
      {renderFooter(email)}
    </WebsiteContainer>
  );
};

export default WebsiteWrapper2;
