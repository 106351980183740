import { createSelector } from 'reselect';
import { isDirty } from 'redux-form';
import { StateOrdersInterface, Error, ErrorData } from './orders';

export interface RootState {
  order: StateOrdersInterface;
}

const ordersGetter = (state: RootState) => state.order;
const loadingGetter = (state: RootState) => state.order.loading;
const orderFormGetter = (state: any) => state.form?.order;
const errorGetter = (state: any) => state.order.error;

export const ordersSelector = createSelector(ordersGetter, (order) => order.orderList);

export const selectedOrderSelector = createSelector(ordersGetter, (order) => order.order);
export const loadingOrderSelector = createSelector(loadingGetter, (loading) => loading);

export const orderAnyTouchedSelector = createSelector(
  orderFormGetter,
  (order) => order?.anyTouched
);

export const trackingNumberSelector = createSelector(
  orderFormGetter,
  (order) => order?.values?.trackingNumber
);

export const isDirtyTrackingNumberSelector = createSelector(
  (currentState) => currentState,
  (state: any) => isDirty('order')(state)
);

export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  const invalidTrackingNumberError = 'There is no such tracking number';

  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        if (err.message === 'TRACKING_NUMBER_INVALID') {
          return invalidTrackingNumberError;
        }
      });
    } else {
      return error.errors.message;
    }
  }

  return null;
});
