import React, { ChangeEvent } from 'react';
import { Field, WrappedFieldProps, EventWithDataHandler } from 'redux-form';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styles, SuccessMessage } from './styles';

type Props = {
  adornment?: JSX.Element | string;
  name: string;
  disabled?: boolean;
  customClass?: string;
  maxLength?: number;
  type?: string;
  multiline?: boolean;
  placeholder?: string;
  validate?: ((value: string) => string | null)[];
  customRef?: React.Ref<HTMLDivElement | HTMLButtonElement>;
  whiteBg?: boolean;
  id?: string;
  textFieldClassName?: string;
  onChange?: EventWithDataHandler<ChangeEvent<any>>;
  message?: String;
  autoComplete?: string | undefined;
};

const useStyles = makeStyles(styles);

const InputComponent = (props: Props & WrappedFieldProps) => {
  const {
    input,
    meta,
    type,
    multiline,
    maxLength,
    adornment: Adornment,
    disabled,
    placeholder = 'johndoe@mail.com',
    customRef,
    whiteBg,
    id,
    textFieldClassName,
    message,
    autoComplete,
  } = props;
  const classes = useStyles({ isPasswordRecovery: whiteBg });
  const { error, active, touched } = meta;
  const hasError = touched && !active && !!error;

  return (
    <>
      <TextField
        {...input}
        id={id}
        inputRef={customRef}
        type={type}
        disabled={disabled}
        error={hasError}
        helperText={hasError && error}
        className={textFieldClassName || classes.textField}
        placeholder={placeholder}
        multiline={multiline}
        autoComplete={autoComplete}
        InputProps={{
          startAdornment: Adornment ? (
            <InputAdornment position="start">{Adornment}</InputAdornment>
          ) : null,
          notched: true,
          classes: {
            root: classes.input,
            notchedOutline: classes.notchedOutline,
            focused: classes.focused,
            error: classes.error,
            disabled: classes.disabled,
          },
        }}
        inputProps={{
          maxLength: maxLength,
          className: classes.additionalInput,
        }}
        variant="outlined"
      />
      {message && !error && <SuccessMessage>{message}</SuccessMessage>}
    </>
  );
};

const InputField = React.forwardRef<HTMLButtonElement, Props>((props, ref) => (
  <Field component={InputComponent} customRef={ref} {...props} />
));

export default InputField;
