export const controls = [
  {
    name: 'Activism/Advocacy',
    label: 'Activism/Advocacy',
  },
  {
    name: 'Arts',
    label: 'Arts',
  },
  {
    name: 'Entrepreneurship/Business',
    label: 'Entrepreneurship/Business',
  },
  {
    name: 'PBL',
    label: 'PBL',
  },
  {
    name: 'Skilled Trades Education',
    label: 'Skilled Trades Education',
  },
  {
    name: 'Social Entrepreneurship',
    label: 'Social Entrepreneurship',
  },
  {
    name: 'Special Education',
    label: 'Special Education',
  },
  {
    name: 'STEM/STEAM',
    label: 'STEM/STEAM',
  },
  {
    name: `Young Women’s Empowerment`,
    label: "Young Women's Empowerment",
  },
];
