import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';
import { UpdateStoreNamePayload, UserInfo } from 'src/ducks/user/actions';

export default {
  getCurrentUser: (): AxiosPromise<AxiosResponse> => httpService.get({ url: 'api/user/info' }),

  updateTeacherProfileInfo: (data: UserInfo) =>
    httpService.patch({
      url: `api/user/teacher`,
      data,
    }),

  resetPasswordStudent: (studentId: number): AxiosPromise<AxiosResponse> =>
    httpService.put({
      url: `api/user/student/${studentId}/reset-password`,
    }),

  updateUserStoreName: (data: UpdateStoreNamePayload): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/store/${data.id}`,
      data: {
        name: data.name,
        storeDescription: data.storeDescription,
      },
    }),

  getStore: (id: number): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/store/${id}`,
    }),

  getStudentsList: (
    storeId: number,
    page: number = 1,
    perPage: number = 10
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/user/student/list/${storeId}?page=${page}&perPage=${perPage}`,
    }),

  createStudent: (data: any): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/user/student',
      data,
    }),

  editStudent: (id: string, data: any): AxiosPromise<AxiosResponse> =>
    httpService.put({
      url: `api/user/student/${id}`,
      data,
    }),

  getStudent: (studentId: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/user/student/${studentId}`,
    }),

  deleteStudent: (studentId: number): AxiosPromise<AxiosResponse> =>
    httpService.delete({
      url: `api/user/student/${studentId}`,
    }),

  getNotifications: (
    websiteId: number,
    page: number = 1,
    perPage: number = 10
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/event/list/${websiteId}?page=${page}&perPage=${perPage}`,
    }),

  getNewNotifications: (websiteId: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `/api/event/get-updates/${websiteId}`,
    }),
};
