import { createSelector } from 'reselect';
import { StateNotificationsInterface } from './notifications';

export interface RootState {
  notifications: StateNotificationsInterface;
}

const notificationsStoreGetter = (state: RootState) => state.notifications;

export const drawerSelector = createSelector(
  notificationsStoreGetter,
  (notification) => notification?.mainDrawerOpen
);

export const notificationsListSelector = createSelector(
  notificationsStoreGetter,
  (notification) => notification?.notificationsList
);
