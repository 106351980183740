import React from 'react';
import { Link } from 'react-router-dom';
import { renderFooter } from './Template3';
import { ShopCartTemplate3Icon } from 'src/components/common/Icons';
import { CartContainer, WebsiteContainer, WebsiteName, WebsiteNameContainer } from './styles';

type WebsiteWrapper3 = {
  email: string;
  websitePath: string;
  name: string;
  children: React.ReactNode;
  productsAmount: number;
};

const WebsiteWrapper3 = ({
  email,
  children,
  websitePath,
  name,
  productsAmount,
}: WebsiteWrapper3) => {
  return (
    <WebsiteContainer isCart>
      <WebsiteNameContainer>
        <Link to={`/shop/${websitePath}`}>
          <WebsiteName>{name}</WebsiteName>
        </Link>
        <Link to={`/shop/${websitePath}/cart`}>
          <CartContainer>
            <ShopCartTemplate3Icon />
            <div>{productsAmount}</div>
          </CartContainer>
        </Link>
      </WebsiteNameContainer>
      {children}
      {renderFooter(email)}
    </WebsiteContainer>
  );
};

export default WebsiteWrapper3;
