import {
  CLEAR_NOTIFICATIONS_LIST,
  CLOSE_MAIN_DRAWER,
  GET_NOTIFICATIONS_LIST_FAIL,
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  TOGGLE_MAIN_DRAWER,
} from './constants';
import { Error } from '../user/user';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { userWebsitesArraySelector } from '../website/selectors';
import userApi from 'src/api/userApi';

export type ActionType = {
  type: string;
  error: Error | null;
  payload?: any;
};

export type StateNotificationsInterface = {
  isLoading: boolean;
  totalPages: number;
  notificationsList: any;
  error: any;
  mainDrawerOpen: boolean;
};

export const notifications: StateNotificationsInterface = {
  isLoading: true,
  totalPages: 0,
  notificationsList: null,
  error: null,
  mainDrawerOpen: false,
};

export default function reducer(
  state: StateNotificationsInterface = notifications,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_NOTIFICATIONS_LIST_REQUEST:
      return { ...state, loading: true, error: null };
    case GET_NOTIFICATIONS_LIST_SUCCESS:
      const list = state?.notificationsList?.list;
      return {
        ...state,
        loading: false,
        notificationsList: {
          list: list ? [...list, ...payload.data] : payload.data,
          currentPage: payload._page,
          totalPages: payload._pages,
          totalNotifications: payload._count,
        },
      };
    case GET_NOTIFICATIONS_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        error,
      };
    case TOGGLE_MAIN_DRAWER:
      return {
        ...state,
        mainDrawerOpen: !state.mainDrawerOpen,
      };
    case CLOSE_MAIN_DRAWER:
      return {
        ...state,
        mainDrawerOpen: false,
      };
    case CLEAR_NOTIFICATIONS_LIST:
      return { ...state, notificationsList: null, error: null };
    default:
      return state;
  }
}

function* getNotificationsListSaga({ payload }: any) {
  const userWebsites = yield select(userWebsitesArraySelector);
  const websiteId = userWebsites?.[0].websites[0]?.id;

  try {
    if (websiteId) {
      const response = yield userApi.getNotifications(websiteId, payload);

      yield put({ type: GET_NOTIFICATIONS_LIST_SUCCESS, payload: response });
    } else {
      yield put({ type: GET_NOTIFICATIONS_LIST_SUCCESS, payload: null });
    }
  } catch (err) {
    yield put({ type: GET_NOTIFICATIONS_LIST_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([takeLatest(GET_NOTIFICATIONS_LIST_REQUEST, getNotificationsListSaga)]);
};
