import React from 'react';
import { FormCheckboxField } from 'src/components/common/checkbox';
import { controls } from './controls';

type ClassroomConcentrationCheckboxesProps = {
  defaultValues?: string[];
};

const ClassroomConcentrationCheckboxes = ({
  defaultValues = [],
}: ClassroomConcentrationCheckboxesProps) => {
  return (
    <>
      {controls.map(({ name, label }) => {
        let checked;
        if (defaultValues) {
          checked = !!defaultValues.find((value) => value === name);
        } else {
          checked = false;
        }

        return <FormCheckboxField key={name} name={name} label={label} checkValue={checked} />;
      })}
    </>
  );
};

export default ClassroomConcentrationCheckboxes;
