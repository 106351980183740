import { AxiosPromise, AxiosResponse } from 'axios';
import { prepareUrlParams } from 'src/ducks/cashout/urlHelper';
import { httpService } from 'src/services';

export default {
  getCashoutList: (
    websiteId: number,
    urlParams: any,
  ): AxiosPromise<AxiosResponse> => {
    const newUrl = prepareUrlParams(`api/cash-out/list/${websiteId}`, urlParams);

    return httpService.get({
      url: newUrl,
    })
  },

  getCashoutReport: (url: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: url,
      responseType: 'blob',
    }),

  getCashout: (cashoutId: number): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/cash-out/${cashoutId}`,
    }),

  createCashout: (data: any): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/cash-out`,
      data,
    }),

  editCashout: (data: any, cashoutId: number): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/cash-out/${cashoutId}`,
      data,
    }),

  deleteCashout: (cashoutId: number): AxiosPromise<AxiosResponse> =>
    httpService.delete({
      url: `api/cash-out/${cashoutId}`,
    }),
};
