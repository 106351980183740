import {
  VIEW_ORDER,
  UPDATE_PRODUCTS_AMOUNT,
  PREVIEW_ORDER_WITH_VALIDATION,
  CREATE_ORDER_WITH_VALIDATION,
  CANCEL_PAYMENT,
} from './constants';
import { FormShippingAddressProps } from 'src/components/pages/shoppingCart/shippingAddress/ShippingAddress';

export type ViewOrder = {
  type: string;
  payload: {
    orderToken: string;
    websitePath: string;
  };
};

export function viewOrder(orderToken: string, websitePath: string) {
  return {
    type: VIEW_ORDER,
    payload: { orderToken, websitePath },
  };
}

export function updateProductsAmount(productsAmount: number) {
  return {
    type: UPDATE_PRODUCTS_AMOUNT,
    payload: productsAmount,
  };
}

type PreviewOrderWithValidationPayload = {
  zipCode?: number;
  state?: string;
  website: string;
};

export type PreviewOrderWithValidation = {
  type: string;
  payload: PreviewOrderWithValidationPayload
};

export function previewOrderWithValidation(data: PreviewOrderWithValidationPayload) {
  return { type: PREVIEW_ORDER_WITH_VALIDATION, payload: data };
}

type CreateOrderWithValidation = {
  phone: string;
  website: string;
} & FormShippingAddressProps

export type CreateOrderWithValidationPayload = {
  type: string;
  payload: CreateOrderWithValidation
}

export function createOrderWithValidation(shippingAddressData: CreateOrderWithValidation) {
  return {
    type: CREATE_ORDER_WITH_VALIDATION,
    payload: shippingAddressData,
  };
}

export type CancelPayment = {
  type: string;
  payload: string;
};

export function cancelPayment(orderToken: string) {
  return {
    type: CANCEL_PAYMENT,
    payload: orderToken,
  };
}
