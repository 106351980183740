import {
  CLEAR_TOOLKIT_LIST,
  GET_TOOLKIT_LIST,
  GET_PROFITS_LIST,
  CLEAR_PROFITS_LIST,
} from './constants';

export function getToolkitList(urlParams: any = {}) {
  return {
    type: GET_TOOLKIT_LIST,
    payload: urlParams,
  };
}

export function clearToolkitList() {
  return { type: CLEAR_TOOLKIT_LIST };
}

export function getProfitsList(urlParams: any = {}) {
  return {
    type: GET_PROFITS_LIST,
    payload: urlParams,
  };
}

export function clearProfitsList() {
  return { type: CLEAR_PROFITS_LIST };
}
