const clientKeys = {
  default: {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  },
};

type ClientKeys = keyof typeof clientKeys;

const serverKeys = {
  getSecret: (environment?: string) => {
    return environment && clientKeys[environment as ClientKeys]
      ? clientKeys[environment as ClientKeys]
      : clientKeys.default;
  },
};

export default serverKeys;
