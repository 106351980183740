import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { errorSelector, pinSelector } from 'src/ducks/user/selectors';
import { Popup } from 'src/components/common/modal';
import popupText from 'src/constants/popupMessages';
import { ResetPinButton } from '../styles';

type ResetPin = {
  userId: number;
  handleClearPin: () => void;
  handleResetPassword: (userId: number) => void;
};

const ResetPin = ({ handleClearPin, handleResetPassword, userId }: ResetPin) => {
  const {
    resetPin: { hintText, successfulText, main },
  } = popupText;
  const [isOpen, setOpen] = useState(false);
  const error = useSelector(errorSelector);
  const pin = useSelector(pinSelector);

  const handleOpenResetPin = () => setOpen(true);

  const handleCloseResetPin = () => {
    setOpen(false);
    if (pin) {
      handleClearPin();
    }
  };

  const handleCopyPin = () => {
    navigator.clipboard.writeText(pin);
    handleCloseResetPin();
  };

  return (
    <>
      <ResetPinButton onClick={handleOpenResetPin}>Reset Pin</ResetPinButton>
      <Popup
        kind={pin ? 'pin' : 'danger'}
        isOpen={isOpen}
        error={error}
        pin={pin}
        mainText={pin ? successfulText : main}
        hintText={hintText}
        handleYesAction={() => handleResetPassword(userId)}
        closeModal={handleCloseResetPin}
        copyPin={handleCopyPin}
      />
    </>
  );
};

export default ResetPin;
