import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, initialize, InjectedFormProps } from 'redux-form';
import { Redirect } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import {
  updateTeacherProfileInfo,
  clearTeacherProfileInfoError,
  getNewNotifications,
} from 'src/ducks/user/actions';
import { User } from 'src/ducks/user/user';
import {
  loadingUserSelector,
  userSelector,
  canViewProfileSelector,
  updateTeacherProfileInfoLoadingSelector,
  updateTeacherProfileInfoErrorSelector,
  teacherProfileAvatarImageDataSelector,
} from 'src/ducks/user/selectors';
import Loader from 'src/components/common/error-pages/Loader';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { PersonalDetails } from './personalDetails';
import { SchoolInformation } from './schoolInformation';
import { ClassroomConcentration } from './classroomConcentration';
import { Popup } from 'src/components/common/modal';
import {
  PrimaryButton,
  OuterContainer,
  CardsContainer,
  ButtonsContainer,
  BackButton,
  ProfileCard,
  PageTitle,
  ProfileContainer,
} from './styles';
import history from 'src/containers/history';
import profileMessages from 'src/constants/profileMessages';
import routesPath from 'src/routes/config';
import { ClassroomConcentrationFormValues } from 'src/components/common/classroomConcentrationCheckboxes/types';
import { controls } from 'src/components/common/classroomConcentrationCheckboxes/controls';

type SaveButtonProps = {
  isLoading: boolean;
};

const SaveButton = ({ isLoading }: SaveButtonProps) => {
  return (
    <PrimaryButton type="submit" disabled={isLoading}>
      <SaveIcon fontSize="small" />
      &nbsp;{profileMessages.saveButton}
    </PrimaryButton>
  );
};

type ProfileFormValues = {
  firstName: string;
  lastName: string;
} & ClassroomConcentrationFormValues;

type ProfilePageContentProps = {
  teacherProfileInfo: User;
};

const ProfilePageContent = ({
  teacherProfileInfo,
  handleSubmit,
}: ProfilePageContentProps & InjectedFormProps<ProfileFormValues, ProfilePageContentProps>) => {
  const isLoading = useSelector(updateTeacherProfileInfoLoadingSelector);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardsContainer>
        <ProfileCard>
          <PersonalDetails teacherProfileInfo={teacherProfileInfo} />
        </ProfileCard>
        <ProfileCard>
          <SchoolInformation teacherProfileInfo={teacherProfileInfo} />
        </ProfileCard>
        <ProfileCard>
          <ClassroomConcentration teacherProfileInfo={teacherProfileInfo} />
        </ProfileCard>
      </CardsContainer>
      <ButtonsContainer>
        {isLoading && <>{profileMessages.loading}</>}
        <BackButton disabled={isLoading} onClick={handleBack}>
          {profileMessages.backButton}
        </BackButton>
        <SaveButton isLoading={isLoading} />
      </ButtonsContainer>
    </form>
  );
};

const ContentWithReduxForm = reduxForm<ProfileFormValues, ProfilePageContentProps>({
  form: 'teacherProfileForm',
})(ProfilePageContent);

const getInitialProfileFormValues = (user: User) => {
  const { firstName, lastName, accesses } = user;
  const userStore = accesses[0].store;
  const storeDescription = userStore.storeDescription;

  let concentrationValues;
  if (storeDescription?.classroomConcentration) {
    concentrationValues = Object.fromEntries(
      storeDescription.classroomConcentration.map((label: string) => [label, true])
    );
  } else {
    concentrationValues = null;
  }

  return {
    firstName,
    lastName,
    ...concentrationValues,
  };
};

const getRequestData = (values: ProfileFormValues, avatarImageData: string | null): any => {
  const classroomConcentration = controls
    .filter(({ name }) => values[name] === true)
    .map(({ name }) => name);
  const { firstName, lastName } = values;
  const payload = {
    userInfo: {
      firstName,
      lastName,
      userImage: avatarImageData || '',
    },
    storeDescription: { classroomConcentration },
  };

  return payload;
};

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isLoading = useSelector(loadingUserSelector);
  const canViewProfile = useSelector(canViewProfileSelector);
  const [isInfoRequested, setInfoRequested] = useState(false);
  const updatingError = useSelector(updateTeacherProfileInfoErrorSelector);
  const avatarImageData = useSelector(teacherProfileAvatarImageDataSelector);

  const handleSubmit = (values: ProfileFormValues) => {
    const payload = getRequestData(values, avatarImageData);
    dispatch(updateTeacherProfileInfo(payload));
  };

  const handleCloseUpdatingErrorPopup = () => {
    dispatch(clearTeacherProfileInfoError());
  };

  useEffect(() => {
    dispatch(getNewNotifications());
    // The userApi.getTeacherProfileInfoForCurrentUser request should be sent
    // only once - when the user is loaded and they have the privilege.  After
    // save is pressed, we update the user's displayName, but we don't need to
    // request teacher profile info again.
    if (isInfoRequested) {
      return;
    }
    if (!(user && canViewProfile)) {
      return;
    }
    setInfoRequested(true);
  }, [isInfoRequested, user, canViewProfile, dispatch]);

  useEffect(() => {
    if (!user) {
      return;
    }
    dispatch(initialize('teacherProfileForm', getInitialProfileFormValues(user)));
  }, [user, dispatch]);

  if (user && !canViewProfile) {
    return <Redirect to={routesPath.students} />;
  }

  return (
    <ProfileContainer>
      <OuterContainer>
        <PageTitle>{profileMessages.pageTitle}</PageTitle>
        {isLoading ? (
          <Loader />
        ) : user === null ? (
          <ErrorMessage>{profileMessages.failedToRetrieveErrorText}</ErrorMessage>
        ) : (
          <ContentWithReduxForm teacherProfileInfo={user} onSubmit={handleSubmit} />
        )}
        <Popup
          kind="error"
          error={null}
          isOpen={!!updatingError}
          mainText={profileMessages.failedToUpdateErrorText}
          closeModal={handleCloseUpdatingErrorPopup}
        />
      </OuterContainer>
    </ProfileContainer>
  );
};

export default Profile;
