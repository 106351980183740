import { getCurrentTab, prepareTabUrl } from "../../accounting/tabHelper";

export interface IQueryParams {
    from: Date | null,
    to: Date | null,
    cashoutType: string,
}

export interface ILoadMoreParams extends IQueryParams {
    page?: number,
    perPage?: number
}

export const defaultQueryParams: IQueryParams = {
    from: null,
    to: null,
    cashoutType: 'all_type',
}

export const prepareQueryParams = (locationSearch: string) => {
    const query: any = new URLSearchParams(locationSearch);

    return {
        from: query.get("from") === null ? defaultQueryParams.from : query.get("from"),
        to: query.get("to") === null ? defaultQueryParams.to : query.get("to"),
        cashoutType: query.get("cashoutType") === null ? defaultQueryParams.cashoutType : query.get("cashoutType"),
    }
}

export const prepareUrl = (
    location: any,
    queryParams: IQueryParams,
    hasTabs: boolean = true): string => {
    let newUrl = location.pathname;

    let selectedTab = getCurrentTab(location);
    if (hasTabs) newUrl = prepareTabUrl(location, selectedTab);

    const newSearchParams = new URLSearchParams();

    if (JSON.stringify(defaultQueryParams) !== JSON.stringify(queryParams)) {
        const paramsList = Object.keys(queryParams) as Array<keyof IQueryParams>;

        paramsList.forEach((key) => {
            const currentParam = queryParams[key]

            if (JSON.stringify(currentParam) !== JSON.stringify(defaultQueryParams[key]) && currentParam !== null) {
                newSearchParams.append(key as string, queryParams[key] as string)
            }
        })
        
        let paramPrefix = '&';
        if(!newUrl.includes('?')) paramPrefix = '?';
        newUrl += paramPrefix + newSearchParams.toString()
    }

    return newUrl
}