import React, { ReactNode, ChangeEvent, KeyboardEvent } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormHelperText, TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteInputChangeReason, AutocompleteRenderInputParams, AutocompleteRenderOptionState } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { QuestionHintIcon } from 'src/components/common/Icons';
import { Hint } from 'src/components/common/hint';
import { styles, Label, inlineStyles } from './styles';

export type AutocompleteOptions = {
  name: string;
  value: string;
};

type Props = {
  name: string;
  validate?: ((value: string) => string | null)[];
  options?: AutocompleteOptions[];
  label?: string;
  hint?: string;
  required?: boolean;
  maxLength?: number;
  renderInput?: (params: AutocompleteRenderInputParams) => ReactNode;
  renderOption?: (option: AutocompleteOptions, state: AutocompleteRenderOptionState) => ReactNode;
  onOpenPopup?: (event: ChangeEvent<{}>) => void;
  onClosePopup?: (event: ChangeEvent<{}>) => void;
  openPopup?: boolean;
  openOnFocus?: boolean;
  /** All props above are used only if Autocomplete is controlled  */
  inputValue: AutocompleteOptions['name'];
  onInputChange?: (
    e: ChangeEvent<{}> | KeyboardEvent<Element>,
    value: AutocompleteOptions['name'],
    reason: AutocompleteInputChangeReason
  ) => boolean;
};

const useStyles = makeStyles(styles);

/**
 * Flexible Autosuggest options Widget comprised of Text input and Select
 * @desc Based on MUI docs: {@link https://mui.com/components/autocomplete/}
 * @todo Error handling ain't to be implemented unless BA or client requires it 
 *
 * @param {Props & WrappedFieldProps} props 
 * @returns {ReactNode}
 */
const AutocompleteComponent = ({
  meta: { error, active, touched },
  required,
  openOnFocus,
  openPopup,
  maxLength,
  label,
  hint,
  options = [],
  renderInput,
  renderOption,
  onOpenPopup,
  onClosePopup,
  onInputChange,
  inputValue,
}: Props & WrappedFieldProps) => {

  const classes = useStyles();

  /** 
   * @todo Implement error handling based on meta once BA or client requires
   * @see {SelectField} Implementation for reference and ideas
   */
  const hasError = touched && !active && !!error;

  /**
   * Widget implies input field customization. If it's not passed, then
   * this default render input will be used as a fallback
   */
  const defaultRenderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant="outlined"
      fullWidth
      inputProps={{
        ...params.inputProps,
        className: classes.input,
        maxLength,
      }}
    />
  );

  /**
   * Widget implies Option layout customization. If it's not passed, then
   * this default render option will be used as a fallback
   */
  const defaultRenderOption = (
    option: AutocompleteOptions,
    state: AutocompleteRenderOptionState
  ) => (
    <div key={option.value} className={classes.option}>
      {option.name}
    </div>
  );

  return (<>
    <Label>
      {label}
      {required && '*'}
      {hint && (
        <Hint placement="top" text={hint}>
          <QuestionHintIcon />
        </Hint>
      )}
    </Label>
    <Autocomplete
      disablePortal
      freeSolo
      selectOnFocus
      fullWidth
      openOnFocus={openOnFocus}
      open={openPopup}
      onOpen={onOpenPopup}
      onClose={onClosePopup}
      ListboxProps={{ style: inlineStyles.listbox }}
      size={'small'}
      inputValue={inputValue}
      getOptionLabel={ (option: AutocompleteOptions) => (option.name || '')}
      options={ options }
      className={classes.autocomplete}
      onInputChange={onInputChange}
      renderInput={(params) => (
        renderInput ? renderInput(params) : defaultRenderInput(params)
      )}
      renderOption={(option, state) => (
        renderOption ? renderOption(option, state) : defaultRenderOption(option, state)
      )}
    />
    {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
  </>);
};

const AutocompleteField = (props: Props) => <Field component={AutocompleteComponent} {...props} />;

export default AutocompleteField;
