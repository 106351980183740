import React, { useEffect } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getWebsiteShop } from 'src/ducks/website/actions';
import { websiteSelector, websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import { orderProductsAmountSelector } from 'src/ducks/shopOrder/selectors';
import ShoppingCart from 'src/components/pages/shoppingCart/ShoppingCart';
import WebsiteWrapper1 from 'src/components/pages/websiteTemplates/websiteTemplate1/WebsiteWrapper1';
import WebsiteWrapper2 from 'src/components/pages/websiteTemplates/websiteTemplate2/WebsiteWrapper2';
import WebsiteWrapper3 from 'src/components/pages/websiteTemplates/websiteTemplate3/WebsiteWrapper3';
import routesPath from 'src/routes/config';
import { ShippingAddress } from './shippingAddress';
import stylesTemplates, { StylesTemplate } from 'src/constants/stylesTemplates';
import { getItemByKeyFromStorage, LocalStorageType } from 'src/utils/helpers';

const ShoppingCartContainer = () => {
  const dispatch = useDispatch();
  const { id: websitePath } = useParams();
  const templateId = useSelector(websiteTemplateIDSelector);
  const { email, name } = useSelector(websiteSelector);
  const productsAmount = useSelector(orderProductsAmountSelector);
  const styles: StylesTemplate | undefined = stylesTemplates.find(
    ({ id }: StylesTemplate) => templateId === id
  );
  const products = getItemByKeyFromStorage('shoppingCart');
  const allProductsAmount =
    products?.reduce((acc: number, currVal: LocalStorageType) => acc + currVal.count, 0) || 0;

  useEffect(() => {
    window.document.title = websitePath;
    dispatch(getWebsiteShop(websitePath));
  }, [dispatch]);

  const chooseWebsiteWrapper = (styles: StylesTemplate) => {
    switch (templateId) {
      case 1:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <Switch>
              <Route
                exact
                path={routesPath.shoppingCart}
                render={() => <ShoppingCart websitePath={websitePath} />}
              />
              <Route
                exact
                path={routesPath.shoppingOrder}
                render={() => <ShippingAddress websitePath={websitePath} styles={styles} />}
              />
            </Switch>
          </WebsiteWrapper1>
        );
      case 2:
        return (
          <WebsiteWrapper2
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <Switch>
              <Route
                exact
                path={routesPath.shoppingCart}
                render={() => <ShoppingCart websitePath={websitePath} />}
              />
              <Route
                exact
                path={routesPath.shoppingOrder}
                render={() => <ShippingAddress websitePath={websitePath} styles={styles} />}
              />
            </Switch>
          </WebsiteWrapper2>
        );
      case 3:
        return (
          <WebsiteWrapper3
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <Switch>
              <Route
                exact
                path={routesPath.shoppingCart}
                render={() => <ShoppingCart websitePath={websitePath} />}
              />
              <Route
                exact
                path={routesPath.shoppingOrder}
                render={() => <ShippingAddress websitePath={websitePath} styles={styles} />}
              />
            </Switch>
          </WebsiteWrapper3>
        );

      default:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <Switch>
              <Route
                exact
                path={routesPath.shoppingCart}
                render={() => <ShoppingCart websitePath={websitePath} />}
              />
              <Route
                exact
                path={routesPath.shoppingOrder}
                render={() => <ShippingAddress websitePath={websitePath} styles={styles} />}
              />
            </Switch>
          </WebsiteWrapper1>
        );
    }
  };

  if (email && templateId && styles) {
    return chooseWebsiteWrapper(styles);
  }
  return null;
};

export default ShoppingCartContainer;
