import React from 'react';
import { Theme } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';

type FontSizeProps = {
  size?: number;
};

export const PersonalDetailsContainer = styled('div')({
  display: 'flex',
});

export const TextContainer = styled('div')({
  flex: 1,
});

export const InfoLine = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 15,
});

export const DisplayName = withTheme(
  styled(({ theme, size, ...otherProps }: TypographyProps & WithTheme & FontSizeProps) => (
    <Typography variant="h2" {...otherProps} />
  ))({
    marginTop: 5,
    marginBottom: 25,
    wordBreak: 'break-word',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: ({ size }: TypographyProps & WithTheme & FontSizeProps) => size,
    fontWeight: 500,
    maxWidth: 325,
  })
);

export const DisplayEmail = styled('div')({
  wordBreak: 'break-word',
});

export const EmailLine = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
  })
);

export const EmailEconWithLogo = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const RowLabelContainer = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
      flex: '0 0 100px',
    }
  )
);

export const styles = (theme: Theme) => ({
  textField: {
    flex: 1,
    padding: 0,
  },
});
