import {
  GET_CASHOUT_LIST,
  GET_CASHOUT_LIST_SUCCESS,
  GET_CASHOUT_LIST_FAIL,
  CLEAR_CASHOUT_LIST,
  VIEW_CASHOUT,
  VIEW_CASHOUT_SUCCESS,
  VIEW_CASHOUT_FAIL,
  EDIT_CASHOUT,
  EDIT_CASHOUT_SUCCESS,
  EDIT_CASHOUT_FAIL,
  CREATE_CASHOUT,
  CREATE_CASHOUT_SUCCESS,
  CREATE_CASHOUT_FAIL,
  DELETE_CASHOUT,
  DELETE_CASHOUT_SUCCESS,
  DELETE_CASHOUT_FAIL,
} from './constants';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import history from 'src/containers/history';
import { userWebsitesArraySelector } from 'src/ducks/website/selectors';
import cashoutApi from 'src/api/cashoutApi';
import { GetCashoutList, GetCashout, EditCashout, CreateCashout, DeleteCashout } from './actions';
import { StoreDescription } from 'src/ducks/user/user';

export const moduleName = 'cashout';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

export type ErrorData = {
  field?: string;
  invalid_value: string;
  message: string;
};

export type Error = {
  error: boolean;
  errors:
    | ErrorData[]
    | {
        title: string;
        message: string;
      };
};

export type Cashout = {
  id: number;
  createdAt: string;
  type: string;
  recipientName: string;
  status: string;
  approvedDate: string | null;
  amount: number;
};

export type CashoutList = {
  list: Cashout[];
  currentPage: number;
  totalPages: number;
  totalCashout: number;
  totalFundsCashOut: number;
};

export type FullCashout = {
  website: {
    id: number;
    store: {
      id: number;
      storeDescription: StoreDescription;
    };
  };
  fullName: string;
  organizationCityAndState: string;
  organizationAddress: string;
  organizationTaxId: string;
  zipCode: string;
  organizationZipCode: string;
  totalFundsCashOut: number;
  needChangesText: string | null;
} & Cashout;

export type StateCashoutInterface = {
  cashoutList: CashoutList | null;
  cashout: FullCashout | null;
  loading: boolean;
  error: Error | null;
};

export const cashoutState: StateCashoutInterface = {
  cashoutList: null,
  cashout: null,
  error: null,
  loading: false,
};

export default function reducer(
  state: StateCashoutInterface = cashoutState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_CASHOUT_LIST:
    case VIEW_CASHOUT:
    case EDIT_CASHOUT:
    case CREATE_CASHOUT:
    case DELETE_CASHOUT:
      return { ...state, loading: true, error: null };

    case GET_CASHOUT_LIST_SUCCESS:
      return handleGetCashout(state, payload);

    case VIEW_CASHOUT_SUCCESS:
      return {
        ...state,
        cashout: payload,
        loading: false,
        error: null,
      };

    case DELETE_CASHOUT_SUCCESS: {
      const updatedTotalFundsCashOut = state.cashoutList?.totalFundsCashOut + payload.amount;

      return {
        ...state,
        cashoutList: {
          ...state.cashoutList,
          list: state.cashoutList?.list.filter((cashout) => cashout.id !== payload.cashoutId),
          totalFundsCashOut: updatedTotalFundsCashOut,
        },
        loading: false,
        error: null,
      };
    }

    case EDIT_CASHOUT_SUCCESS:
    case CREATE_CASHOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case GET_CASHOUT_LIST_FAIL:
      return {
        ...state,
        cashoutList: null,
        loading: false,
        error: error,
      };

    case VIEW_CASHOUT_FAIL:
      return {
        ...state,
        cashout: null,
        loading: false,
        error: error,
      };

    case EDIT_CASHOUT_FAIL:
    case CREATE_CASHOUT_FAIL:
    case DELETE_CASHOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
      };

    case CLEAR_CASHOUT_LIST:
      return { ...state, cashoutList: null, error: null };

    default:
      return state;
  }
}

function handleGetCashout(state: StateCashoutInterface, payload: any) {
  const list = state?.cashoutList?.list;

  if (!payload) {
    return {
      ...state,
      cashoutList: null,
      loading: false,
      error: null,
    };
  }

  return {
    ...state,
    cashoutList: {
      list: list ? [...list, ...payload.data] : payload.data,
      currentPage: payload._page,
      totalPages: payload._pages,
      totalCashout: payload._total,
      totalFundsCashOut: payload.totalFundsCashOut,
    },
    loading: false,
    error: null,
  };
}

function* getCashoutListSaga({ payload }: GetCashoutList) {
  const userWebsites = yield select(userWebsitesArraySelector);
  const websiteId = userWebsites?.[0].websites[0]?.id;

  try {
    if (websiteId) {
      const response = yield cashoutApi.getCashoutList(websiteId, payload);

      yield put({ type: GET_CASHOUT_LIST_SUCCESS, payload: response });
    } else {
      yield put({ type: GET_CASHOUT_LIST_SUCCESS, payload: null });
    }
  } catch (err) {
    yield put({ type: GET_CASHOUT_LIST_FAIL, error: err.data });
  }
}

function* getCashoutSaga({ payload: cashoutId }: GetCashout) {
  try {
    const response = yield cashoutApi.getCashout(cashoutId);

    yield put(
      initialize('cashout', {
        fullName: response.fullName,
        amount: response.amount.toString(),
        note: response.note,
        organizationCityAndState: response.organizationCityAndState,
        organizationAddress: response.organizationAddress,
        organizationTaxId: response.organizationTaxId,
        organizationZipCode: response.organizationZipCode,
        organizationName: response.recipientName,
      })
    );
    yield put({ type: VIEW_CASHOUT_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: VIEW_CASHOUT_FAIL, error: err.data });
  }
}

function* editCashoutSaga({ payload }: EditCashout) {
  const userWebsites = yield select(userWebsitesArraySelector);
  const websiteId = userWebsites?.[0].websites[0]?.id;

  try {
    if (websiteId) {
      yield cashoutApi.editCashout({ ...payload.data, website: websiteId }, payload.cashoutId);
    }

    yield put({ type: EDIT_CASHOUT_SUCCESS });
    yield history.push({
      pathname: '/accounting',
      search: '?tab=2',
      state: { activeTabCashout: true },
    });
  } catch (err) {
    yield put({ type: EDIT_CASHOUT_FAIL, error: err.data });
  }
}

function* createCashoutSaga({ payload }: CreateCashout) {
  const userWebsites = yield select(userWebsitesArraySelector);
  const websiteId = userWebsites?.[0].websites[0]?.id;

  try {
    if (websiteId) {
      yield cashoutApi.createCashout({ ...payload, website: websiteId });
    }

    yield put({ type: CREATE_CASHOUT_SUCCESS });
    yield history.push({
      pathname: '/accounting',
      search: '?tab=2',
      state: { activeTabCashout: true },
    });
  } catch (err) {
    yield put({ type: CREATE_CASHOUT_FAIL, error: err.data });
  }
}

function* deleteCashoutSaga({ payload }: DeleteCashout) {
  try {
    yield cashoutApi.deleteCashout(payload.cashoutId);

    yield put({ type: DELETE_CASHOUT_SUCCESS, payload });
  } catch (err) {
    yield put({ type: DELETE_CASHOUT_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(GET_CASHOUT_LIST, getCashoutListSaga),
    takeLatest(VIEW_CASHOUT, getCashoutSaga),
    takeLatest(EDIT_CASHOUT, editCashoutSaga),
    takeLatest(CREATE_CASHOUT, createCashoutSaga),
    takeLatest(DELETE_CASHOUT, deleteCashoutSaga),
  ]);
};
