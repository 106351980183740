import React from 'react';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { FormInputField } from 'src/components/common/inputs';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import validations from 'src/components/common/inputs/validations';
import { User } from 'src/ducks/user/user';
import profileMessages from 'src/constants/profileMessages';
import { AvatarEditor } from '../avatarEditor';
import {
  PersonalDetailsContainer,
  TextContainer,
  DisplayName,
  InfoLine,
  EmailLine,
  RowLabelContainer,
  DisplayEmail,
  EmailEconWithLogo,
  styles,
} from './styles';

type NameLabelProps = {
  htmlFor: string;
  children: React.ReactNode;
};

const NameLabel = ({ htmlFor, children }: NameLabelProps) => {
  return (
    <RowLabelContainer>
      <label htmlFor={htmlFor}>{children}</label>
    </RowLabelContainer>
  );
};

type PersonalDetailsProps = {
  teacherProfileInfo: User;
};

const PersonalDetails = ({ teacherProfileInfo, classes }: PersonalDetailsProps & WithStyles) => {
  const { firstName, lastName, email } = teacherProfileInfo;
  const displayName = `${firstName} ${lastName}`;
  const size = displayName.length > 30 ? 20 : 30;

  return (
    <>
      <PersonalDetailsContainer>
        <AvatarEditor userImages={teacherProfileInfo.userImages} />
        <TextContainer>
          <DisplayName size={size}>{displayName}</DisplayName>
          <EmailLine>
            <EmailEconWithLogo>
              <MailOutlineIcon />
              <b>&nbsp;{profileMessages.email}&nbsp;</b>
            </EmailEconWithLogo>
            <DisplayEmail>{email}</DisplayEmail>
          </EmailLine>
          <InfoLine>
            <NameLabel htmlFor="profile_firstName">{profileMessages.firstName}</NameLabel>
            <FormInputField
              id="profile_firstName"
              placeholder={firstName}
              name="firstName"
              maxLength={inputRestrictions.MAX_LENGTH}
              validate={validations.firstName}
              textFieldClassName={classes.textField}
            />
          </InfoLine>
          <InfoLine>
            <NameLabel htmlFor="profile_lastName">{profileMessages.lastName}</NameLabel>
            <FormInputField
              id="profile_lastName"
              placeholder={lastName}
              name="lastName"
              maxLength={inputRestrictions.MAX_LENGTH}
              validate={validations.lastName}
              textFieldClassName={classes.textField}
            />
          </InfoLine>
        </TextContainer>
      </PersonalDetailsContainer>
    </>
  );
};

export default withStyles(styles)(PersonalDetails);
