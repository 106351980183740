import React, { useState } from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { FormLabel } from '@material-ui/core';
import { FormInputField } from 'src/components/common/inputs';
import { FormSelectField } from 'src/components/common/selects';
import { Hint } from 'src/components/common/hint';
import { QuestionHintIcon } from 'src/components/common/Icons';
import { ClassroomConcentrationCheckboxes } from 'src/components/common/classroomConcentrationCheckboxes';
import { ClassroomConcentrationFormValues } from 'src/components/common/classroomConcentrationCheckboxes/types';
import validations from 'src/components/common/inputs/validations';
import { FormContainer, ContinueButton, ContinueButtonContainer } from '../signin/styles';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import { ClassroomConcentrationLabelContainer, BusinessNameWrapper, HintWrapper } from './styles';

export type FormsSignUpProps = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirm: string;
  city: string;
  school: string;
  schoolLevel: string;
  schoolName: string;
  store: string;
  classroom: boolean;
} & ClassroomConcentrationFormValues;

type Props = InjectedFormProps<FormsSignUpProps, {}>;

const FormSchoolInformation = ({ handleSubmit, valid }: Props) => {
  const [selectedSchoolLevel, setSelectedSchoolLevel] = useState<string>('');
  const titleHint =
    ' Don’t have a business name in mind yet? No problem! You can always change your business name.';
  const options = [
    {
      name: 'Elementary School',
      value: 'Elementary School',
    },
    {
      name: 'Middle School',
      value: 'Middle School',
    },
    {
      name: 'High School',
      value: 'High School',
    },
    {
      name: 'Other',
      value: 'Other',
    },
  ];

  const handleChangeSchoolLevel = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSchoolLevel(value as string);
  };

  const renderValue = (selected: any) => (!selected ? 'School Level' : selected);

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FormInputField
          placeholder="School Address..."
          name="school"
          validate={validations.school}
        />
        <FormInputField
          placeholder="City, State..."
          name="city"
          validate={validations.city}
        />
        <FormInputField
          placeholder="ZIP code"
          name="zipCode"
          validate={validations.zipCode}
          maxLength={inputRestrictions.MAX_LENGTH_ZIP_CODE}
        />
        <FormSelectField
          name="schoolLevel"
          options={options}
          selectedValue={selectedSchoolLevel}
          handleChange={handleChangeSchoolLevel}
          renderValue={renderValue}
          validate={validations.schoolLevel}
        />
        <FormInputField
          placeholder="School Name"
          name="schoolName"
          validate={validations.schoolName}
        />
        <BusinessNameWrapper>
          <FormInputField
            placeholder="Business Name"
            name="store"
            validate={validations.store}
            maxLength={inputRestrictions.BUSINESS_NAME_MAX_LENGTH}
          />
          <HintWrapper>
            <Hint placement="top" text={titleHint}>
              <QuestionHintIcon />
            </Hint>
          </HintWrapper>
        </BusinessNameWrapper>
        <ClassroomConcentrationLabelContainer>
          <FormLabel>Classroom Concentration:</FormLabel>
        </ClassroomConcentrationLabelContainer>
        <ClassroomConcentrationCheckboxes />
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            Create My Account
          </ContinueButton>
        </ContinueButtonContainer>
      </FormContainer>
    </form>
  );
};

// const validate = (values: ClassroomConcentrationFormValues) => {
//   const errors: FormErrors<ClassroomConcentrationFormValues> = {};
//   const keys = Object.keys(values);
//   const isFindCheckedCheckboxes = keys
//     .find(i => values[i] && typeof values[i] === 'boolean');
//   if (!isFindCheckedCheckboxes) {
//     const key = keys.find(o => !values[o]);
//     if (key)
//       errors.ywe = 'Please provide the required field';
//   }
//   return errors;
// };

export default reduxForm<FormsSignUpProps, {}>({
  form: 'schoolInformation',
  // validate,
})(FormSchoolInformation);
