import React, { useState } from 'react';
import { Popup } from 'src/components/common/modal';
import { DeleteIcon } from 'src/components/common/Icons';
import popupText from 'src/constants/popupMessages';
import { IconButton } from '@material-ui/core';

type DeleteStudentProps = {
  id: number;
  error: string | (string | undefined)[] | null;
  handleRemoveProduct: (studentId: number) => void;
};

const RemoveProduct = (props: DeleteStudentProps) => {
  const { id, error, handleRemoveProduct } = props;
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    handleRemoveProduct(id);
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleOpen}>
        <DeleteIcon />
      </IconButton>
      <Popup
        kind={'danger'}
        isOpen={isOpen}
        error={error}
        mainText={popupText.deleteProduct.mainText}
        closeModal={handleClose}
        handleYesAction={handleRemove}
        isRemove
      />
    </>
  );
};

export default RemoveProduct;
