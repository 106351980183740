import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';

export default {
  createOrder: (data: any, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/shop/order`,
      data,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),

  previewOrder: (data: any, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/shop/order/preview`,
      data,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),

  viewOrder: (orderToken: string, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/shop/order/${orderToken}`,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),

  cancelPayment: (orderToken: string, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/shop/order/${orderToken}/cancel`,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),
};
