import { styled } from '@material-ui/core/styles';
import { ActionContainer } from 'src/components/pages/students/studenstList/styles';
import {
  StyledTableCellInfo as TableSellOrderList,
  StyledDateCell,
} from 'src/components/pages/orders/orderList/styles';

export const DeleteContainer = styled(ActionContainer)({
  '& button': {
    height: 44,
    width: 44,
    '&:disabled': {
      '& svg > path': {
        fill: '#B3C0CE',
      },
    },
    '& div, & div > svg': {
      width: 30,
      height: 30,
    },
  },
});

export const StyledTableCellInfo = styled(TableSellOrderList)({
  '&:first-child': {
    color: '#44566C',
    width: 120,
  },
  '&:nth-of-type(2)': {
    maxWidth: 600,
  },
});

export const StyledDateCellCashout = styled(StyledDateCell)({
  maxWidth: 170,
});
