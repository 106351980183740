import React from 'react';
import {
  Accordion,
  AccordionProps,
  AccordionSummary,
  AccordionSummaryProps,
  RadioGroup,
  RadioGroupProps,
  FormControlLabel,
  List,
} from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

export const ExpansionStore = withTheme(
  styled(({ theme, ...otherProps }: AccordionProps & WithTheme) => <Accordion {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    boxShadow: 'none',
    '&::before': {
      content: 'none',
    },
    '&&': {
      margin: 0,
    },
  })
);

export const ExpansionSummary = styled(({ ...otherProps }: AccordionSummaryProps) => (
  <AccordionSummary classes={{ expanded: 'expanded', content: 'content' }} {...otherProps} />
))({
  paddingLeft: 21,
  '&&': {
    minHeight: 48,
  },
  '& .expanded, .content': {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '&:hover': {
    backgroundColor: '#F8FAFB',
  },
});

export const RadioContainer = withTheme(
  styled(({ theme, status, ...otherProps }: any) => <RadioGroup {...otherProps} />)({
    position: 'relative',
    padding: ' 11px 0 11px 36px',
    backgroundColor: ({ theme, status }: any) =>
      status === 'selected' ? theme.customPalette.background.greyBg : '#FFFFFF',
    '&:hover': {
      backgroundColor: ({ theme }: any) => theme.customPalette.background.greyBg,
    },
    '& > a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& > label > span': {
      fontWeight: 500,
      padding: 0,
      paddingRight: 19,
      fontSize: 15,
    },
    '&::before': {
      content: ({ status }: any) => (status === 'selected' ? 'close-quote' : 'none'),
      position: 'absolute',
      height: '100%',
      top: 0,
      left: -4,
      width: 8,
      borderRadius: 5,
      backgroundColor: ({ theme }: any) => theme.customPalette.buttons.activeBg,
    },
  })
);

export const StoreMenuList = styled(List)({
  padding: '5px 0',
});
