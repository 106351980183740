import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormsWebsiteType } from 'src/components/pages/website/form-website/FormWebsiteDescription';
import FormWebsiteDescription from './form-website/FormWebsiteDescription';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import {
  createWebsiteAction,
  getListTemplateAction,
  getWebsiteAction,
} from 'src/ducks/website/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { loadingWebsiteSelector } from 'src/ducks/website/selectors';
import { errorSelector, userWebsite } from 'src/ducks/user/selectors';
import TabsBar from './tabs/Tabs';
import Loader from 'src/components/common/error-pages/Loader';
import {
  WebsiteDesignContainer,
  MainContainer,
  FormContainer,
  Title,
  ImageContainer,
  WebsiteDescription,
} from './styles';

const Website = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loadingWebsiteSelector);
  const errors = useSelector(errorSelector);
  const userWebsiteIds = useSelector(userWebsite);

  useEffect(() => {
    dispatch(getNewNotifications());
    dispatch(getListTemplateAction());
    if (userWebsiteIds?.length) {
      dispatch(getWebsiteAction(userWebsiteIds[0].id));
    }
  }, [dispatch]);

  const handleSubmitForm = (values: FormsWebsiteType) => {
    dispatch(createWebsiteAction(values));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <WebsiteDesignContainer>
          <Title variant="h4">Website Design</Title>
          <MainContainer>
            <ImageContainer>
              <TabsBar />
            </ImageContainer>
            <FormContainer>
              <WebsiteDescription>Description</WebsiteDescription>
              {Array.isArray(errors) && errors.length ? (
                errors.map((error) => <ErrorMessage>{error}</ErrorMessage>)
              ) : (
                <ErrorMessage>{errors}</ErrorMessage>
              )}
              <FormWebsiteDescription onSubmit={handleSubmitForm} />
            </FormContainer>
          </MainContainer>
        </WebsiteDesignContainer>
      )}
    </>
  );
};

export default Website;
