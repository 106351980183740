import React from 'react';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';

export const FooterContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 1340,
  minHeight: 208,
  margin: '0 auto',
});

export const FooterSection = styled('div')({
  display: 'flex',
  minHeight: 104,
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const Logo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 110,
  height: 35,
});

export const LinkBlock = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: 170,
});

export const SocialIcons = withTheme(
  styled(({ theme, ...otherProps }: any) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    width: 16,
    height: 16,
    cursor: 'pointer',
    '&:hover': {
      '& > svg > path': {
        fill: ({ theme }: any) => theme.customPalette.text.primary,
      },
    },
  })
);

export const DividingStrip = styled('hr')({
  width: '100%',
  backgroundColor: '#EAEDF0',
  height: 1,
  margin: 0,
  border: 'none',
});

export const CopyrightText = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      color: ({ theme }: WithTheme) => theme.customPalette.text.lightGrey,
    }
  )
);

export const TermsText = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
      '&:hover': {
        color: ({ theme }: any) => theme.customPalette.text.primary,
      },
    }
  )
);

export const LinkText = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
      '&:hover': {
        color: ({ theme }: any) => theme.customPalette.text.highlight,
      },
    }
  )
);
