import {
  GET_CASHOUT_LIST,
  CLEAR_CASHOUT_LIST,
  VIEW_CASHOUT,
  EDIT_CASHOUT,
  CREATE_CASHOUT,
  DELETE_CASHOUT,
} from './constants';

export type GetCashoutList = {
  type: string;
  payload: {
    page: number;
    perPage: number;
  };
};

export function getCashoutList(urlParams: any = {}) {
  return {
    type: GET_CASHOUT_LIST,
    payload: urlParams,
  };
}

export function clearCashoutList() {
  return { type: CLEAR_CASHOUT_LIST };
}

export type GetCashout = {
  type: string;
  payload: number;
};

export function getCashout(cashoutId: number) {
  return {
    type: VIEW_CASHOUT,
    payload: cashoutId,
  };
}

export type EditCashout = {
  type: string;
  payload: {
    data: any;
    cashoutId: number;
  };
};

export function editCashout(cashoutId: number, data: any) {
  return {
    type: EDIT_CASHOUT,
    payload: { cashoutId, data },
  };
}

export type CreateCashout = {
  type: string;
  payload: any;
};

export function createCashout(data: any) {
  return {
    type: CREATE_CASHOUT,
    payload: data,
  };
}

export type DeleteCashout = {
  type: string;
  payload: {
    cashoutId: number;
    amount: number;
  };
};

export function deleteCashout(cashoutId: number, amount: number) {
  return {
    type: DELETE_CASHOUT,
    payload: { cashoutId, amount },
  };
}
