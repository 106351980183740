import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getWebsiteShop } from 'src/ducks/website/actions';
import { viewOrder } from 'src/ducks/shopOrder/actions';
import { websiteSelector, websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import { orderStatusSelector, orderProductsAmountSelector } from 'src/ducks/shopOrder/selectors';
import WebsiteWrapper1 from 'src/components/pages/websiteTemplates/websiteTemplate1/WebsiteWrapper1';
import WebsiteWrapper2 from 'src/components/pages/websiteTemplates/websiteTemplate2/WebsiteWrapper2';
import WebsiteWrapper3 from 'src/components/pages/websiteTemplates/websiteTemplate3/WebsiteWrapper3';
import { SuccessPage } from 'src/components/common/success-page';
import { removeItemByKeyStorage } from 'src/utils/helpers';

const SuccessWebsitePage = () => {
  const { websitePath, orderToken } = useParams();
  const dispatch = useDispatch();
  const templateId = useSelector(websiteTemplateIDSelector);
  const { email, name } = useSelector(websiteSelector);
  const orderStatus = useSelector(orderStatusSelector);
  const productsAmount = useSelector(orderProductsAmountSelector);
  const successText = 'Thank you! Your payment was successful';
  const backUrl = `/shop/${websitePath}`;

  useEffect(() => {
    window.document.title = websitePath;
  }, []);

  useEffect(() => {
    dispatch(viewOrder(orderToken, websitePath));

    if (orderStatus) {
      removeItemByKeyStorage('shoppingCart');
      removeItemByKeyStorage('shippingMethod');
      dispatch(getWebsiteShop(websitePath));
    }
  }, [orderStatus]);

  const chooseWebsiteWrapper = () => {
    switch (templateId) {
      case 1:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount}
          >
            <SuccessPage text={successText} backUrl={backUrl} />
          </WebsiteWrapper1>
        );
      case 2:
        return (
          <WebsiteWrapper2
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount}
          >
            <SuccessPage text={successText} backUrl={backUrl} />
          </WebsiteWrapper2>
        );
      case 3:
        return (
          <WebsiteWrapper3
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount}
          >
            <SuccessPage text={successText} backUrl={backUrl} />
          </WebsiteWrapper3>
        );

      default:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount}
          >
            <SuccessPage text={successText} backUrl={backUrl} />
          </WebsiteWrapper1>
        );
    }
  };

  if (email && templateId) {
    return chooseWebsiteWrapper();
  }
  return null;
};

export default SuccessWebsitePage;
