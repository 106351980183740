import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signUpAction } from 'src/ducks/auth/actions';
import { errorSelector } from 'src/ducks/auth/selectors';
import UnAuthorized from 'src/components/common/error-pages/UnAuthorized';
import { setItemToStorage } from 'src/utils/helpers';
import FormCreateAccount, { FormCreateAccountProps } from './FormCreateAccount';
import FormSchoolInformation from './FormSchoolInformation';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';

type Props = {
  data: FormCreateAccountProps;
  handler: (data: FormCreateAccountProps) => void;
};

const SignUp = ({ data, handler }: Props) => {
  const { id: inviteToken } = useParams();
  if (inviteToken) setItemToStorage('inviteToken', inviteToken);

  const dispatch = useDispatch();

  const show = Object.keys(data).length === 0;

  const handleSignUp = () => dispatch(signUpAction(data));

  const error = useSelector(errorSelector);
  const isShowForm = error && error.key;

  return (
    <>
      {isShowForm ? <UnAuthorized error={error} /> : <ErrorMessage>{error}</ErrorMessage>}
      {show && !isShowForm && <FormCreateAccount onSubmit={handler} />}
      {!show && <FormSchoolInformation onSubmit={handleSignUp} />}
    </>
  );
};

export default SignUp;
