import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getStudentsList,
  clearStudentPin,
  resetStudentPassword,
  deleteStudent,
  clearStudentsList,
  getNewNotifications,
} from 'src/ducks/user/actions';
import { getStudentsListSelector } from 'src/ducks/user/selectors';
import { storeSelector, errorSelector, loadingUserSelector } from 'src/ducks/user/selectors';
import Loader from 'src/components/common/error-pages/Loader';
import { ContinueButton } from 'src/components/pages/auth/signin/styles';
import { ButtonContainer } from 'src/components/pages/students/studentForm/styles';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { StudentsList } from './studenstList';
import { StudentContainer, ActionContainer, StoreNameContainer } from './styles';

const Students = () => {
  const dispatch = useDispatch();
  const userStores = useSelector(storeSelector);
  const error = useSelector(errorSelector);
  const studentsList = useSelector(getStudentsListSelector);
  const isLoading = useSelector(loadingUserSelector);
  const currentPage = studentsList?.currentPage;
  const totalPages = studentsList?.totalPages;

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      dispatch(getStudentsList(Number(currentPage) + 1));
    }
  };

  useEffect(() => {
    dispatch(getNewNotifications());
    dispatch(getStudentsList());
    return () => {
      dispatch(clearStudentsList());
    };
  }, [dispatch]);

  const handleRemoveStudent = (studentId: number) => {
    dispatch(deleteStudent(studentId));
  };

  const handleClearPin = () => {
    dispatch(clearStudentPin());
  };

  const handleResetPassword = (userId: number) => {
    dispatch(resetStudentPassword(userId));
  };

  return (
    <>
      <StudentContainer>
        {userStores?.length &&
          userStores.map((userStore) => (
            <div key={userStore.store.id}>
              <ErrorMessage>{error}</ErrorMessage>
              <ActionContainer>
                <StoreNameContainer variant="h5">{userStore.store.name}</StoreNameContainer>
                {userStore.isTeacher && (
                  <ButtonContainer>
                    <Link to="/students/add">
                      <ContinueButton variant="contained" color="primary" type="submit">
                        + Add Student
                      </ContinueButton>
                    </Link>
                  </ButtonContainer>
                )}
              </ActionContainer>
              {isLoading && !studentsList?.list?.length ? (
                <Loader />
              ) : studentsList?.list?.length ? (
                <StudentsList
                  isTeacher={userStore.isTeacher}
                  studentsList={studentsList.list}
                  handleClearPin={handleClearPin}
                  handleResetPassword={handleResetPassword}
                  handleRemoveStudent={handleRemoveStudent}
                  loadMore={loadMore}
                  error={error}
                />
              ) : (
                <div>You don't have any students</div>
              )}
            </div>
          ))}
      </StudentContainer>
    </>
  );
};

export default Students;
