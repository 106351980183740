import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Select } from '@material-ui/core';
import { SelectWrapper, styles } from './styles';
import { WebsiteCollection } from 'src/constants/productsTypes';

/** @TODO REUSE THIS COMPONENT FOR ALL REDUX FORM INDEPEDANT SELECT COMPONENTS */

const useStyles = makeStyles(styles);

type CollectionSelectProps = {
  collections: WebsiteCollection[];
  selectedCollection: WebsiteCollection;
  onCollectionChange: (collection: WebsiteCollection) => void;
};

const CollectionSelect = ({
  collections = [],
  selectedCollection,
  onCollectionChange,
}: CollectionSelectProps) => {

  const classes = useStyles();

  const renderOptions = collections.map(( {id, collectionName}: WebsiteCollection) => (
    <MenuItem value={id} key={id}>
      {collectionName}
    </MenuItem>
  ));

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<{ name?: string, value: unknown }>) => {
    const collection = collections.find(
      collection => String(collection.id) === String(value)
    );
    if (collection) {
      onCollectionChange(collection);
    }
  }

  return (
    <SelectWrapper>
      <Select
        id="productSelect-collection"
        value={selectedCollection?.id || ''}
        onChange={handleChange}
        className={classes.select}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          elevation: 0,
          PopoverClasses: { paper: classes.popover },
        }}
        variant="outlined"
      >
        {renderOptions}
      </Select>
    </SelectWrapper>
  );
};

export default CollectionSelect;
