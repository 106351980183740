import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import InputMask from 'react-input-mask';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { FormInputComponent } from 'src/components/common/formInput/FormInput';
import { styles } from '../../inputs/styles';

type MaskedProps = {
  name: string;
  disabled?: boolean;
  maxLength?: number;
  type?: string;
  label?: string;
  validate?: ((value: string) => string | null)[];
  customRef?: React.Ref<HTMLButtonElement>;
  mask?: string | Array<string | RegExp>;
};

const MaskedInput = (fieldProps: MaskedProps & WrappedFieldProps & WithStyles) => {
  const updatedFieldProps = { ...fieldProps };
  delete updatedFieldProps.classes;
  delete updatedFieldProps.mask;
  const Component = FormInputComponent(updatedFieldProps);

  return (
    <InputMask
      mask={fieldProps.mask || ''}
      disabled={fieldProps.disabled}
      alwaysShowMask
      {...fieldProps.input}
    >
      {() => Component}
    </InputMask>
  );
};

const MaskedInputField = React.forwardRef<HTMLButtonElement, MaskedProps>((props, ref) => (
  <Field component={MaskedInput} customRef={ref} {...props} />
));

export default withStyles(styles)(MaskedInputField);
