import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Typography } from '@material-ui/core';
import { errorSelector } from 'src/ducks/auth/selectors';
import { FormInputField } from 'src/components/common/inputs';
import { switchPages } from 'src/ducks/auth/actions';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import validations from 'src/components/common/inputs/validations';
import { Link } from 'react-router-dom';
import { EmailIcon } from 'src/components/common/Icons';
import {
  ErrorMessage,
  FormContainer,
  ContinueButton,
  AdditionalInfo,
  ContinueButtonContainer,
} from './styles';

export type EmailProps = {
  email: string;
};

type Props = InjectedFormProps<EmailProps, {}>;

const ForgotPassword = (props: Props) => {
  const { handleSubmit, valid } = props;
  const error = useSelector(errorSelector);
  const dispatch = useDispatch();
  const goBack = () => dispatch(switchPages());
  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <FormInputField
          whiteBg
          placeholder="Enter your email"
          adornment={<EmailIcon />}
          name="email"
          maxLength={inputRestrictions.MAX_LENGTH_EMAIL}
          validate={validations.email}
        />
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            Reset Password
          </ContinueButton>
        </ContinueButtonContainer>
        <AdditionalInfo>
          <Link to="/" onClick={goBack}>
            <Typography variant="body2">Go back to Login</Typography>
          </Link>
        </AdditionalInfo>
      </FormContainer>
    </form>
  );
};

export default reduxForm<EmailProps, {}>({
  form: 'forgotpassword',
})(ForgotPassword);
