import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Theme, Button, ButtonProps } from '@material-ui/core';
import { ResetPinButton } from '../../students/studenstList/styles';

export const styles = (theme: Theme) => ({
  select: {
    width: '100%',
    minWidth: 200,
    height: 45,
    backgroundColor: theme.customPalette.background.inputWhiteBg,
    '&.Mui-disabled': {
      opacity: 0.7,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.customPalette.borders.select}`,
      },
    },
    '& .MuiOutlinedInput-input': {
      background: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.select}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.select}`,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.select}`,
    },
  },
  input: {
    color: theme.customPalette.text.primary,
  },
  menuItem: {
    color: theme.customPalette.text.primary,
    '&:hover': {
      background: theme.customPalette.background.inputBg,
    },
  },
  popover: {
    border: `1px solid ${theme.customPalette.borders.select}`,
    marginTop: 5,
  },
});

export const ReportWrapper = styled('div')({
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: 30,
  '& > *': {
    marginRight: 20,
  }
});

export const BaseButton = withTheme(
  styled((props: ButtonProps & WithTheme) => <Button {...props} />)({
    minWidth: 130,
    boxShadow: '0px 8px 16px rgba(0, 141, 186, 0.19)',
    textTransform: 'capitalize',
    fontSize: 15,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    },
    '&:disabled': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledBg,
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledColor,
    },
  }),
);

export const SelectWrapper = styled('div')({
  minWidth: 150,
})

export const ApplyButton = styled(BaseButton)({
});

export const ResetButton = styled(ResetPinButton)({
  width: 'auto',
  minWidth: 130,
});

export const ExportReportButton = styled(ResetPinButton)({
  width: 'auto',
  marginLeft: 'auto',
  marginRight: 0,
  minWidth: 130,
  '& svg': {
    marginRight: 10,
  }
});

export const DownloadReportButton = styled(BaseButton)({  
  marginLeft: 'auto',
  marginRight: 0,
  minWidth: 130,
});

export const ExportInfoWrapper = styled('div')({
  color: 'rgb(0, 129, 255)',
})

export const ExportErrorWrapper = styled('div')({
  color: '#FF3D57',
})