import { moduleName } from './orders';

export const LIST_ORDER = `${moduleName}/LIST_ORDER`;
export const LIST_ORDER_SUCCESS = `${moduleName}/LIST_ORDER_SUCCESS`;
export const LIST_ORDER_FAIL = `${moduleName}/LIST_ORDER_FAIL`;

export const CLEAR_ORDER_LIST = `${moduleName}/CLEAR_ORDER_LIST`;

export const VIEW_ORDER = `${moduleName}/VIEW_ORDER`;
export const VIEW_ORDER_SUCCESS = `${moduleName}/VIEW_ORDER_SUCCESS`;
export const VIEW_ORDER_FAIL = `${moduleName}/VIEW_ORDER_FAIL`;

export const EDIT_ORDER = `${moduleName}/EDIT_ORDER`;
export const EDIT_ORDER_SUCCESS = `${moduleName}/EDIT_ORDER_SUCCESS`;
export const EDIT_ORDER_FAIL = `${moduleName}/EDIT_ORDER_FAIL`;
