import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { Select, MenuItem, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styles } from './styles';

export type SelectOptions = {
  name: string;
  value: string;
};

type Props = {
  name: string;
  validate?: ((value: string) => string | null)[];
  options?: SelectOptions[];
  selectedValue?: string;
  handleChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  renderValue?: any;
  customOptions?: any;
};

const useStyles = makeStyles(styles);

const SelectComponent = (props: Props & WrappedFieldProps) => {
  const {
    input,
    meta: { error, active, touched },
    options,
    selectedValue,
    handleChange,
    renderValue,
    customOptions,
  } = props;
  const classes = useStyles();
  const hasError = touched && !active && !!error;

  const renderOptions = options?.map(({ name, value }: SelectOptions) => (
    <MenuItem key={value} value={value} className={classes.menuItem}>
      {name}
    </MenuItem>
  ));

  const renderCustomSelector = () => (
    <Select
      {...input}
      error={hasError}
      className={classes.select}
      value={selectedValue}
      displayEmpty
      renderValue={renderValue}
      onChange={handleChange}
      inputProps={{
        className: classes.input,
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        elevation: 0,
        PopoverClasses: { paper: classes.popover },
      }}
      variant="outlined"
      children={customOptions}
    />
  );

  return (
    <>
      {customOptions ?
        renderCustomSelector() :
        <Select
          {...input}
          error={hasError}
          className={classes.select}
          value={selectedValue}
          displayEmpty
          renderValue={renderValue}
          onChange={handleChange}
          inputProps={{
            className: classes.input,
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
            elevation: 0,
            PopoverClasses: { paper: classes.popover },
          }}
          variant="outlined"
        >
          {renderOptions}
        </Select>
      }
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </>
  );
};

const SelectField = (props: Props) => <Field component={SelectComponent} {...props} />;

export default SelectField;
