import React, { useState, useEffect } from 'react';
import { Radio, FormControlLabel } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import routes from 'src/routes/config';
import { ItemText, ItemIcon } from '../styles';
import { ExpansionStore, ExpansionSummary, RadioContainer, StoreMenuList } from './styles';
import { InternalLink } from 'src/components/common/smartLinkWrapper/styles';
import { CheckedIconRadioMenu, IconRadioMenu } from 'src/components/common/Icons';

const storeItems = [
  { link: routes.students, label: 'Students' },
  { link: routes.website, label: 'Website Design' },
  { link: routes.products, label: 'Products' },
  { link: routes.orders, label: 'Orders' },
];

type StoreProps = {
  name: string;
  Icon: () => JSX.Element;
};

const StoreMenu = ({ Icon, name }: StoreProps) => {
  const { pathname } = useLocation();
  const splitPathname = pathname.split('/');
  const currentStoreItems = storeItems.find((el) => splitPathname.includes(el.link.slice(1)));
  const currentLabel = currentStoreItems?.label || null;
  const [value, setValue] = useState(currentLabel);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (currentLabel) {
      setValue(currentLabel);
      setOpen(true);
    } else setValue(null);
  }, [currentLabel]);

  const handleChange = (value: string) => setValue(value);
  const handleOpen = (event: React.ChangeEvent<{}>, expanded: boolean) => setOpen(expanded);
  const storeIconStatus = () => (value ? 'selected' : '');
  const status = (label: string) => (value === label ? 'selected' : '');

  return (
    <ExpansionStore expanded={open} onChange={handleOpen}>
      <ExpansionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ItemIcon status={storeIconStatus()}>
          <Icon />
        </ItemIcon>
        <ItemText primary={name} fontWeightMedium />
      </ExpansionSummary>
      <StoreMenuList>
        {storeItems.map((item: { link: string; label: string }) => (
          <InternalLink to={item.link} onClick={() => handleChange(item.label)} key={item.label}>
            <RadioContainer value={value} status={status(item.label)}>
              <FormControlLabel
                value={item.label}
                control={<Radio icon={<IconRadioMenu />} checkedIcon={<CheckedIconRadioMenu />} />}
                label={item.label}
              />
            </RadioContainer>
          </InternalLink>
        ))}
      </StoreMenuList>
    </ExpansionStore>
  );
};

export default StoreMenu;
