import {
  GET_SHOP_PRODUCTS,
  VIEW_SHOP_PRODUCT,
  ADD_PRODUCT_TO_SHOPPING_CART,
  SET_IS_VALID_PRODUCTS,
  CHECKOUT_WITH_VALIDATION,
  GET_SHOP_PRODUCTS_BY_IDS,
  SET_SHIPPING_METHOD,
  GET_SHOP_OTHER_PRODUCTS,
} from './constants';

export type ShopProductsType = {
  type: string;
  payload: ShopProductsPayload;
};

export type ShopProductsPayload = {
  count?: number;
  page?: number;
  path: string;
  productsIds?: number[];
  isShowMore?: boolean;
  collectionName?: string;
  isOtherCollection?: boolean;
};

export function getShopProducts({
  count = 18,
  page = 1,
  path,
  productsIds = [],
  isShowMore = false,
  collectionName,
}: ShopProductsPayload) {
  return {
    type: GET_SHOP_PRODUCTS,
    payload: { page, count, path, productsIds, isShowMore, collectionName },
  };
}

export function getShopOtherProducts({
  count = 6,
  page = 1,
  path,
  productsIds = [],
  isShowMore = false,
  collectionName,
}: ShopProductsPayload) {
  return {
    type: GET_SHOP_OTHER_PRODUCTS,
    payload: { page, count, path, productsIds, isShowMore, collectionName, isOtherCollection: true }
  }
} 

export type ShopProductsByIds = {
  type: string;
  payload: ShopProductsByIdsPayload;
}

export type ShopProductsByIdsPayload = {
  websitePath: string;
  productsIds?: number[];
  isCheckout?: boolean;
};

export function getShopProductsByIds({ websitePath, productsIds = [], isCheckout = false }: ShopProductsByIdsPayload) {
  return {
    type: GET_SHOP_PRODUCTS_BY_IDS,
    payload: { websitePath, productsIds, isCheckout },
  };
}

export type AddProductToShoppingCart = {
  type: string;
  payload: {
    productAmount: number;
    productChoiceId?: number;
  }
}

export function addProductToShoppingCart(productAmount: number, productChoiceId?: number) {
  return {
    type: ADD_PRODUCT_TO_SHOPPING_CART,
    payload: { productAmount, productChoiceId },
  };
}

export type ViewProduct = {
  type: string;
  payload: {
    productId: number;
    websitePath: string;
  }
}

export function viewShopProduct(productId: number, websitePath: string) {
  return {
    type: VIEW_SHOP_PRODUCT,
    payload: { productId, websitePath },
  };
}

export function setIsValidProducts(externalProducts: any) {
  return { type: SET_IS_VALID_PRODUCTS, payload: externalProducts };
}

export function checkoutWithValidation(websitePath: string) {
  return {
    type: CHECKOUT_WITH_VALIDATION,
    payload: websitePath,
  };
}

export function setShippingMethod(shippingMethod: string | null) {
  return {
    type: SET_SHIPPING_METHOD,
    payload: shippingMethod,
  }
}
