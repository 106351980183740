import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Tab, TabProps, Tabs } from '@material-ui/core';

export const TabsWrapper = styled(({ ...otherProps }: any) => (
  <Tabs classes={{ indicator: 'indicator', flexContainer: 'flexContainer' }} {...otherProps} />
))({
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#008DBA',
    '& > span': {
      width: '100%',
      backgroundColor: '#008DBA',
    },
  },
  '& .flexContainer': {
    backgroundColor: '#ffffff',
  },
});

export const CustomTab = withTheme(
  styled(({ ...otherProps }: TabProps) => (
    <Tab classes={{ wrapper: 'template-icon', selected: 'selected' }} {...otherProps} />
  ))({
    color: '#44566C',
    textTransform: 'capitalize',
    fontSize: 15,
    opacity: 1,
    '& .template-icon': {
      flexDirection: 'row',
      '& > svg': {
        margin: '0 6px 0 0 !important',
        '& > path': {
          fill: '#8697A8',
        },
      },
    },
    '&.selected': {
      '& .template-icon': {
        color: '#44566C',
        '& svg > path': {
          fill: '#44566C',
        },
      },
    },
  })
);
