import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { ShoppingCartContainer, ShoppingCartName } from '../styles';
import { StylesProps } from '../order/styles';

export const ShippingContainer = ShoppingCartContainer;

export const ShippingPrompt = styled('div')({
  fontSize: 18,
  paddingTop: 8,
});

export const ShippingName = ShoppingCartName;

export const FormContainer = styled('div')({
  width: '100%',
  paddingTop: 24,
  paddingBottom: 30,
});

export const InputsWrapper = styled('div')({
  display: 'flex',
  '& > div': {
    width: 200,
    flex: '1 1 auto',
    paddingRight: 40,
    '@media (max-width: 830px)': {
      width: 'auto',
      paddingRight: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  '@media (max-width: 830px)': {
    flexDirection: 'column',
  },
});

export const TextAreaContainer = styled('div')({
  '& textarea': {
    height: 80,
    border: '1px solid #EAEDF0',
    background: 'none',
    resize: 'none',
  },
});

export const ChangeAddressContainer = styled('div')({
  textAlign: 'right',
});

export const CommentTitleContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& > span': {
    paddingLeft: 10,
  },
});

export const ConfirmAddressButton = styled(
  ({ theme, styles, isConfirmAddress, ...otherProps }: any) => <button {...otherProps} />,
)({
  width: 300,
  marginBottom: 30,
  border: ({ styles }: any) => styles.checkoutButton.border,
  borderRadius: ({ styles }: StylesProps) => styles?.checkoutButton.borderRadius,
  textAlign: 'center',
  padding: '16px 0',
  fontSize: 24,
  fontWeight: ({ styles }: StylesProps) => styles?.checkoutButton.fontWeight,
  cursor: 'pointer',
  color: ({ isConfirmAddress, styles }: any) =>
    isConfirmAddress ? styles.changeAddressColor : styles.orderColor,
  backgroundColor: ({ isConfirmAddress, styles }: any) =>
    isConfirmAddress ? styles.changeAddressBackground : styles.confirmAddressBackground,
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    cursor: 'unset',
    opacity: 0.5,
  },
  '@media (max-width: 768px)': {
    width: '100%',
    textAlign: 'center',
  },
});

export const CartWrapper = styled('div')({
  flex: 1,
  '& > div > div': {
    padding: 0,
    margin: 0,
  },
  '& > div > div > div': {
    flex: 1,
  },
});

export const BackIcon = styled(({ styles, ...otherProps }: any) => <Link {...otherProps} />)({
  position: 'absolute',
  top: -40,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '& > div': {
    color: ({ styles }: any) => styles.cartColor,
    fontSize: 18,
    paddingLeft: 15,
  },
  '& > svg': {
    '& > path': {
      fill: ({ styles }: any) => styles.cartColor,
    },
  },
  '@media (max-width: 650px)': {
    position: 'relative',
    top: -10,
  },
});
