import { Theme, ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { createMuiTheme } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createMuiTheme' {
  export interface Theme {
    customPalette: {
      avatar: {
        color?: string;
        background?: string;
      };
      background: {
        greyBg: string;
        inputBg: string;
        inputWhiteBg: string;
        header: string;
        page: string;
        blue: string;
        footerBlue: string;
      };
      buttons: {
        activeBg: string;
        activeColor: string;
        disabledBg: string;
        disabledColor: string;
        blue: string;
        primary: string;

        secondary: {
          color: string;
          bg: string;
          hoverColor: string;
          hoverBg: string;
          disabledColor: string;
          disabledBg: string;
        };
      };
      modalButtons: {
        bg: string;
        color: string;
        secondaryColor: string;
        hoverBg: string;
        activeBg: string;
      };
      borders: {
        activeInput: string;
        select: string;
        errorInput: string;
        successInput: string;
        placeholder: string;
        divider: string;
        menu: string;
      };
      text: {
        primary: string;
        grey: string;
        error: string;
        success: string;
        blue: string;
        active: string;
        lightGrey: string;
        highlight: string;
      };
      checkbox: {
        default: string;
        checked: string;
        label: string;
      };
    };
    siteTemplatePalette: {
      text: {
        primary: string,
        light: string,
        success: string, 
        secondary: string,
      },
      background: {
        main: string,
        secondary: string,
        btn: string,
        btnHover: string,
        whiteBtn: string,
        whiteBtnHover: string,
        dark: string,
        dark1: string,
      },
      shadow: {
        btn: string,
        whiteBtn: string,
      },
    }
  }

  export interface ThemeOptions {
    customPalette?: {
      avatar: {
        color?: string;
        background?: string;
      };
      background?: {
        greyBg?: string;
        inputBg?: string;
        inputWhiteBg?: string;
        header?: string;
        page?: string;
        blue?: string;
        primary?: string;
        footerBlue?: string;
      };
      buttons?: {
        activeBg?: string;
        activeColor?: string;
        disabledBg?: string;
        disabledColor?: string;
        blue?: string;

        secondary?: {
          color?: string;
          bg?: string;
          hoverColor?: string;
          hoverBg?: string;
          disabledColor?: string;
          disabledBg?: string;
        };
      };
      modalButtons?: {
        bg?: string;
        color?: string;
        secondaryColor?: string;
        hoverBg?: string;
        activeBg?: string;
      };
      borders?: {
        activeInput?: string;
        select?: string;
        errorInput?: string;
        successInput?: string;
        placeholder?: string;
        divider?: string;
        menu?: string;
      };
      text?: {
        primary?: string;
        grey?: string;
        error?: string;
        success?: string;
        blue?: string;
        active?: string;
        highlight?: string;
      };
      checkbox?: {
        default: string;
        checked: string;
        label?: string;
      };
    };
    siteTemplatePalette?: {
      text: {
        primary: string,
        light: string,
        success: string, 
        secondary: string,
      },
      background: {
        main: string,
        secondary: string,
        btn: string,
        btnHover: string,
        whiteBtn: string,
        whiteBtnHover: string,
        dark: string,
        dark1: string,
      },
      shadow: {
        btn: string,
        whiteBtn: string,
      },
    }
  }
}

export default function createMyTheme(options: any) {
  return createMuiTheme({ ...options });
}
