import React, { useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { isAuthenticatedSelector } from 'src/ducks/user/selectors';
import { getCurrentUser } from 'src/ducks/user/actions';
import { initApplication } from 'src/ducks/auth/actions';
import { Home } from 'src/components/pages/home';
import { Students } from 'src/components/pages/students';
import { AddStudent } from 'src/components/pages/students/studentForm';
import { Website } from 'src/components/pages/website';
import { Profile } from 'src/components/pages/profile';
import { MainLayout } from 'src/containers/main';
import Products from 'src/components/pages/products/Products';
import Product from 'src/components/pages/products/Product';
import { Orders } from 'src/components/pages/orders';
import { ViewOrder } from 'src/components/pages/orders/viewOrder';
import { ViewWebsite } from 'src/components/pages/view-website';
import { Terms } from 'src/components/pages/terms';
import { Accounting } from './pages/accounting';
import { ShoppingCartContainer } from 'src/components/pages/shoppingCart';
import { SuccessWebsitePage } from 'src/components/pages/success-website-page';
import { Cashout } from 'src/components/pages/cashout/cashout';
import { ShopProductDetailsContainer } from 'src/components/pages/shopProductDetails';
import NotFound from 'src/components/common/error-pages/NotFound';
import AuthPage from 'src/routes/AuthPage';
import CanceledPaymentRoute from 'src/routes/CanceledPaymentRoute';
import { getItemByKeyFromStorage } from 'src/utils/helpers';
import routesPath from 'src/routes/config';
import history from 'src/containers/history';
import '@stripe/stripe-js';

import 'src/assets/styles/main.css';
import { OrderMobile } from './pages/orders/OrderMobile';

const RouterComponent = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const isAuthLocalStorage = getItemByKeyFromStorage('user');

  useLayoutEffect(() => {
    dispatch(initApplication());
  }, [dispatch]);

  useLayoutEffect(() => {
    const pathname = window.location.pathname;
    const isSignupRoute = pathname.includes('signup');
    const isResetPasswordRoute = pathname.includes('reset-password');
    const isTermsOfService = pathname.includes('terms-of-service');
    const isPublicRoute = /\bshop/.test(pathname);
    const getUserCondition =
      pathname !== '/' && !isResetPasswordRoute && !isSignupRoute && !isPublicRoute;

    if (!isAuthLocalStorage && isTermsOfService) {
      return;
    }

    if (getUserCondition) {
      dispatch(getCurrentUser());
    }
  }, [dispatch]);

  const PrivateRoutes = () => (
    <MainLayout isAuthenticated={isAuthenticated}>
      <Switch>
        <Route exact path={routesPath.signin} component={AuthPage} />
        <Route exact path={routesPath.signup} component={AuthPage} />
        <Route exact path={routesPath.forgotPassword} component={AuthPage} />
        <Route exact path={routesPath.resetPassword} component={AuthPage} />
        <Route exact path={routesPath.home} component={Home} />
        <Route exact path={routesPath.students} component={Students} />
        <Route exact path={routesPath.addStudent} component={AddStudent} />
        <Route exact path={routesPath.editStudent} component={AddStudent} />
        <Route exact path={routesPath.products} component={Products} />
        <Route exact path={routesPath.addProduct} component={Product} />
        <Route exact path={routesPath.editProduct} component={Product} />
        <Route exact path={routesPath.website} component={Website} />
        <Route exact path={routesPath.profile} component={Profile} />
        <Route exact path={routesPath.orders} component={Orders} />
        <Route exact path={routesPath.viewOrder} component={ViewOrder} />
        <Route exact path={routesPath.viewOrderMobile} component={OrderMobile} />
        <Route exact path={routesPath.termsOfService} component={Terms} />
        <Route exact path={routesPath.accounting} component={Accounting} />
        <Route exact path={routesPath.addCashout} component={Cashout} />
        <Route exact path={routesPath.editCashout} component={Cashout} />
        <Route exact path={routesPath.notFound} component={NotFound} />
        <Redirect exact from="*" to={routesPath.notFound} />
      </Switch>
    </MainLayout>
  );

  return (
    <Router history={history as any}>
      <Switch>
        <Route exact path={routesPath.viewWebsite} component={ViewWebsite} />
        <Route exact path={routesPath.shoppingCart} component={ShoppingCartContainer} />
        <Route exact path={routesPath.shoppingOrder} component={ShoppingCartContainer} />
        <Route exact path={routesPath.successBilling} component={SuccessWebsitePage} />
        <Route exact path={routesPath.productDetails} component={ShopProductDetailsContainer} />
        <CanceledPaymentRoute exact path={routesPath.cancelBilling} />
        <Route path="*" render={() => <PrivateRoutes />} />
      </Switch>
    </Router>
  );
};

export default RouterComponent;
