import styled from 'styled-components';

export const UploadImgStyles = styled.div`
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  input[type='file'] {
    opacity: 0;
    z-index: -1;
    height: 0;
    width: 0;
  }
`;

export const Output = styled.div`
  img {
    width: 100%;
    height: 547px;
    max-width: 100%;
    max-height: 547px;
  }
  position: relative;
  margin-top: 20px;
  &:nth-child(6n + 6) {
    margin-right: 0;
  }
`;

export const Delete = styled('div')({
  marginTop: 20,
})

export const Overlay = styled('div')({
  display: 'flex',
  height: 547,
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#ffffff',
  fontWeight: 500,
  opacity: 0,
  '&:hover': {
    opacity: 1,
    cursor: 'pointer',
    backgroundColor: 'rgba(68, 86, 108, 0.8)',
  },
  '> svg': {
    paddingBottom: 5,
  }
})

export const OverlayText = styled.div`
  line-height: 21px;
  font-size: 15px;
  font-weight: 400;
  padding-top: 5px;
`;

export const ErrorMessage = styled('div')({
  color: 'red',
});
