export const PROFITS_TAB = 0;
export const TOOLKIT_TAB = 1;
export const CASHOUT_TAB = 2;

export interface ITab {
    tab: number,
}

const defaultTab: ITab = {
    //tab: TOOLKIT_TAB,
    tab: 3,
}

export const getCurrentTab = (location: Location): number => {
    const query: any = new URLSearchParams(location.search);
    const tab = query.get("tab") === null ? TOOLKIT_TAB : parseInt(query.get("tab"));

    return tab
}

export const prepareTabUrl = (location: Location, tab: number): string => {
    let newUrl = location.pathname;

    if (tab !== defaultTab.tab && tab !== null) {
        let newTabParams = new URLSearchParams();
        newTabParams.append('tab', `${tab}`);
        newUrl += '?' + newTabParams.toString();
    }

    return newUrl
}