import { styled, Theme } from "@material-ui/core";

export const styles = (theme: Theme) => ({
  select: {
    width: '100%',
    fontSize: '1.38rem',
    backgroundColor: theme.customPalette.background.inputWhiteBg,
    '&.Mui-disabled': {
      opacity: 0.7,
      '& .MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.customPalette.borders.select}`,
      },
    },
    '& .MuiOutlinedInput-input': {
      background: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.placeholder}`,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.placeholder}`,
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.placeholder}`,
    },
  },
  input: {
    color: theme.customPalette.text.primary,
  },
  menuItem: {
    color: theme.customPalette.text.primary,
    '&:hover': {
      background: theme.customPalette.background.inputBg,
    },
  },
  popover: {
    border: `1px solid ${theme.customPalette.borders.placeholder}`,
    marginTop: 5,
    maxHeight: 370,
    '& li': {
      fontSize: '1.38rem',
    },
  },
});

export const SelectWrapper = styled('div')({
  maxWidth: 500,
  minWidth: 200,
})
