import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { errorSelector, calculationSelector } from 'src/ducks/shopOrder/selectors';
import { invalidProductsSelector, isValidProductsSelector } from 'src/ducks/shopProducts/selectors';
import { checkoutWithValidation, getShopProductsByIds } from 'src/ducks/shopProducts/actions';
import { StylesTemplate } from 'src/constants/stylesTemplates';
import {
  OrderContainer,
  Title,
  HorizontalLineOrder,
  SubTotalContainer,
  PriceOrder,
  DeliveryAddress,
  ProceedToPaymentButton,
} from './styles';

type CartOrderSummaryProps = {
  styles: StylesTemplate;
  cartSubtotalPrice: number;
  websitePath: string;
  productsIds: number[];
  isProductsExist: boolean;
  isMatchingMethods: boolean;
};

export const calculatePrice = (calculatedPrice: number, currentPrice: number) => {
  if (calculatedPrice) {
    return `$ ${calculatedPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
  }
  return `$ ${currentPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
};

const CartOrderSummary = (props: CartOrderSummaryProps) => {
  const { styles, cartSubtotalPrice, websitePath, productsIds, isProductsExist, isMatchingMethods } = props;
  const error = useSelector(errorSelector);
  const isValidProducts = useSelector(isValidProductsSelector);
  const invalidProducts = useSelector(invalidProductsSelector) || [];
  const calculation = useSelector(calculationSelector);
  const dispatch = useDispatch();

  const handleCheckValidation = () => {
    dispatch(getShopProductsByIds({ productsIds, websitePath, isCheckout: true }));
    dispatch(checkoutWithValidation(websitePath));
  };

  return (
    <OrderContainer styles={styles}>
      <Title styles={styles}>Order Summary</Title>
      <HorizontalLineOrder styles={styles} />
      <SubTotalContainer>
        <div>
          <Title styles={styles}>Sub-Total</Title>
          <PriceOrder styles={styles}>
            {calculatePrice(calculation.subtotal, cartSubtotalPrice)}
          </PriceOrder>
        </div>
      </SubTotalContainer>
      <DeliveryAddress>Shipping & Taxes Calculated At Checkout</DeliveryAddress>
      <HorizontalLineOrder styles={styles} />
      <SubTotalContainer>
        <div>
          <Title styles={styles}>Total</Title>
          <PriceOrder styles={styles}>
            {calculatePrice(calculation.grandTotal, cartSubtotalPrice)}
          </PriceOrder>
        </div>
      </SubTotalContainer>
      <ProceedToPaymentButton
        onClick={handleCheckValidation}
        disabled={!isValidProducts || !isProductsExist || !!error || !isMatchingMethods || !!invalidProducts.length}
        styles={styles}
      >
        Checkout
      </ProceedToPaymentButton>
    </OrderContainer>
  );
};

export default CartOrderSummary;
