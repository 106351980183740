import React from 'react';
import { TableBody, TableRow, Paper, TableContainer, IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ProductsList } from 'src/ducks/products/products';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { EditIcon } from 'src/components/common/Icons';
import defaultImg from 'src/assets/images/default.png';
import {
  TableWrapper,
  Head,
  StyledBodyTableRow,
  StyledTableCell,
  StyledTableCellTitle,
} from '../../students/studenstList/styles';
import { Image, Img, IconHover } from './styles';
import { RemoveProduct } from '../removeProduct';

type ProductsListProps = {
  productsList: any;
  loadMore: () => void;
  error: string | (string | undefined)[] | null;
  handleRemoveProduct: (studentId: number) => void;
};

const ProductsListComponent = (props: ProductsListProps) => {
  const { error, productsList, loadMore, handleRemoveProduct } = props;

  useInfiniteScroll({ loadMore });

  const renderTableActions = (id: number) => (
    <>
      <StyledTableCell align="left">
        <Link to={`/products/${id}`}>
          <IconButton>
            <IconHover>
              <EditIcon />
            </IconHover>
          </IconButton>
        </Link>
      </StyledTableCell>
      <StyledTableCell align="left">
        <RemoveProduct id={id} error={error} handleRemoveProduct={handleRemoveProduct} />
      </StyledTableCell>
    </>
  );

  const renderUsersTable = () => (
    <TableContainer component={Paper} style={{ marginTop: 40 }}>
      <TableWrapper>
        <Head>
          <TableRow>
            <StyledTableCell style={{ width: '25%' }}>Photo</StyledTableCell>
            <StyledTableCell style={{ width: '20%' }}>Name</StyledTableCell>
            <StyledTableCell align="left" style={{ width: '20%' }}>
              Price
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: '20%' }}>
              Inventory
            </StyledTableCell>
            <StyledTableCell />
            <StyledTableCell />
          </TableRow>
        </Head>
        <TableBody>
          {productsList?.list.map(({ id, name, price, inventory, imagesGallery }: ProductsList) => {
            const image = imagesGallery[Object.keys(imagesGallery)[0]];
            return (
              <StyledBodyTableRow key={id}>
                <StyledTableCell align="left">
                  <Img
                    style={
                      image &&
                      image[0].url && {
                        cursor: 'default',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${image && image[0].url})`,
                      }
                    }
                  >
                    {!image && <Image src={defaultImg} />}
                  </Img>
                </StyledTableCell>
                <StyledTableCellTitle align="left" title={name}>
                  {name}
                </StyledTableCellTitle>
                <StyledTableCell align="left">${price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</StyledTableCell>
                <StyledTableCell align="left">{inventory}</StyledTableCell>
                {renderTableActions(id)}
              </StyledBodyTableRow>
            );
          })}
        </TableBody>
      </TableWrapper>
    </TableContainer>
  );

  return renderUsersTable();
};

export default ProductsListComponent;
