import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import {
  increaseProductAmount,
  decreaseProductAmount,
  removeProduct,
  hasExternalProductsChanged,
  availableShippingMethods,
  prepareIsExistShippingMethod,
} from 'src/utils/helpers';
import productImage from 'src/assets/images/shopping-cart/product-image.png';
import { RemoveIconCart } from 'src/components/common/Icons';
import { LocalStorageType } from 'src/utils/helpers';
import {
  HorizontalLine,
  ProductContainer,
  CardContainer,
  CardInfo,
  CardNameWithPriceContainer,
  CardName,
  WarningProductAmount,
  ChangedPrice,
  CardBottomContainer,
  Price,
  CounterAndDeleteProduct,
  ShoppingCartImage,
  ProductChoice,
} from '../styles';
import { LOCAL_DELIVERY, LOCAL_PICKUP, NON_USPS_SHIPPING, NOT_APPLICABLE, USPS_DELIVERY, SHIPPING_NOT_AVAILABLE, USPS_NON_PRIORITY_MAIL, USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';
import { cartShippingMethods } from 'src/constants/cartShippingMethods';

type ShoppingProductsProps = {
  shopProduct: LocalStorageType;
  styles: any;
  externalProducts: any;
  handleUpdateProducts: (products: any) => void;
  isInvalidPage: boolean;
  isMatchingMethods: boolean;
  shippingMethod: string | null;
};

const ShoppingProducts = (props: ShoppingProductsProps) => {
  const { id: websitePath } = useParams();
  const history = useHistory();
  const {
    shopProduct,
    styles,
    externalProducts = [],
    handleUpdateProducts,
    isInvalidPage,
    isMatchingMethods,
    shippingMethod,
  } = props;
  const {
    count,
    product: {
      id,
      name,
      imagesGallery,
      price,
      isPriceChanged,
      externalPrice,
      isInventoryChanged,
      externalInventory = 0,
      isPhysicalProduct
    },
    productChoice,
  } = shopProduct;
  const image = imagesGallery[Number(Object.keys(imagesGallery)[0])];
  const totalPrice = (count * externalPrice).toLocaleString('en-US', { minimumFractionDigits: 2 });
  const isProductCountExceeds = isPhysicalProduct ? count >= externalInventory : isInventoryChanged;

  const availableMethods = availableShippingMethods([shopProduct]);
  const isExistShippingMethod = prepareIsExistShippingMethod(shippingMethod, availableMethods, shopProduct.product);
  const [isOutOfStock, setIsOutOfStock] = useState(false);

  useEffect(() => {
    setIsOutOfStock(((isPhysicalProduct || (!isPhysicalProduct && isInventoryChanged)) &&
      externalInventory === 0) || !externalProducts?.find((p: any) => p.id === id))
  }, [externalProducts, isPhysicalProduct, isInventoryChanged, externalInventory, id])

  useEffect(() => {
    if (externalProducts?.length) {
      const updatedProducts = hasExternalProductsChanged(shopProduct, externalProducts);
      handleUpdateProducts(updatedProducts);
    }
  }, [externalProducts]);

  const handleDecreaseAmount = (id: number) => {
    const updatedProducts = decreaseProductAmount(id, productChoice?.id);
    handleUpdateProducts(updatedProducts);
  };

  const handleIncreaseAmount = (id: number, externalInventory: number) => {
    const updatedProducts = increaseProductAmount(id, externalInventory, productChoice?.id);
    handleUpdateProducts(updatedProducts);
  };

  const handleRemoveProduct = (id: number) => {
    const updatedProducts = removeProduct(id, productChoice?.id);
    handleUpdateProducts(updatedProducts);
  };

  const getInventoryWarning = (externalInventory: number) => {
    const outOfStockMessage = 'the product is out of stock';
    const inventoryWarningAmount = `only ${externalInventory} items left`;

    if (externalInventory === 0 || isOutOfStock) {
      return outOfStockMessage;
    } else {
      return inventoryWarningAmount;
    }
  };

  const disableRedirectIfOutOfStock = (e: SyntheticEvent) => {
    if (
      (isPhysicalProduct && externalInventory === 0) ||
      (!isPhysicalProduct && isInventoryChanged)) {
      e.preventDefault();
    } else {
      history.push(`/shop/${websitePath}/${id}`);
    }
  };

  const renderProductChoice = () => {
    if (productChoice) {
      if (productChoice.productOptionValues[1]) {
        return `${productChoice.productOptionValues[0].name}, ${productChoice.productOptionValues[1].name}`;
      }
      return productChoice.productOptionValues[0].name;
    }
    return null;
  };

  const renderShippingMethod = () => {
    const { product } = shopProduct;
    let methods = [];
    const isUspsDelivery = product.shippingType === USPS_PRIORITY_MAIL || product.shippingType === USPS_NON_PRIORITY_MAIL;

    if (product.isPhysicalProduct) {
      if (isUspsDelivery) {
        methods.push(cartShippingMethods[USPS_DELIVERY]);
      }
      if (product.shippingType === NON_USPS_SHIPPING) {
        methods.push(cartShippingMethods[NON_USPS_SHIPPING]);
      }
      if (product.shippingType === SHIPPING_NOT_AVAILABLE) {
        methods.push(cartShippingMethods[SHIPPING_NOT_AVAILABLE]);
      }
      if (product.isLocalPickup) {
        methods.push(cartShippingMethods[LOCAL_PICKUP]);
      }
      if (product.isLocalDelivery) {
        methods.push(cartShippingMethods[LOCAL_DELIVERY]);
      }
    } else {
      methods.push(cartShippingMethods[NOT_APPLICABLE]);
    }

    return `Shipping Method: ${methods.join(', ')}`;
  };

  return (
    <>
      <ProductContainer>
        <CardContainer>
          <ShoppingCartImage
            src={image ? image[0].url : productImage}
            onClick={disableRedirectIfOutOfStock}
            isOutOfStock={isOutOfStock}
            isExistShippingMethod={isExistShippingMethod}
          />
          <CardInfo>
            <CardNameWithPriceContainer isExistShippingMethod={isExistShippingMethod}>
              <div>
                <CardName
                  onClick={disableRedirectIfOutOfStock}
                  isOutOfStock={isOutOfStock}
                >
                  {name}
                </CardName>
                <ProductChoice>{renderProductChoice()}</ProductChoice>
                <ProductChoice>{!isMatchingMethods && renderShippingMethod()}</ProductChoice>
              </div>
              <div>
                {(isOutOfStock || (isInventoryChanged && externalInventory < count)) && (
                  <WarningProductAmount>
                    {getInventoryWarning(externalInventory)}
                  </WarningProductAmount>
                )}
                {(isPhysicalProduct ? (externalInventory !== 0 && isPriceChanged) : isPriceChanged) && (
                  <ChangedPrice>
                    {`the price has changed $${externalPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })} `}
                    <span>{`$${price.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}</span>
                  </ChangedPrice>
                )}
              </div>
            </CardNameWithPriceContainer>
            <CardBottomContainer>
              {styles &&
                <Price
                  styles={styles}
                  isExistShippingMethod={isExistShippingMethod}
                >
                  $ {totalPrice}
                </Price>
              }
              <CounterAndDeleteProduct
                isOutOfStock={isOutOfStock}
                isProductCountExceeds={isProductCountExceeds}
                isExistShippingMethod={isExistShippingMethod}
              >
                <div>
                  {!isInvalidPage && <span onClick={() => handleDecreaseAmount(id)}>&#8722;</span>}
                  <span>{count}</span>
                  {!isInvalidPage && (
                    <span onClick={() => handleIncreaseAmount(id, externalInventory)}>+</span>
                  )}
                </div>
                {!isInvalidPage && (
                  <div onClick={() => handleRemoveProduct(id)}>
                    <RemoveIconCart />
                  </div>
                )}
              </CounterAndDeleteProduct>
            </CardBottomContainer>
          </CardInfo>
        </CardContainer>
      </ProductContainer>
      <HorizontalLine />
    </>
  );
};

export default ShoppingProducts;
