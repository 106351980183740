import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import {
  getShippingMethodSelector,
  invalidProductsSelector,
  shopProductsByIdsSelector,
  loadingShopProductSelector
} from 'src/ducks/shopProducts/selectors';
import { setIsValidProducts, getShopProductsByIds } from 'src/ducks/shopProducts/actions';
import { updateProductsAmount } from 'src/ducks/shopOrder/actions';
import {
  getItemByKeyFromStorage,
  calculateSubtotalPrice,
  matchingMethods,
  availableShippingMethods,
} from 'src/utils/helpers';
import stylesTemplates, { StylesTemplate } from 'src/constants/stylesTemplates';
import { LocalStorageType } from 'src/utils/helpers';
import { ShoppingProducts } from './shoppingProducts';
import CartOrderSummary from './order/CartOrderSummary';
import ShippingOptionsSelector from './shippingOptionsSelector';
import {
  CartContainer,
  ShoppingCartContainer,
  ShoppingCartName,
  HorizontalLine,
  CardFooter,
  CardSubTotal,
  Wrapper,
  MisMatchDeliveryMessage,
} from './styles';
import { FullScreenPreloader } from '../../common/fullScreenPreloader';

type ShoppingCartProps = {
  websitePath: string;
  isShowInvalidProducts?: boolean;
};

const ShoppingCart = ({ websitePath, isShowInvalidProducts = false }: ShoppingCartProps) => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [productsIds, setProductsIds] = useState([]);
  const templateId = useSelector(websiteTemplateIDSelector);
  const productsList = useSelector(shopProductsByIdsSelector);
  const invalidProducts = useSelector(invalidProductsSelector);
  const shippingMethod = useSelector(getShippingMethodSelector);
  const isLoading = useSelector(loadingShopProductSelector);
  const externalProducts = productsList?.length ? productsList : undefined;
  const styles = stylesTemplates.find(({ id }: StylesTemplate) => templateId === id);
  const allProductsAmount =
    products?.reduce((acc: number, currVal: LocalStorageType) => acc + currVal.count, 0) || 0;
  const cartSubtotalPrice = Number(calculateSubtotalPrice(products, externalProducts));
  const misMatchDeliveryMethodsMessage = 'Uh-oh! It looks like there are different delivery methods in your cart. ' +
    'Modify your cart and move the greyed out items below to a new order';
  const mapProducts = products.map(({ product }: any) => ({ ...product }));
  const isMatchingMethods = matchingMethods(mapProducts, shippingMethod);
  const availableMethods = availableShippingMethods(products);

  useEffect(() => {
    dispatch(updateProductsAmount(allProductsAmount));
  }, [allProductsAmount]);

  useEffect(() => {
    const productsLocalStorage = getItemByKeyFromStorage('shoppingCart');

    if (productsLocalStorage) {
      const productsIds = productsLocalStorage.map(({ product }: LocalStorageType) => product.id);
      setProducts(productsLocalStorage);
      setProductsIds(productsIds);
      dispatch(getShopProductsByIds({ productsIds, websitePath }));
    }
  }, [dispatch, websitePath]);

  const handleUpdateProducts = (updatedProducts: any) => {
    setProducts(updatedProducts);
    dispatch(setIsValidProducts(externalProducts));
  };

  const renderProducts = (products: LocalStorageType[]) => {
    let currentProducts = products;

    if (isShowInvalidProducts) {
      currentProducts = products.filter(({ product }) => invalidProducts.includes(product.id));
    }
    return (
      <>
        {currentProducts.map((product: LocalStorageType, index: number) => (
          <ShoppingProducts
            key={`${product.product.id}-${index}`}
            shopProduct={product}
            styles={styles}
            handleUpdateProducts={handleUpdateProducts}
            externalProducts={externalProducts}
            isInvalidPage={!!isShowInvalidProducts}
            isMatchingMethods={isMatchingMethods}
            shippingMethod={shippingMethod}
          />
        ))}
        {!isShowInvalidProducts && (
          <CardFooter>
            <CardSubTotal styles={styles}>
              <div>Sub-Total</div>
              <div>$ {cartSubtotalPrice.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
            </CardSubTotal>
          </CardFooter>
        )}
      </>
    );
  };

  return (
    <Wrapper>
      {isLoading && <FullScreenPreloader />}
      <CartContainer>
        {styles ? (
          <>
            <ShoppingCartContainer styles={styles}>
              <ShoppingCartName styles={styles}>
                Shopping Cart
                <span>{`(${allProductsAmount} items)`}</span>
              </ShoppingCartName>
              {!isMatchingMethods && !!products.length &&
              <MisMatchDeliveryMessage>
                <div>{misMatchDeliveryMethodsMessage}</div>
              </MisMatchDeliveryMessage>
              }
              {!!products.length && (
                <ShippingOptionsSelector
                  isMatchingMethods={isMatchingMethods}
                  availableMethods={availableMethods}
                />
              )}
              <HorizontalLine />
              {products.length ? renderProducts(products) : 'There is nothing in your cart yet'}
            </ShoppingCartContainer>
            {!isShowInvalidProducts && (
              <CartOrderSummary
                styles={styles}
                cartSubtotalPrice={cartSubtotalPrice}
                websitePath={websitePath}
                productsIds={productsIds}
                isProductsExist={!!products.length}
                isMatchingMethods={isMatchingMethods}
              />
            )}
          </>
        ) : null}
      </CartContainer>
    </Wrapper>
  );
};

export default ShoppingCart;
