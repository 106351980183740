import React from 'react';
import { FormControl, FormControlProps } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { PriceAndAddToCartContainer } from 'src/components/pages/websiteTemplates/websiteTemplate3/styles';
import { StylesProps } from './order/styles';

type ShoppingCartImageProps = {
  isOutOfStock?: boolean;
  isExistShippingMethod?: boolean;
  onClick: any;
  src: string;
};

type CardNameProps = {
  isOutOfStock?: boolean;
  onClick?: any;
};

type CounterAndDeleteProductProps = {
  isOutOfStock: boolean;
  isProductCountExceeds: boolean;
  isExistShippingMethod?: boolean;
};

type ExistShippingProducts = {
  isExistShippingMethod: boolean;
}

export const Wrapper = styled('div')({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
});

export const CartContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  margin: '60px',
  '@media (max-width: 1024px)': {
    margin: '30px 40px',
    flexDirection: 'column',
  },
  '@media (max-width: 768px)': {
    margin: '30px 40px',
  },
});

export const ShoppingCartContainer = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />
))({
  position: 'relative',
  width: '65%',
  maxWidth: '100%',
  marginBottom: 30,
  height: 'fit-content',
  backgroundColor: '#FFFFFF',
  color: ({ styles }: StylesProps) => styles?.cartColor,
  boxShadow: ({ styles }: StylesProps) => styles?.boxShadow,
  fontWeight: ({ styles }: StylesProps) => styles?.fontWeight,
  borderRadius: 8,
  padding: '30px 24px 44px 24px',
  '@media (max-width: 1024px)': {
    width: 'auto',
    maxWidth: 'unset',
  },
});

export const ShoppingCartName = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />
))({
  fontSize: 30,
  fontWeight: ({ styles }: StylesProps) => styles?.titleFontWeight,
  '& > span': {
    verticalAlign: 2,
    fontSize: 18,
    fontWeight: 400,
    paddingLeft: 8,
  },
  '@media (max-width: 550px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
});

export const HorizontalLine = styled('div')({
  width: '100%',
  borderTop: '1px solid #C4C4C4',
  marginTop: 16,
});

export const ProductContainer = styled('div')({
  paddingTop: 40,
});

export const CardContainer = styled('div')({
  display: 'flex',
  '@media (max-width: 550px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const ShoppingCartImage = styled(({ isOutOfStock, isExistShippingMethod, ...otherProps }: ShoppingCartImageProps) => (
  <img {...otherProps} />
))({
    width: 142,
    height: 142,
    cursor: ({ isOutOfStock }: ShoppingCartImageProps) => isOutOfStock ? 'default' : 'pointer',
    opacity: ({ isExistShippingMethod }: ShoppingCartImageProps) => isExistShippingMethod ? 1 : 0.5,
  },
);

export const CardInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  padding: '0 20px 0 10px',
  '@media (max-width: 550px)': {
    alignItems: 'center',
  },
});

export const CardNameWithPriceContainer = styled(
  ({ isExistShippingMethod, ...otherProps }: ExistShippingProducts) => (
  <div {...otherProps} />
))({
  display: 'flex',
  justifyContent: 'space-between',
  opacity: ({ isExistShippingMethod }: ExistShippingProducts) => isExistShippingMethod ? 1 : 0.5,
  '& > div:last-child': {
    textAlign: 'right',
    alignSelf: 'flex-start',
  },
  '@media (max-width: 768px)': {
    flexDirection: 'column-reverse',
  },
});

export const ProductChoice = styled('div')({
  fontSize: 18,
});

export const CardName = styled(({ isOutOfStock, ...otherProps }: CardNameProps) => (
  <div {...otherProps} />
))({
  fontSize: 30,
  maxWidth: 500,
  fontWeight: 300,
  wordBreak: 'break-word',
  cursor: ({ isOutOfStock }: CardNameProps) => (isOutOfStock ? 'default' : 'pointer'),
  '@media (max-width: 1600px)': {
    width: 350,
  },
  '@media (max-width: 550px)': {
    width: 'auto',
  },
});

export const WarningProductAmount = styled('div')({
  fontSize: 18,
  color: '#FF3D57',
});

export const ChangedPrice = styled('div')({
  fontSize: 18,
  fontWeight: 300,
  '& > span': {
    textDecoration: 'line-through',
  },
});

export const CardBottomContainer = PriceAndAddToCartContainer;

export const Price = styled(({ styles, isExistShippingMethod, ...otherProps }: any) => <div {...otherProps} />)({
  fontWeight: ({ styles }: StylesProps) => styles?.fontWeight,
  fontSize: 30,
  opacity: ({ isExistShippingMethod }: any) => (isExistShippingMethod !== undefined && !isExistShippingMethod) ? 0.5 : 1,
});

export const CounterAndDeleteProduct = styled(
  ({ isOutOfStock, isProductCountExceeds, isExistShippingMethod, ...otherProps }: CounterAndDeleteProductProps) => <div {...otherProps} />,
)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontSize: 24,
  width: 200,
  '& > div ': {
    '&:last-child': {
      cursor: 'pointer',
    },
    '& > span': {
      '&:nth-of-type(3)': {
        opacity: ({ isOutOfStock, isProductCountExceeds, isExistShippingMethod }: any) => ((isOutOfStock || isProductCountExceeds || !isExistShippingMethod) ? 0.5 : 1),
        pointerEvents: ({ isProductCountExceeds }: any) => (isProductCountExceeds ? 'none' : 'auto'),
      },
      pointerEvents: ({ isOutOfStock }: any) => (isOutOfStock ? 'none' : 'auto'),
      opacity: ({ isOutOfStock , isExistShippingMethod }: any) => ((isOutOfStock || !isExistShippingMethod) ? 0.5 : 1),
      cursor: 'pointer',
      '&:nth-of-type(2)': {
        cursor: 'default',
        padding: '0 20px',
      },
    },
  },
});

export const Title = styled('div')({
  fontSize: 30,
});

export const CardFooter = styled('div')({
  paddingTop: 25,
  paddingRight: 34,
  '@media (max-width: 550px)': {
    paddingRight: 0,
  },
});

export const CardSubTotal = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />
))({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  fontSize: 30,
  fontWeight: ({ styles }: StylesProps) => styles?.titleFontWeight,
  '& > div:first-child': {
    paddingRight: 80,
    '@media (max-width: 550px)': {
      paddingRight: 0,
    },
  },
  '@media (max-width: 550px)': {
    paddingRight: 0,
    justifyContent: 'space-between',
  },
});

export const SelectorWrapper = styled(({ ...otherProps }: FormControlProps) =>
  <FormControl {...otherProps} />,
)({
  width: 350,
});

export const MisMatchDeliveryMessage = styled(({ isInfo, ...otherProps }: any) =>
  <div {...otherProps} />)({
  fontSize: 18,
  lineHeight: '21px',
  paddingTop: 10,
  paddingBottom: 10,
  '& > div': {
    '&:first-child': {
      color: ({ isInfo }: any) => isInfo ? '#0081FF' : '#FF3D57',
    },
    '&:last-child':{
      paddingTop: 15,
      fontWeight: 500,
      '& > span':{
        fontWeight: 400,
      }
    }
  },
});

export const Label = styled('div')({
  fontSize: 15,
  color: '#8697A8',
  lineHeight: '18px',
  paddingBottom: 10,
  paddingTop: 10,
});
