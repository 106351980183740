import { useEffect, useState } from 'react';
import { WebsiteCollection } from 'src/constants/productsTypes';
import { SelectOptions } from 'src/components/common/selects/SelectField';

export const useCollectionsAsOptions = (collections: WebsiteCollection[]) => {
  const [options, setOptions] = useState<SelectOptions[]>([]);

  useEffect( () => {
    const options = collections.reduce( (acc, collection) => {
      if (collection) {
        acc.push({
          name: collection.collectionName,
          value: String(collection.id)
        })
      }
      return acc;
    }, [] as SelectOptions[])
    setOptions(options?.length ? options : []);
  }, [collections, collections.length])
  
  return options;
}