import {
  GET_SHOP_PRODUCTS,
  GET_SHOP_PRODUCTS_SUCCESS,
  GET_SHOP_PRODUCTS_FAIL,
  GET_SHOP_PRODUCTS_BY_IDS,
  GET_SHOP_PRODUCTS_BY_IDS_SUCCESS,
  GET_SHOP_PRODUCTS_BY_IDS_FAIL,
  ADD_PRODUCT_TO_SHOPPING_CART,
  ADD_PRODUCT_TO_SHOPPING_CART_SUCCESS,
  ADD_PRODUCT_TO_SHOPPING_CART_FAIL,
  VIEW_SHOP_PRODUCT,
  VIEW_SHOP_PRODUCT_SUCCESS,
  VIEW_SHOP_PRODUCT_FAIL,
  SET_IS_VALID_PRODUCTS,
  SET_IS_VALID_PRODUCTS_SUCCESS,
  CHECKOUT_WITH_VALIDATION,
  SET_SHIPPING_METHOD,
  GET_SHOP_OTHER_PRODUCTS,
  GET_SHOP_OTHER_PRODUCTS_SUCCESS,
  GET_SHOP_OTHER_PRODUCTS_FAIL,
} from './constants';
import { all, put, takeLatest, call, select, take, delay } from 'redux-saga/effects';
import shopProducts from 'src/api/shopProducts';
import oauthApi from 'src/api/oauthApi';
import history from 'src/containers/history';
import { S3Image } from 'src/constants/commonTypes';
import { isValidProductsAtCart, setProductToShoppingCart } from 'src/utils/helpers';
import { ShopProductsType, ViewProduct, AddProductToShoppingCart, ShopProductsByIds } from './actions';
import { WebsiteCollection } from 'src/constants/productsTypes';

export const moduleName = 'shopProducts';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

export type ShopProduct = {
  id: number;
  description: string;
  imagesGallery: S3Image[];
  inventory: number;
  isDigitalProduct?: boolean;
  isLocalDelivery?: boolean;
  isLocalPickup?: boolean;
  isPhysicalProduct?: boolean;
  websiteCollection?: WebsiteCollection;
  shippingType: string;
  name: string;
  price: number;
  note: string;
};

export type StateShopProductsInterface = {
  shopProductsList: ShopProductsList | null;
  shopProductsOthers: ShopProductsList | null;
  shopProductsByIdsList: FullShopProduct[] | null;
  shopProduct: FullShopProduct | null;
  isValidProducts: boolean;
  invalidProductsIds: Number[];
  shippingMethod: string | null;
  loading: boolean;
  error: Error | null;
};

export type ShopProductsList = {
  list: ShopProduct[];
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export type ProductOptionValue = {
  id: number;
  name: string;
  productOption: {
    id: number;
    name: string;
  }
}

export type ProductChoice = {
  id: number;
  price: number;
  inventory: number;
  productOptionValues: ProductOptionValue[];
}

export type FullShopProduct = {
  hasOptions: boolean;
  productChoices: ProductChoice[];
} & ShopProduct;

export type Error = {
  error: boolean;
  errors:
    | ErrorData[]
    | {
    message: string;
    title: string;
  };
};

export type ErrorData = {
  field?: string;
  code?: string;
  invalid_value: string;
  message: string;
};

export const shopProductsState: StateShopProductsInterface = {
  shopProductsList: null,
  shopProductsOthers: null,
  shopProductsByIdsList: null,
  shopProduct: null,
  isValidProducts: false,
  invalidProductsIds: [],
  shippingMethod: null,
  error: null,
  loading: false,
};

export default function reducer(
  state: StateShopProductsInterface = shopProductsState,
  { type, payload, error }: ActionType,
) {
  switch (type) {
    case GET_SHOP_PRODUCTS:
    case GET_SHOP_OTHER_PRODUCTS:
    case VIEW_SHOP_PRODUCT:
    case ADD_PRODUCT_TO_SHOPPING_CART:
    case GET_SHOP_PRODUCTS_BY_IDS:
      return { ...state, loading: true, error: null };

    case GET_SHOP_PRODUCTS_SUCCESS:
        return handleShopProductsList(state, payload);

    case GET_SHOP_OTHER_PRODUCTS_SUCCESS:
      return handleShopOthersProductsList(state, payload);

    case GET_SHOP_PRODUCTS_BY_IDS_SUCCESS:
      return {
        ...state,
        shopProductsByIdsList: payload.data,
        isValidProducts: payload.isValidProducts,
        invalidProductsIds: payload.invalidProductsIds,
        loading: false,
        error: null,
      }

    case ADD_PRODUCT_TO_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };

    case VIEW_SHOP_PRODUCT_SUCCESS:
      return {
        ...state,
        shopProduct: payload,
        loading: false,
        error: null,
      };

    case SET_IS_VALID_PRODUCTS_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        isValidProducts: payload.isValidProducts,
        invalidProductsIds: payload.invalidProductsIds,
      };

    case SET_SHIPPING_METHOD:
      return {
        ...state,
        shippingMethod: payload,
        error: null,
        loading: false,
      }

    case GET_SHOP_PRODUCTS_FAIL:
      return {
        ...state,
        shopProductsList: null,
        loading: false,
        error,
      };

    case GET_SHOP_OTHER_PRODUCTS_FAIL:
      return {
        ...state,
        shopProductsOthers: null,
        loading: false,
        error,
      };

    case GET_SHOP_PRODUCTS_BY_IDS_FAIL:
      return {
        ...state,
        shopProductsByIdsList: null,
        loading: false,
        error,
      };

    case VIEW_SHOP_PRODUCT_FAIL:
      return {
        ...state,
        shopProduct: null,
        loading: false,
        error,
      };

    case ADD_PRODUCT_TO_SHOPPING_CART_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };

    default:
      return state;
  }
}

/** @todo Reuse function below @see {handleShopProductsList} */
function handleShopOthersProductsList(state: StateShopProductsInterface, payload: any) {
  if (payload.isShowMore) {
    const list = state?.shopProductsOthers?.list;
    return {
      ...state,
      shopProductsOthers: {
        list: list ? [...list, ...payload.data] : payload.data,
        currentPage: payload._page,
        totalPages: payload._pages,
        totalCount: payload._total,
      },
      error: null,
      loading: false,
    };
  }

  return {
    ...state,
    shopProductsOthers: {
      list: payload.data,
      currentPage: payload._page,
      totalPages: payload._pages,
      totalCount: payload._total,
    },
    error: null,
    loading: false,
  };
}

function handleShopProductsList(state: StateShopProductsInterface, payload: any) {
  if (payload.isShowMore) {
    const list = state?.shopProductsList?.list;
    return {
      ...state,
      shopProductsList: {
        list: list ? [...list, ...payload.data] : payload.data,
        currentPage: payload._page,
        totalPages: payload._pages,
        totalCount: payload._total,
      },
      error: null,
      loading: false,
    };
  }

  return {
    ...state,
    shopProductsList: {
      list: payload.data,
      currentPage: payload._page,
      totalPages: payload._pages,
      totalCount: payload._total,
    },
    error: null,
    loading: false,
  };
}

function* getShopProductsSaga({ payload }: ShopProductsType) {
  try {
    const { path, count, page, collectionName, productsIds } = payload || {};
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const response = yield shopProducts.getListProducts({
      path,
      page: page || 1,
      perPage: count || 18,
      anonymousToken,
      collectionName,
      productsIds
    });

    yield put({
      type: GET_SHOP_PRODUCTS_SUCCESS,
      payload: { ...response, isShowMore: payload.isShowMore },
    });
  } catch (err) {
    yield put({ type: GET_SHOP_PRODUCTS_FAIL, error: err.data });
  }
}

function* getOtherProductsSaga({ payload }: ShopProductsType) {
  try {
    const { path, count, page, collectionName, productsIds, isShowMore } = payload || {};
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const response = yield shopProducts.getListProducts({
      path,
      page: page || 1,
      perPage: count || 6,
      anonymousToken,
      collectionName,
      productsIds,
      isOtherCollection: true
    });

    yield put({
      type: GET_SHOP_OTHER_PRODUCTS_SUCCESS,
      payload: { ...response, isShowMore },
    });
  } catch (err) {
    yield put({ type: GET_SHOP_OTHER_PRODUCTS_FAIL, error: err.data });
  }
}

function* getShopProductsByIdsSaga({ payload: { productsIds, websitePath, isCheckout } }: ShopProductsByIds) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const uniqProductIds = [...new Set(productsIds)];
    const response = yield shopProducts.getListProductsByIds(
      websitePath,
      anonymousToken,
      uniqProductIds,
    );
    
    const { isValidProducts, invalidProductsIds } = yield call(
      isValidProductsAtCart,
      response.data,
      isCheckout,
    );

    yield put({
      type: GET_SHOP_PRODUCTS_BY_IDS_SUCCESS,
      payload: { data: response.data, isValidProducts, invalidProductsIds },
    });
  } catch (err) {
    yield put({ type: GET_SHOP_PRODUCTS_BY_IDS_FAIL, error: err.data });
  }
}

function* addProductToShoppingCartSaga({ payload: { productAmount, productChoiceId } }: AddProductToShoppingCart) {
  try {
    const product = yield select((state) => state?.shopProducts?.shopProduct);
    const foundProductChoice = product?.productChoices?.find((item: ProductChoice) => item.id === productChoiceId);

    if(product?.isPhysicalProduct){
      if (product?.inventory > 0) {
        setProductToShoppingCart('shoppingCart', product, productAmount, foundProductChoice);
      }
    } else {
      setProductToShoppingCart('shoppingCart', product, productAmount, foundProductChoice);
    }
    yield delay(500);
    yield put({ type: ADD_PRODUCT_TO_SHOPPING_CART_SUCCESS });
  } catch (err) {
    yield put({ type: ADD_PRODUCT_TO_SHOPPING_CART_FAIL, error: err.data });
  }
}

function* viewShopProductsSaga({ payload }: ViewProduct) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const response = yield shopProducts.viewShopProduct(payload.productId, payload.websitePath, anonymousToken);
    
    yield put({ type: VIEW_SHOP_PRODUCT_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: VIEW_SHOP_PRODUCT_FAIL, error: err.data });
  }
}

function* setIsValidProductsSaga({ payload: externalProducts }: any) {
  const { isValidProducts, invalidProductsIds } = yield call(
    isValidProductsAtCart,
    externalProducts,
  );

  yield put({
    type: SET_IS_VALID_PRODUCTS_SUCCESS,
    payload: { isValidProducts, invalidProductsIds },
  });
}

function* checkoutWithValidationSaga({ payload: websitePath }: any) {
  yield take(GET_SHOP_PRODUCTS_BY_IDS_SUCCESS);
  const { isValidProducts } = yield select((state) => state.shopProducts);

  if (isValidProducts) {
    history.push(`/shop/${websitePath}/cart/order`);
  }
}

export const saga = function* () {
  yield all([
    takeLatest(GET_SHOP_OTHER_PRODUCTS, getOtherProductsSaga),
    takeLatest(GET_SHOP_PRODUCTS, getShopProductsSaga),
    takeLatest(GET_SHOP_PRODUCTS_BY_IDS, getShopProductsByIdsSaga),
    takeLatest(ADD_PRODUCT_TO_SHOPPING_CART, addProductToShoppingCartSaga),
    takeLatest(VIEW_SHOP_PRODUCT, viewShopProductsSaga),
    takeLatest(SET_IS_VALID_PRODUCTS, setIsValidProductsSaga),
    takeLatest(CHECKOUT_WITH_VALIDATION, checkoutWithValidationSaga),
  ]);
};
