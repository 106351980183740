import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createOrderWithValidation } from 'src/ducks/shopOrder/actions';
import {
  calculationSelector,
  stripeSessionIdSelector,
  shippingAddressFormSelector,
  errorSelector,
  loadingOrderSelector,
} from 'src/ducks/shopOrder/selectors';
import { loadStripe } from '@stripe/stripe-js';
import inputUtils from 'src/utils/formUtils';
import history from 'src/containers/history';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import { StylesTemplate } from 'src/constants/stylesTemplates';
import { calculatePrice } from './CartOrderSummary';
import {
  OrderContainer,
  Title,
  HorizontalLineOrder,
  SubTotalContainer,
  PriceOrder,
  SubTitle,
  SubPrice,
  DeliveryAddress,
  ProceedToPaymentButton,
} from './styles';

type OrderProps = {
  valid: boolean;
  deliveryAddress: any;
  styles: StylesTemplate;
  cartSubtotalPrice: number;
  website: number;
  getCurrentProducts: () => void;
  isValidExternalProducts: boolean;
};

const publishKeyStripe: string =
  (process.env.REACT_APP_NODE_ENV === 'dev'
    ? process.env.REACT_APP_PUBLISHABLE_KEY_STRIPE_DEV
    : process.env.REACT_APP_PUBLISHABLE_KEY_STRIPE_PROD) || '';

const stripePromise = loadStripe(publishKeyStripe);

const Order = (props: OrderProps) => {
  const {
    styles,
    valid,
    deliveryAddress,
    cartSubtotalPrice,
    website,
    getCurrentProducts,
    isValidExternalProducts,
  } = props;
  const dispatch = useDispatch();
  const calculation = useSelector(calculationSelector);
  const stripeSessionId = useSelector(stripeSessionIdSelector);
  const shippingAddress = useSelector(shippingAddressFormSelector);
  const isLoading = useSelector(loadingOrderSelector);
  const error = useSelector(errorSelector);

  useEffect(() => {
    if (stripeSessionId) {
      (async function redirectToCheckout() {
        const stripe = await stripePromise;
        await stripe?.redirectToCheckout({ sessionId: stripeSessionId });
      })();
    }
  }, [stripeSessionId]);

  useEffect(() => {
    if (!isValidExternalProducts) {
      history.push(`/shop/${website}/cart`);
    }
  }, [isValidExternalProducts]);

  const handleLoadStripe = () => {
    const createOrderData = {
      ...shippingAddress,
      state: shippingAddress.state.toUpperCase(),
      phone: inputUtils.normalizePhone(shippingAddress.phone),
      website,
    };
    getCurrentProducts();
    dispatch(createOrderWithValidation(createOrderData));
  };

  return (
    <OrderContainer styles={styles}>
      {isLoading && <FullScreenPreloader />}
      <Title styles={styles}>Order Summary</Title>
      <HorizontalLineOrder styles={styles} />
      <SubTotalContainer>
        <div>
          <Title styles={styles}>Sub-Total</Title>
          <PriceOrder styles={styles}>
            {calculatePrice(calculation.subtotal, cartSubtotalPrice)}
          </PriceOrder>
        </div>
        <div>
          <SubTitle>Shipping</SubTitle>
          <SubPrice>$ {calculation.shipping.toLocaleString('en-US', { minimumFractionDigits: 2 })}</SubPrice>
        </div>
        <div>
          <SubTitle>Taxes</SubTitle>
          <SubPrice>$ {calculation.tax.toLocaleString('en-US', { minimumFractionDigits: 2 })}</SubPrice>
        </div>
      </SubTotalContainer>
      <HorizontalLineOrder styles={styles} />
      <SubTotalContainer>
        <div>
          <Title styles={styles}>Total</Title>
          <PriceOrder styles={styles}>
            {calculatePrice(calculation.grandTotal, cartSubtotalPrice)}
          </PriceOrder>
        </div>
      </SubTotalContainer>
      <Title styles={styles}>Shipping Address</Title>
      <DeliveryAddress>
        {typeof deliveryAddress !== 'string' ? (
          <>
            <div>{`${deliveryAddress.address},`}</div>
            <div>{`${deliveryAddress.city}, ${deliveryAddress.state.toUpperCase()} ${deliveryAddress.zipCode}`}</div>
          </>
        ) : (
          'Please enter your shipping address'
        )}
      </DeliveryAddress>
      <ProceedToPaymentButton
        onClick={handleLoadStripe}
        disabled={!valid || !!error || isLoading}
        styles={styles}
      >
        Proceed to Payment
      </ProceedToPaymentButton>
    </OrderContainer>
  );
};

export default Order;
