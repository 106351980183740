import { styled } from '@material-ui/core';

export const Wrapper = styled('div')({
  padding: '0 80px',
  display: 'flex',
  alignItems: 'center',
  '& > a': {
    fontSize: '1.2rem',
    color: '#44566C',
    textDecoration: 'none'
  },
  '@media (max-width: 768px)': {
    padding: '0 40px'
  }
});