import React from 'react';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { UnAuthorizedWrapper } from './styles';

type ErrorType = {
  error: {
    key: string;
    text: string;
  };
};

const UnAuthorized = ({ error }: ErrorType) => {
  const { text, key } = error;
  return (
    <UnAuthorizedWrapper>
      <ErrorMessage>{text}</ErrorMessage>
      <br />
      {key === 'activated' && <a href="/">Please, Sign In</a>}
    </UnAuthorizedWrapper>
  );
};

export default UnAuthorized;
