import React from 'react';
import { styled } from '@material-ui/core/styles';

export const TermsContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  padding: '52px 52px 0 60px',
  textAlign: 'center',
});

export const Title = styled('h1')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '41px',
  lineHeight: '55px',
  letterSpacing: '0.01em',
  color: '#8697A8',
});

export const SectionTitle = styled('h3')({
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '22px',
  lineHeight: '25px',
  color: '#8697A8',
  textAlign: 'start',
});

export const SectionText = styled('div')(
  {
    textAlign: 'start',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '19px',
    color: '#44566C',
  },
);
