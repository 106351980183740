import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';

export const StudentContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    padding: 40,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.greyBg,
  }),
);

export const ActionContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: 10,
    height: 75,
    '& a': {
      textDecoration: 'none',
    },
    '& h5': {
      padding: 0,
    },
  }),
);

export const TitleContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'flex-end',
  }),
);

export const Container = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    flexDirection: 'column',
    padding: 40,
    backgroundColor: 'rgb(248, 250, 251)',
  }),
);

export const Title = styled('div')({
  fontFamily: 'Roboto',
  fontWeight: 500,
  fontSize: 30,
  lineHeight: 0.35,
  color: '#44566C',
  paddingBottom: 36,
});

export const FormContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 600,
    padding: '50px 50px 30px 25px',
    width: '100%',
  }),
);

export const StoreNameContainer = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      display: 'flex',
      fontWeight: 500,
      paddingBottom: 30,
      color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    },
  ),
);
