import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import React from 'react';

type InputProps = {
  customBg?: string;
};

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: 30,
  '@media (max-width: 1820px)': {
    width: '100%',
  },
});

export const Label = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <label {...otherProps} />)({
    display: 'flex',
    fontSize: 15,
    lineHeight: 0.12,
    textTransform: 'capitalize',
    color: '#8697A8',
    paddingBottom: 10,
  })
);

export const Input = withTheme(
  styled(({ theme, customBg, ...otherProps }: WithTheme & InputProps) => <input {...otherProps} />)(
    {
      background: ({ customBg }: InputProps) => (!customBg ? '#FFFFFF' : customBg),
      borderRadius: 5,
      padding: '12px 18px',
      fontSize: 15,
      color: '#44566C',
      border: '1px solid #EAEDF0',
      '&:hover': {
        background: '#EAEDF0',
      },
      '&:focus': {
        border: '1px solid #008DBA',
        outline: 'none',
      },
      '&:disabled': {
        border: 'none',
        background: '#F8FAFB',
        opacity: 0.7,
        '&:hover': {
          background: ({ customBg }: InputProps) => (!customBg ? '#FFFFFF' : customBg),
        },
      },
    }
  )
);

export const InputError = styled('span')({
  fontSize: 11,
  color: '#FF3D57',
});
