import React from 'react';
import DatePicker from 'react-datepicker'
import { makeStyles } from '@material-ui/core/styles';
import "react-datepicker/dist/react-datepicker.css";
import { DatePickerInputWrapper, styles } from './styles';

type DatePickerInputProps = {
    selected?: Date | null,
    onChange: (dates: any) => void,
    startDate: Date | null,
    endDate: Date | null,
    selectsRange?: boolean,
    minDate?: Date | null,
    maxDate?: Date | null,
};

const useStyles = makeStyles(styles);

const DatePickerInput = (props: DatePickerInputProps) => {
    const {
        selected,
        onChange,
        startDate,
        endDate,
        selectsRange = true,
        minDate = null,
        maxDate = null,
    } = props;
    const classes = useStyles();

    return (
        <DatePickerInputWrapper>
            <DatePicker
                className={classes.input}
                selected={selected}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange={selectsRange}
                monthsShown={1}
                minDate={minDate}
                maxDate={maxDate}
                showDisabledMonthNavigation
                showMonthYearDropdown
                placeholderText="Select date range"
            />
        </DatePickerInputWrapper>
    )
};

export default DatePickerInput;

