import React from 'react';
import { withTheme, WithTheme, styled } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@material-ui/core';

export const ErrorMessage = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.error,
  })
);

export const FormContainer = styled('div')({
  width: 350,
});

export const ContinueButton = withTheme(
  styled((props: ButtonProps & WithTheme) => <Button {...props} />)({
    width: '100%',
    height: 40,
    boxShadow: '0px 8px 16px rgba(0, 141, 186, 0.19)',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    },
    '&:disabled': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledBg,
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledColor,
    },
  })
);

export const ContinueButtonContainer = styled('div')({
  width: '100%',
  padding: '14px 0 10px 0',
});

export const AdditionalInfo = withTheme(
  styled('div')({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '36px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
    '& > a': {
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
      textDecoration: 'none',
      '&:hover': {
        color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  })
);
