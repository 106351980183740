import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { setShippingMethod } from 'src/ducks/shopProducts/actions';
import { MenuItem } from '@material-ui/core';
import { FormSelectField } from 'src/components/common/selects';
import { cartShippingMethods, CartShippingMethodsType } from 'src/constants/cartShippingMethods';
import {
  setByKeyToStorage,
  getItemByKeyFromStorage,
} from 'src/utils/helpers';
import { SelectorWrapper, Label } from '../styles';
import { OptionListType, prepareOptionList } from '../../products/shippingOptions/ShippingOptionsForm';

type FormShippingOptionProps = {
  shopShippingOption: string;
}

type ShippingOptionsSelectorProps = {
  isMatchingMethods: boolean;
  availableMethods: any[];
}

type Props = InjectedFormProps<FormShippingOptionProps, ShippingOptionsSelectorProps, {}>;

const ShippingOptionsSelector = ({ isMatchingMethods, availableMethods }: ShippingOptionsSelectorProps) => {
  const dispatch = useDispatch();
  const [shippingType, setSelectedShippingType] = useState<any>('');
  const messageForSelector = 'Please select a delivery method';
  const shippingMethodLocalStorage = getItemByKeyFromStorage('shippingMethod');
 
  useEffect(() => {
    if (shippingMethodLocalStorage && availableMethods.includes(shippingMethodLocalStorage)) {
      setSelectedShippingType(shippingMethodLocalStorage);
    } else {
      const defaultAvailableMethod = availableMethods.find((availableMethod: string) => {
        return cartShippingMethods.hasOwnProperty(availableMethod);
      }); 

      if (defaultAvailableMethod) {
        setSelectedShippingType(defaultAvailableMethod);
      } else {
        setSelectedShippingType('');
      }
    }
  }, [isMatchingMethods, availableMethods]);

  useEffect(() => {
    if (shippingType) {
      setByKeyToStorage('shippingMethod', shippingType);
      dispatch(setShippingMethod(shippingType));
    }
  }, [shippingType]);

  const handleChangeShippingOption = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedShippingType(value as string);
  };

  const renderShippingMethods = () => {
    const optionList = prepareOptionList(cartShippingMethods);
    const menuItems = optionList.map((method: OptionListType) => (
      <MenuItem
        key={method.value}
        value={method.value}
        disabled={!availableMethods.includes(method.value)}
      >
        {method.name}
      </MenuItem>
    ));
    const placeholderMenuItem = <MenuItem value="" disabled>{messageForSelector}</MenuItem>;

    if (availableMethods.length) {
      return menuItems;
    }

    return [placeholderMenuItem, ...menuItems];
  };

  return (
    <SelectorWrapper>
      <Label>{messageForSelector}</Label>
      <FormSelectField
        name="shopShippingOption"
        selectedValue={shippingType}
        customOptions={renderShippingMethods()}
        handleChange={handleChangeShippingOption}
      />
    </SelectorWrapper>
  );
};

export default reduxForm<FormShippingOptionProps, ShippingOptionsSelectorProps, {}>({
  form: 'shopShippingOption',
})(ShippingOptionsSelector);
