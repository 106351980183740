import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const Toolbar = styled('div')({
  minHeight: 64,
});

export const Main = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.greyBg,
  })
);
