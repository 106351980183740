import React from 'react';
import { FooterHomePage } from 'src/containers/footerHomePage';
import { dataForTermsOfService, dataForPrivacyPolicy } from 'src/constants/dataForTermsOfService';
import { TermsContainer, Title, SectionTitle, SectionText } from './styles';

const Terms = () => {
  return (
    <TermsContainer>
      <Title>TERMS OF SERVICE</Title>
      <i>Real World Scholars</i>
      {dataForTermsOfService.map(({ title, text }, i) => (
        <div key={i}>
          <SectionTitle>{title}</SectionTitle>
          <SectionText dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ))}
      <Title>PRIVACY POLICY</Title>
      {dataForPrivacyPolicy.map(({ title, text }, i) => (
        <div key={i}>
          <SectionTitle>{title}</SectionTitle>
          <SectionText dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      ))}
      <FooterHomePage />
    </TermsContainer>
  );
};

export default Terms;
