import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';
import { closeMainDrawer } from 'src/ducks/notifications/actions';
import { AvatarWrapper, NotifyWrapper, TextWrapper, TimeWrapper } from './styles';
import { getDateElements } from 'src/utils/helpers';
import vars from 'src/constants/vars';
import newOrderIcon from 'src/assets/images/icons/new_order.png';
import toolkitBonusApprovedIcon from 'src/assets/images/icons/toolkit_bonus_approved.png';
import cashOutApprovedIcon from 'src/assets/images/icons/cash_out_approved.png';
import cashOutAttentionIcon from 'src/assets/images/icons/cash_out_attention.png';

const Notification = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { notification } = props;
  const { order, announcement, toolkit, cashOut, cashOutNeeded } = vars.notificationsTypes;
  const today = new Date();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let formattedOrderDate;
  let text;
  if (notification.type === order) {
    text = notification.text.slice(0, -25);
    const date = notification.text.slice(-25);
    const localDate = utcToZonedTime(date, timeZone);
    formattedOrderDate = format(localDate, 'MM/dd/yyyy HH:mm', { timeZone });
  }

  const notificationText =
    notification.type === order ? text + formattedOrderDate : notification.text;

  let notificationDate;

  const formatMin = (min: number | string) => {
    if (min >= 10) {
      return min;
    } else {
      return `0${min}`;
    }
  };

  const isToday = (someDate: any) => {
    const date = new Date(someDate);
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  };

  const isYesterday = (someDate: any) => {
    const date = new Date(someDate);
    return (
      date.getDate() == today.getDate() - 1 &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  };

  if (isToday(notification.createdAt)) {
    const date = new Date(notification.createdAt);
    const hours = date.getHours();
    const min = date.getMinutes();
    notificationDate = `Today ${hours}:${formatMin(min)}`;
  } else if (isYesterday(notification.createdAt)) {
    const date = new Date(notification.createdAt);
    const hours = date.getHours();
    const min = date.getMinutes();
    notificationDate = `Yesterday ${hours}:${formatMin(min)}`;
  } else {
    const { year, month, day, hours, minutes, seconds } = getDateElements(notification.createdAt);
    const formattedDate = new Date(year, month, day, hours, minutes, seconds);
    notificationDate = format(formattedDate, 'MM/dd/yyyy');
  }

  const renderIcon = () => {
    if (notification.type === order) {
      return <img src={newOrderIcon} alt=""/>;
    }

    if (notification.type === announcement) {
      return <img src={cashOutAttentionIcon} alt=""/>;
    }

    if (notification.type === toolkit) {
      return <img src={toolkitBonusApprovedIcon} alt=""/>;
    }

    if (notification.type === cashOut) {
      return <img src={cashOutApprovedIcon} alt=""/>;
    }

    if (notification.type === cashOutNeeded) {
      return <img src={cashOutAttentionIcon} alt=""/>;
    }
  };

  const onNotificationClick = () => {
    if (notification.type === announcement && notification.metadata?.url) {
      return window.open(notification.metadata.url, '_blank');
    }

    if (notification.type === order) {
      history.push({
        pathname: '/orders',
      });
      dispatch(closeMainDrawer());
    }

    if (notification.type === toolkit) {
      history.push({
        pathname: '/accounting',
        state: { activeToolkitTab: true },
      });
      dispatch(closeMainDrawer());
    }

    if (notification.type === cashOut || notification.type === cashOutNeeded) {
      history.push({
        pathname: '/accounting',
        search: '?tab=2',
        state: { activeTabCashout: true },
      });
      dispatch(closeMainDrawer());
    }

    return null;
  };

  return (
    <NotifyWrapper
      style={notification.isViewed ? { background: '#ffffff' } : { background: '#F2F7FB' }}
      onClick={onNotificationClick}
    >
      <AvatarWrapper>{renderIcon()}</AvatarWrapper>
      <TextWrapper>{notificationText}</TextWrapper>
      <TimeWrapper>{notificationDate}</TimeWrapper>
    </NotifyWrapper>
  );
};

export default Notification;
