import { createSelector } from 'reselect';
import { StateHomeInterface, Error, ErrorData } from './home';

export interface RootState {
  home: StateHomeInterface;
}

const errorGetter = (state: any) => state.home.error;
const homeGetter = (state: RootState) => state.home;

export const homeListSelector = createSelector(
  homeGetter,
  (home: StateHomeInterface) => home.homeList,
);

export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        return err.message;
      });
    } else {
      return error.errors.message;
    }
  }

  return null;
});
