import React from 'react';
import { cashoutTypes } from 'src/constants/cashOutTypes';
import MySchoolForm from './MySchoolForm';
import OrganizationForm from './OrganizationForm';
import ClassWallet from './ClassWallet';
import useMemorizedValidate from './memoizedValidationAmount';

type CashoutFormsByTypesProps = {
  isAllowEditCashout?: boolean;
  totalFundsCashOut: number;
  selectedCashoutType: string;
  needChangesText: string | null;
};

const CashoutFormsByTypes = ({
  isAllowEditCashout,
  totalFundsCashOut,
  selectedCashoutType,
  needChangesText,
}: CashoutFormsByTypesProps) => {
  const errorMessageForAmount = `You can submit only $${totalFundsCashOut}`;

  const validateAmount = useMemorizedValidate({
    amount: totalFundsCashOut,
    errorText: errorMessageForAmount,
  });

  const chooseCashoutByType = () => {
    if (selectedCashoutType === cashoutTypes.mySchool) {
      return (
        <MySchoolForm
          isAllowEditCashout={isAllowEditCashout}
          validateAmount={validateAmount}
          needChangesText={needChangesText}
        />
      );
    }
    if (selectedCashoutType === cashoutTypes.organization) {
      return (
        <OrganizationForm
          isAllowEditCashout={isAllowEditCashout}
          validateAmount={validateAmount}
          needChangesText={needChangesText}
        />
      );
    }
    if (selectedCashoutType === cashoutTypes.classWallet) {
      return (
        <ClassWallet
          isAllowEditCashout={isAllowEditCashout}
          validateAmount={validateAmount}
          needChangesText={needChangesText}
        />
      );
    }
  };

  return chooseCashoutByType() || null;
};

export default CashoutFormsByTypes;
