import { createSelector } from 'reselect';
import { StateAuthInterface } from './auth';

export interface RootState {
  auth: StateAuthInterface;
}

const messageFromOldLink = "Code didn't work. Try again or resend a new one";
const newMessageForOldLink =
  'It looks like this link has expired! Please use the link from the latest email';

export const errorSelector = createSelector(
  (state: RootState) => state.auth,
  (auth) => {
    if (typeof auth.error === 'string') {
      return auth.error;
    }
    if (auth.error && auth.error.message) {
      return auth.error.message || auth.error.statusText;
    }
    if (auth.error && auth.error.key) {
      return { key: auth.error.key, text: auth.error.text };
    }
    if (auth.error && Array.isArray(auth.error.data.errors)) {
      const existedStoreName = auth.error.data.errors.find(({ field }) => field === 'store.name');
      if (existedStoreName && existedStoreName.invalid_value)
        return `Business Name "${existedStoreName.invalid_value}" is already used.`;
    }
    if (auth.error && Array.isArray(auth.error.data.errors)) {
      const existedStoreName = auth.error.data.errors.find(
        ({ message }) => message === messageFromOldLink
      );
      if (existedStoreName?.message) {
        return newMessageForOldLink;
      }
    }
    if (auth.error && Array.isArray(auth.error.data.errors)) {
      const existedUser = auth.error.data.errors.find(({ field }) => field === 'inviteToken');
      if (existedUser && existedUser.invalid_value)
        return { key: 'inviteToken', text: 'You are already registered in the system.' };
      return auth.error.data.errors.find(({ message }) => message).message;
    }
    if (auth.error && auth.error.data.errors && auth.error.data.errors.title === 'UNKNOWN_ERROR') {
      return { key: 'UNKNOWN_ERROR', text: 'Something wrong...' };
    }
    if (auth.error && auth.error.status === 404 && auth.error.data.errors.message === 'Not Found') {
      return {
        key: 'Not Found',
        text: 'It looks like this link has expired! Please use the link from the latest email.',
      };
    }
    return auth.error && auth.error.data.error_description;
  }
);

const loadingGetter = (state: RootState) => state.auth.loading;
export const loadingSelector = createSelector(loadingGetter, (loading) => loading);
const teacherEmailGetter = (state: RootState) => state.auth.teacherEmail;
export const teacherEmailSelector = createSelector(
  teacherEmailGetter,
  (teacherEmail) => teacherEmail
);
const checkRecoveryTokenGetter = (state: RootState) => state.auth.recoveryTokenIsChecked;
export const checkRecoveryTokenSelector = createSelector(
  checkRecoveryTokenGetter,
  (recoveryTokenIsChecked) => recoveryTokenIsChecked
);
const linkSuccessfullySentGetter = (state: RootState) => state.auth.linkSuccessfullySent;
export const linkSuccessfullySentSelector = createSelector(
  linkSuccessfullySentGetter,
  (linkSuccessfullySent) => linkSuccessfullySent
);
const passwordSuccessfullyResetGetter = (state: RootState) => state.auth.passwordSuccessfullyReset;
export const passwordSuccessfullyResetSelector = createSelector(
  passwordSuccessfullyResetGetter,
  (passwordSuccessfullyReset) => passwordSuccessfullyReset
);
const isTeacherGetter = (state: RootState) => state.auth.isTeacher;
export const isTeacherSelector = createSelector(isTeacherGetter, (isTeacher) => isTeacher);
