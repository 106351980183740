import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';

export default {
  getListProducts: (params: any): AxiosPromise<AxiosResponse> => {
    const { anonymousToken, productsIds, path, collectionName, ...otherParams } = params;
    const searchParams = new URLSearchParams({...otherParams, ...(collectionName && { collectionName })}).toString();
    return httpService.get({
      url: `api/shop/product/list/${path}?${searchParams}`,
      params: { ids: productsIds },
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      }
    })
  },

  getListProductsByIds: (
    websitePath: string,
    anonymousToken: string,
    productsIds?: number[]
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/shop/product/list-by-ids/${websitePath}`,
      params: { ids: productsIds },
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),

  viewShopProduct: (productId: number, websitePath: string, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/shop/product/${productId}/${websitePath}`,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),
};
