import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  animatedItem: {
    animation: `$myEffect 1000ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    animation: `$myEffectExit 1000ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0.8,
    transform: 'translateX(-200%)',
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0.8,
      transform: 'translateX(500px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    '100%': {
      opacity: 0.8,
      transform: 'translateX(500px)',
    },
  },
}));
