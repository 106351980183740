import React from 'react';
import { useHistory } from 'react-router-dom';
import { TableBody, TableRow, Paper, TableContainer } from '@material-ui/core';
import { Order } from 'src/ducks/orders/orders';
import deliveryStatuses, { DeliveryStatus } from 'src/constants/deliveryStatuses';
import { Hint } from 'src/components/common/hint';
import { QuestionHintIcon } from 'src/components/common/Icons';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { formatDate } from 'src/utils/helpers';
import {
  TableWrapper,
  Head,
  StyledTableCell,
} from 'src/components/pages/students/studenstList/styles';
import { LabelWrapper } from 'src/components/pages/website/form-website/style';
import {
  StyledTableCellInfo,
  StyledDateCell,
  StyledTableRow,
  OrdersTotalTitle,
  DefaultTrackingMessage,
} from './styles';

const tableColumnHeaders: string[] = [
  'Order ID',
  'Customer Name',
  'Date',
  'Total',
  'Status',
  'Tracking number',
];

type OrderListProps = {
  loadMore: () => void;
  orderList: Order[];
  totalOrders?: number;
};

const OrderList = (props: OrderListProps) => {
  const history = useHistory();
  const { loadMore, orderList, totalOrders } = props;

  useInfiniteScroll({ loadMore });

  const getMappedStatus = (status: string) => {
    return deliveryStatuses.find(
      (deliveryStatus: DeliveryStatus) => deliveryStatus.status === status
    );
  };

  const getOrdersList = (order: Order) => {
    const deliveryStatus = getMappedStatus(order.status);
    const path = order.type === 'offline' ? `/orders-mobile` : '/orders';

    return (
      <StyledTableRow key={order.id} onClick={() => history.push(`${path}/${order.id}`)}>
        <StyledTableCellInfo>#{order.id}</StyledTableCellInfo>
        <StyledTableCellInfo>{order.type === 'offline' ? '-' : order.fullName}</StyledTableCellInfo>
        <StyledDateCell>
          <div>{formatDate(order.createdAt)}</div>
        </StyledDateCell>
        <StyledTableCellInfo>
          ${order.grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </StyledTableCellInfo>
        <StyledTableCellInfo color={deliveryStatus?.color}>
          <span />
          {deliveryStatus?.shownStatus || order.status}
        </StyledTableCellInfo>
        <StyledTableCellInfo isDefaultValue={!order.trackingNumber}>
          {order.trackingNumber || (
            <>
              <DefaultTrackingMessage>You can enter the tracking number</DefaultTrackingMessage>
              <DefaultTrackingMessage>on the Order Detail page</DefaultTrackingMessage>
            </>
          )}
        </StyledTableCellInfo>
      </StyledTableRow>
    );
  };

  const renderOrdersTable = () => {
    const titleHint = 'This number is associated with your customer’s order';

    return (
      <TableContainer component={Paper}>
        <TableWrapper>
          <Head>
            <TableRow>
              {tableColumnHeaders.map((columnHeader: string) => {
                if (columnHeader === 'Order ID') {
                  return (
                    <StyledTableCell key={columnHeader}>
                      <LabelWrapper>
                        <label>{columnHeader}</label>
                        <Hint placement="top" text={titleHint}>
                          <QuestionHintIcon />
                        </Hint>
                      </LabelWrapper>
                    </StyledTableCell>
                  );
                }
                return <StyledTableCell key={columnHeader}>{columnHeader}</StyledTableCell>;
              })}
            </TableRow>
          </Head>
          <TableBody>{orderList.map((order: Order) => getOrdersList(order))}</TableBody>
        </TableWrapper>
      </TableContainer>
    );
  };

  return (
    <>
      <OrdersTotalTitle>Orders ({totalOrders})</OrdersTotalTitle>
      {renderOrdersTable()}
    </>
  );
};

export default OrderList;
