import React, { useEffect } from 'react';
import { StudentContainer, Title } from '../students/styles';
import { useDispatch, useSelector } from 'react-redux';
import { clearToolkitList, getToolkitList } from 'src/ducks/accounting/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { toolkitListSelector, errorSelector } from 'src/ducks/accounting/selectors';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { ToolkitList } from './toolkitList';
import { TotalBonuses } from './toolkitList/styles';
import { Report } from '../accounting/report';
import { useLocation } from 'react-router';
import { ILoadMoreParams, prepareQueryParams } from '../accounting/report/queryHelper';
import { userStoreId } from 'src/ducks/user/selectors';
import accountingApi from 'src/api/accountingApi';
import { prepareOnDownload } from 'src/ducks/cashout/urlHelper';

const Toolkit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const toolkitList = useSelector(toolkitListSelector);
  const errors = useSelector(errorSelector);
  const totalBonus = toolkitList?.totalBonus;
  const currentPage = Number(toolkitList?.currentPage);
  const totalPages = toolkitList?.totalPages;
  const storeId = useSelector(userStoreId);
  const baseDownloadUrl = `api/toolkit/report/${storeId}`;

  const loadData = (params: any = {}) => {
    dispatch(getToolkitList(params));
  }

  const resetFilter = () => {
    dispatch(clearToolkitList());
  }

  useEffect(() => {
    dispatch(getNewNotifications());
    const queryParams = prepareQueryParams(location.search);
    loadData(queryParams);
    return resetFilter;
  }, []);

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      let queryParams: ILoadMoreParams = prepareQueryParams(location.search);
      queryParams.page = Number(currentPage) + 1;
      loadData(queryParams)
    }
  };


  const onExportReport = (reportUrl: string, startDate: Date | null, endDate: Date | null) => {
    accountingApi.getToolkitReport(reportUrl)
      .then(prepareOnDownload('Toolkit Bonuses', startDate, endDate));
  };

  return (
    <StudentContainer>
      <Title>
        {'Total Bonus  '}
        <TotalBonuses>
          ${totalBonus?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </TotalBonuses>
      </Title>
      <Report
        loadData={loadData}
        resetFilter={resetFilter}
        baseDownloadUrl={baseDownloadUrl}
        idFilterByType={false}
        onExportReport={onExportReport}
      />
      {errors && Array.isArray(errors) ? (
        errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
      ) : (
        <ErrorMessage>{errors}</ErrorMessage>
      )}
      {toolkitList?.list?.length ?
        <ToolkitList
          toolkitList={toolkitList}
          loadMore={loadMore}
        />
        :
        <div>You don't have any toolkit bonuses</div>
      }
    </StudentContainer>
  );
};

export default Toolkit;
