import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, clearFields, change, initialize } from 'redux-form';
import { TableBody, TableRow } from '@material-ui/core';
import { TrashIcon } from 'src/components/common/Icons';
import {
  productVersionsChipsFormSelector,
  productVersionsNamesFormSelector,
  tableFormFormSelector,
} from 'src/ducks/products/selectors';
import VersionsTable from './VersionsTable/VersionsTable';
import InfoBlock from './InfoBlock';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { cleanTableDataOneChip } from 'src/utils/helpers';
import {
  ChipsT,
  NamesT,
  TableDataT,
  CleanRowT,
  CustomFieldsProps,
} from 'src/constants/productsTypes';
import {
  Conteiner,
  LeftPartContainer,
  RightPartContainer,
  ConteinerForAddOptions,
  TableWrapper,
  Head,
  StyledBodyTableRow,
  StyledTableCell,
  IconButton,
  Separator,
  OptionInput,
  StyledTableContainer,
  StyledError,
} from './styles';

const CustomFields = ({ changeData, productType }: CustomFieldsProps) => {
  const dispatch = useDispatch();
  const [resultArr, setResultArr] = useState<string[]>([]);
  const [chipError, setChipError] = useState<boolean>(false);
  const chips: ChipsT = useSelector(productVersionsChipsFormSelector);
  const names: NamesT = useSelector(productVersionsNamesFormSelector);
  const tableDataFormValues: TableDataT = useSelector(tableFormFormSelector);
  const firstName: string | undefined = names?.name1;
  const secondName: string | undefined = names?.name2;
  const firstChipsArr: string[] = chips?.chip1;
  const secondChipsArr: string[] = chips?.chip2;
  const onlyLettersRegExp = /^[a-zA-Z\s]+$/;
  const deleteSpacesRegExp = /\s+/g;
  const keyValueForComma = 188;

  useEffect(() => {
    changeData(resultArr);
  }, [resultArr, changeData]);

  useEffect(() => {
    if(resultArr.length === 0) {
      dispatch(change('hasCustomFields', 'hasCustomFields', false));
      dispatch(change('isVariousSizes', 'isVariousSizes', false));
    } else {
      dispatch(change('hasCustomFields', 'hasCustomFields', true));
    }
  }, [resultArr]);

  useEffect(() => {
    const isNotEmptyChipArr1 = firstChipsArr?.length > 0;
    const isNotEmptyChipArr2 = secondChipsArr?.length > 0;
    if (isNotEmptyChipArr1 && !isNotEmptyChipArr2) {
      setResultArr(firstChipsArr.map((firstOption: string) => `${firstOption}`));
    }
    if (isNotEmptyChipArr2 && !isNotEmptyChipArr1) {
      setResultArr(secondChipsArr.map((secondOption: string) => `${secondOption}`));
    }
    if (isNotEmptyChipArr2 && isNotEmptyChipArr1) {
      setResultArr(
        firstChipsArr
          .map((firstOption: string) => {
            return secondChipsArr.map((secondOption: string) => `${firstOption},${secondOption}`);
          })
          .flat()
      );
    }
    if (!isNotEmptyChipArr2 && !isNotEmptyChipArr1) {
      setResultArr([]);
    }
  }, [chips]);

  const cleanRow = (e: CleanRowT) => {
    const id: string = e.target.id;
    const name = id === '0' ? 'name1' : 'name2';
    const chip = id === '0' ? 'chip1' : 'chip2';
    dispatch(clearFields('ProductVersionsNames', false, false, name));
    dispatch(change('ProductVersionsChips', chip, []));
    cleanTableDataAllRow();
  };

  const cleanTableDataAllRow = () => {
    if (tableDataFormValues) {
      dispatch(clearFields('tableData', false, false, ...Object.keys(tableDataFormValues)));
    }
  };

  const handleDeleteChipInFirstInput = (delChipName: string) => {
    dispatch(
      change(
        'ProductVersionsChips',
        'chip1',
        firstChipsArr.filter((chipName: string) => chipName !== delChipName)
      )
    );
    const namesForDeletedInTableData = cleanTableDataOneChip(delChipName, 1, tableDataFormValues);
    dispatch(clearFields('tableData', false, false, ...namesForDeletedInTableData));
  };

  const handleDeleteChipInSecondInput = (delChipName: string) => {
    dispatch(
      change(
        'ProductVersionsChips',
        'chip2',
        secondChipsArr.filter((chipName: string) => chipName !== delChipName)
      )
    );
    const namesForDeletedInTableData = cleanTableDataOneChip(delChipName, 2, tableDataFormValues);
    dispatch(clearFields('tableData', false, false, ...namesForDeletedInTableData));
  };

  const validation = (name: string | undefined, chipsArr: string[], newChipName: string) => {
    const lessThan31Symbols = newChipName.length < 31;
    setChipError(!lessThan31Symbols);
    return name && lessThan31Symbols && chipsArr.length < 5 && newChipName.match(onlyLettersRegExp);
  };

  const deleteSpaces = (newChipName: string) => newChipName.trim();

  const handleAddChipInFirstInput = (newChipName: string) => {
    const validationPassed = validation(firstName, firstChipsArr, newChipName);
    const chipWithoutGaps = deleteSpaces(newChipName);
    if (validationPassed) {
      dispatch(
        initialize('ProductVersionsChips', {
          ...chips,
          chip1: firstChipsArr ? [...firstChipsArr, chipWithoutGaps] : [chipWithoutGaps],
        })
      );
    }
  };

  const handleAddChipInSecondInput = (newChipName: string) => {
    const validationPassed = validation(secondName, secondChipsArr, newChipName);
    const chipWithoutGaps = deleteSpaces(newChipName);
    if (validationPassed) {
      dispatch(
        initialize('ProductVersionsChips', {
          ...chips,
          chip2: secondChipsArr ? [...secondChipsArr, chipWithoutGaps] : [chipWithoutGaps],
        })
      );
    }
  };

  return (
    <Conteiner>
      <ConteinerForAddOptions>
        <LeftPartContainer>
          <StyledTableContainer>
            <TableWrapper>
              <Head>
                <TableRow>
                  <StyledTableCell style={{ width: '25%' }}>Category Name</StyledTableCell>
                  <StyledTableCell style={{ width: '20%' }}>Options</StyledTableCell>
                  <StyledTableCell />
                </TableRow>
              </Head>
              <TableBody>
                <StyledBodyTableRow>
                  <StyledTableCell align="left">
                    <FormInput
                      placeholder="For example, “Size”..."
                      type="text"
                      name="name1"
                      validate={validations.chipName}
                      customBg="#F8FAFB"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <OptionInput
                      value={firstChipsArr}
                      placeholder={'Enter values separated by commas...'}
                      onAdd={handleAddChipInFirstInput}
                      onDelete={handleDeleteChipInFirstInput}
                      newChipKeyCodes={[keyValueForComma]}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <IconButton onClick={cleanRow} id={'0'}>
                      <TrashIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledBodyTableRow>
                <StyledBodyTableRow>
                  <StyledTableCell align="left">
                    <FormInput
                      placeholder="For example, “Size”..."
                      type="text"
                      name="name2"
                      validate={validations.chipName}
                      customBg="#F8FAFB"
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <OptionInput
                      value={secondChipsArr}
                      placeholder="Enter values separated by commas..."
                      onAdd={handleAddChipInSecondInput}
                      onDelete={handleDeleteChipInSecondInput}
                      newChipKeyCodes={[keyValueForComma]}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <IconButton onClick={cleanRow} id={'1'}>
                      <TrashIcon />
                    </IconButton>
                  </StyledTableCell>
                </StyledBodyTableRow>
              </TableBody>
            </TableWrapper>
          </StyledTableContainer>
          {chipError ? (
            <StyledError>This field must contain no more than 30 characters</StyledError>
          ) : null}
        </LeftPartContainer>
        <Separator />
        <RightPartContainer>
          <InfoBlock productType={productType} />
        </RightPartContainer>
      </ConteinerForAddOptions>
      <VersionsTable resArr={resultArr} productType={productType} />
    </Conteiner>
  );
};

export default reduxForm<NamesT, CustomFieldsProps>({
  form: 'ProductVersionsNames',
})(CustomFields);
