import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

const turnParamsToObject = (url: string): Record<string, any> =>
  Object.fromEntries(new URLSearchParams(url))

// type QueryParams = <Params extends { [K in keyof Params]?: string } = {}>
export const useQueryParams = (): Record<string, string | Array<any> | undefined> => {
  const location = useLocation();
  const [params, setParams] = useState({});

  useEffect( () => {
    setParams( turnParamsToObject(location.search) )
  }, [location.search]);

  return params;
}