import React from 'react';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';

export const ViewOrderContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Card {...otherProps} />)({
    fontSize: 20,
    fontWeight: 500,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    boxShadow: '0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05)',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);

export const CustomerWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '30px 40px 0 40px',
    '& > div:last-child': {
      color: ({ theme }: any) => theme.customPalette.text.grey,
      fontSize: 15,
      fontWeight: 400,
    },
  })
);

export const InfoTitle = styled('div')({
    paddingBottom: 12,
});
