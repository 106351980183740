import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';

export default {
  getProductsList: (
    websiteId: number,
    page: number = 1,
    perPage: number = 10
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/product/list/${websiteId}?page=${page}&perPage=${perPage}`,
    }),

  addProduct: (data: any): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/product',
      data,
    }),

  removeProduct: (id: number): AxiosPromise<AxiosResponse> =>
    httpService.delete({
      url: `api/product/${id}`,
    }),

  getProduct: (id: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/product/${id}`,
    }),

  editProduct: (id: string, data: any): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/product/${id}`,
      data,
    }),
};
