import { useEffect } from 'react';

type UseInfiniteScrollProps = {
  loadMore: () => void;
};

const useInfiniteScroll = ({ loadMore }: UseInfiniteScrollProps) => {
  useEffect(() => {
    const handlerScroll = () => {
      if (
        Math.ceil(document.documentElement.clientHeight + window.scrollY) >=
        document.body.scrollHeight
      ) {
        loadMore();
      }
    };

    window.addEventListener('scroll', handlerScroll);
    return () => window.removeEventListener('scroll', handlerScroll);
  }, [loadMore]);
};

export default useInfiniteScroll;
