export type CashOutType = {
  type: string;
  shownType: string;
};

export const cashoutTypes = {
  mySchool: 'my_school',
  organization: 'organization',
  classWallet: 'class_wallet',
};

export default [
  {
    type: cashoutTypes.mySchool,
    shownType: 'My School',
  },
  {
    type: cashoutTypes.organization,
    shownType: 'Organization',
  },
  {
    type: cashoutTypes.classWallet,
    shownType: 'ClassWallet',
  },
];
