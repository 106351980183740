import React from 'react';
import Modal from 'react-modal';
import { LockIcon, DangerIcon, DeleteIconPopup } from 'src/components/common/Icons';
import {
  PopupDefault,
  PopupMainContainer,
  PopupButtonsContainer,
  PopupText,
  PopupHint,
  PopupHeader,
  PopupButton,
} from './styles';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import noScroll from 'no-scroll';

type Props = {
  kind?: 'pin' | 'danger' | 'error' | 'custom';
  pin?: string;
  error?: string | (string | undefined)[] | null;
  isOpen: boolean;
  closeModal: (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => void;
  handleYesAction?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  copyPin?: () => void;
  isRemove?: boolean;
  mainText?: string;
  hintText?: string;
  warningText?: string;
  renderCustomContent?: () => React.ReactNode;
  additionalContentStyle?: { [prop: string]: any };
  additionalWrapperStyle?: { [prop: string]: any };
};

const Popup = (props: Props) => {
  const {
    kind,
    isRemove,
    isOpen,
    pin,
    error,
    closeModal,
    handleYesAction,
    copyPin,
    mainText,
    hintText,
    warningText,
    renderCustomContent,
    additionalContentStyle = {},
    additionalWrapperStyle = {},
  } = props;

  const renderDangerContent = () => (
    <>
      {isRemove ? <DeleteIconPopup /> : <DangerIcon />}
      <PopupMainContainer>
        <PopupText>{mainText}</PopupText>
        <PopupHint>{hintText}</PopupHint>
        <PopupHint>{warningText}</PopupHint>
      </PopupMainContainer>
      <PopupButtonsContainer>
        <PopupButton isSecondary onClick={closeModal}>
          No
        </PopupButton>
        <PopupButton onClick={handleYesAction}>Yes</PopupButton>
      </PopupButtonsContainer>
    </>
  );

  const renderInfoContent = () => (
    <>
      <LockIcon />
      <PopupMainContainer>
        <PopupText>{mainText}</PopupText>
        <PopupHeader>{pin}</PopupHeader>
      </PopupMainContainer>
      <PopupButtonsContainer>
        <PopupButton isSecondary onClick={closeModal}>
          Ok
        </PopupButton>
        <PopupButton onClick={copyPin}>Copy pin and close</PopupButton>
      </PopupButtonsContainer>
    </>
  );

  const renderErrorContent = () => (
    <>
      <DangerIcon />
      <PopupMainContainer>
        <PopupText>{mainText}</PopupText>
        <PopupHint>{hintText}</PopupHint>
      </PopupMainContainer>
      <PopupButtonsContainer>
        <PopupButton onClick={closeModal}>Ok</PopupButton>
      </PopupButtonsContainer>
    </>
  );

  const renderContent = () => {
    switch (kind) {
      case 'pin':
        return renderInfoContent();
      case 'danger':
        return renderDangerContent();
      case 'error':
        return renderErrorContent();
      case 'custom': {
        if (!renderCustomContent) {
          console.error('renderCustomContent is required when kind is "custom":', props);
          return null;
        }
        return renderCustomContent();
      }
      default: {
        console.warn('Unrecognized popup kind:', kind);
        return null;
      }
    }
  };

  const handleRequestClose = (
    e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
  ) => {
    closeModal(e);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={handleRequestClose}
        onAfterOpen={() => {
          noScroll.on();
        }}
        onAfterClose={() => {
          noScroll.off();
        }}
        style={{
          overlay: {
            backgroundColor: 'rgb(229, 229, 229, .6)',
          },
          content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',
            transform: 'translate(-50%, -50%)',
            width: 'auto',
            maxWidth: '400px',
            ...additionalContentStyle,
          },
        }}
        className="default-popup"
        ariaHideApp={false}
      >
        <PopupDefault style={{ ...additionalWrapperStyle }}>
          {error && <ErrorMessage>Something wrong...</ErrorMessage>}
          {renderContent()}
        </PopupDefault>
      </Modal>
    </>
  );
};

export default Popup;
