import React from 'react';
import { styled } from '@material-ui/core/styles';

export const NotifyWrapper = styled('div')({
  display: 'flex',
  padding: 25,
  borderBottom: '1px solid #EAEDF0',
  justifyContent: 'space-between',
});

export const AvatarWrapper = styled('div')({
  marginRight: 12,
});

export const TextWrapper = styled('div')({
  display: 'flex',
  marginRight: 33,
  fontSize: 13,
  color: '#44566C',
  flex: 1,
  wordBreak: 'break-word',
});

export const TimeWrapper = styled('div')({
  display: 'flex',
  fontSize: 13,
  color: '#8697A8',
});

export const NoNotify = styled('div')({
  padding: 30,
});
