import React from 'react';
import { Link } from 'react-router-dom';
import { renderFooter } from './Template1';
import { ShopCartTemplate1Icon } from 'src/components/common/Icons';
import { WebsiteContainer, WebsiteName, CartIconContainer } from './styles';

type WebsiteWrapper1 = {
  email: string;
  websitePath: string;
  name: string;
  children: React.ReactNode;
  productsAmount: number;
};

const WebsiteWrapper1 = ({
  email,
  children,
  websitePath,
  name,
  productsAmount,
}: WebsiteWrapper1) => {
  return (
    <WebsiteContainer isCart>
      <WebsiteName isCart>
        <Link to={`/shop/${websitePath}`}>
          <div>{name}</div>
        </Link>
        <CartIconContainer>
          <Link to={`/shop/${websitePath}/cart`}>
            <ShopCartTemplate1Icon />
            <div>{productsAmount}</div>
          </Link>
        </CartIconContainer>
      </WebsiteName>
      {children}
      {renderFooter(email)}
    </WebsiteContainer>
  );
};

export default WebsiteWrapper1;
