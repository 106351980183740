import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
  CLEAR_TOOLKIT_LIST,
  GET_TOOLKIT_LIST,
  GET_TOOLKIT_LIST_FAIL,
  GET_TOOLKIT_LIST_SUCCESS,
  GET_PROFITS_LIST,
  GET_PROFITS_LIST_SUCCESS,
  GET_PROFITS_LIST_FAIL,
  CLEAR_PROFITS_LIST,
} from './constants';
import accountingApi from 'src/api/accountingApi';
import { ActionType, Error } from '../user/user';

export const moduleName = 'accounting';

export type StateToolkitInterface = {
  error: Error | null;
  loading: boolean;
  toolkitList: ToolkitList | null;
  profitList: ProfitList | null;
};

export type Toolkit = {
  id: number;
  createdAt: string;
  toolkitName: string;
  total: number;
};

export type ToolkitList = {
  list: Toolkit[];
  currentPage: number;
  totalPages: number;
  totalBonus: any;
};

export const toolkitState: StateToolkitInterface = {
  toolkitList: null,
  profitList: null,
  error: null,
  loading: false,
};

export type Profit = {
  id: number;
  createdAt: string;
  grandTotal: number;
  profitTotal: number;
  transactionNumbers: null | string[];
  type: string;
  zipCode: string;
};

export type ProfitList = {
  list: Profit[];
  currentPage: number;
  totalPages: number;
  totalProfit: number;
};

type ToolkitPayload = {
  page?: number;
};

export default function reducer(
  state: StateToolkitInterface = toolkitState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_TOOLKIT_LIST:
    case GET_PROFITS_LIST:
      return { ...state, loading: true, error: null };

    case GET_TOOLKIT_LIST_SUCCESS:
      const list = state?.toolkitList?.list;
      return {
        ...state,
        loading: false,
        toolkitList: {
          list: list ? [...list, ...payload.data] : payload.data,
          currentPage: payload._page,
          totalPages: payload._pages,
          totalBonus: payload.totalBonus,
        },
      };

    case GET_TOOLKIT_LIST_FAIL:
    case GET_PROFITS_LIST_FAIL:
      return {
        ...state,
        loading: false,
        toolkitList: null,
        profitList: null,
        error,
      };

    case CLEAR_TOOLKIT_LIST:
    case CLEAR_PROFITS_LIST:
      return {
        ...state,
        toolkitList: null,
        profitList: null,
        error: null,
      };

    case GET_PROFITS_LIST_SUCCESS:
      const profitArray = state?.profitList?.list;
      return {
        ...state,
        profitList: {
          list: profitArray ? [...profitArray, ...payload.data] : payload.data,
          currentPage: payload._page,
          totalPages: payload._pages,
          totalProfit: payload.totalProfit,
        },
        loading: false,
        error: null,
      };

    default:
      return state;
  }
}

function* getToolkitListSaga(payload: any) {
  const user = yield select((state) => state.user?.user);
  const storeId = user?.accesses && user?.accesses[0].store?.id;

  try {
    if (storeId) {
      const response = yield accountingApi.getToolkitList(storeId, payload.payload);

      yield put({ type: GET_TOOLKIT_LIST_SUCCESS, payload: response });
    }
  } catch (err) {
    yield put({ type: GET_TOOLKIT_LIST_FAIL, error: err });
  }
}

function* getViewProfitSaga({ payload }: ActionType) {
  const user = yield select((state) => state.user?.user);
  const storeId = user?.accesses && user?.accesses[0].store?.id;

  try {
    if (storeId) {
      const response = yield accountingApi.getProfitOrder(storeId, payload);
      yield put({ type: GET_PROFITS_LIST_SUCCESS, payload: response });
    }
  } catch (err) {
    yield put({ type: GET_PROFITS_LIST_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(GET_TOOLKIT_LIST, getToolkitListSaga),
    takeLatest(GET_PROFITS_LIST, getViewProfitSaga),
  ]);
};
