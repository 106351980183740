import React from 'react';
import { styled } from '@material-ui/core/styles';

export const Overlay = styled('div')({
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  bottom: 0,
  cursor: 'pointer',
  height: '100%',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 999,
});

export const MainDrawer = styled('div')({
  width: 450,
  background: '#ffffff',
  position: 'absolute',
  right: 0,
  height: '100vh',
  overflow: 'auto',
});

export const DrawerTitle = styled('div')({
  fontSize: 30,
  fontWeight: 'bold',
  color: '#44566C',
  padding: '40px 0 20px 30px',
});

export const TitleWrapper = styled('div')({
  borderBottom: '1px solid #EAEDF0',
});
