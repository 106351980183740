import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const Wrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '30px',
  })
);

export const Label = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <label {...otherProps} />)({
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: '#8697A8',
  })
);

export const LabelWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
  })
);
