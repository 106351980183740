import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';
import { CreatedWebsite } from 'src/ducks/website/website';
import { PaginationParams } from 'src/interfaces/http';

export default {
  getListTemplate: (): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: 'api/template/list',
    }),

  getWebsite: (id: number): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/website/${id}`,
    }),

  getWebsiteShop: (websitePath: string, anonymousToken: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/shop/website/${websitePath}`,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    }),

  createWebsite: (data: CreatedWebsite): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/website',
      data,
    }),

  editWebsite: (id: number, data: CreatedWebsite): AxiosPromise<AxiosResponse> =>
    httpService.patch({
      url: `api/website/${id}`,
      data,
    }),

  getCollections: (websiteId: string, anonymousToken: string, params: PaginationParams = {}): AxiosPromise<AxiosResponse> => {
    const searchParams = new URLSearchParams(params as Record<string, string>).toString();
    const url = `api/website-collection/list/${websiteId}?${searchParams}`;
    return httpService.get({
      url,
      headers: {
        Authorization: `Bearer ${anonymousToken}`,
      },
    });
  }
};
