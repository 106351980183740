import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { errorSelector } from 'src/ducks/website/selectors';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { UploadImages } from 'src/components/common/upload-file';
import WebsiteTemplates from '../website-templates/WebsiteTemplates';
import {
  websiteListTemplateSelector,
  websiteTemplateIDSelector,
  uploadedAmazonImagesSelector,
} from 'src/ducks/website/selectors';
import { S3Image } from 'src/constants/commonTypes';
import { PaperRoot, TabsWrapper, CustomTab } from './styles';
import { WebsiteTemplateIcon, WebsiteCameraIcon } from 'src/components/common/Icons';
import defaultTemplate1 from 'src/assets/images/default-templates/default-template-1.png';
import defaultTemplate2 from 'src/assets/images/default-templates/default-template-2.png';
import defaultTemplate3 from 'src/assets/images/default-templates/default-template-3.png';

const images = [defaultTemplate1, defaultTemplate2, defaultTemplate3];

export type TemplateProps = {
  id: number;
  name: string;
  path: string;
};

const TabsBar = () => {
  const [tab, setActiveTab] = useState(0);
  const [notSandedImageFile, setNotSandedImageFile] = useState([]);
  const templateID = useSelector(websiteTemplateIDSelector);
  const arrayImages = useSelector(uploadedAmazonImagesSelector);
  const errors = useSelector(errorSelector);

  const emptyArrayOrArrayImageWithURL = (images: S3Image[]) => {
    if (Array.isArray(images) && images.length > 0) {
      return images;
    }
    return notSandedImageFile;
  };

  const { data } = useSelector(websiteListTemplateSelector);
  if (!data) return null;
  const extendedTemplateImages = data.map((item: TemplateProps, i: number) => {
    const path = images[i];
    return { ...item, path };
  });

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <>
      {errors && Array.isArray(errors) ? (
        errors.map((error) => <ErrorMessage>{error}</ErrorMessage>)
      ) : (
        <ErrorMessage>{errors}</ErrorMessage>
      )}
      <PaperRoot square>
        <TabsWrapper value={tab} TabIndicatorProps={{ children: <span /> }} onChange={handleChange}>
          <CustomTab disableRipple label="Templates" icon={<WebsiteTemplateIcon />} />
          <CustomTab disableRipple label="Site image" icon={<WebsiteCameraIcon />} />
        </TabsWrapper>
      </PaperRoot>
      {tab === 0 && (
        <WebsiteTemplates
          templateId={templateID || extendedTemplateImages[0].id}
          images={extendedTemplateImages}
        />
      )}
      {tab === 1 && (
        <UploadImages
          templateId={templateID}
          files={emptyArrayOrArrayImageWithURL(arrayImages)}
          setNotUploadedImageFile={setNotSandedImageFile}
        />
      )}
    </>
  );
};

export default TabsBar;
