import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getItemByKeyFromStorage } from 'src/utils/helpers';
import routesPath from 'src/routes/config';

type Props = {
  render: any;
};

const DisabledForAuthorizedUserRoute = (props: Props & RouteProps) => {
  const { render: Component, ...rest } = props;
  const isAuthenticated = Boolean(getItemByKeyFromStorage('auth'));

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isAuthenticated && Component) {
          return <Component {...routeProps} />;
        }
        return <Redirect to={routesPath.home} />;
      }}
    />
  );
};

export default DisabledForAuthorizedUserRoute;
