import React from 'react';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { FormArea } from 'src/components/common/textarea';
import {
  CashoutDescription,
  FormBlock,
  TextAreaContainer,
  PromptText,
  NeededChangesPrompt,
  LabelBlock,
  Label,
  LabelDescription,
} from '../styles';

type OrganizationFormProps = {
  isAllowEditCashout?: boolean;
  validateAmount: (value: number) => string | null;
  needChangesText: string | null;
};

const OrganizationForm = ({ isAllowEditCashout, validateAmount, needChangesText }: OrganizationFormProps) => {
  const titleHint =
    'We need this information for tax purposes. You can search an organization’s ' +
    'website or reach out to them directly to find this number.';

  return (
    <CashoutDescription>
      <FormBlock id='form-block-cashout'>
        <FormInput
          type="text"
          name="organizationName"
          label="Organization Name:"
          customBg="#F8FAFB"
          validate={validations.cashout.organizationName}
          disabled={!isAllowEditCashout}
        />
        <FormInput
          type="text"
          name="organizationAddress"
          label="Organization Address:"
          customBg="#F8FAFB"
          validate={validations.cashout.organizationAddress}
          disabled={!isAllowEditCashout}
        />
        <FormInput
          type="text"
          name="organizationCityAndState"
          label="Organization City, State:"
          customBg="#F8FAFB"
          validate={validations.cashout.organizationCityAndState}
          disabled={!isAllowEditCashout}
        />
        <FormInput
          type="text"
          name="organizationTaxId"
          label="Organization Tax ID:"
          customBg="#F8FAFB"
          isNeedHelp
          title={titleHint}
          validate={validations.cashout.organizationTaxId}
          disabled={!isAllowEditCashout}
        />
        <FormInput
          type="text"
          name="organizationZipCode"
          label="Zip Code:"
          customBg="#F8FAFB"
          validate={validations.zipCode}
          disabled={!isAllowEditCashout}
        />
      </FormBlock>
      <FormBlock>
        <FormInput
          name="amount"
          label="Amount:"
          customBg="#F8FAFB"
          validate={[...validations.cashout.amount, validateAmount]}
          disabled={!isAllowEditCashout}
        />
        <PromptText>
          This check will be sent to your school so you and your students can deliver or mail the
          donation to the organization of your choosing. If you would prefer that we send the check
          directly to the organization, please make note in the field below.
        </PromptText>
        <TextAreaContainer>
          <FormArea
            name="note"
            label="Note:"
            validate={validations.cashout.note}
            disabled={!isAllowEditCashout}
          />
        </TextAreaContainer>
      </FormBlock>
      {
        needChangesText && (
          <NeededChangesPrompt>
            <LabelBlock>
              <Label>What Changes Are Needed?</Label>
              <LabelDescription>{needChangesText}</LabelDescription>
            </LabelBlock>
          </NeededChangesPrompt>
        )
      }
    </CashoutDescription>
  );
};

export default OrganizationForm;
