import React from 'react';
import { CardContainer } from 'src/components/pages/auth/styles';
import { Typography, TypographyProps } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

export const WebsiteDesignContainer = styled('div')({
  padding: '40px 50px 50px',
  backgroundColor: 'rgb(248, 250, 251)',
});

export const MainContainer = styled('div')({
  display: 'flex',
  borderRadius: 10,
  boxShadow: '0px 4px 8px rgba(169, 194, 209, 0.25)',
});

export const ImageContainer = styled('div')({
  flexGrow: 1,
  padding: '0 40px',
});

export const FormContainer = withTheme(
  styled(CardContainer)({
    justifyContent: 'space-between',
    flexDirection: 'column',
    alignItems: 'center',
    width: 430,
    minWidth: 400,
    padding: '15px 0 35px 0',
  })
);

export const Title = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      paddingBottom: 20,
      color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    }
  )
);

export const WebsiteDescription = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    paddingBottom: 20,
    paddingLeft: 25,
    fontSize: 20,
    fontWeight: 500,
    alignSelf: 'flex-start',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
  })
);
