import React from 'react';
import { useSelector } from 'react-redux';
import { User } from 'src/ducks/user/user';
import profileMessages from 'src/constants/profileMessages';
import { ProfileCardTitle } from '../styles';
import { StyledRow, RowLabel, RowValue } from './styles';
import { firstStoreNameSelector } from 'src/ducks/user/selectors';

type InformationRowProps = {
  label: string;
  value: string;
};

const InformationRow = ({ label, value }: InformationRowProps) => {
  const size = value.length > 35 ? 14 : 16;
  return (
    <StyledRow>
      <RowLabel>{label}</RowLabel>
      <RowValue size={size}>{value}</RowValue>
    </StyledRow>
  );
};

type SchoolInformationProps = {
  teacherProfileInfo: User;
};

const SchoolInformation = ({ teacherProfileInfo }: SchoolInformationProps) => {
  const businessName = useSelector(firstStoreNameSelector);
  const { accesses } = teacherProfileInfo;
  const userStore = accesses[0].store;
  const storeDescription = userStore.storeDescription;

  if (storeDescription) {
    const { schoolAddress, cityAndState, schoolLevel, schoolName, zipCode } = storeDescription;

    return (
      <>
        <ProfileCardTitle>{profileMessages.schoolInformation}</ProfileCardTitle>
        <InformationRow label={profileMessages.schoolAddress} value={schoolAddress} />
        <InformationRow label={profileMessages.cityAndState} value={cityAndState} />
        <InformationRow label={profileMessages.zipCode} value={zipCode} />
        <InformationRow label={profileMessages.schoolLevel} value={schoolLevel} />
        <InformationRow label={profileMessages.schoolName} value={schoolName} />
        {businessName && (
          <InformationRow label={profileMessages.businessName} value={businessName} />
        )}
      </>
    );
  }

  return null;
};

export default SchoolInformation;
