import inputUtils from 'src/utils/formUtils';
import formValidations from 'src/constants/formValidations';
import inputRestrictions from 'src/constants/inputFieldRestrictions';

const {
  required,
  validatePassword,
  validatePin,
  maxLength,
  minLength,
  validateEmail,
  validateWebsiteURL,
  minValue,
  maxValue,
  isInteger,
  validateZipCode,
  validateState,
  validateZIP,
  validatePhone,
  isNumber,
} = inputUtils;
const {
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  PASSWORD_INCORRECT,
  PASSWORD_REQUIRED,
  PIN_REQUIRED,
  PIN_INCORRECT,
  LOGIN_REQUIRED,
  EMAIL_INCORRECT,
  EMAIL_REQUIRED,
  CITY_REQUIRED,
  ZIP_CODE_REQUIRED,
  ZIP_CODE_INCORRECT,
  SCHOOL_REQUIRED,
  STORE_REQUIRED,
  SCHOOL_LEVEL_REQUIRED,
  FIELD_REQUIRED,
  WEBSITE_REQUIRED,
  WEBSITE_URL_INCORRECT,
  STATE_INCORRECT,
  ZIP_INCORRECT,
  PHONE_INCORRECT,
} = formValidations;

const validations = {
  pin: [required(PIN_REQUIRED), validatePin(PIN_INCORRECT)],
  login: [required(LOGIN_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH)],
  firstName: [
    required(FIRST_NAME_REQUIRED),
    maxLength(inputRestrictions.MAX_LENGTH),
    minLength(inputRestrictions.MIN_LENGTH_NAME),
  ],
  lastName: [
    required(LAST_NAME_REQUIRED),
    maxLength(inputRestrictions.MAX_LENGTH),
    minLength(inputRestrictions.MIN_LENGTH_NAME),
  ],
  email: [
    required(EMAIL_REQUIRED),
    maxLength(inputRestrictions.MAX_LENGTH_EMAIL),
    validateEmail(EMAIL_INCORRECT),
  ],
  password: [required(PASSWORD_REQUIRED), validatePassword(PASSWORD_INCORRECT)],
  city: [required(CITY_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_CITY)],
  zipCode: [required(ZIP_CODE_REQUIRED), validateZipCode(ZIP_CODE_INCORRECT)],
  school: [required(SCHOOL_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_SCHOOL_NAME)],
  store: [
    minLength(inputRestrictions.MIN_LENGTH_NAME),
    required(STORE_REQUIRED),
    maxLength(inputRestrictions.BUSINESS_NAME_MAX_LENGTH),
  ],
  schoolLevel: [required(SCHOOL_LEVEL_REQUIRED)],
  studentName: [
    maxLength(inputRestrictions.MAX_LENGTH_STUDENT_NAME),
    minLength(inputRestrictions.MIN_LENGTH_NAME),
    required(FIELD_REQUIRED),
  ],
  studentUserName: [
    maxLength(inputRestrictions.MAX_LENGTH),
    minLength(inputRestrictions.MIN_LENGTH_NAME),
    required(FIELD_REQUIRED),
  ],
  // fields for custom fields
  chipName: [
    maxLength(inputRestrictions.MAX_LENGTH),
    minLength(inputRestrictions.MIN_LENGTH_NAME),
    required(FIELD_REQUIRED),
  ],
  // fields for websiteDescription form
  businessName: [required(WEBSITE_REQUIRED), maxLength(inputRestrictions.BUSINESS_NAME_MAX_LENGTH)],
  websiteTagline: [required(WEBSITE_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_EMAIL)],
  aboutAs: [required(WEBSITE_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_ABOUT_AS)],
  websiteURL: [
    required(WEBSITE_REQUIRED),
    maxLength(inputRestrictions.MAX_LENGTH_WEB_SITE_URL),
    validateWebsiteURL(WEBSITE_URL_INCORRECT),
  ],
  productName: [required(FIELD_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_PRODUCT_NAME)],
  productDescription: [required(FIELD_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_ABOUT_AS)],
  price: [
    required(FIELD_REQUIRED),
    maxLength(inputRestrictions.MAX_LENGTH_PRICE),
    minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INPUTS),
    isNumber(),
  ],
  inventory: [
    maxLength(inputRestrictions.MAX_LENGTH_INVENTORY),
    minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INVENTORY),
    isInteger(),
  ],
  width: [
    maxLength(inputRestrictions.MAX_LENGTH_PRICE),
    minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INPUTS),
    isNumber(),
  ],
  height: [
    maxLength(inputRestrictions.MAX_LENGTH_PRICE),
    minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INPUTS),
    isNumber(),
  ],
  length: [
    maxLength(inputRestrictions.MAX_LENGTH_PRICE),
    minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INPUTS),
    isNumber(),
  ],
  required: [
    required(FIELD_REQUIRED),
  ],
  trackingNumber: [maxLength(inputRestrictions.MAX_TRACKING_NUMBER)],
  schoolName: [required(FIELD_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_EMAIL)],
  shipping: {
    fullName: [
      required(FIELD_REQUIRED),
      minLength(inputRestrictions.MIN_LENGTH_NAME),
      maxLength(100),
    ],
    address: [required(FIELD_REQUIRED), minLength(1), maxLength(50)],
    city: [required(FIELD_REQUIRED), minLength(2), maxLength(28)],
    state: [required(FIELD_REQUIRED), validateState(STATE_INCORRECT)],
    zip: [required(FIELD_REQUIRED), validateZIP(ZIP_INCORRECT)],
    phone: [required(FIELD_REQUIRED), validatePhone(PHONE_INCORRECT)],
  },
  cashout: {
    fullName: [required(FIELD_REQUIRED), maxLength(inputRestrictions.MAX_LENGTH_FULL_NAME)],
    amount: [
      required(FIELD_REQUIRED),
      maxLength(inputRestrictions.MAX_LENGTH_CASHOUT_AMOUT),
      minValue(inputRestrictions.MIN_VALUE_FOR_NUMBERS_INPUTS),
      isNumber(),
    ],
    note: [maxLength(inputRestrictions.MAX_LENGTH_CASHOUT_NOTE)],
    organizationName: [
      required(FIELD_REQUIRED),
      maxLength(inputRestrictions.MAX_LENGTH_ORGANIZATION_NAME),
    ],
    organizationAddress: [
      required(FIELD_REQUIRED),
      maxLength(inputRestrictions.MAX_LENGTH_ORGANIZATION_ADDRESS),
    ],
    organizationCityAndState: [
      required(FIELD_REQUIRED),
      maxLength(inputRestrictions.MAX_LENGTH_ORGANIZATION_ADDRESS),
    ],
    organizationTaxId: [
      required(FIELD_REQUIRED),
      maxLength(inputRestrictions.MAX_LENGTH_ORGANIZATION_TAX_ID),
    ],
  },
  deliveryFee: [
    maxValue(inputRestrictions.MAX_VALUE_DELIVERY_FEE),
    minValue(inputRestrictions.MIN_VALUE_DELIVERY_FEE),
    isNumber(),
  ],
  productNote: [
    maxLength(inputRestrictions.MAX_LENGTH_PRODUCT_NOTE),
  ]
};

/**
 * Add rule 'required' for USPS Shipping option
 * @param validationRules 
 * @returns 
 */
export const addUspsRules = (validationRules: any[], isUspsShipping: boolean | undefined = true) => {
  if (isUspsShipping) {
    return validationRules.concat(validations.required)
  }

  return validationRules;
}

export default validations;