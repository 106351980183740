import { moduleName } from './shopProducts';

export const GET_SHOP_PRODUCTS = `${moduleName}/GET_SHOP_PRODUCTS`;
export const GET_SHOP_PRODUCTS_SUCCESS = `${moduleName}/GET_SHOP_PRODUCTS_SUCCESS`;
export const GET_SHOP_PRODUCTS_FAIL = `${moduleName}/GET_SHOP_PRODUCTS_FAIL`;

export const GET_SHOP_OTHER_PRODUCTS = `${moduleName}/GET_SHOP_OTHER_PRODUCTS`;
export const GET_SHOP_OTHER_PRODUCTS_SUCCESS = `${moduleName}/GET_SHOP_OTHER_PRODUCTS_SUCCESS`;
export const GET_SHOP_OTHER_PRODUCTS_FAIL = `${moduleName}/GET_SHOP_OTHER_PRODUCTS_FAIL`;

export const GET_SHOP_PRODUCTS_BY_IDS = `${moduleName}/GET_SHOP_PRODUCTS_BY_IDS`;
export const GET_SHOP_PRODUCTS_BY_IDS_SUCCESS = `${moduleName}/GET_SHOP_PRODUCTS_BY_IDS_SUCCESS`;
export const GET_SHOP_PRODUCTS_BY_IDS_FAIL = `${moduleName}/GET_SHOP_PRODUCTS_BY_IDS_FAIL`;

export const ADD_PRODUCT_TO_SHOPPING_CART = `${moduleName}/ADD_PRODUCT_TO_SHOPPING_CART`;
export const ADD_PRODUCT_TO_SHOPPING_CART_SUCCESS = `${moduleName}/ADD_PRODUCT_TO_SHOPPING_CART_SUCCESS`;
export const ADD_PRODUCT_TO_SHOPPING_CART_FAIL = `${moduleName}/ADD_PRODUCT_TO_SHOPPING_CART_FAIL`;

export const VIEW_SHOP_PRODUCT = `${moduleName}/VIEW_SHOP_PRODUCT`;
export const VIEW_SHOP_PRODUCT_SUCCESS = `${moduleName}/VIEW_SHOP_PRODUCT_SUCCESS`;
export const VIEW_SHOP_PRODUCT_FAIL = `${moduleName}/VIEW_SHOP_PRODUCT_FAIL`;

export const SET_IS_VALID_PRODUCTS = `${moduleName}/SET_IS_VALID_PRODUCTS`;
export const SET_IS_VALID_PRODUCTS_SUCCESS = `${moduleName}/SET_IS_VALID_PRODUCTS_SUCCESS`;

export const CHECKOUT_WITH_VALIDATION = `${moduleName}/CHECKOUT_WITH_VALIDATION`;

export const SET_SHIPPING_METHOD = `${moduleName}/SET_SHIPPING_METHOD`;
