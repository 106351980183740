import { styled } from '@material-ui/core/styles';
import { ResetPinButton } from 'src/components/pages/students/studenstList/styles';
import { ContinueButton } from 'src/components/pages/auth/signin/styles';


export const ButtonContainer = styled('div')({
  textAlign: 'right',
});

export const AddProductButton = styled(ContinueButton)({
  width: 150,
});

export const BackButton = styled(ResetPinButton)({
  maxWidth: 130,
  marginRight: 25,
});

export const SmallImage = styled('div')({
  display: 'flex',
  height: 140,
  width: 140,
  marginBottom: 24,
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
});

export const ContainerColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const ErrorMessage = styled('div')({
  fontSize: 11,
  color: '#FF3D57',
});

export const ContainerFlex = styled('div')({
  display: 'flex',
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 50,
});

export const Remove = styled('span')({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  right: 0,
  width: 24,
  height: 24,
});

export const SpaceBetweenWrapper = styled('span')({
  alignItems: 'flex-end',
  display: 'flex',
  justifyContent: 'space-between',
});

export const ProductErrorMessage = styled('div')({
  color: '#FF3D57',
  marginTop: -15,
  marginBottom: 15,
});

export const UploadImgContainer = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  background: '#F8FAFB',
  borderRadius: 10,
  height: 596,
  width: 596,
  '&:hover': {
    backgroundColor: '#EAEDF0',
  },
  '@media (max-width: 1540px)': {
    width: 500,
    height: 500,
  },
});

export const FormTitle = styled('div')({
  fontWeight: 500,
  fontSize: 20,
  lineHeight: '23px',
  color: '#44566C',
  marginBottom: 35,
});

export const InputsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& > div': {
    marginRight: 15,
  },
  '@media (max-width: 1620px)': {
    flexWrap: 'wrap',
    '& > div': {
      marginRight: 0,
    }
  },
});

export const ProductInfo = styled('div')({
  maxWidth: 600,
  '& > div': {
    width: '100%',
    '& > div': {
      '@media (max-width: 1820px)': {
        maxWidth: 200,
        minWidth: 160,
      },
      '@media (max-width: 1620px)': {
        maxWidth: '100%',
      },
    },
  },
  '& textarea': {
    height: 65,
    border: '1px solid #EAEDF0',
    color: '#44566C',
    resize: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '24px',
  },
});

export const ContentContainer = styled('div')({
  background: '#fff',
  display: 'flex',
  flexDirection: 'column',
});

export const ImagesContainer = styled('div')({
  display: 'flex',
  padding: '50px 25px 50px',
});

export const SmImgWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: 40,
});

export const SmTitle = styled('div')({
  fontWeight: 500,
  fontSize: 15,
  lineHeight: '18px',
  textTransform: 'capitalize',
  color: '#44566C',
  marginBottom: 30,
});


export const ShowCustomOptionsButton = styled('a')({
  display: 'inline-flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 300,
  margin: '0 auto',
  color: '#008DBA',
  '&:p': {
    marginBottom: 15,
  },
  '&:hover': {
    color: '#0081FF',
    cursor: 'default',
  },
});
