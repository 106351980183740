import React from 'react';
import { AppBar, AppBarProps, Avatar } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

export const AppBarContainer = withTheme(
  styled(({ theme, ...otherProps }: AppBarProps & WithTheme) => <AppBar {...otherProps} />)({
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.header,
    boxShadow: 'none',
    borderBottom: ({ theme }: WithTheme) => `1px solid ${theme.customPalette.borders.divider}`,
    zIndex: 2,
  })
);

export const DefaultAvatar = withTheme(
  styled(Avatar)({
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.avatar.background,
    marginRight: 15,
    fontSize: 14,
    fontWeight: 500,
  })
);

export const UserAvatar = styled(Avatar)({
  marginRight: 15,
});

export const Menu = withTheme(
  styled('div')({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
  })
);

export const NotificationButton = styled('div')({
  cursor: 'pointer',
  marginRight: 5,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 40,
  height: 40,
  position: 'relative',
  '&:hover': {
    background: '#EAEDF0',
    borderRadius: '50%',
  },
});

export const BadgeIconWrapper = styled('div')({
  position: 'absolute',
  right: 7,
  top: 5,
});
