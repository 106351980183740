import {
  LIST_ORDER,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_FAIL,
  CLEAR_ORDER_LIST,
  VIEW_ORDER,
  VIEW_ORDER_SUCCESS,
  VIEW_ORDER_FAIL,
  EDIT_ORDER,
  EDIT_ORDER_SUCCESS,
  EDIT_ORDER_FAIL,
} from './constants';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import orderApi from 'src/api/orderApi';
import history from 'src/containers/history';
import { GetOrderList, GetOrder, EditOrder } from './actions';
import { userWebsitesArraySelector } from 'src/ducks/website/selectors';
import { prepareUspsDeliveryIndex } from 'src/utils/helpers';

export const moduleName = 'order';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

export type StateOrdersInterface = {
  orderList: OrderList | null;
  order: FullOrder | null;
  loading: boolean;
  error: Error | null;
};

export type Error = {
  error: boolean;
  errors:
  | ErrorData[]
  | {
    title: string;
    message: string;
  };
};

export type ErrorData = {
  field?: string;
  invalid_value: string;
  message: string;
};

export type Order = {
  id: number;
  createdAt: string;
  status: string;
  fullName: string;
  trackingNumber: string;
  grandTotal: number;
  type: string | null | undefined;
};

export type ProductChoiceNames = {
  optionName: string;
  valueName: string;
};

export type OrderedProduct = {
  id: number;
  name: string;
  price: number;
  quantity: number;
  shippingType: string;
  productChoiceNames?: ProductChoiceNames[] | null;
};

export type FullOrder = {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  email: string;
  subtotal: number;
  shipping: number;
  shippingContainer: string | null;
  shippingMethod: string;
  tax: number;
  note: string;
  orderedProducts: OrderedProduct[];
} & Order;

export type OrderList = {
  list: Order[];
  currentPage: number;
  totalPages: number;
  totalOrders: number;
};

export const ordersState: StateOrdersInterface = {
  orderList: null,
  order: null,
  error: null,
  loading: false,
};

export default function reducer(
  state: StateOrdersInterface = ordersState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case LIST_ORDER:
    case VIEW_ORDER:
    case EDIT_ORDER:
      return { ...state, loading: true, error: null };

    case LIST_ORDER_SUCCESS:
      return handleListOrderSuccess(state, payload);

    case VIEW_ORDER_SUCCESS:
      let replacedOrder = replaceUspsDeliveryShippingMethod(payload)

      return {
        ...state,
        order: replacedOrder,
        loading: false,
        error: null,
      };

    case EDIT_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case LIST_ORDER_FAIL:
      return {
        ...state,
        orderList: null,
        loading: false,
        error: error,
      };

    case VIEW_ORDER_FAIL:
      return {
        ...state,
        order: null,
        loading: false,
        error: error,
      };

    case EDIT_ORDER_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
      };

    case CLEAR_ORDER_LIST:
      return { ...state, orderList: null, error: null };

    default:
      return state;
  }
}

/** Need refactor. Move this to api or refactor Cart for send replaced order shippingMethod */
const replaceUspsDeliveryShippingMethod = (order: FullOrder) => {
  let index = prepareUspsDeliveryIndex(order)
  if (index) {
    let replacedOrder: FullOrder = { ...order };
    replacedOrder.shippingMethod = index;
    return replacedOrder
  }

  return order
}

function handleListOrderSuccess(state: StateOrdersInterface, payload: any) {
  const list = state?.orderList?.list;

  if (!payload) {
    return {
      ...state,
      orderList: null,
      loading: false,
      error: null,
    };
  }

  return {
    ...state,
    orderList: {
      list: list ? [...list, ...payload.data] : payload.data,
      currentPage: payload._page,
      totalPages: payload._pages,
      totalOrders: payload._total,
    },
    loading: false,
    error: null,
  };
}

function* getOrderListSaga({ payload }: GetOrderList) {
  const userWebsites = yield select(userWebsitesArraySelector);
  const websiteId = userWebsites?.[0].websites[0]?.id;

  try {
    if (websiteId) {
      const response = yield orderApi.getOrderList(websiteId, payload);


      yield put({ type: LIST_ORDER_SUCCESS, payload: response });
    } else {
      yield put({ type: LIST_ORDER_SUCCESS, payload: null });
    }
  } catch (err) {
    yield put({ type: LIST_ORDER_FAIL, error: err.data });
  }
}

function* getOrderSaga({ payload: orderId }: GetOrder) {
  try {
    const response = yield orderApi.getOrder(orderId);

    yield put(
      initialize('order', {
        trackingNumber: response.trackingNumber,
      })
    );
    yield put({ type: VIEW_ORDER_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: VIEW_ORDER_FAIL, error: err.data });
  }
}

function* editOrderSaga({ payload }: EditOrder) {
  const { trackingNumber } = yield select((state) => state.order.order);

  try {
    yield orderApi.editOrder(payload);
    yield put({ type: EDIT_ORDER_SUCCESS });
    yield history.push('/orders');
  } catch (err) {
    yield put(
      initialize('order', {
        trackingNumber: trackingNumber,
      })
    );
    yield put({ type: EDIT_ORDER_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(LIST_ORDER, getOrderListSaga),
    takeLatest(VIEW_ORDER, getOrderSaga),
    takeLatest(EDIT_ORDER, editOrderSaga),
  ]);
};
