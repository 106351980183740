import React, { MouseEvent } from 'react';
//ignore @types for lib
// @ts-ignore
import Slider from 'react-slick';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

export type HeroImageBackgroundProps = {
  heroImageBackground: string;
};

type WhiteButtonProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export type ContactUsProps = {
  type: string;
  value: string;
};

export type ImageContainerProps = {
  isOutOfStock?: boolean;
};

export type AddToCartButtonProps = {
  disabled?: boolean;
  onClick?: any;
};

export type WebsiteContainerProps = {
  isCart?: boolean;
  bgColor?: string;
};

export const WebsiteContainer = styled(
  ({ isCart, bgColor, ...otherProps }: WebsiteContainerProps) => <div {...otherProps} />
)({
  fontFamily: 'Spartan, sans-serif',
  width: '100%',
  minHeight: '100vh',
  display: ({ isCart }: WebsiteContainerProps) => (isCart ? 'flex' : 'block'),
  flexDirection: 'column',
  height: ({ isCart }: WebsiteContainerProps) => (isCart ? 'fit-content' : 'inherit'),
  backgroundColor: ({ bgColor }: WebsiteContainerProps) => (bgColor ? bgColor : 'none'),
  '& > a': {
    textDecoration: 'none',
  },
});

export const HeroImageContainer = styled('div')({
  height: 720,
  '@media (max-width: 768px)': {
    height: 500,
  },
});

export const HeroImageBackground = styled(
  ({ heroImageBackground, ...otherProps }: HeroImageBackgroundProps) => <div {...otherProps} />
)({
  backgroundImage: ({ heroImageBackground }: HeroImageBackgroundProps) =>
    `url(${heroImageBackground})`,
  width: '100%',
  height: 720,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  '@media (max-width: 768px)': {
    height: 500,
  },
});

export const WebsiteName = withTheme(
  styled(({ theme, isCart, bgColor, ...otherProps }: WithTheme & WebsiteContainerProps) => (
    <div {...otherProps} />
  ))({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 42,
    fontWeight: 800,
    textTransform: 'capitalize',
    wordBreak: 'break-word',
    padding: '50px 0',
    backgroundColor: ({ isCart }: WebsiteContainerProps) =>
      isCart ? 'rgba(68, 86, 108, 0.5)' : 'inherit',
    color: ({ isCart }: WebsiteContainerProps) => (isCart ? '#FFFFFF' : 'inherit'),
    '& > a': {
      textDecoration: 'none',
      color: '#FFFFFF',
      '& > div': {
        paddingLeft: ({ isCart }: WebsiteContainerProps) => (isCart ? 80 : 'unset'),
      },
    },
    '& > div': {
      cursor: 'pointer',
      paddingRight: ({ isCart }: WebsiteContainerProps) => (isCart ? 80 : 'unset'),
    },
    '@media (max-width: 768px)': {
      fontSize: 30,
      '& > a > div': {
        paddingLeft: ({ isCart }: WebsiteContainerProps) => (isCart ? 40 : 'unset'),
      },
      '& > div': {
        paddingRight: ({ isCart }: WebsiteContainerProps) => (isCart ? 40 : 'unset'),
      },
    },
  })
);

export const WebsiteNameContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    height: '100%',
    padding: '0 80px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
    fontWeight: 800,
    '@media (max-width: 768px)': {
      padding: '0 40px',
    },
  })
);

export const TaglineContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    wordBreak: 'break-word',
    textAlign: 'center',
    fontSize: 72,
    '@media (max-width: 768px)': {
      fontSize: 50,
    },
  })
);

export const GoToShopContainer = styled('div')({
  padding: '50px 0',
  '@media (max-width: 768px)': {
    bottom: 25,
  },
});

export const WhiteButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & WhiteButtonProps) => <button {...otherProps} />)
    ({
      border: 'none',
      borderRadius: 15,
      padding: '20px 130px',
      fontSize: 38,
      fontWeight: 700,
      cursor: 'pointer',
      color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtn,
      '&:hover': {
        backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtnHover,
        boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.whiteBtn,
      },
      '&:focus': {
        outline: 'none',
      },
      '@media (max-width: 768px)': {
        fontSize: 30,
        padding: '20px 100px',
      },
    })
);

export const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > a': {
    marginTop: 30,
  }
});

export const ImageContainer = withTheme(
  styled(({ theme, isOutOfStock, ...otherProps }: WithTheme & ImageContainerProps) => (
    <div {...otherProps} />
  ))({
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: ({ isOutOfStock }: ImageContainerProps) => (isOutOfStock ? 'default' : 'pointer'),
    '& > img': {
      width: '100%',
      height: 'auto',
      opacity: ({ isOutOfStock }: ImageContainerProps) => (isOutOfStock ? 0.4 : 1),
      borderRadius: 8,
    },
  })
);

export const OutOfStock = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    position: 'absolute',
    width: 300,
    textAlign: 'center',
    zIndex: 1,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: 30,
    fontWeight: 400,
  })
);

export const CardInfo = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    flex: 1,
    wordBreak: 'break-word',
  })
);

export const CardName = styled('div')({
  fontWeight: 700,
  fontSize: 23,
  marginBottom: 10,
});

export const Price = styled('div')({
  fontWeight: 700,
  fontSize: 23,  
  marginBottom: 10,
});


export const FreeShipping = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontWeight: 500,
    fontSize: 18,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.success,
    textTransform: 'capitalize',
    marginBottom: 25,
  })
);

export const ViewDetailsButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & WhiteButtonProps) => <button {...otherProps} />)
    ({
      border: 'none',
      width: '100%',
      borderRadius: 6,
      padding: '15px 30px',
      fontSize: 24,
      fontWeight: 500,
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.btn,
      '&:hover': {
        backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.btnHover,
        boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.btn,
      },
      '&:focus': {
        outline: 'none',
      },
    })
);

export const AddToCartContainer = styled('div')({
  paddingTop: 24,
});

export const AddToCartButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & AddToCartButtonProps) => (
    <button {...otherProps} />
  ))({
    border: 'none',
    borderRadius: 8,
    padding: '10px 0',
    fontSize: 20,
    fontWeight: 600,
    cursor: 'pointer',
    width: '100%',
    color: ({ theme }: WithTheme) => theme.customPalette.text.active,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.primary,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      opacity: 0.5,
      cursor: 'unset',
    },
  })
);

export const AboutUsContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    textAlign: 'center',
    maxWidth: 1200,
    width: '80%',
    margin: '0 auto',
    padding: '100px 0 100px 0',
    '@media (max-width: 768px)': {
      padding: '60px 0 60px 0',
    },
  })
);

export const AboutUsTitle = styled('div')({
  fontSize: 80,
  fontWeight: 800,
  '@media (max-width: 768px)': {
    fontSize: 50,
  },
});

export const AboutUsDescription = styled('div')({
  fontFamily: 'Open Sans',
  fontSize: 33,
  fontWeight: 400,
  paddingTop: 30,
  wordBreak: 'break-word',
  '@media (max-width: 768px)': {
    fontSize: 26,
  },
});

export const FooterContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.footerBlue,
    color: ({ theme }: WithTheme) => theme.customPalette.text.active,
    textAlign: 'center',
  })
);

export const ContactUsButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ContactUsProps) => <input {...otherProps} />)({
    width: 300,
    height: 60,
    border: 'none',
    borderRadius: 15,
    padding: '10px 70px',
    fontSize: 24,
    fontWeight: 600,
    cursor: 'pointer',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.header,
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtnHover,
      boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.whiteBtn,
    },
  })
);

export const LearnMore = styled('a')({
  fontSize: 24,
  color: 'inherit',
  display: 'block',
  padding: '30px 0 20px 0',
});

export const LoadMoreContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    maxWidth: 1180,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '50px',
  })
);

export const Line = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    borderTop: ({ theme }: WithTheme) => `1px solid ${theme.siteTemplatePalette.text.secondary}`,
  })
);

export const LoadMoreButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & WhiteButtonProps) => <button {...otherProps} />)
    ({
      minWidth: 200,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
      border: 'none',
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'capitalize',
      cursor: 'pointer',
    })
);

export const IconWrapper = styled('svg')({
  fill: 'black',
  width: 22,
  height: 15,
  marginLeft: 15,
});

export const CartIconContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& div': {
    fontSize: 16,
    position: 'absolute',
    top: 10,
    left: 22,
    color: '#FFFFFF',
  },
});

export const CarouselWrapper = withTheme(
  styled(({ theme, ...otherProps }: any) => <Slider {...otherProps} />)({
    borderRadius: 8,
    overflow: 'visible',
    filter:
      'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-2px 4px 20px rgba(68, 86, 108, 0.16))',
    '& .slick-prev': {
      left: '-45px !important',
      width: 'auto',
      height: 'auto',
    },
    '& .slick-next': {
      right: '-45px !important',
      width: 'auto',
      height: 'auto',
    },
    '& button': {
      opacity: 0,
      color: '#44566C',
      zIndex: 100,
      '&::before': {
        fontSize: 68,
      },
      '&:hover': {
        opacity: '1 !important',
      },
    },
    '& .CarouselItem ~ div': {
      '&:nth-child(2)': {
        right: -32,
      },
      '&:nth-child(3)': {
        left: -32,
      },
      '&:hover': {
        '& button': {
          opacity: 1,
        },
      },
    },
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  })
);

export const Name = styled('div')({
  '@media (max-width: 768px)': {
    textAlign: 'center',
  },
});
