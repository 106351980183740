import React, { ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { QuestionHintIcon, SaveIcon } from 'src/components/common/Icons';
import { businessNameChangedAction } from 'src/ducks/website/actions';
import { getStore } from 'src/ducks/user/actions';
import { FormInputField } from 'src/components/common/inputs';
import { Hint } from 'src/components/common/hint';
import validations from 'src/components/common/inputs/validations';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import { businessNameMessage } from 'src/ducks/website/selectors';
import { storeSelector } from 'src/ducks/user/selectors';
import { Wrapper, Label, LabelWrapper } from './style';
import {
  FormContainer,
  ContinueButton,
  ContinueButtonContainer,
} from 'src/components/pages/auth/signin/styles';

export type FormsWebsiteType = {
  name: string;
  tagline: string;
  aboutAs: string;
  email: string;
  websiteURL: string;
};

export type FormProps = {
  website?: {
    email: string;
  };
};

const textForEmailHint =
  'This email address will be used if a customer contacts your business. We suggest you use either a business email or the teacher’s email';
const textForWebsiteHint =
  'What the link to your site will look like. Created automatically from the business name';

type Props = InjectedFormProps<FormsWebsiteType, FormProps, {}>;

const FormWebsiteDescription = ({ handleSubmit, valid }: Props) => {
  const dispatch = useDispatch();
  const successMessage: any = useSelector(businessNameMessage);
  const store: any = useSelector(storeSelector);
  const isWebsiteExist = store?.length && !!store[0].store.websites.length;

  useEffect(() => {
    if (store?.length) {
      dispatch(getStore(store[0].store.id));
    }
  }, [dispatch]);

  const handleNameChange = (event?: ChangeEvent<any>, newValue?: string) => {
    if (newValue) {
      dispatch(businessNameChangedAction(newValue));
    }

    return;
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <Wrapper>
          <Label>Business Name: *</Label>
          <FormInputField
            placeholder="Business Name"
            name="name"
            validate={validations.businessName}
            maxLength={inputRestrictions.BUSINESS_NAME_MAX_LENGTH}
            onChange={handleNameChange}
            message={successMessage}
          />
        </Wrapper>
        <Wrapper>
          <Label>Website Tagline: *</Label>
          <FormInputField
            placeholder="Website Tagline"
            name="tagline"
            validate={validations.websiteTagline}
          />
        </Wrapper>
        <Wrapper>
          <Label>About Us: *</Label>
          <FormInputField
            placeholder="About Us"
            multiline
            name="aboutAs"
            validate={validations.aboutAs}
          />
        </Wrapper>
        <Wrapper>
          <LabelWrapper>
            <Label>EdCorp Email: *</Label>
            <Hint placement="top" text={textForEmailHint}>
              <QuestionHintIcon />
            </Hint>
          </LabelWrapper>
          <FormInputField name="email" validate={validations.email} />
        </Wrapper>
        <Wrapper>
          <LabelWrapper>
            <Label>Website URL: *</Label>
            <Hint placement="top" text={textForWebsiteHint}>
              <QuestionHintIcon />
            </Hint>
          </LabelWrapper>
          <FormInputField placeholder="Website URL" name="websiteURL" multiline disabled />
        </Wrapper>
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            {isWebsiteExist ? (
              <>
                {' '}
                <SaveIcon /> Save
              </>
            ) : (
              'create a website'
            )}
          </ContinueButton>
        </ContinueButtonContainer>
      </FormContainer>
    </form>
  );
};

export default reduxForm<FormsWebsiteType, FormProps, {}>({
  form: 'websiteDescription',
  destroyOnUnmount: false,
})(FormWebsiteDescription);
