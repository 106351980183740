import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { cancelPayment } from 'src/ducks/shopOrder/actions';

type Props = {
  component: any;
  exact: boolean;
  path: string;
};

const CanceledPaymentRoute = (props: Props & any) => {
  const { component: Component, computedMatch, ...rest } = props;
  const {
    params: { websitePath, orderToken },
  } = computedMatch;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cancelPayment(orderToken));
  }, []);

  return <Route {...rest} render={() => <Redirect to={`/shop/${websitePath}/cart`} exact />} />;
};

export default CanceledPaymentRoute;
