import React from 'react';
import { format } from 'date-fns';
import { getDateElements } from 'src/utils/helpers';
import { StyledDate } from './styles';

type DateWithTimeAndMeridiemrops = {
  date: string;
  dateFormat?: string;
  durationFormat?: string;
};

const DateWithTimeAndMeridiem = ({
  date,
  dateFormat = 'MM/dd/yyyy',
  durationFormat = 'hh:mm a',
}: DateWithTimeAndMeridiemrops) => {
  const { year, month, day, hours, minutes, seconds } = getDateElements(date);
  const formattedDate = new Date(year, month, day, hours, minutes, seconds);

  return (
    <>
      <StyledDate>{format(formattedDate, dateFormat)}&nbsp;</StyledDate>
      <span>{format(formattedDate, durationFormat)}</span>
    </>
  );
};

export default DateWithTimeAndMeridiem;
