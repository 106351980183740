import {
  CLEAR_NOTIFICATIONS_LIST,
  CLOSE_MAIN_DRAWER,
  GET_NOTIFICATIONS_LIST_REQUEST,
  TOGGLE_MAIN_DRAWER,
} from './constants';

export function getNotificationsList(page: number = 1, perPage = 10) {
  return {
    type: GET_NOTIFICATIONS_LIST_REQUEST,
    payload: page,
    perPage,
  };
}

export function clearNotificationsList() {
  return { type: CLEAR_NOTIFICATIONS_LIST };
}

export function toggleMainDrawer() {
  return { type: TOGGLE_MAIN_DRAWER };
}

export function closeMainDrawer() {
  localStorage.removeItem('firstLoad');
  return { type: CLOSE_MAIN_DRAWER };
}
