import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClickAwayListener } from '@material-ui/core';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import { logout } from 'src/ducks/auth/actions';
import { canViewProfileSelector } from 'src/ducks/user/selectors';
import { LogoutIcon, ProfileNameIcon } from 'src/components/common/Icons';
import { MenuList, StyledTooltip, StyledUserName, UserMenuItem, UserItem } from './styles';
import history from 'src/containers/history';

type UserMenuProps = {
  fullName: string;
};

const UserMenu = ({ fullName }: UserMenuProps) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  const handleNavigateToProfile = () => {
    setOpen(false);
    history.push('/profile');
  };

  const handleLogoutUser = () => {
    dispatch(logout());
    handleTooltipClose();
  };

  const canViewProfile = useSelector(canViewProfileSelector);

  const renderMenuItems = () => (
    <>
      {canViewProfile && (
        <>
          <UserMenuItem onClick={handleNavigateToProfile}>
            <ProfileNameIcon />
            <UserItem>Profile</UserItem>
          </UserMenuItem>
        </>
      )}
      <UserMenuItem onClick={handleLogoutUser}>
        <LogoutIcon />
        <UserItem>Logout</UserItem>
      </UserMenuItem>
    </>
  );

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <StyledTooltip
        PopperProps={{
          disablePortal: true,
        }}
        arrow
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={<MenuList>{renderMenuItems()}</MenuList>}
      >
        <StyledUserName onClick={handleTooltipOpen} disableRipple>
          {fullName}
          <ArrowDropDown />
        </StyledUserName>
      </StyledTooltip>
    </ClickAwayListener>
  );
};

export default UserMenu;
