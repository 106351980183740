import React, { useState, useRef, useEffect, ReactNode, Dispatch, SetStateAction } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import ReactAvatarEditor from 'react-avatar-editor';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Slider from '@material-ui/core/Slider';
import IconButton from '@material-ui/core/IconButton';
import {
  Clipper,
  avatarSize,
  ModalContent,
  ModalContentBlock,
  PreviewHeader,
  ScaleEditorContainer,
  styles,
  PRODUCT_IMAGE_SIZE,
  EDITOR_MARGIN_HOR,
  EDITOR_MARGIN_VER,
  EDITOR_BORDER_RADIUS,
  EDITOR_BORDER_COLOR,
  SITE_TEMPLATE_IMAGE_WIDTH,
  SITE_TEMPLATE_IMAGE_HEIGHT,
} from './styles';
import { Popup } from 'src/components/common/modal';
import { PopupButtonsContainer, PopupButton } from 'src/components/common/modal/styles';
import { setAvatarImageData } from 'src/ducks/user/actions';
import { IImageFile } from 'src/components/pages/products/posterImage/PosterImage';

const DEFAULT_SCALE = 1;
const MIN_SCALE = 1;
const MAX_SCALE = 2;
const SCALE_STEP_BY_BUTTON = 0.05;
const SCALE_STEP_BY_SLIDER = 0.01;

interface IAvatarEditorProps {
  width?: number;
  height?: number;
  border?: number | number[];
  borderRadius?: number;
  color?: number[];
}

interface ICropperOptions {
  reactAvatarEditor: IAvatarEditorProps,
  wrapperClassName: string,
  previewClassName?: string,
}

type EditorWrapperProps = {
  options: ICropperOptions;
  children: ReactNode;
};

const EditorWrapper = withStyles(styles)(
  ({ children, classes, options }: EditorWrapperProps & WithStyles) => {
    return (
      <div className={classes[options.wrapperClassName]}>
        {children}
      </div>
    );
  }
);

type ZoomButtonProps = {
  icon: ReactNode;
  edge: 'start' | 'end';
  sign: number;
  setScale: Dispatch<SetStateAction<number>>;
};

const ZoomButton = ({ icon, edge, sign, setScale }: ZoomButtonProps) => {
  const handleClick = () => {
    setScale((scale: number) => {
      const newValue = scale + sign * SCALE_STEP_BY_BUTTON;
      if (newValue < MIN_SCALE) {
        return MIN_SCALE;
      }
      if (newValue > MAX_SCALE) {
        return MAX_SCALE;
      }
      return newValue;
    });
  };
  return (
    <IconButton onClick={handleClick} style={{ backgroundColor: 'transparent' }} edge={edge}>
      {icon}
    </IconButton>
  );
};

type ScaleEditorProps = {
  scale: number;
  setScale: Dispatch<SetStateAction<number>>;
  cropperAdditionalStyle?: any
};

const ScaleEditor = ({ scale, setScale, cropperAdditionalStyle }: ScaleEditorProps) => {
  const handleChange = (
    e: any, // eslint-disable-line no-unused-vars
    value: number | number[]
  ) => {
    setScale(value as number);
  };
  return (
    <ScaleEditorContainer style={cropperAdditionalStyle}>
      <ZoomButton icon={<ZoomOutIcon />} sign={-1} setScale={setScale} edge="start" />
      <Slider
        value={scale}
        step={SCALE_STEP_BY_SLIDER}
        min={MIN_SCALE}
        max={MAX_SCALE}
        valueLabelDisplay="off"
        onChange={handleChange}
      />
      <ZoomButton icon={<ZoomInIcon />} sign={1} setScale={setScale} edge="end" />
    </ScaleEditorContainer>
  );
};

type CropImageModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  imageData: IImageFile | string | null;
  handleCropImage?: (dataUrl: any) => void;
  variant?: string;
};

export const extendsFiles = (file: File & { id?: number }) => {
  if (!file.id) file.id = Math.floor(Math.random() / Math.random() * 1000);
};

const CropImageModal = withStyles(styles)(
  ({
    isOpen, 
    closeModal, 
    imageData, 
    handleCropImage, 
    variant = 'avatar',
    classes,
  }: CropImageModalProps & WithStyles) => {
  const ref = useRef<ReactAvatarEditor | null>(null);
  const [dataUrl, setDataUrl] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      setScale(DEFAULT_SCALE);
    }
  }, [isOpen]);

  const updatePreview = () => {
    const editor = ref.current;
    if (!editor) {
      return;
    }
    const canvas: any = editor.getImageScaledToCanvas();
    const dataUrl = canvas.toDataURL();
    setDataUrl(dataUrl);
    
    canvas!.toBlob((b: any) => {
      let file: any = new File([b], 'fileName', { type: "image/jpeg"});
      file.src = dataUrl;

      extendsFiles(file);
      setFile(file);
    });
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleOk = () => {
    closeModal();

    if (dataUrl) {
      if (handleCropImage) return handleCropImage(file)

      dispatch(setAvatarImageData(dataUrl));
    }
  };

  const getCropperStyles = (variant: string): any => {
    let styles: any = {}

    switch (variant) {
      case 'siteTemplate':
        styles = {
          modalContentStyle: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          modalContentBlockStyle: {
            paddingLeft: 5,
            paddingRight: 5,
          },
          additionalWrapperStyle: {
            paddingTop: 5,
            paddingBottom: 10,
          },
          scaleEditorStyle: {
            position: 'relative',
            bottom: 80,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            width: '90%',
          }
        }
       
        break;  
    }
  
    return styles
  }

  const getCropperOptions = (variant: string): ICropperOptions => {
    let options: ICropperOptions = {
      reactAvatarEditor: {
        width: avatarSize,
        height: avatarSize,
        border: [EDITOR_MARGIN_HOR, EDITOR_MARGIN_VER],
        borderRadius: EDITOR_BORDER_RADIUS,
        color: EDITOR_BORDER_COLOR,
      },
      wrapperClassName: 'avatarEditorWrapper',
      previewClassName: 'avatarContainer',
    }
    switch (variant) {
      case 'product': 
        options = {
          reactAvatarEditor: {
            width: PRODUCT_IMAGE_SIZE,
            height: PRODUCT_IMAGE_SIZE,
            border: 0,
            borderRadius: 0,
          },
          wrapperClassName: 'productEditorWrapper',
          // previewClassName: 'productContainer',
        }
        break;

      case 'siteTemplate':
        options = {
          reactAvatarEditor: {
            width: SITE_TEMPLATE_IMAGE_WIDTH,
            height: SITE_TEMPLATE_IMAGE_HEIGHT,
            border: 0,
            borderRadius: 0,
          },
          wrapperClassName: 'siteTemplateEditorWrapper',
        }
        break;
    }
  
    return options
  }

  const renderContent = () => {
    const options = getCropperOptions(variant)
    
    return (
      <>
        <ModalContent style={styles.modalContentStyle}>
          <ModalContentBlock style={styles.modalContentBlockStyle}>
            <EditorWrapper options={options}>
              {imageData && (
                <ReactAvatarEditor
                  ref={ref}
                  onImageReady={updatePreview}
                  onImageChange={updatePreview}
                  image={ typeof imageData === 'string' ? imageData : imageData.src as string}
                  scale={scale}
                  rotate={0}
                  {...options.reactAvatarEditor}
                />
              )}
            </EditorWrapper>
            <ScaleEditor scale={scale} setScale={setScale} cropperAdditionalStyle={styles.scaleEditorStyle} />
          </ModalContentBlock>
          {options.previewClassName && (
            <ModalContentBlock>
              <div className={classes[options.previewClassName]}>
                <Clipper>{dataUrl && <img src={dataUrl} alt="Avatar preview" />}</Clipper>
              </div>
              <PreviewHeader>Preview</PreviewHeader>
            </ModalContentBlock>
          )}
        </ModalContent>
        <PopupButtonsContainer>
          <PopupButton isSecondary className="secondary" onClick={handleCancel}>
            Cancel
          </PopupButton>
          <PopupButton onClick={handleOk}>Ok</PopupButton>
        </PopupButtonsContainer>
      </>
    );
  };

  const styles = getCropperStyles(variant)

  return (
    <Popup
      isOpen={isOpen}
      closeModal={closeModal}
      additionalContentStyle={{
        maxWidth: 'auto',
      }}
      additionalWrapperStyle={{
        width: '100%',
        ...styles.additionalWrapperStyle,
      }}
      kind="custom"
      renderCustomContent={renderContent}
      error={null}
    />
  );
});

export default CropImageModal;
