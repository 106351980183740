import { createSelector } from 'reselect';
import { StateWebsiteInterface, ErrorData, Error } from './website';
import { userGetter } from '../user/selectors';

export interface RootState {
  website: StateWebsiteInterface;
}

const errorGetter = (state: any) => state.website.error;
export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  const tooSmallImageError = 'The minimal height should be 720px';
  const wrongAspectRationError = 'Image aspect ratio should be 16:9';

  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        if (err.code === 'TOO_LOW_ERROR') {
          return tooSmallImageError;
        }
        if (err.code === 'RATIO_TOO_SMALL_ERROR') {
          return wrongAspectRationError;
        }

        return err.message;
      });
    }

    return error.errors.message;
  }

  return null;
});

export const userWebsitesArraySelector = createSelector(userGetter, (user) =>
  user?.accesses?.map((item) => ({ websites: item.store.websites }))
);

const websiteGetter = ({ website }: RootState) => website;

export const loadingWebsiteSelector = createSelector(websiteGetter, ({ loading }) => loading);
export const websiteSelector = createSelector(websiteGetter, ({ website }) => website);
export const websiteUrlSelector = createSelector(websiteGetter, ({ websiteURL }) => websiteURL);
export const websiteListTemplateSelector = createSelector(websiteGetter, ({ listTemplate }) => listTemplate);
export const websiteTemplateIDSelector = createSelector(websiteGetter, ({ templateID }) => templateID);
export const uploadedAmazonImagesSelector = createSelector( websiteGetter, ({ arrayImages }) => arrayImages);
export const businessNameMessage = createSelector(websiteGetter, ({ message }) => message);
export const websiteCollections = createSelector(websiteGetter, ({ collections }) => collections);
