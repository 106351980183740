import {
  GET_LIST_WEBSITE_TEMPLATES,
  SET_SELECTED_WEBSITE_ID,
  SET_UPLOAD_WEBSITE_HERO_IMAGE,
  CREATE_WEBSITE,
  GET_WEBSITE,
  GET_WEBSITE_SHOP,
  BUSINESS_NAME_CHANGED,
  GET_WEBSITE_COLLECTIONS,
} from './constants';
import { FormsWebsiteType } from 'src/components/pages/website/form-website/FormWebsiteDescription';

export function getListTemplateAction() {
  return {
    type: GET_LIST_WEBSITE_TEMPLATES,
  };
}

export function selectedWebsiteTemplateIDAction(templateId: number) {
  return {
    type: SET_SELECTED_WEBSITE_ID,
    payload: templateId,
  };
}

export function setUploadWebsiteHeroImageAction(heroImage?: string) {
  return {
    type: SET_UPLOAD_WEBSITE_HERO_IMAGE,
    payload: heroImage,
  };
}

export function createWebsiteAction(data: FormsWebsiteType) {
  return {
    type: CREATE_WEBSITE,
    payload: data,
  };
}

export function getWebsiteAction(id: number) {
  return {
    type: GET_WEBSITE,
    payload: id,
  };
}

export function businessNameChangedAction(name: string) {
  return {
    type: BUSINESS_NAME_CHANGED,
    payload: name,
  };
}

export type WebsiteShop = {
  type: string;
  payload: string;
};

export function getWebsiteShop(websitePath: string) {
  return {
    type: GET_WEBSITE_SHOP,
    payload: websitePath,
  };
}

export function getWebsiteCollections(websiteId: number) {
  return {
    type: GET_WEBSITE_COLLECTIONS,
    payload: { websiteId },
  };
}