import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearProductsList, getProductsList, removeProduct } from 'src/ducks/products/actions';
import { userWebsite } from 'src/ducks/user/selectors';
import { productsListSelector } from 'src/ducks/products/selectors';
import { errorSelector } from 'src/ducks/user/selectors';
import ProductsList from './productsList/ProductsList';
import history from 'src/containers/history';
import { StudentContainer, Title } from '../students/styles';
import { LinkWebsiteDesign } from 'src/components/pages/cashout/styles';
import { AddProductButton, SpaceBetweenWrapper } from './styles';

const Products = () => {
  const dispatch = useDispatch();
  const error = useSelector(errorSelector);
  const productsList = useSelector(productsListSelector);
  const websiteIds: any = useSelector(userWebsite);
  const currentPage = Number(productsList?.currentPage);
  const totalPages = productsList?.totalPages;
  const totalProducts = productsList?.total;

  useEffect(() => {
    if (websiteIds?.length) {
      dispatch(getProductsList(websiteIds[0].id));
      return () => {
        dispatch(clearProductsList());
      };
    }
  }, [dispatch]);

  const toAddProduct = () => {
    history.push('products/add');
  };

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      const params = {
        websiteId: websiteIds[0].id,
        page: currentPage + 1,
      };
      dispatch(getProductsList(params));
    }
  };

  const handleRemoveProduct = (id: number) => {
    dispatch(removeProduct(id));
  };

  return (
    <StudentContainer>
      {websiteIds?.length ? (
        <>
          <SpaceBetweenWrapper>
            <Title>Products ({totalProducts} Total)</Title>
            <AddProductButton
              variant="contained"
              color="primary"
              onClick={toAddProduct}
              disabled={!websiteIds || !websiteIds.length}
            >
              + Add product
            </AddProductButton>
          </SpaceBetweenWrapper>
          {productsList?.list?.length ? (
            <ProductsList
              productsList={productsList}
              handleRemoveProduct={handleRemoveProduct}
              error={error}
              loadMore={loadMore}
            />
          ) : (
            <div>You don't have any products</div>
          )}
        </>
      ) : (
        <div>Please enter a <LinkWebsiteDesign to="/add-website">Website Design</LinkWebsiteDesign> before adding
          products</div>
      )}
    </StudentContainer>
  );
};

export default Products;
