import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';
import envSettings from 'src/config/envSettings';
import { storeDescription } from 'src/constants/commonTypes';

type SignUpTeacher = {
  store: {
    name: string;
    storeDescription: storeDescription;
  };
  firstName: string;
  lastName: string;
  plainPassword: {
    first: string;
    second: string;
  };
  inviteToken: string;
};

type ResetPassword = {
  token: string;
  password: {
    first: string;
    second: string;
  };
};

export default {
  getToken: (username: string, password: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/oauth/v2/token',
      data: {
        username,
        password,
        ...envSettings.secret,
        grant_type: 'password',
      },
    }),

  getAccessToken: (): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/oauth/v2/token',
      data: {
        ...envSettings.secret,
        grant_type: 'client_credentials',
        scope: 'ROLE_ANONYMOUS',
      },
    }),

  getTeacherEmail: (inviteToken: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/invite/${inviteToken}`,
    }),

  signUpTeacher: (data: SignUpTeacher): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/user/register-teacher`,
      data: {
        // ...envSettings.secret,
        ...data,
      },
    }),

  forgotPassword: (email: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/forget-password/request`,
      data: { email },
    }),

  checkRecoveryToken: (token: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/forget-password/check-token`,
      data: { token },
    }),

  resetPassword: (data: ResetPassword): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: `api/forget-password/reset`,
      data,
    }),

  updateToken: (refreshToken: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/oauth/v2/token',
      data: {
        refresh_token: refreshToken,
        ...envSettings.secret,
        grant_type: 'refresh_token',
      },
    }),

  logout: (refreshToken: string): AxiosPromise<AxiosResponse> =>
    httpService.post({
      url: 'api/logout',
      data: { refreshToken },
    }),
};
