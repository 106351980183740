export type DeliveryStatus = {
  status: string;
  shownStatus: string;
  color: string;
};

export default [
  {
    status: 'paid',
    shownStatus: 'Pending',
    color: '#A154F2',
  },
  {
    status: 'shipping',
    shownStatus: 'Fulfilled',
    color: '#22CCE2',
  },
  {
    status: 'completed',
    shownStatus: 'Delivered',
    color: '#09B66D',
  },
];
