import React from 'react';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { formatDate } from 'src/utils/helpers';
import {
  Head,
  StyledBodyTableRow,
  StyledTableCell,
  StyledTableCellTitle,
  TableWrapper,
} from '../../students/studenstList/styles';
import { Paper, TableBody, TableContainer, TableRow } from '@material-ui/core';
import { Toolkit, ToolkitList } from 'src/ducks/accounting/accounting';

type ToolkitListProps = {
  toolkitList: ToolkitList;
  loadMore: () => void;
};

const ToolkitListComponent = (props: ToolkitListProps) => {
  const { toolkitList, loadMore } = props;

  useInfiniteScroll({ loadMore });

  const renderUsersTable = () => (
    <TableContainer component={Paper} style={{ marginTop: 40 }}>
      <TableWrapper>
        <Head>
          <TableRow>
            <StyledTableCell style={{ width: '5%' }}>Toolkit</StyledTableCell>
            <StyledTableCell style={{ width: '5%' }}>Date</StyledTableCell>
            <StyledTableCell align="left" style={{ width: '5%' }}>
              Total
            </StyledTableCell>
          </TableRow>
        </Head>
        <TableBody>
          {toolkitList?.list.map(({ id, createdAt, toolkitName, total }: Toolkit) => {
            return (
              <StyledBodyTableRow key={id}>
                <StyledTableCell align="left">{toolkitName}</StyledTableCell>
                <StyledTableCellTitle align="left">{formatDate(createdAt)}</StyledTableCellTitle>
                <StyledTableCell align="left">
                  ${total.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                </StyledTableCell>
              </StyledBodyTableRow>
            );
          })}
        </TableBody>
      </TableWrapper>
    </TableContainer>
  );

  return renderUsersTable();
};

export default ToolkitListComponent;
