import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { initialize } from 'redux-form';
import { errorSelector, pinSelector } from 'src/ducks/user/selectors';
import { Popup } from 'src/components/common/modal';
import userAccesses from 'src/constants/userAccesses';
import { SaveIcon } from 'src/components/common/Icons';
import { ButtonContainer, AddStudentButton } from '../styles';

type AddStudentProps = {
  valid: boolean;
  isStudentExist: boolean;
  handleClearPin: () => void;
  handleCreateStudent: () => void;
  handleUpdateStudent: () => void;
};

const mainText = 'Your pin code for student login';

const AddStudent = ({
  handleClearPin,
  valid,
  isStudentExist,
  handleCreateStudent,
  handleUpdateStudent,
}: AddStudentProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const error = useSelector(errorSelector);
  const pin = useSelector(pinSelector);

  useEffect(() => {
    dispatch(
      initialize('editStudent', {
        name: userAccesses.ACCESS_EDITOR,
      })
    );
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleCreate = () => {
    handleCreateStudent();
    handleOpen();
  };

  const handleClose = () => {
    setOpen(false);
    if (pin) {
      handleClearPin();
    }
    history.push('/students');
  };

  const handleCopyPin = () => {
    navigator.clipboard.writeText(pin);
    handleClose();
  };

  return (
    <>
      <ButtonContainer>
        <AddStudentButton
          variant="contained"
          color="primary"
          disabled={!valid}
          onClick={isStudentExist ? handleUpdateStudent : handleCreate}
        >
          {isStudentExist ? (
            <>
              {' '}
              <SaveIcon /> Save
            </>
          ) : (
            '+ Add Student'
          )}
        </AddStudentButton>
      </ButtonContainer>
      {pin && (
        <Popup
          kind="pin"
          isOpen={isOpen}
          error={error}
          pin={pin}
          mainText={mainText}
          closeModal={handleClose}
          copyPin={handleCopyPin}
        />
      )}
    </>
  );
};

export default AddStudent;
