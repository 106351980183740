import React from 'react';
import { Button, Tooltip, withStyles } from '@material-ui/core';
import { styled, WithTheme, withTheme, Theme } from '@material-ui/core/styles';

export const MenuList = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    padding: '5px 0',
    '&:focus': {
      outline: 'none',
    },
  })
);

export const MenuItem = withTheme(
  styled(({ theme, ...otherProps }: any) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 15,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
  })
);

export const UserMenuItem = withTheme(
  styled(({ theme, ...otherProps }: any) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 15,
    lineHeight: '18px',
    fontWeight: 400,
    padding: '5px 0 5px 15px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
  })
);

export const UserItem = withTheme(
  styled(({ theme, ...otherProps }: any) => <div {...otherProps} />)({
    paddingLeft: 14,
  })
);

export const StyledTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    width: 164,
    top: 0,
    backgroundColor: theme.customPalette.background.header,
    border: `1px solid ${theme.customPalette.borders.divider}`,
    filter:
      'drop-shadow(0px 4px 4px rgba(169, 194, 209, 0.05)) drop-shadow(0px 8px 16px rgba(169, 194, 209, 0.1))',
    padding: 0,
  },
  arrow: {
    left: '123px !important',
    width: 20,
    height: 11,
    marginTop: '-11px !important',
    color: theme.customPalette.background.header,
    '&::before': {
      border: `1px solid ${theme.customPalette.borders.divider}`,
    },
  },
  popper: {
    pointerEvents: 'auto',
  },
}))(Tooltip);

export const StyledUserName = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    color: theme.customPalette.text.primary,
    '&:hover': {
      background: 'none',
      color: '#008DBA',
    },
    '&:focus': {
      color: '#008DBA',
    },
    textTransform: 'initial',
  },
}))(Button);
