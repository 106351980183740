import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teacherEmailSelector } from 'src/ducks/auth/selectors';
import { reduxForm, InjectedFormProps, FormErrors, initialize } from 'redux-form';
import { FormInputField } from 'src/components/common/inputs';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import validations from 'src/components/common/inputs/validations';
import { config } from 'src/config/config';
import { EmailIcon, PasswordIcon, ProfileNameIcon } from 'src/components/common/Icons';
import { FormContainer, ContinueButton, ContinueButtonContainer } from '../signin/styles';
import { FullNameContainer, NameInput } from './styles';

export type FormCreateAccountProps = {
  firstName?: string;
  lastName?: string;
  password?: string;
  confirm?: string;
};

type Props = InjectedFormProps<FormCreateAccountProps, {}>;

const FormCreateAccount = ({ handleSubmit, valid }: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      initialize('createAccount', {
        lastName: '',
      })
    );
  }, [dispatch]);

  const teacherEmail = useSelector(teacherEmailSelector);
  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FullNameContainer>
          <NameInput>
            <FormInputField
              placeholder="First Name"
              name="firstName"
              adornment={<ProfileNameIcon />}
              maxLength={inputRestrictions.MAX_LENGTH}
              validate={validations.firstName}
            />
          </NameInput>
          <NameInput>
            <FormInputField
              placeholder="Last Name"
              name="lastName"
              autoComplete="off"
              maxLength={inputRestrictions.MAX_LENGTH}
              validate={validations.lastName}
            />
          </NameInput>
        </FullNameContainer>
        <FormInputField
          placeholder={teacherEmail}
          disabled={!!teacherEmail}
          adornment={<EmailIcon />}
          name="email"
        />
        <FormInputField
          placeholder="Password"
          adornment={<PasswordIcon />}
          name="password"
          type="password"
          autoComplete="new-password"
          maxLength={inputRestrictions.MAX_LENGTH_PASSWORD}
        />
        <FormInputField
          placeholder="Password (Confirm)"
          adornment={<PasswordIcon />}
          name="confirm"
          type="password"
          maxLength={inputRestrictions.MAX_LENGTH_PASSWORD}
        />
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            Next
          </ContinueButton>
        </ContinueButtonContainer>
      </FormContainer>
    </form>
  );
};

const validatePassword = (pw?: string, type?: string) => {
  if (!pw) return `Please provide the required field`;
  else if (!config.regexpValidatePass.test(pw)) {
    return `${type} must contain not less than 8 characters including letters, numbers and characters`;
  }
};

const validate = (values: FormCreateAccountProps) => {
  const errors: FormErrors<FormCreateAccountProps> = {};

  if (!values.password) errors.password = validatePassword(values.password, 'Password');
  else if (values.password) errors.password = validatePassword(values.password, 'Password');
  if (!values.confirm) errors.confirm = validatePassword(values.confirm, 'Confirm password');
  else if (values.confirm) errors.confirm = validatePassword(values.confirm, 'Confirm password');
  if (values.password !== values.confirm)
    errors.confirm = 'Passwords do not match. Please try again';

  return errors;
};

export default reduxForm<FormCreateAccountProps, {}>({
  form: 'createAccount',
  validate,
})(FormCreateAccount);
