import React from 'react';
import { Radio, RadioProps } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

export const RadioButton = withTheme(
  styled(({ theme, ...otherProps }: RadioProps & WithTheme) =>
    <Radio classes={{ checked: 'checked' }} {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    '&.checked:hover': {
      background: 'none',
    },
    '&:hover': {
      background: 'none',
    },
  }),
);
