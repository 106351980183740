import { moduleName } from './accounting';

export const GET_TOOLKIT_LIST = `${moduleName}/GET_TOOLKIT_LIST`;
export const GET_TOOLKIT_LIST_SUCCESS = `${moduleName}/GET_TOOLKIT_LIST_SUCCESS`;
export const GET_TOOLKIT_LIST_FAIL = `${moduleName}/GET_TOOLKIT_LIST_FAIL`;

export const CLEAR_TOOLKIT_LIST = `${moduleName}/CLEAR_TOOLKIT_LIST`;

export const GET_PROFITS_LIST = `${moduleName}/GET_PROFITS_LIST`;
export const GET_PROFITS_LIST_SUCCESS = `${moduleName}/GET_PROFITS_LIST_SUCCESS`;
export const GET_PROFITS_LIST_FAIL = `${moduleName}/GET_PROFITS_LIST_FAIL`;

export const CLEAR_PROFITS_LIST = `${moduleName}/CLEAR_PROFITS_LIST`;
