import { useMemo } from 'react';
import inputUtils from 'src/utils/formUtils';

type MemorizedValidateProps = {
  amount: number;
  errorText: string;
};

const useMemorizedValidate = ({ amount, errorText }: MemorizedValidateProps) => {
  return useMemo(() => inputUtils.maxValue(amount, errorText), [amount, errorText]);
};

export default useMemorizedValidate;
