import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import { Template1 } from 'src/components/pages/websiteTemplates/websiteTemplate1';
import { Template2 } from 'src/components/pages/websiteTemplates/websiteTemplate2';
import { Template3 } from 'src/components/pages/websiteTemplates/websiteTemplate3';
import { getShopOtherProducts, getShopProducts, ShopProductsPayload } from 'src/ducks/shopProducts/actions'
import { getWebsiteCollections, getWebsiteShop } from 'src/ducks/website/actions';
import { loadingWebsiteSelector, websiteCollections, websiteSelector, websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import { shopOtherProductsSelector, shopProductsSelector } from 'src/ducks/shopProducts/selectors';
import { ProductProps } from 'src/ducks/website/website';
import { ShopProductsList } from 'src/ducks/shopProducts/shopProducts';
import { WebsiteCollection } from 'src/constants/productsTypes';
import { useQueryParams } from 'src/components/hooks/useQueryParams';

const websiteTemplates = [ Template1, Template2, Template3 ];
const getWebsiteTemplate = (templateID: number = 0) => () => (
  templateID > 0 ? websiteTemplates[templateID - 1] : websiteTemplates[0]
);

const productsCountPerPage = 18;
const productsCountPerPageWithCollection = 12;
const othersProductsCountPerPage = 6;

const defaultSelectCollection: WebsiteCollection = {
  id: 'all_products',
  collectionName: 'All Products',
};

export type WebsiteTemplateProps = {
  loadMore: (shouldLoadOthers?: boolean) => void;
  products: ProductProps[];
  otherProducts?: ProductProps[] | null;
  collections: WebsiteCollection[];
  selectedCollection: WebsiteCollection;
  onCollectionChange: (collection: WebsiteCollection) => void;
  totalCount?: number;
  totalCountOthers?: number;
  isLoading: boolean;
  disableRedirectIfOutOfStock: (e: SyntheticEvent, inventory: number, isPhysicalProduct?: boolean) => void
}

type ViewWebsiteParams = {
  id?: string;
  collection?: string;
};

const ViewWebsite = () => {
  const dispatch = useDispatch();
  const {
    replace: historyReplace,
    push: historyPush,
    location: { pathname, search }
  } = useHistory();
  const { id: path = '' } = useParams<ViewWebsiteParams>();
  const {
    collection: queryCollectionName
  } = useQueryParams();
  const isLoading = useSelector(loadingWebsiteSelector);
  const templateID = useSelector(websiteTemplateIDSelector);
  const websiteId = useSelector(websiteSelector)?.id || null;
  const shopProducts = useSelector(shopProductsSelector) || {} as ShopProductsList;
  const shopProductsOthers = useSelector(shopOtherProductsSelector) || {} as ShopProductsList;

  const collections: WebsiteCollection[] = useSelector(websiteCollections) || [];
  const allCollections = [{ ...defaultSelectCollection }, ...collections];

  const [selectedCollection, setSelectedCollection] = useState<WebsiteCollection>(allCollections[0]);
 
  const [WebsiteTemplate, setWebsiteTemplate] = useState( getWebsiteTemplate() );

  useEffect(() => {
    if (path) {
      window.document.title = path;
      dispatch(getWebsiteShop(path));
    }
  }, [path, dispatch]);

  useEffect(() => {
    if (templateID && path) {
      setWebsiteTemplate( getWebsiteTemplate(templateID) );
    }
  }, [path, templateID, dispatch]);

   useEffect(() => {
    if (websiteId) {
      dispatch( getWebsiteCollections(websiteId) );
    }
  }, [websiteId]);

  const isAllProductsChosen = (collection?: WebsiteCollection): boolean => {
    const collectionCandidate = collection ? collection : selectedCollection;
    return collectionCandidate.id === allCollections[0].id;
  };

  const buildGetProductsParams = ({
    page,
    isShowMore,
    collection,
    isOtherCollection,
  }: Partial<ShopProductsPayload & { collection: WebsiteCollection }> = {}): ShopProductsPayload => {
    const baseParams: ShopProductsPayload = { path, page, isShowMore };
    const { collectionName } = collection ?? selectedCollection ?? {}
    const params = isOtherCollection
      ? { count: othersProductsCountPerPage, collectionName }
      : isAllProductsChosen(collection)
      ? { count: productsCountPerPage }
      : { count: productsCountPerPageWithCollection, collectionName }
    return { ...baseParams, ...params }
  };

  const loadMore = (shouldLoadOthers: boolean = false) => {
    if (!shouldLoadOthers) {
      if (shopProducts.currentPage
        && shopProducts.totalPages
        && shopProducts.currentPage < shopProducts.totalPages
      ) {
        const nextPage = Number(shopProducts.currentPage) + 1;
        const params = buildGetProductsParams({ page: nextPage, isShowMore: true });
        dispatch( getShopProducts(params) );
      }
    } else {
      const nextPage = Number(shopProductsOthers.currentPage) + 1;
      const params = buildGetProductsParams({ page: nextPage, isShowMore: true, isOtherCollection: true });
      dispatch( getShopOtherProducts(params) )
    }
  };

  const fetchProductsByCollection = (collection?: WebsiteCollection) => {
    let params = buildGetProductsParams({ collection });
    dispatch( getShopProducts(params) );
    if (!isAllProductsChosen(collection)) {
      params = buildGetProductsParams({ collection, isOtherCollection: true });
      dispatch( getShopOtherProducts(params) );
    }
  }

  const handleCollectionChange = (collection: WebsiteCollection) => {
    const query = collection && collection.id !== defaultSelectCollection.id 
      ? `?${new URLSearchParams({ collection: collection.collectionName }).toString()}`
      : '';
    historyPush( pathname + query);
  }

  const disableRedirectIfOutOfStock = (e: SyntheticEvent, inventory: number, isPhysicalProduct?: boolean) => {
    if (isPhysicalProduct && inventory === 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
      if (!search) {
        setSelectedCollection(defaultSelectCollection);
        fetchProductsByCollection(defaultSelectCollection);
      } else if (search && queryCollectionName) {
        const collection = collections.find(
          c => c.collectionName === queryCollectionName.toLocaleString()
        );
        if(collection) {
          setSelectedCollection(collection);
          fetchProductsByCollection(collection);
        } else {
          historyReplace( pathname );
        }
      }

      if (search && search.search('fbclid') == 1) {
        historyPush( pathname );
      }
  }, [queryCollectionName, search, collections.length]);

  return (
    <>
      {isLoading && !shopProducts.list?.length ? (
        <FullScreenPreloader />
      ) : templateID && (
        <WebsiteTemplate
          collections={allCollections}
          selectedCollection={selectedCollection}
          loadMore={loadMore}
          products={shopProducts.list}
          otherProducts={ isAllProductsChosen() ? null : shopProductsOthers.list }
          totalCount={shopProducts.totalCount}
          totalCountOthers={shopProductsOthers.totalCount}
          isLoading={isLoading}
          disableRedirectIfOutOfStock={disableRedirectIfOutOfStock}
          onCollectionChange={handleCollectionChange}
        />
      )}
    </>
  );
};

export default ViewWebsite;
