import React, { useState } from 'react';
import { Popup } from 'src/components/common/modal';
import { DeleteIcon } from 'src/components/common/Icons';
import popupText from 'src/constants/popupMessages';

type DeleteStudentProps = {
  cashoutId: number;
  handleRemoveCashout: (studentId: number, amount: number) => void;
  amount: number;
};

const DeleteStudent = (props: DeleteStudentProps) => {
  const { cashoutId, handleRemoveCashout, amount } = props;
  const [isOpen, setOpen] = useState(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleClose = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleRemove = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleRemoveCashout(cashoutId, amount);
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleOpen}>
        <DeleteIcon />
      </div>
      <Popup
        kind="danger"
        isOpen={isOpen}
        mainText={popupText.deleteCashout.mainText}
        closeModal={handleClose}
        handleYesAction={handleRemove}
        isRemove
      />
    </>
  );
};

export default DeleteStudent;
