import { all, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { sessionService } from 'src/services';
import oauthApi from 'src/api/oauthApi';
import { FormProps } from 'src/routes/AuthPage';
import history from 'src/containers/history';
import {
  SIGN_IN_AS_TEACHER,
  SIGN_IN_AS_TEACHER_FAIL,
  SIGN_IN_AS_TEACHER_SUCCESS,
  SIGN_UP_TEACHER,
  SIGN_UP_TEACHER_SUCCESS,
  SIGN_UP_TEACHER_FAIL,
  APP_INITIALIZER_FAIL,
  GET_TEACHER_EMAIL_FOR_SIGN_UP,
  GET_TEACHER_EMAIL_FOR_SIGN_UP_SUCCESS,
  LOGOUT,
  LOGOUT_FAIL,
  GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL,
  GET_ACCESS_TOKEN,
  GET_ACCESS_TOKEN_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_AS_TEACHER_SUCCESS,
  FORGOT_PASSWORD_AS_TEACHER_FAIL,
  CHECK_RECOVERY_TOKEN,
  CHECK_RECOVERY_TOKEN_SUCCESS,
  CHECK_RECOVERY_TOKEN_FAIL,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  SET_ROLE_AS_TEACHER,
  SET_ROLE_AS_STUDENT,
  SWITCH_PAGES,
} from './constants';
import { GET_USER, CLEAR_USER, GET_USER_SUCCESS } from 'src/ducks/user/constants';
import { isUserTeacher } from 'src/ducks/user/selectors';
import { CLEAR_WEBSITE } from 'src/ducks/website/constants';
import { getInviteTokenStorage, removeItemByKeyStorage } from 'src/utils/helpers';
import SSEventStream from '../../redux/store/middlewares/SSEventStream';

export const moduleName = 'auth';

type SignInAsTeacher = {
  type: string;
  payload: FormProps;
};

type ForgotPassword = {
  type: string;
  payload: FormProps;
};

type CheckRecoveryToken = {
  type: string;
  payload: string;
};

type ResetPassword = {
  type: string;
  payload: {
    token: string;
    password: {
      first: string;
      second: string;
    };
  };
};

type ErrorType = {
  data: {
    errors: {
      title: string;
      message: string;
    };
    error_description: string;
  };
  status: number | null;
  key: string;
  text: string;
  message: string;
  statusText: string;
};

export type StateAuthInterface = {
  error: ErrorType;
  loading: boolean;
  message: string;
  title: string;
  inviteToken: string;
  teacherEmail: string;
  isTeacher: boolean;
  recoveryTokenIsChecked: boolean;
  linkSuccessfullySent: boolean;
  passwordSuccessfullyReset: boolean;
};

export const authState: StateAuthInterface = {
  error: {
    data: {
      errors: {
        title: '',
        message: '',
      },
      error_description: '',
    },
    status: null,
    key: '',
    text: '',
    message: '',
    statusText: '',
  },
  loading: false,
  message: '',
  title: '',
  inviteToken: '',
  teacherEmail: '',
  isTeacher: false,
  recoveryTokenIsChecked: false,
  linkSuccessfullySent: false,
  passwordSuccessfullyReset: false,
};

export type ActionTypeSignup = {
  type: string;
  error: {
    config?: {};
    data?: {};
    headers?: {};
    request: any;
    status: number;
    statusText: string;
  };
  payload: {
    id: number;
    email: string;
    isExpired: boolean;
    status: string;
    firstName: string;
    lastName: string;
    password: string;
    confirm: string;
  };
};

type ActionType = {
  type: string;
  error: {
    config?: {};
    data?: {};
    headers?: {};
    request: any;
    status: number;
    statusText: string;
  };
  payload?: any;
};

export default function reducer(
  state: StateAuthInterface = authState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_TEACHER_EMAIL_FOR_SIGN_UP:
    case SIGN_UP_TEACHER:
    case SIGN_IN_AS_TEACHER:
    case FORGOT_PASSWORD:
    case CHECK_RECOVERY_TOKEN:
      return { ...state, loading: true };
    case GET_TEACHER_EMAIL_FOR_SIGN_UP_SUCCESS:
      return {
        ...state,
        teacherEmail: payload,
        loading: false,
        error: null,
      };
    case SIGN_IN_AS_TEACHER_SUCCESS:
    case SIGN_UP_TEACHER_SUCCESS:
    case FORGOT_PASSWORD_AS_TEACHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        linkSuccessfullySent: true,
      };
    case CHECK_RECOVERY_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        recoveryTokenIsChecked: true,
        linkSuccessfullySent: false,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        passwordSuccessfullyReset: true,
      };
    case APP_INITIALIZER_FAIL:
    case SIGN_IN_AS_TEACHER_FAIL:
    case GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL:
    case SIGN_UP_TEACHER_FAIL:
    case FORGOT_PASSWORD_AS_TEACHER_FAIL:
    case RESET_PASSWORD_FAIL:
    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
        linkSuccessfullySent: false,
      };
    case SET_ROLE_AS_TEACHER:
      return {
        ...state,
        isTeacher: true,
        linkSuccessfullySent: false,
        passwordSuccessfullyReset: false,
      };
    case SET_ROLE_AS_STUDENT:
      return {
        ...state,
        isTeacher: false,
      };
    case CHECK_RECOVERY_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: error,
        recoveryTokenIsChecked: true,
      };
    case SWITCH_PAGES:
      return {
        ...state,
        isTeacher: true,
        linkSuccessfullySent: false,
        error: null,
      };
    case LOGOUT: {
      return {
        ...state,
        user: null,
        loading: false,
      };
    }
    default:
      return state;
  }
}

function* signInAsTeacher({ payload }: SignInAsTeacher) {
  const { email, password } = payload;
  const pinLength = 4;
  try {
    if (password.length > pinLength) {
      yield put({ type: SET_ROLE_AS_TEACHER });
    } else {
      yield put({ type: SET_ROLE_AS_STUDENT });
    }
    const response = yield oauthApi.getToken(email, password);
    if (response.data && response.data.access_token) {
      yield sessionService.init({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: response.data.expires_in,
      });
    } else {
      yield sessionService.init({
        accessToken: response.access_token,
        refreshToken: response.refresh_token,
        expiresIn: response.expires_in,
      });
    }
    yield put({ type: SIGN_IN_AS_TEACHER_SUCCESS });
    yield put({ type: GET_USER });

    setTimeout(() => {
      const user = JSON.parse(localStorage.getItem('user') || '');
      const websiteId = user.accesses[0].store.websites[0].id;
    }, 1000);
  } catch (err) {
    yield put({ type: SIGN_IN_AS_TEACHER_FAIL, error: err });
  }
}

function* forgotPassword({ payload }: ForgotPassword) {
  const { email } = payload;

  try {
    const { access_token, expires_in, scope, token_type } = yield oauthApi.getAccessToken();
    yield sessionService.saveAnonymous({
      accessToken: access_token,
      expiresIn: expires_in,
      scope: scope,
      tokenType: token_type,
    });
    yield oauthApi.forgotPassword(email);
    yield put({ type: FORGOT_PASSWORD_AS_TEACHER_SUCCESS });
  } catch (err) {
    yield put({ type: FORGOT_PASSWORD_AS_TEACHER_FAIL, error: err });
  }
}

function* checkRecoveryToken({ payload }: CheckRecoveryToken) {
  try {
    const { access_token, expires_in, scope, token_type } = yield oauthApi.getAccessToken();
    yield sessionService.saveAnonymous({
      accessToken: access_token,
      expiresIn: expires_in,
      scope: scope,
      tokenType: token_type,
    });
    yield oauthApi.checkRecoveryToken(payload);
    yield put({ type: CHECK_RECOVERY_TOKEN_SUCCESS });
  } catch (err) {
    yield put({ type: CHECK_RECOVERY_TOKEN_FAIL, error: err });
  }
}

function* resetPassword({ payload }: ResetPassword) {
  try {
    yield oauthApi.resetPassword(payload);
    yield put({ type: RESET_PASSWORD_SUCCESS });
  } catch (err) {
    yield put({ type: RESET_PASSWORD_FAIL, error: err });
  }
}

function* logout() {
  const refreshToken = sessionService.getRefreshToken();
  yield sessionService.destroySession();
  yield removeItemByKeyStorage('user');
  yield removeItemByKeyStorage('auth');

  const isTeacher = yield select(isUserTeacher);

  if (isTeacher) {
    yield put({ type: SET_ROLE_AS_TEACHER });
  } else {
    yield put({ type: SET_ROLE_AS_STUDENT });
  }

  yield put({ type: CLEAR_USER });
  yield put({ type: CLEAR_WEBSITE });
  yield history.push('/');
  try {
    if (refreshToken) {
      yield oauthApi.logout(refreshToken);
    }
  } catch (err) {
    yield put({ type: LOGOUT_FAIL, error: err });
  }
}

function* getAccessTokenSaga() {
  try {
    if (!getInviteTokenStorage('auth')) {
      const { access_token, expires_in, scope, token_type } = yield oauthApi.getAccessToken();
      yield sessionService.saveAnonymous({
        accessToken: access_token,
        expiresIn: expires_in,
        scope: scope,
        tokenType: token_type,
      });
      yield put({ type: GET_TEACHER_EMAIL_FOR_SIGN_UP });
    }
  } catch (err) {
    yield put({ type: GET_ACCESS_TOKEN_FAIL, error: err }); //show error not connection
  }
}

function* getTeacherEmail() {
  const inviteToken = getInviteTokenStorage('inviteToken');
  try {
    const response = yield oauthApi.getTeacherEmail(JSON.parse(inviteToken));
    if (response.isExpired) {
      yield put({
        type: GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL,
        error: {
          key: 'isExpired',
          text: `It looks like this link has expired!
         Please contact support@realworldscholars.org to generate a new link.`,
        },
      });
    } else if (response.status === 'activated') {
      yield put({
        type: GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL,
        error: { key: 'activated', text: 'You are already registered in the system.' },
      });
    } else {
      yield put({
        type: GET_TEACHER_EMAIL_FOR_SIGN_UP_SUCCESS,
        payload: response.email,
      });
    }
  } catch (err) {
    yield put({ type: GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL, error: err });
  }
}

function* signUpTeacherSaga({
  payload: { firstName, lastName, password, confirm },
}: ActionTypeSignup) {
  const inviteToken = JSON.parse(getInviteTokenStorage('inviteToken'));
  const { values } = yield select((state) => state.form.schoolInformation);
  const { teacherEmail: email } = yield select((state) => state.auth);
  try {
    yield oauthApi.signUpTeacher({
      store: {
        name: values.store,
        storeDescription: {
          cityAndState: values.city,
          schoolName: values.schoolName,
          schoolAddress: values.school,
          schoolLevel: values.schoolLevel,
          classroomConcentration: Object.keys(values).filter((key) => values[key] === true),
          zipCode: values.zipCode,
        },
      },
      firstName: firstName,
      lastName: lastName,
      plainPassword: {
        first: password,
        second: confirm,
      },
      inviteToken,
    });
    removeItemByKeyStorage('inviteToken');
    yield put({ type: SIGN_UP_TEACHER_SUCCESS });
    yield put({ type: SIGN_IN_AS_TEACHER, payload: { email, password } });
  } catch (err) {
    yield put({ type: SIGN_UP_TEACHER_FAIL, error: err });
  }
}

export const saga = function* () {
  yield all([
    takeEvery(GET_ACCESS_TOKEN, getAccessTokenSaga),
    takeEvery(GET_TEACHER_EMAIL_FOR_SIGN_UP, getTeacherEmail),
    takeEvery(SIGN_UP_TEACHER, signUpTeacherSaga),
    takeEvery(CHECK_RECOVERY_TOKEN, checkRecoveryToken),
    takeLatest(SIGN_IN_AS_TEACHER, signInAsTeacher),
    takeLatest(FORGOT_PASSWORD, forgotPassword),
    takeLatest(RESET_PASSWORD, resetPassword),
    takeLatest(LOGOUT, logout),
  ]);
};
