import React from 'react';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';

export const HomeContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 auto',
    padding: '52px 52px 0 60px',
    minHeight: '100%',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.greyBg,
  }),
);

export const HomeTitle = styled('h1')({
  width: 866,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '41px',
  lineHeight: '55px',
  letterSpacing: '0.01em',
  color: '#8697A8',
  '@media (max-width: 1910px)': {
    width: '100%',
    textAlign: 'center',
  },
});

export const CardsContainer = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: 1590,
  '@media (max-width: 1910px)': {
    justifyContent: 'center',
  },
});

export const HomeCard = styled('div')({
  display: 'flex',
  boxSizing: 'border-box',
  position: 'relative',
  width: 492,
  height: 200,
  margin: '0 25px 30px 0',
  backgroundColor: '#FFFFFF',
  borderRadius: 10,
  boxShadow: '0 12px 16px rgba(169, 194, 209, 0.1)',
  '&:hover': {
    boxShadow: '0px 32px 32px rgba(169, 194, 209, 0.1), 0px 8px 16px rgba(169, 194, 209, 0.05)',
  },
  '&:active': {
    boxShadow: 'none',
  },
});

export const CardWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  paddingRight: 20,
  wordBreak: 'break-word',
});

export const CardTitle = styled('h3')({
  margin: '0 0 16px',
  padding: '27px 0 0 30px',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '20px',
  lineHeight: '23px',
  color: '#44566C',
});

export const CardImage = styled('img')({
  width: 160,
  height: 160,
  alignSelf: 'center',
});

export const CardsText = withTheme(
  styled(({ theme, ...otherProps }: & WithTheme) => <p {...otherProps} />)(
    {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '15px',
      lineHeight: '19px',
      padding: '0 30px',
      color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
      '& span > span': {
        color: '#008DBA',
        cursor: 'pointer',
        textDecoration: 'none',
        '&:hover': {
          color: '#0081FF',
        },
      },
    },
  ),
);
