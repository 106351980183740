import React from 'react';
import { Card, Typography, TypographyProps, CardProps } from '@material-ui/core';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

type AuthBackgroundProps = {
  authBackground: string;
};
type PasswordRecoweryProps = {
  passwordRecowery?: boolean;
};
type TextProps = {
  success?: boolean;
};

type ContactUsProps = {
  type: string;
  value: string;
};

export const AuthContainer = withTheme(
  styled(({ authBackground, ...otherProps }: AuthBackgroundProps) => <div {...otherProps} />)({
    height: 'auto',
    minHeight: '100%',
    width: '100%',
    backgroundImage: ({ authBackground }: AuthBackgroundProps) => `url(${authBackground})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundColor: ({ theme }: AuthBackgroundProps & WithTheme) =>
      theme.customPalette.background.greyBg,
  }),
);

export const Logo = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '80px 0 60px 0',
});

export const CardContainer = withTheme(
  styled(
    ({ theme, passwordRecowery, ...otherProps }: CardProps & WithTheme & PasswordRecoweryProps) => (
      <Card {...otherProps} />
    ),
  )({
    width: 430,
    margin: '0 auto',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: ({ passwordRecowery }: PasswordRecoweryProps) =>
      passwordRecowery ? '#EAEDF0' : '#FFFFFF',
    filter:
      'drop-shadow(0px 4px 8px rgba(169, 194, 209, 0.25)) drop-shadow(0px 11px 32px rgba(169, 194, 209, 0.25))',
    boxShadow:
      '0px 32px 32px -18px rgba(169, 194, 209, 0.25), 0px 8px 16px -8px rgba(169, 194, 209, 0.15)',
    borderRadius: 10,
  }),
);

export const LoginText = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    paddingBottom: 39,
    fontSize: 30,
    lineHeight: '35px',
    fontWeight: 500,
  }),
);

export const CardHeader = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  height: '116px',
  backgroundColor: '#fff',
  borderBottom: '1px #EAEDF0 solid',
});

export const HeaderIcon = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  padding: '35px 13px 0 0',
});

export const HeaderText = withTheme(
  styled(({ theme, success, ...otherProps }: TypographyProps & WithTheme & TextProps) => (
    <Typography {...otherProps} />
  ))({
    color: ({ theme, success }: WithTheme & TextProps) =>
      success ? theme.customPalette.text.success : theme.customPalette.text.primary,
    fontWeight: ({ success }: TextProps) => (success ? 'normal' : 500),
    padding: ({ success }: TextProps) => (success ? '30px 67px 0' : '35px 0 0 0'),
    fontSize: ({ success }: TextProps) => (success ? '20px' : '30px'),
    textAlign: 'center',
  }),
);

export const CardContent = styled('div')({
  padding: '24px 0 35px 0',
});

export const ContactUs = styled(({ ...otherProps }: ContactUsProps) =>
  <input {...otherProps} />)({
  position: 'absolute',
  right: 50,
  bottom: 50,
  border: 'none',
  background: 'none',
  fontSize: 16,
  cursor: 'pointer',
  color: 'rgb(0, 141, 186)',
  fontWeight: 500,
  fontFamily: 'Roboto',
  '&:focus': {
    outline: 'none',
  },
});
