import {
  APP_INITIALIZER,
  GET_ACCESS_TOKEN,
  SIGN_IN_AS_TEACHER,
  SIGN_UP_TEACHER,
  LOGOUT,
  FORGOT_PASSWORD,
  CHECK_RECOVERY_TOKEN,
  RESET_PASSWORD,
  SWITCH_PAGES,
} from './constants';
import { FormProps, ResetPassword } from 'src/routes/AuthPage';
import { FormCreateAccountProps } from 'src/components/pages/auth/signup/FormCreateAccount';

export function initApplication() {
  return {
    type: APP_INITIALIZER,
  };
}

export function getAccessToken() {
  return { type: GET_ACCESS_TOKEN };
}

export function signinAsTeacher(values: FormProps) {
  return {
    type: SIGN_IN_AS_TEACHER,
    payload: values,
  };
}

export function forgotPassword(values: FormProps) {
  return {
    type: FORGOT_PASSWORD,
    payload: values,
  };
}

export function checkRecoveryToken(token: string) {
  return {
    type: CHECK_RECOVERY_TOKEN,
    payload: token,
  };
}

export function resetPassword(values: ResetPassword) {
  return {
    type: RESET_PASSWORD,
    payload: values,
  };
}

export function signUpAction(payload: FormCreateAccountProps) {
  return {
    type: SIGN_UP_TEACHER,
    payload: payload,
  };
}

export function switchPages() {
  return {
    type: SWITCH_PAGES,
  };
}

export function logout() {
  return { type: LOGOUT };
}
