import React from 'react';
import { PhotoIcon } from 'src/components/common/Icons';
import {
  PosterImageText,
  UploadProductImageText,
} from '../styles';

const UploadText = () => {
  return (
    <PosterImageText>
      <PhotoIcon />
      <UploadProductImageText>Click to upload <br/> product image</UploadProductImageText>
      <p>It's best to use images at least 400/400 pixels</p>
      <>Upload up to 4 images</>
      <p>File size cannot exceed 3MB</p>
    </PosterImageText>
  );
};

export default UploadText;
