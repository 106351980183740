import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { websiteSelector } from 'src/ducks/website/selectors';
import { ProductProps } from 'src/ducks/website/website';
import { calculateFullProductsAmount } from 'src/utils/helpers';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import heroImageBackground from 'src/assets/images/websites/website-3/hero-image.jpg';
import productImage from 'src/assets/images/websites/product-image.png';
import { ShopCartTemplate3Icon, LogoIconSmall, DownArrow } from 'src/components/common/Icons';
import vars from 'src/constants/vars';
import {
  WebsiteContainer,
  WebsiteNameContainer,
  WebsiteName,
  CartContainer,
  HeroImageContainer,
  HeroImageBackground,
  TaglineContainer,
  TaglineNme,
  ShopNowContainer,
  ShopNowButton,
  CardContainer,
  ImageContainer,
  OutOfStock,
  CardInfo,
  CardName,
  CardDescription,
  Price,
  HorizontalLine,
  AboutUsContainer,
  AboutUsTitle,
  AboutUsDescription,
  FooterContainer,
  ContactUsButton,
  LearnMore,
  LoadMoreContainer,
  LoadMoreButton,
  CarouselWrapper,
  FreeShipping,
  Line,
  IconWrapper,
} from './styles';
import { NON_USPS_SHIPPING, USPS_NON_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';
import { WebsiteTemplateProps } from '../../view-website/ViewWebsite';
import { CollectionSelect } from 'src/components/pages/products/collectionSelect';
import { ColumnLayout, ProductsLayout, ProductsSection, ProductsSectionTitle, styles } from '../styles';
import { NoProducts } from '../noProducts';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(styles);

export const renderFooter = (email: string) => (
  <FooterContainer>
    <div>
      <LogoIconSmall isFooter />
    </div>
    <a href={`mailto:${email}`}>
      <ContactUsButton type="submit" value="CONTACT US" />
    </a>
    <LearnMore href="https://www.realworldscholars.org/edcorps">
      Learn More about EdCorps
    </LearnMore>
  </FooterContainer>
);

const Template3 = ({
  products,
  otherProducts,
  loadMore,
  collections,
  selectedCollection,
  onCollectionChange,
  totalCount,
  totalCountOthers,
  isLoading,
  disableRedirectIfOutOfStock
}: WebsiteTemplateProps) => {
  const { name, tagline, heroImages, email, description, path } = useSelector(websiteSelector);
  const isLoadMoreShow = products && totalCount !== products.length;
  const isLoadMoreOthersShow = otherProducts && totalCountOthers !== otherProducts.length;
  const productsAmount = calculateFullProductsAmount();
  const classes = useStyles();

  const renderProductList = (product: ProductProps) => {
    const { id, imagesGallery, name, shippingType, description, price, inventory, isPhysicalProduct } = product;
    const imageList = Object.values(imagesGallery).map((arr: any) => arr[1].url);
    const isOutOfStock = isPhysicalProduct && inventory === 0;
    const isShippingIncluded = shippingType === USPS_NON_PRIORITY_MAIL || shippingType === NON_USPS_SHIPPING;

    return (
      <div key={id}>
        <CardContainer>
          {imageList.length ? (
            <CarouselWrapper {...vars.sliderSettings}>
              {imageList.map((url: string, i: number) => {
                return (
                  <ImageContainer isOutOfStock={isOutOfStock} key={i}>
                    {isOutOfStock && (
                      <OutOfStock>This item is currently out of stock</OutOfStock>
                    )}
                    <img src={url} alt="" />
                  </ImageContainer>
                );
              })}
            </CarouselWrapper>
          ) : (
            <CarouselWrapper {...vars.sliderSettings}>
              <ImageContainer isOutOfStock={isOutOfStock}>
                {isOutOfStock && <OutOfStock>This item is currently out of stock</OutOfStock>}
                <img src={productImage} alt="" />
              </ImageContainer>
            </CarouselWrapper>
          )}
          <CardInfo>
            <Link
              to={`/shop/${path}/${id}`}
              onClick={(e: SyntheticEvent) => disableRedirectIfOutOfStock(e, inventory, isPhysicalProduct)}
            >
              <CardName>{name.slice(0, 25)}</CardName>
              <FreeShipping>{isShippingIncluded && 'Shipping Included'}</FreeShipping>
              <CardDescription>{description}</CardDescription>
              <Price>${price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Price>
            </Link>
          </CardInfo>
        </CardContainer>
        <HorizontalLine />
      </div>
    );
  };

  return (
    <WebsiteContainer>
      {isLoading && <FullScreenPreloader />}
      <WebsiteNameContainer>
        <WebsiteName>{name}</WebsiteName>
        <Link to={`/shop/${path}/cart`}>
          <CartContainer>
            <ShopCartTemplate3Icon />
            <div>{productsAmount}</div>
          </CartContainer>
        </Link>
      </WebsiteNameContainer>
      <HeroImageContainer>
        <HeroImageBackground
          heroImageBackground={(heroImages[0] && heroImages[0].url) || heroImageBackground}
        >
          <TaglineContainer>
            <TaglineNme>{tagline}</TaglineNme>
            <ShopNowContainer href="#products">
              <ShopNowButton>SHOP NOW</ShopNowButton>
            </ShopNowContainer>
          </TaglineContainer>
        </HeroImageBackground>
      </HeroImageContainer>
      <ProductsSection id="products">
        <CollectionSelect
          selectedCollection={selectedCollection}
          collections={collections}
          onCollectionChange={onCollectionChange}
        />
        {products?.length
          ? <ProductsLayout columnLayout={ColumnLayout.TWO_COLUMNS} className={classes.columnLayout}>
            { products.map((product: ProductProps) => renderProductList(product)) }
            </ProductsLayout>
          : <NoProducts />}
      </ProductsSection>
      {isLoadMoreShow && (
        <LoadMoreContainer>
          <Line />
          <LoadMoreButton onClick={() => loadMore()}>
            Show More
            <IconWrapper><DownArrow /></IconWrapper>
          </LoadMoreButton>
          <Line />
        </LoadMoreContainer>
      )}
      {/** Reuse code above and below to avoid DRY */}
      { otherProducts?.length && (
        <>
          <ProductsSection id="products-others">
            <ProductsSectionTitle>Other Products</ProductsSectionTitle>
            <ProductsLayout columnLayout={ColumnLayout.TWO_COLUMNS} className={classes.columnLayout}>
              { otherProducts.map((product: ProductProps) => renderProductList(product)) }
            </ProductsLayout>
          </ProductsSection>
          {isLoadMoreOthersShow && (
            <LoadMoreContainer>
              <Line />
              <LoadMoreButton onClick={() => loadMore(true)}>
                Show More
                <IconWrapper><DownArrow/></IconWrapper>
              </LoadMoreButton>
              <Line />
            </LoadMoreContainer>
          )}
        </>
      )}
      <AboutUsContainer>
        <AboutUsTitle>About Us</AboutUsTitle>
        <AboutUsDescription>{description}</AboutUsDescription>
      </AboutUsContainer>
      {renderFooter(email)}
    </WebsiteContainer>
  );
};

export default Template3;
