import { styled } from '@material-ui/core/styles';

export const InfoWrapper = styled('div')({
  position: 'relative',
  maxWidth: 536,
  color: '#8697A8',
  fontSize: '15px',
  lineHeight: '21px',
  paddingLeft: 75,
  marginBottom: 10,
  '& > span': {
    color: '#44566C',
  },
});

export const AdditionalElement = styled('div')({
  position: 'absolute',
  left: 52,
  top: -6,
  '& > svg': {
    position: 'absolute',
    left: -2,
    top: 5,
  },
});
