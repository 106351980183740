import React from 'react';
import {
  TableRow,
  TableCell,
  TableCellProps,
  TableContainer,
  Paper,
  Table,
} from '@material-ui/core';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const StyledTableContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => (
    <TableContainer component={Paper} {...otherProps} />
  ))({
    alignSelf: 'flex-start',
    boxShadow: 'none',
    borderRadius: 0,
    marginBottom: 50,
    width: 'auto',
    '@media (max-width: 1600px)': {
      alignSelf: 'center',
    },
  })
);

export const TableWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Table {...otherProps} />)({
    maxWidth: 1100,
  })
);

export const StyledBodyTableRow = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <TableRow {...otherProps} />)({})
);

export const StyledTableCell = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    borderBottom: 'none !important',
    padding: '0 21px 0 0',
    width: '100%',
    '& > div': {
      marginBottom: 0,
      cursor: 'pointer',
      maxWidth: 182,
      '@media (max-width: 1600px)': {
        maxWidth: 130,
      },
    },
    position: 'relative',
  })
);

export const VariantText = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <p {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: '20px',
    lineHeight: '27px',
    wordBreak: 'break-all',
    '@media (max-width: 1600px)': {
      fontSize: '18px',
      lineHeight: '20px',
    },
  })
);
