import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import authReducer, { moduleName as authModule } from 'src/ducks/auth/auth';
import userReducer, { moduleName as userModule } from 'src/ducks/user/user';
import productsReducer, { moduleName as productsModule } from 'src/ducks/products/products';
import websiteReducer, { moduleName as websiteModule } from 'src/ducks/website/website';
import accountingReducer, { moduleName as accountingModule } from 'src/ducks/accounting/accounting';
import ordersReducer, { moduleName as ordersModule } from 'src/ducks/orders/orders';
import shopOrderReducer, { moduleName as shopOrderModule } from 'src/ducks/shopOrder/shopOrder';
import cashoutReducer, { moduleName as cashoutModule } from 'src/ducks/cashout/cashout';
import shopProductsReducer, { moduleName as shopProductsModule } from 'src/ducks/shopProducts/shopProducts';
import { moduleName as notificationModule } from 'src/ducks/notifications/constants';
import notificationsReducer from 'src/ducks/notifications/notifications';
import homeReducer, { moduleName as homeModule } from 'src/ducks/home/home';

export default combineReducers({
  form,
  [authModule]: authReducer,
  [userModule]: userReducer,
  [productsModule]: productsReducer,
  [websiteModule]: websiteReducer,
  [accountingModule]: accountingReducer,
  [ordersModule]: ordersReducer,
  [shopOrderModule]: shopOrderReducer,
  [cashoutModule]: cashoutReducer,
  [shopProductsModule]: shopProductsReducer,
  [notificationModule]: notificationsReducer,
  [homeModule]: homeReducer,
});
