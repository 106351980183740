import React from 'react';
import { ClassroomConcentrationCheckboxes } from 'src/components/common/classroomConcentrationCheckboxes';
import { User } from 'src/ducks/user/user';
import profileMessages from 'src/constants/profileMessages';
import { ProfileCardTitle } from '../styles';

type ClassroomConcentrationProps = {
  teacherProfileInfo: User;
};

const ClassroomConcentration = ({ teacherProfileInfo }: ClassroomConcentrationProps) => {
  const { accesses } = teacherProfileInfo;
  const userStore = accesses[0].store;
  const storeDescription = userStore.storeDescription;

  return (
    <>
      <ProfileCardTitle>{profileMessages.classroomConcentration}</ProfileCardTitle>
      <ClassroomConcentrationCheckboxes defaultValues={storeDescription?.classroomConcentration} />
    </>
  );
};

export default ClassroomConcentration;
