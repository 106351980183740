import React from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableCellProps,
  TableContainer,
  Paper,
} from '@material-ui/core';
import ChipInput from 'material-ui-chip-input';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const Conteiner = styled('div')({
  margin: '28px 61px 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const StyledTableContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => (
    <TableContainer component={Paper} {...otherProps} />
  ))({
    boxShadow: 'none',
    borderRadius: 0,
    overflow: 'hidden',
  })
);

export const ConteinerForAddOptions = styled('div')({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  marginBottom: 50,
});

export const HalfPartContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '48%',
  minWidth: 730,
});

export const LeftPartContainer = styled(({ ...otherProps }: any) => (
  <HalfPartContainer {...otherProps} />
))({
  marginBottom: 15,
});

export const RightPartContainer = styled(({ ...otherProps }: any) => (
  <HalfPartContainer {...otherProps} />
))({
  paddingTop: 30,
});

export const Separator = styled('div')({
  marginTop: 17,
  width: 1,
  backgroundColor: '#EAEDF0',
  height: 96,
});

export const TableWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Table {...otherProps} />)({
    maxWidth: '100%',
  })
);

export const Head = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <TableHead {...otherProps} />)({
    textTransform: 'uppercase',
    '& th': {
      color: '#8697A8',
    },
  })
);

export const StyledBodyTableRow = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <TableRow {...otherProps} />)({})
);

export const StyledTableCell = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    borderBottom: 'none !important',
    padding: '0 21px 0 0',
    '& > div': {
      marginBottom: 0,
      cursor: 'pointer',
    },
    position: 'relative',
  })
);

export const OptionInput = withTheme(
  styled(({ theme, ...otherProps }: any) => <ChipInput {...otherProps} />)({
    background: '#F8FAFB',
    minWidth: 360,
    marginTop: 10,
    border: '1px solid #EAEDF0',
    borderRadius: 5,
    '& > div': {
      paddingLeft: 5,
      minHeight: 43,
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
      '&:hover': {
        '&:before': {
          borderBottom: 'none !important',
        },
      },
    },
    '& input': {
      color: '#757575',
      opacity: 1,
      fontSize: '15px',
      paddingLeft: 18,
      fontFamily: 'Arial',
      '&::-webkit-input-placeholder': {
        color: '#757575',
        opacity: 1,
        fontSize: '15px',
        fontFamily: 'Arial',
      },
    },
    '& .MuiChip-root': {
      backgroundColor: '#EAEDF0',
      margin: '5px 3px 3px 3px',
      borderRadius: 5,
    },
    '&:hover': {
      background: '#EAEDF0',
      '& .MuiChip-root': {
        backgroundColor: '#DEDFE0',
      },
    },
  })
);

export const IconButton = withTheme(
  styled(({ theme, ...otherProps }: any) => <button type="button" {...otherProps} />)({
    width: 55,
    height: 55,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#FFFFFF',
    '& > svg': {
      pointerEvents: 'none',
    },
    '&:hover': {
      backgroundColor: '#EAEDF0',
      '& > svg': {
        fill: '#44566C !important',
      },
    },
    '&:focus, &:active': {
      outline: 'none',
    },
  })
);

export const StyledError = withTheme(
  styled(({ theme, ...otherProps }: any) => <p {...otherProps} />)({
    color: '#FF3D57',
    paddingLeft: 255,
  })
);
