const colors = {
  blue: {
    darkBlue: '#44566C',
    lightBlue: '#F8FAFB',
    inputBlue: '#F8FAFB',
    buttonBlue: '#008DBA',
    buttonDisable: '#C7DDF2',
  },
  gray: {
    lightGray: '#8697A8',
  },
  black: '#000000',
  white: '#FFFFFF',

  grey01: '#F8FAFB',
  grey02: '#44566C',
  grey03: '#EAEDF0',
  grey04: '#8697A8',
  grey05: '#EFF4F8',
  grey06: '#C4C4C4',
  grey07: '#E5E5E5',
  grey08: '#B3C0CE',
  grey09: '#303E4E',
  grey10: '#465466',
  grey11: '#393E44',
  grey12: '#15232E',

  blue01: 'rgba(0, 141, 186, 0.28)',
  blue02: '#008DBA',
  blue03: '#0081FF',
  blue04: '#C7DDF2',
  blue05: '#E2F5FF',
  blue06: '#465466',

  red01: '#FF3D57',

  green01: '#09B66D',
  green02: '#00844C',

  orange01: '#FF8A48',
};

const palette = {
  avatar: {
    color: colors.white,
    background: colors.orange01,
  },
  background: {
    greyBg: colors.grey01,
    inputBg: colors.grey01,
    inputWhiteBg: colors.white,
    header: colors.white,
    page: colors.grey07,
    blue: colors.grey03,
    footerBlue: colors.blue06,
  },
  buttons: {
    activeBg: colors.blue02,
    activeColor: colors.white,
    disabledBg: colors.blue04,
    disabledColor: colors.grey04,
    blue: colors.blue05,
    primary: colors.grey02,

    secondary: {
      color: colors.blue03,
      bg: colors.blue05,
      hoverColor: colors.blue03,
      hoverBg: colors.blue06,
      disabledColor: colors.grey08,
      disabledBg: colors.grey03,
    },
  },
  modalButtons: {
    bg: colors.grey01,
    color: colors.grey02,
    secondaryColor: colors.grey04,
    hoverBg: colors.grey03,
    activeBg: colors.grey04,
  },
  borders: {
    activeInput: colors.blue01,
    select: colors.grey03,
    errorInput: colors.red01,
    successInput: colors.green01,
    placeholder: colors.grey04,
    divider: colors.grey03,
    menu: colors.grey06,
  },
  text: {
    primary: colors.grey02,
    grey: colors.grey04,
    error: colors.red01,
    success: colors.green01,
    blue: colors.blue03,
    active: colors.white,
    lightGrey: colors.grey08,
    highlight: colors.blue02,
  },
  checkbox: {
    default: colors.grey08,
    checked: colors.blue02,
    label: colors.gray.lightGray,
  },
};

const siteTemplatePalette = {
  text: {
    primary: colors.black,
    secondary: colors.grey04,
    light: colors.white,
    success: colors.green02,
  },
  background: {
    main: colors.white,
    secondary: colors.black,
    btn: colors.grey09,
    btnHover: colors.grey10,
    whiteBtn: colors.white,
    whiteBtnHover: colors.grey03,
    dark: colors.grey11,
    dark1: colors.grey12,
  },
  shadow: {
    btn: `0 3px 15px ${colors.grey09}`,
    whiteBtn: `0 3px 15px ${colors.grey04}`,
  },
}

const generalStyles = {
  customPalette: palette,
  siteTemplatePalette: siteTemplatePalette,
  typography: {
    h1: {
      letterSpacing: 0,
    },
    h2: {
      letterSpacing: 0,
    },
  },
};

export default generalStyles;
