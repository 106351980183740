import React from 'react';
import { Drawer, DrawerProps, ListItemText, ListItemTextProps } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

type ListProps = {
  status?: string;
  onClick?: any;
};
type TextProps = {
  fontWeightMedium?: boolean;
};

export const DrawerContainer = withTheme(
  styled(({ theme, ...otherProps }: DrawerProps & WithTheme) => (
    <Drawer classes={{ paper: 'drawerPaper' }} {...otherProps} />
  ))({
    width: 240,
    flexShrink: 0,
    zIndex: 2,
    '& .drawerPaper': {
      width: 240,
    },
  })
);

export const Logo = styled('div')({
  padding: '15px 0 0 25px',
  '& svg': {
    cursor: 'pointer',
  },
});

export const ListItem = withTheme(
  styled(({ theme, status, ...otherProps }: WithTheme & ListProps) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: 48,
    paddingLeft: 21,
    cursor: 'pointer',
    backgroundColor: ({ status }: ListProps) => (status === 'selected' ? '#F8FAFB' : '#FFFFFF'),
    pointerEvents: ({ status }: ListProps) => (status === 'disabled' ? 'none' : 'auto'),
    '&:hover': {
      backgroundColor: '#F8FAFB',
    },
    '&::before': {
      content: ({ status }: ListProps) => (status === 'selected' ? 'close-quote' : 'none'),
      position: 'absolute',
      height: 'inherit',
      top: 0,
      left: -4,
      width: 8,
      borderRadius: 5,
      backgroundColor: ({ theme }: any) => theme.customPalette.buttons.activeBg,
    },
  })
);

export const ItemText = withTheme(
  styled(
    ({
      theme,
      status,
      fontWeightMedium,
      ...otherProps
    }: ListItemTextProps & WithTheme & ListProps & TextProps) => <ListItemText {...otherProps} />
  )({
    paddingLeft: 15,
    color: ({ status, theme }: any) => {
      switch (status) {
        case 'notVisited':
          return theme.customPalette.text.highlight;
        case 'disabled':
          return theme.customPalette.text.grey;
        default:
          return theme.customPalette.text.primary;
      }
    },
    '& > span': {
      fontWeight: ({ fontWeightMedium }: any) => (fontWeightMedium ? 500 : 'normal'),
      fontSize: '15px',
      lineHeight: '18px',
    },
  })
);

export const ItemIcon = withTheme(
  styled(({ theme, status, ...otherProps }: WithTheme & ListProps) => <div {...otherProps} />)({
    paddingTop: 1,
    '& svg': {
      fill: ({ status, theme }: any) => {
        switch (status) {
          case 'selected':
            return theme.customPalette.text.primary;
          case 'notVisited':
            return theme.customPalette.text.highlight;
          default:
            return theme.customPalette.text.lightGrey;
        }
      },
    },
  })
);

export const Navigator = styled('span')({
  display: 'flex',
});

export const SidebarContainer = styled('div')({
  '& > hr': {
    margin: '10px 0',
  },
});
