import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearNotificationsList, getNotificationsList } from 'src/ducks/notifications/actions';
import Notification from './Notification';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import { NoNotify } from './styles';

const Notifications = (props: any) => {
  const dispatch = useDispatch();
  const { loadMore, notificationsList } = props;
  const currentPage = Number(notificationsList?.currentPage);
  const totalPages = notificationsList?.totalPages;

  useEffect(() => {
    dispatch(getNotificationsList(1));
    setTimeout(() => {
      localStorage.setItem('firstLoad', '1');
    }, 1100);
    return () => {
      dispatch(clearNotificationsList());
    };
  }, [dispatch]);

  const renderNotify = () => (
    <>
      {notificationsList?.list.length ? (
        notificationsList?.list.map((item: any) => {
          return <Notification key={item.id} notification={item} />;
        })
      ) : (
        <NoNotify>No notifications yet!</NoNotify>
      )}
    </>
  );

  return (
    <InfiniteScroll
      id="drawer-notifications"
      pageStart={1}
      threshold={-20}
      useWindow={false}
      loadMore={loadMore}
      hasMore={currentPage < totalPages}
    >
      {renderNotify()}
    </InfiniteScroll>
  );
};

export default Notifications;
