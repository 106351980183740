import {
  GET_HOME_LIST,
  GET_HOME_LIST_SUCCESS,
  GET_HOME_LIST_FAIL,
  CLEAR_HOME_LIST,
} from './constants';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import homeApi from 'src/api/homeApi';
import { S3Image } from 'src/constants/commonTypes';
import { isUserTeacher } from 'src/ducks/user/selectors';
import { GetHomeList } from './actions';

export const moduleName = 'home';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

export type ErrorData = {
  field?: string;
  invalid_value: string;
  message: string;
};

export type Error = {
  error: boolean;
  errors:
    | ErrorData[]
    | {
    title: string;
    message: string;
  };
};

export type Home = {
  id: number;
  position: number;
  dashboard: string;
  name: string;
  description: string;
  url: string;
  blockIcons: S3Image[];
}

export type HomeList = {
  list: Home[];
  bannerText: string;
  currentPage: number;
  totalPages: number;
  totalHome: number;
}

export type StateHomeInterface = {
  homeList: HomeList | null;
  loading: boolean;
  error: Error | null;
}

export const homeState: StateHomeInterface = {
  homeList: null,
  loading: false,
  error: null,
};

export default function reducer(
  state: StateHomeInterface = homeState,
  { type, payload, error }: ActionType,
) {
  switch (type) {
    case GET_HOME_LIST:
      return { ...state, loading: true, error: null };

    case GET_HOME_LIST_SUCCESS:
      const list = state?.homeList?.list;

      return {
        ...state,
        homeList: {
          list: list ? [...list, ...payload.data]: payload.data,
          bannerText: payload.bannerText,
          currentPage: payload._page,
          totalPages: payload._pages,
          totalHome: payload._total,
        },
        loading: false,
        error: null,
      };

    case GET_HOME_LIST_FAIL:
      return { ...state, loading: false, error };

    case CLEAR_HOME_LIST:
      return {...state, homeList: null, error: null}

    default:
      return state;
  }
}

function* getHomePageSaga({ payload: { page, perPage } }: GetHomeList) {
  const isTeacher = yield select(isUserTeacher);
  let response;

  try {
    if (isTeacher) {
      response = yield homeApi.getHomePageTeacher(page, perPage);
    } else {
      response = yield homeApi.getHomePageStudent(page, perPage);
    }

    yield put({ type: GET_HOME_LIST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: GET_HOME_LIST_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(GET_HOME_LIST, getHomePageSaga),
  ]);
};
