import { Facebook, Instagram, Twitter, YouTube } from 'src/components/common/Icons';

export const dataForSocialLinks = [
  {
    link: 'https://www.facebook.com/realworldscholars',
    icon: Facebook,
    external: true,
  },
  {
    link: 'https://twitter.com/RWScholars',
    icon: Twitter,
    external: true,
  },
  {
    link: 'https://www.youtube.com/channel/UCymcuxv6JzyMfZtgqz054fA',
    icon: YouTube,
    external: true,
  },
  {
    link: 'https://www.instagram.com/realworldscholars/',
    icon: Instagram,
    external: true,
  },
];

export const dataForNavLinks = [
  {
    link: 'http://www.realworldscholars.org/',
    text: 'About',
    external: true,
  },
  {
    link: 'mailto:contact@realworldscholars.org',
    text: 'Contact',
    external: true,
  },
];

export const linkForTerms = '/terms-of-service';
