import { AxiosPromise, AxiosResponse } from 'axios';
import { httpService } from 'src/services';

export default {
  getHomePageTeacher: (
    page: number = 1,
    perPage: number = 10
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/home-page/teacher-list?page=${page}&perPage=${perPage}`,
    }),

  getHomePageStudent: (
    page: number = 1,
    perPage: number = 10
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/home-page/student-list?page=${page}&perPage=${perPage}`,
    }),
};
