import React from 'react';
import { InternalLink, ExternalLink } from './styles';

type Props = {
  children: JSX.Element | JSX.Element[];
  link: string;
  group?: boolean;
  external: boolean;
};
const SmartLinkWrapper = ({ children, link, group, external }: Props) => {
  if (link) {
    if (external) {
      return (
        <ExternalLink group={group} href={link} target="_blank">
          {children}
        </ExternalLink>
      );
    }
    return (
      <InternalLink group={group} to={link}>
        {children}
      </InternalLink>
    );
  }
  return <>{children}</>;
};

export default SmartLinkWrapper;
