import React from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { switchPages } from 'src/ducks/auth/actions';
import { Link } from 'react-router-dom';
import { DangerIcon } from 'src/components/common/Icons';
import { WarningWindowContainer, WindowIcon, WindowContent, WindowButton } from './styles';

export type WarningWindowProps = {
  error: string;
};

const WarningWindow = ({ error }: WarningWindowProps) => {
  const dispatch = useDispatch();
  const goBack = () => dispatch(switchPages());
  const linkToForgotPassword = error?.includes('Reset Password');
  return (
    <WarningWindowContainer>
      <WindowIcon>
        <DangerIcon />
      </WindowIcon>
      <WindowContent>{error}</WindowContent>
      {linkToForgotPassword && (
        <WindowButton>
          <Link to="/forgot-password" onClick={goBack}>
            <Typography variant="body2">Reset Password</Typography>
          </Link>
        </WindowButton>
      )}
    </WarningWindowContainer>
  );
};

export default WarningWindow;
