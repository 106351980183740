import { FullName } from 'src/containers/header/Header';
import { format, utcToZonedTime } from 'date-fns-tz';
import deliveryStatuses, { DeliveryStatus } from '../constants/deliveryStatuses';
import { LOCAL_DELIVERY, LOCAL_PICKUP, NON_USPS_SHIPPING, NOT_APPLICABLE, orderShippingMethods, OrderShippingMethodsType, SHIPPING_NOT_AVAILABLE, USPS_DELIVERY, USPS_MULTIPLE, USPS_NON_PRIORITY_MAIL, USPS_PRIORITY_MAIL } from '../constants/orderShippingMethods';
import { ProductProps, ShoppingCartProps } from 'src/ducks/website/website';
import {
  ProductChoice,
  FullShopProduct,
  ProductOptionValue,
} from 'src/ducks/shopProducts/shopProducts';
import vars from '../constants/vars';
import { ChipsT, NamesT, TableDataT, ValidationDataT } from '../constants/productsTypes';
import { FullOrder } from 'src/ducks/orders/orders';

export function getInviteTokenStorage(key: string) {
  return localStorage.getItem(key) || '';
}

export function getItemByKeyFromStorage(key: string) {
  const isExistItem = localStorage.getItem(key);
  return isExistItem ? JSON.parse(isExistItem) : null;
}

export function formatDate(date: any) {
  if (date) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const localDate = utcToZonedTime(date, timeZone);
    return format(localDate, 'MM/dd/yyyy HH:mm', { timeZone });
  }

  return '';
}

export function getUserWebsiteID(websitesArray: any) {
  if (Array.isArray(websitesArray) && websitesArray.length > 0) {
    const { websites }: any = websitesArray[0];
    if (websites.length > 0) {
      return websites[0].id;
    }
  }
}

export function setItemToStorage(key: string, item: {}) {
  if (key && item) {
    localStorage.setItem(key, JSON.stringify(item));
  }
}

export function setByKeyToStorage(key: string, value: string) {
  return localStorage.setItem(key, JSON.stringify(value));
}

export function setUserToStorage(key: string, user: {}) {
  return localStorage.setItem(key, JSON.stringify(user));
}

export function removeItemByKeyStorage(key: string) {
  localStorage.removeItem(key);
}

export const findFirstLetters = (user: FullName): string => {
  const { firstName, lastName, displayName } = user;

  if (displayName) {
    return displayName.slice(0, 2).toUpperCase();
  }
  return `${firstName?.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase()}`;
};

export const findMappedDeliveryStatus = (status: string) => {
  return deliveryStatuses.find(
    (deliveryStatus: DeliveryStatus) => deliveryStatus.status === status
  );
};

export const prepareNextDeliveryStatus = (status: any): DeliveryStatus | null => {
  let newDeliveryStatus: DeliveryStatus | null = null;

  deliveryStatuses.forEach(
    (deliveryStatus: DeliveryStatus, index) => {
      if (deliveryStatus.status === status) {
        newDeliveryStatus = deliveryStatuses[index + 1]
      }
    }
  );

  return newDeliveryStatus
};

export const findMappedShippingMethod = (order: FullOrder): string => {
  let { shippingMethod } = order;

  let methodIndex = prepareUspsDeliveryIndex(order);
  if (methodIndex) return orderShippingMethods[methodIndex];

  return orderShippingMethods[shippingMethod];
};

/** Prepare index in orderShippingMethods for USPS Delivery */
export const prepareUspsDeliveryIndex = (order: FullOrder): string | null => {
  let { shippingMethod } = order;

  if (shippingMethod !== USPS_DELIVERY) return null;

  if (!order.shipping) {
    return USPS_NON_PRIORITY_MAIL
  }

  let hasUspsPriority = false;
  let hasUspsNonPriority = false;

  order.orderedProducts.forEach(product => {
    if (product.shippingType === USPS_PRIORITY_MAIL) {
      hasUspsPriority = true;
    }

    if (product.shippingType === USPS_NON_PRIORITY_MAIL) {
      hasUspsNonPriority = true;
    }
  })

  if (hasUspsPriority && hasUspsNonPriority) return USPS_MULTIPLE;
  if (hasUspsPriority) return USPS_PRIORITY_MAIL;
  if (hasUspsNonPriority) return USPS_NON_PRIORITY_MAIL;

  return null;
};

export const getDateElements = (date: string) => {
  const values = date.split(/[^0-9]/);
  const year = parseInt(values[0], 10);
  const month = parseInt(values[1], 10) - 1;
  const day = parseInt(values[2], 10);
  const hours = parseInt(values[3], 10);
  const minutes = parseInt(values[4], 10);
  const seconds = parseInt(values[5], 10);

  return { year, month, day, hours, minutes, seconds };
};

export function removeExpirationShoppingCart(key: string) {
  const date = getItemByKeyFromStorage('_expiresShoppingCart');
  if (!date) return;
  if (date.time < new Date()) {
    localStorage.removeItem(key);
    localStorage.removeItem('shippingMethod');
  }
}

export function setExpirationTimeShoppingCart(key: string, day: number) {
  const currentDate = new Date();
  const expireDay = currentDate.getTime() + day * 24 * 60 * 60 * 1000; //7 days
  setItemToStorage(key, { time: expireDay });
}

export function setProductToShoppingCart(
  key: string,
  product: ProductProps,
  productAmount: number,
  productChoice?: ProductChoice
) {
  const existShoppingCart = getItemByKeyFromStorage(key);
  removeExpirationShoppingCart(key);
  setExpirationTimeShoppingCart('_expiresShoppingCart', 7);
  const updatedProduct = {
    ...product,
    price: productChoice ? productChoice.price : product.price,
    isPriceChanged: false,
    externalPrice: productChoice ? productChoice.price : product.price,
    isInventoryChanged: false,
    externalInventory: productChoice ? productChoice.inventory : product.inventory,
  };

  if (!existShoppingCart || !existShoppingCart.length) {
    const shoppingCart = [];
    shoppingCart.push({
      count: productAmount,
      product: updatedProduct,
      productChoice,
    });
    return setItemToStorage(key, shoppingCart);
  }
  if (existShoppingCart && existShoppingCart.length > 0) {
    const existProduct = existShoppingCart.find((item: ShoppingCartProps) => {
      if (item.product.id === updatedProduct.id) {
        if (productChoice?.id) {
          if (item?.productChoice.id === productChoice.id) {
            return item;
          } else {
            return;
          }
        }
        return item;
      }
      return null;
    });

    if (existProduct) {
      if (existProduct.productChoice && productChoice?.id) {
        if (existProduct.productChoice.id === productChoice.id) {
          existProduct.count += productAmount;

          const findIndex = existShoppingCart.findIndex(
            (item: ShoppingCartProps) =>
              item.product.id === updatedProduct.id && item.productChoice.id === productChoice.id
          );
          existShoppingCart.splice(findIndex, 1, existProduct);
          return setItemToStorage(key, existShoppingCart);
        }
      } else {
        existProduct.count += productAmount;

        const findIndex = existShoppingCart.findIndex(
          (item: ShoppingCartProps) => item.product.id === updatedProduct.id
        );
        existShoppingCart.splice(findIndex, 1, existProduct);
        return setItemToStorage(key, existShoppingCart);
      }
    }
    existShoppingCart.push({
      count: productAmount,
      productChoice,
      product: updatedProduct,
    });
    return setItemToStorage(key, existShoppingCart);
  }
}

export const calculateFullProductsAmount = () => {
  const shoppingCart = getItemByKeyFromStorage('shoppingCart');

  if (shoppingCart) {
    return shoppingCart.reduce((acc: number, currVal: ShoppingCartProps) => acc + currVal.count, 0);
  }
  return 0;
};

export const increaseProductAmount = (
  productId: number,
  externalInventory: number,
  productChoiceId?: number
) => {
  const products = getItemByKeyFromStorage('shoppingCart');
  const updatedProducts = products.reduce(
    (acc: ShoppingCartProps[], { count, product, productChoice }: ShoppingCartProps) => {
      if (product.id === productId && productChoice?.id === productChoiceId) {
        if (product.isPhysicalProduct) {
          if (count < externalInventory) {
            count += 1;
          }
        } else {
          count += 1;
        }
      }

      acc.push({ product, count, productChoice });
      return acc;
    },
    []
  );

  setItemToStorage('shoppingCart', updatedProducts);
  return updatedProducts;
};

export const decreaseProductAmount = (productId: number, productChoiceId?: number) => {
  const products = getItemByKeyFromStorage('shoppingCart');
  const updatedProducts = products.reduce(
    (acc: ShoppingCartProps[], { count, product, productChoice }: ShoppingCartProps) => {
      if (product.id === productId && productChoice?.id === productChoiceId) {
        if (count !== 1) {
          count -= 1;
        }
      }

      acc.push({ product, count, productChoice });
      return acc;
    },
    []
  );

  setItemToStorage('shoppingCart', updatedProducts);
  return updatedProducts;
};

export const removeProduct = (productId: number, productChoiceId?: number) => {
  const products = getItemByKeyFromStorage('shoppingCart');
  const foundIndex = products.findIndex(
    ({ product, productChoice }: ShoppingCartProps) =>
      product.id === productId && productChoice?.id === productChoiceId
  );
  products.splice(foundIndex, 1);

  setItemToStorage('shoppingCart', products);

  if (!products.length) {
    removeItemByKeyStorage('shippingMethod');
  }

  return products;
};

export type LocalStorageProduct = {
  isPriceChanged: boolean;
  externalPrice: number;
  isInventoryChanged: boolean;
  externalInventory: number;
} & ProductProps;

export type LocalStorageType = {
  count: number;
  product: LocalStorageProduct;
  productChoice: ProductChoice;
};

export const hasExternalProductsChanged = (
  currentProduct: ShoppingCartProps,
  externalProducts?: FullShopProduct[]
) => {
  const products = getItemByKeyFromStorage('shoppingCart');

  const externalProduct = externalProducts?.find(
    ({ id }: FullShopProduct) => id === currentProduct.product.id
  );

  const updatedProducts = products.reduce(
    (acc: LocalStorageType[], { count, product, productChoice }: LocalStorageType) => {
      if (product.id === currentProduct.product.id) {
        if (externalProduct) {
          if (!externalProduct?.productChoices.length && !productChoice) {
            product.externalPrice = externalProduct.price;
            product.isPriceChanged = product.price !== externalProduct.price;
            product.externalInventory = externalProduct.inventory;
            product.isInventoryChanged =
              !!product?.isPhysicalProduct && count !== externalProduct.inventory;
          } else if (
            (!externalProduct?.productChoices.length && productChoice) ||
            (externalProduct?.productChoices.length && !productChoice)
          ) {
            product.externalPrice = externalProduct.price;
            product.isPriceChanged = product.price !== externalProduct.price;
            product.externalInventory = 0;
            product.isInventoryChanged = !!product?.isPhysicalProduct && true;
          } else if (externalProduct?.productChoices.length && productChoice) {
            if (productChoice.id === currentProduct.productChoice.id) {
              const externalProductWithCurrentChoice = externalProduct.productChoices.find(
                ({ id }: ProductChoice) => id === currentProduct.productChoice.id
              );

              if (!externalProductWithCurrentChoice) {
                product.isInventoryChanged = !!product?.isPhysicalProduct && true;
                productChoice.inventory = 0;
                product.externalInventory = 0;
              } else {
                product.externalPrice = externalProductWithCurrentChoice.price;
                product.isPriceChanged =
                  productChoice.price !== externalProductWithCurrentChoice.price;
                product.externalInventory = externalProductWithCurrentChoice.inventory;
                product.isInventoryChanged =
                  !!product?.isPhysicalProduct &&
                  count !== externalProductWithCurrentChoice.inventory;
                productChoice.productOptionValues =
                  externalProductWithCurrentChoice.productOptionValues;
              }
            }
          }

          product.name = externalProduct.name;
          product.imagesGallery = externalProduct.imagesGallery;
          product.shippingType = externalProduct.shippingType;
          product.isDigitalProduct = externalProduct.isDigitalProduct;
          product.isLocalDelivery = externalProduct.isLocalDelivery;
          product.isLocalPickup = externalProduct.isLocalPickup;
          product.isPhysicalProduct = externalProduct.isPhysicalProduct;
        } else {
          product.externalInventory = 0;
          product.isInventoryChanged = !!product?.isPhysicalProduct && true;
        }
      }

      acc.push({ product, count, productChoice });
      return acc;
    },
    []
  );

  setItemToStorage('shoppingCart', updatedProducts);
  return updatedProducts;
};

export const isValidProductsAtCart = (
  externalProducts: FullShopProduct[],
  isCheckout?: boolean
) => {
  const products: LocalStorageType[] = getItemByKeyFromStorage('shoppingCart');
  const shippingMethod: string = getItemByKeyFromStorage('shippingMethod');
  const invalidProductsIds: Number[] = [];
  let isValidProducts = false;

  if (!products) {
    return { isValidProducts, invalidProductsIds };
  }

  const externalProductsIds = externalProducts.map(({ id }: FullShopProduct) => id);
  const productsIds = [...new Set(products.map(({ product }: LocalStorageType) => product.id))];

  if (isCheckout && externalProductsIds.length === productsIds.length) {
    const isMatchingMethods = matchingMethods(externalProducts, shippingMethod);

    if (!isMatchingMethods) {
      return { isValidProducts: isMatchingMethods, invalidProductsIds };
    }
    isValidProducts = isMatchingMethods;
  }

  if (externalProductsIds.length !== productsIds.length) {
    products.forEach(({ product }: LocalStorageType) => {
      if (!externalProductsIds.includes(product.id)) {
        isValidProducts = false;
        invalidProductsIds.push(product.id);
      } else {
        if (isCheckout) {
          const filterExternalProducts = externalProducts.filter(
            (externalProduct: FullShopProduct) => {
              if (externalProduct.id === product.id) {
                return product;
              }
            }
          );
          isValidProducts = matchingMethods(filterExternalProducts, shippingMethod);
        } else {
          isValidProducts = true;
        }
      }
    });
    return { isValidProducts, invalidProductsIds };
  }

  isValidProducts = externalProducts.every((externalProducts: FullShopProduct) => {
    const { id, inventory, price, hasOptions, productChoices } = externalProducts;
    const externalProductChoicesIds = productChoices.map(({ id }: ProductChoice) => id);

    if (hasOptions) {
      // find product and validate price and inventory for product with custom fields
      return productChoices.every(
        ({
          id: productChoiceId,
          price: productChoicePrice,
          inventory: productChoiceInventory,
        }: ProductChoice) => {
          let isValidProductChoice = true;

          const foundProduct = products.find((currentProduct: LocalStorageType) => {
            if (
              currentProduct?.productChoice?.id &&
              currentProduct?.productChoice?.id === id &&
              !externalProductChoicesIds.includes(currentProduct.productChoice?.id)
            ) {
              isValidProductChoice = false;
              invalidProductsIds.push(currentProduct.product.id);
              return null;
            }

            if (
              currentProduct.product.id === id &&
              currentProduct?.productChoice?.id === productChoiceId
            ) {
              return currentProduct;
            }
            return null;
          });

          if (foundProduct) {
            if (foundProduct?.productChoice?.price !== productChoicePrice) {
              invalidProductsIds.push(foundProduct.product.id);
            }

            if (foundProduct.product.isPhysicalProduct) {
              if (productChoiceInventory >= foundProduct.count) {
                isValidProductChoice = true;
              } else {
                invalidProductsIds.push(foundProduct.product.id);
                isValidProductChoice = false;
              }
            }
          }
          return isValidProductChoice;
        }
      );
    } else {
      // find product and validate price and inventory for product without custom fields
      const foundProduct = products.find(({ product }: LocalStorageType) => product.id === id);

      if (foundProduct) {
        if (foundProduct.product.price !== price) {
          invalidProductsIds.push(foundProduct.product.id);
        }

        if (foundProduct.product.isPhysicalProduct) {
          if (inventory >= foundProduct.count) {
            return true;
          } else {
            invalidProductsIds.push(foundProduct.product.id);
            return false;
          }
        }
      }
    }
    return true;
  });
  const uniqArray = [...new Set(invalidProductsIds)];

  return { isValidProducts, invalidProductsIds: uniqArray };
};

export const calculateSubtotalPrice = (
  products: LocalStorageType[],
  externalProducts?: FullShopProduct[]
) => {
  const subTotal = products.reduce((acc: number, currVal: LocalStorageType) => {
    const {
      count,
      product: { id: currentProductId, isPhysicalProduct },
      productChoice,
    } = currVal;

    if (externalProducts) {
      const foundExternalProduct = externalProducts.find(
        ({ id }: FullShopProduct) => id === currentProductId
      );

      if (foundExternalProduct && (foundExternalProduct.inventory !== 0 || !isPhysicalProduct)) {
        const foundExternalProductChoice = foundExternalProduct?.productChoices.find(
          ({ id }: ProductChoice) => id === productChoice?.id
        );
        if (foundExternalProductChoice) {
          return acc + foundExternalProductChoice.price * count;
        }

        return acc + foundExternalProduct.price * count;
      }
    }
    return acc;
  }, 0);

  return subTotal;
};

export type NormalizedProduct = {
  product: number;
  quantity: number;
  price: number;
};

export const normalizeProductsToOrder = (): NormalizedProduct[] => {
  const products = getItemByKeyFromStorage('shoppingCart');

  return products?.map(({ count, product, productChoice }: LocalStorageType) => {
    const productChoiceValueNames = productChoice?.productOptionValues.map(
      ({ name }: ProductOptionValue) => name
    );
    return {
      product: product.id,
      quantity: count,
      price: product.externalPrice,
      productChoice: productChoice?.id,
      productChoiceValueNames: productChoice ? productChoiceValueNames : undefined,
    };
  });
};

export const mapOrderBox = (boxName: string) => {
  const FLAT_RATE_ENVELOPE = 'FLAT RATE ENVELOPE';
  const SM_FLAT_RATE_BOX = 'SM FLAT RATE BOX';
  const MD_FLAT_RATE_BOX = 'MD FLAT RATE BOX';
  const LG_FLAT_RATE_BOX = 'LG FLAT RATE BOX';

  if (boxName === FLAT_RATE_ENVELOPE) {
    return 'Priority Mail Flat Rate Envelope - EP14F';
  } else if (boxName === SM_FLAT_RATE_BOX) {
    return 'Priority Mail Small Flat Rate Box';
  } else if (boxName === MD_FLAT_RATE_BOX) {
    return 'Priority Mail Medium Flat Rate Box';
  } else if (boxName === LG_FLAT_RATE_BOX) {
    return 'Priority Mail Large Flat Rate Box';
  }
};

export const cleanTableDataOneChip = (chip: string, number: number, tableDataFormValues: any) => {
  const currentName: string = number === 1 ? `${chip},` : `,${chip}`;
  const objForDel =
    tableDataFormValues &&
    Object.keys(tableDataFormValues).find(
      (name: string) => name.includes(currentName) || name === chip
    );
  return tableDataFormValues && objForDel ? objForDel : [];
};

export const getDataWithCustomField = (
  chips: ChipsT,
  names: NamesT,
  tableDataFormValues: TableDataT,
  resArr: string[]
) => {
  const chipQuantity =
    resArr.length > 0 ? Object.values(chips).filter((chipArr: string[]) => chipArr.length > 0) : [];
  const nameQuantity =
    resArr.length > 0 ? Object.values(names).filter((name: string | undefined) => name) : [];
  const data: ValidationDataT = {
    chipArr: { ...chipQuantity },
    namesArr: { ...nameQuantity },
  };

  const productOptionsArr = chipQuantity.map((quantityElement: string[], i: number) => {
    return {
      name: data.namesArr[i],
      productOptionValues: {
        ...data.chipArr[i].map((chip: string) => {
          return {
            name: chip,
          };
        }),
      },
    };
  });

  const productChoicesArr = resArr.map((name: string) => {
    return {
      ...vars.defaultObjForProductChoices,
      ...tableDataFormValues[name],
    };
  });

  return {
    productOptions: { ...productOptionsArr },
    productChoices: { ...productChoicesArr },
  };
};

export const isInternalDomain = (url: string) => /\bedcorps/.test(url);

export const availableShippingMethods = (products?: any, isExternal: boolean = false): Array<string> => {
  const shippingMethods = new Set<string>();
  let shopProducts;

  if (isExternal) {
    shopProducts = products;
  } else {
    shopProducts = products?.map(({ product }: LocalStorageType) => ({ ...product }));
  }

  shopProducts?.forEach((product: LocalStorageProduct) => {
    if (product.isPhysicalProduct) {
      const isUspsDelivery = product.shippingType === USPS_PRIORITY_MAIL || product.shippingType === USPS_NON_PRIORITY_MAIL;
      if (isUspsDelivery) {
        shippingMethods.add(USPS_DELIVERY);
      }
      if (product.shippingType === NON_USPS_SHIPPING) {
        shippingMethods.add(NON_USPS_SHIPPING);
      }
      if (
        (product.shippingType === SHIPPING_NOT_AVAILABLE)
        && !(product.isLocalDelivery || product.isLocalPickup)
      ) {
        shippingMethods.add(SHIPPING_NOT_AVAILABLE);
      }
      if (product.isLocalDelivery) {
        shippingMethods.add(LOCAL_DELIVERY);
      }
      if (product.isLocalPickup) {
        shippingMethods.add(LOCAL_PICKUP);
      }
    } else {
      shippingMethods.add(NOT_APPLICABLE);
    }
  });

  return [...shippingMethods];
};

export const matchingMethods = (
  externalProducts?: FullShopProduct[] | LocalStorageProduct[],
  shippingMethod?: string | null
) => {
  let shoppingCart = getItemByKeyFromStorage('shoppingCart');
  const currentMethodsByProducts: (boolean | undefined)[] = [];

  if (externalProducts?.length) {
    shoppingCart = externalProducts;
  } else {
    shoppingCart = shoppingCart?.map(({ product }: ShoppingCartProps) => ({ ...product }));
  }

  if (shippingMethod) {
    shoppingCart.forEach(
      ({
        isLocalDelivery,
        isLocalPickup,
        shippingType,
        isPhysicalProduct,
      }: FullShopProduct | LocalStorageProduct) => {
        if (shippingMethod === USPS_DELIVERY) {
          currentMethodsByProducts.push(shippingType === USPS_PRIORITY_MAIL || shippingType === USPS_NON_PRIORITY_MAIL);
        }
        if (shippingMethod === NON_USPS_SHIPPING) {
          currentMethodsByProducts.push(shippingType === NON_USPS_SHIPPING);
        }
        if (shippingMethod === SHIPPING_NOT_AVAILABLE) {
          currentMethodsByProducts.push(shippingType === SHIPPING_NOT_AVAILABLE);
        }
        if (shippingMethod === LOCAL_DELIVERY) {
          currentMethodsByProducts.push(isLocalDelivery);
        }
        if (shippingMethod === LOCAL_PICKUP) {
          currentMethodsByProducts.push(isLocalPickup);
        }
        if (shippingMethod === NOT_APPLICABLE) {
          currentMethodsByProducts.push(isPhysicalProduct);
        }
      }
    );

    const isMatchingMethods = !currentMethodsByProducts.some((method) => !method);

    if (isMatchingMethods) {
      return isMatchingMethods;
    } else {
      const isChangedMethods = currentMethodsByProducts.every((method) => !method);
      return isChangedMethods;
    }
  }
  return false;
};

export const prepareIsExistShippingMethod = (
  shippingMethod: string | null,
  availableMethods: string[],
  product: LocalStorageProduct,
): boolean => {
  if (shippingMethod === null) return false;

  if (shippingMethod === SHIPPING_NOT_AVAILABLE
    && product.shippingType === SHIPPING_NOT_AVAILABLE
    && (availableMethods.includes(LOCAL_DELIVERY) || availableMethods.includes(LOCAL_PICKUP))
  ) {
    return true;
  }

  return availableMethods.includes(shippingMethod)
}