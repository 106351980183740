import { styled } from '@material-ui/core/styles';

export const Image = styled('img')({
  height: 55,
  objectFit: 'contain',
  width: 65,
  cursor: 'default',
});

export const Img = styled('div')({
  alignItems: 'center',
  display: 'flex',
  background: '#e0e2e3',
  borderRadius: 10,
  height: 125,
  justifyContent: 'center',
  width: 125,
});

export const IconHover = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  width: 40,
});
