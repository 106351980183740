import React from 'react';
import { styled } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';


export const DatePickerInputWrapper = styled('div')({
    width: '100%',
    maxWidth: 230,
})

export const styles = (theme: Theme) => ({
    input: {
      width: '100%',
      maxWidth: 200,
      fontSize: 15,
      backgroundColor: theme.customPalette.background.inputWhiteBg,
      border: `1px solid ${theme.customPalette.borders.select}`,
      color: theme.customPalette.text.primary,
      borderRadius: 4,
      padding: '12px 14px',
      '&:focus-visible': {
          outline: 'none',
          border: '1px solid #008DBA',
      },
      '&:hover': {
        background: '#EAEDF0',
      },
    },
});