import React from 'react';
import { Remove, CurrentImg, Container } from './styles';
import { INCORRECT_MIN_HEIGHT, INCORRECT_MIN_WIDTH } from 'src/constants/formValidations';
import CloseIcon from '@material-ui/icons/Close';
import UploadText from './uploadText/UploadText';
import { useImageUpload } from 'src/utils/useImageUpload';
import { PRODUCT_IMAGE_SIZE } from '../../profile/avatarEditor/styles';

export interface IImageFile extends File {
  src?: string | ArrayBuffer | null,
  id?: string,
}

export interface IFile extends File {
  url: string,
  id: string,
  isRemoved?: boolean,
}

type Props = {
  currentImage?: any;
  handleChangeImage?: any;
  handleChangeCurrentImage?: any;
  handleChangeErrors?: any;
  isDisabled?: boolean;
  setModalOpen: (isOpen: boolean) => void;
};

const PosterImage = ({
  currentImage,
  handleChangeImage,
  handleChangeCurrentImage,
  handleChangeErrors,
  isDisabled,
  setModalOpen,
}: Props) => {
  const afterLoadValidation = (src: IImageFile['src']) => {
    return new Promise((resolve, reject) => {
      const img: any = new Image();
      img.src = src;

      img.onload = () => {
        if (img.height < PRODUCT_IMAGE_SIZE) {
          handleChangeImage(null);
          handleChangeErrors(INCORRECT_MIN_HEIGHT);
          return reject;
        }
  
        if (img.width < PRODUCT_IMAGE_SIZE) {
          handleChangeImage(null);
          handleChangeErrors(INCORRECT_MIN_WIDTH);
          return reject;
        }

        return resolve(true)
      }

      img.onerror = reject
    })
  };

  const onImageDataAvailable = (imageData: string) => {   
    handleChangeImage(imageData);
    handleChangeErrors(null);
   
    setModalOpen(true);
  };

  const onError = (error: string) => {
    handleChangeErrors(error);
  };

  const { triggerFileUpload, LoadImageInput } = useImageUpload(onImageDataAvailable, onError, afterLoadValidation);

  return (
    <Container onClick={triggerFileUpload}>
      {(currentImage?.id || currentImage?.url) && (
        <CurrentImg
          style={{
            backgroundImage: `url(${currentImage.url || URL.createObjectURL(currentImage)})`,
          }}
        >
          <Remove
            onClick={(e) => {
              e.stopPropagation();
              handleChangeCurrentImage({});
            }}
          >
            <CloseIcon />
          </Remove>
        </CurrentImg>
      )}
      {currentImage.id === undefined && <UploadText />}
      <LoadImageInput 
        disabled={currentImage?.id >= 0 || isDisabled}
      />
    </Container>
  );
};

export default PosterImage;
