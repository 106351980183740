import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ProtectedRoute from 'src/routes/ProtectedRoute';
import DisabledForAuthorizedUserRoute from 'src/routes/DisabledForAuthorizedUserRoute';
import Signin from 'src/components/pages/auth/signin';
import Signup from 'src/components/pages/auth/signup';
import ForgotPassword from 'src/components/pages/auth/forgotPassword';
import NewPassword from 'src/components/pages/auth/newPassword';
import { FormCreateAccountProps } from 'src/components/pages/auth/signup/FormCreateAccount';
import {
  signinAsTeacher,
  getAccessToken,
  forgotPassword,
  resetPassword,
} from 'src/ducks/auth/actions';
import Auth from 'src/components/pages/auth/Auth';
import routesPath from 'src/routes/config';

export type FormProps = {
  email: string;
  password: string;
};
export type NewPasswordProps = {
  first: string;
  second: string;
};

export type ResetPassword = {
  token: string;
  password: {
    first: string;
    second: string;
  };
};

const AuthPage = () => {
  const dispatch = useDispatch();
  const [values, setData] = useState({});
  const { id: recoveryToken } = useParams();
  const pathname = window.location.pathname;
  const isRouteSignUp = pathname.includes('signup');
  const isRouteFogotPassword = pathname.includes('forgot-password');
  const isRouteResetPassword = pathname.includes('reset-password');
  const isChangePassword = isRouteFogotPassword || isRouteResetPassword;

  const getCurrentTitle = () => {
    if (values && Object.keys(values).length) {
      return 'School Information';
    } else {
      if (isRouteFogotPassword) {
        return 'Forgot Password?';
      } else if (isRouteResetPassword) {
        return 'Enter New Password';
      } else if (pathname === routesPath.signin) {
        return 'Log In';
      } else {
        return 'Create Account';
      }
    }
  };

  const title = getCurrentTitle();
  useEffect(() => {
    if (isRouteSignUp && title === 'Create Account') {
      dispatch(getAccessToken());
    }
  });
  const handleSignIn = (values: FormProps) => {
    dispatch(signinAsTeacher(values));
  };
  const handleForgotPassword = (values: FormProps) => {
    dispatch(forgotPassword(values));
  };
  const updatePassword = (values: NewPasswordProps) => {
    const data = {
      token: recoveryToken,
      password: values,
    };
    dispatch(resetPassword(data));
  };
  const handleSubmitNext = (values: FormCreateAccountProps) => setData(values);

  return (
    <ProtectedRoute>
      <Auth text={title} style={isChangePassword}>
        <DisabledForAuthorizedUserRoute
          exact
          path={routesPath.signin}
          render={(props: any) => <Signin onSubmit={handleSignIn} {...props} />}
        />
        <DisabledForAuthorizedUserRoute
          exact
          path={routesPath.signup}
          render={(props: any) => <Signup data={values} handler={handleSubmitNext} {...props} />}
        />
        <DisabledForAuthorizedUserRoute
          exact
          path={routesPath.forgotPassword}
          render={(props: any) => <ForgotPassword onSubmit={handleForgotPassword} {...props} />}
        />
        <DisabledForAuthorizedUserRoute
          path={routesPath.resetPassword}
          render={(props: any) => <NewPassword onSubmit={updatePassword} {...props} />}
        />
      </Auth>
    </ProtectedRoute>
  );
};

export default AuthPage;
