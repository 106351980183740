import React, { MouseEvent } from 'react';
//ignore @types for lib
// @ts-ignore
import Slider from 'react-slick';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { WebsiteContainer as TemplateContainer } from 'src/components/pages/websiteTemplates/websiteTemplate1/styles';
import {
  HeroImageBackgroundProps,
  ContactUsProps,
  AddToCartButtonProps,
  ImageContainerProps,
} from 'src/components/pages/websiteTemplates/websiteTemplate1/styles';

type ShopNowContainerProps = {
  href?: string;
  onClick?: any;
};

type ButtonProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const WebsiteContainer = TemplateContainer;

export const WebsiteNameContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    padding: '50px 80px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 38,
    fontWeight: 600,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
    '& > a': {
      textDecoration: 'none',
      color: 'black',
    },
    '@media (max-width: 768px)': {
      padding: '30px 40px',
    },
  })
);

export const WebsiteName = styled('div')({
  fontSize: 35,
  textTransform: 'capitalize',
  wordBreak: 'break-word',
  color: '#15232E',
  '@media (max-width: 768px)': {
    textAlign: 'center',
  },
});

export const CartContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  '& > div': {
    fontSize: 24,
    position: 'absolute',
    paddingTop: 6,
  },
});

export const HeroImageContainer = styled('div')({
  height: 720,
  '@media (max-width: 768px)': {
    height: 500,
  },
});

export const HeroImageBackground = styled(
  ({ heroImageBackground, ...otherProps }: HeroImageBackgroundProps) => <div {...otherProps} />
)({
  backgroundImage: ({ heroImageBackground }: HeroImageBackgroundProps) =>
    `url(${heroImageBackground})`,
  width: '100%',
  height: 720,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  '@media (max-width: 768px)': {
    height: 500,
  },
});

export const TaglineContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    position: 'relative',
    height: '100%',
    margin: 'auto',
    width: '70%',
    maxWidth: 800,
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.dark1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
  })
)

export const TaglineNme = styled('div')({
  wordBreak: 'break-word',
  textAlign: 'center',
  fontSize: 77,
  fontWeight: 800,
  '@media (max-width: 768px)': {
    fontSize: 55,
  },
});

export const ShopNowContainer = styled('a')({
  width: '100%',
  maxWidth: 580,
  marginTop: 90,
});

export const ShopNowButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ShopNowContainerProps) => (
    <button {...otherProps} />
  ))({
    width: '100%',
    border: 'none',
    padding: '20px 130px',
    fontSize: 26,
    fontWeight: 600,
    cursor: 'pointer',
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtn,
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtnHover,
      boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.whiteBtn,
    },
    '&:focus': {
      outline: 'none',
    },
    '@media (max-width: 768px)': {
      fontSize: 30,
      padding: '20px 100px',
    },
  })
);

export const PriceAndAddToCartContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    paddingTop: 20,
  },
  '@media (max-width: 650px)': {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
});

export const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
});

export const ImageContainer = withTheme(
  styled(({ theme, isOutOfStock, ...otherProps }: WithTheme & ImageContainerProps) => (
    <div {...otherProps} />
  ))({
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '35%',
    cursor: ({ isOutOfStock }: ImageContainerProps) => isOutOfStock ? 'default' : 'pointer',
    '& > img': {
      width: '100%',
      height: 'auto',
      opacity: ({ isOutOfStock }: ImageContainerProps) => (isOutOfStock ? 0.4 : 1),
    },
  })
);

export const OutOfStock = styled('div')({
  position: 'absolute',
  textAlign: 'center',
  maxWidth: 350,
  color: '#15232E',
  fontSize: 30,
  fontWeight: 500,
});

export const CarouselWrapper = withTheme(
  styled(({ theme, ...otherProps }: any) => <Slider {...otherProps} />)({
    borderRadius: 8,
    overflow: 'visible',
    marginRight: 25,
    width: '35%',
    filter:
      'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-2px 4px 20px rgba(68, 86, 108, 0.16))',
    '& .slick-prev': {
      left: '-30px !important',
      width: 'auto',
      height: 'auto',
    },
    '& .slick-next': {
      right: '-30px !important',
      width: 'auto',
      height: 'auto',
    },
    '& button': {
      opacity: 0,
      color: '#44566C',
      zIndex: 100,
      '&::before': {
        fontSize: 45,
      },
      '&:hover': {
        opacity: '1 !important',
      },
    },
    '& .CarouselItem ~ div': {
      '&:nth-child(2)': {
        right: -32,
      },
      '&:nth-child(3)': {
        left: -32,
      },
      '&:hover': {
        '& button': {
          opacity: 1,
        },
      },
    },
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  })
);

export const CardInfo = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    flex: 1,
    wordBreak: 'break-word',
    '& > a': {
      textDecoration: 'none',
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }
  })
);

export const CardName = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    marginBottom: 10,
    fontWeight: 700,
    fontSize: 23,  
  })
);

export const FreeShipping = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontWeight: 500,
    fontSize: 17,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.success,
    textTransform: 'capitalize',
    marginBottom: 10,
  })
);

export const CardDescription = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontFamily: 'Open Sans',
    fontSize: 17,
    fontWeight: 400,
    wordBreak: 'break-word',
    marginBottom: 10,
    flex: 1,
    '@media (max-width: 768px)': {
      fontSize: 14,
    },
  })
);

export const Price = styled('div')({
  fontWeight: 700,
  fontSize: 23,
  alignSelf: 'flex-end',
});


export const AddToCartButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & AddToCartButtonProps) => (
    <button {...otherProps} />
  ))({
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 0',
    fontSize: 24,
    fontWeight: 500,
    cursor: 'pointer',
    width: 210,
    color: ({ theme }: WithTheme) => theme.customPalette.text.active,
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.dark1,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      cursor: 'unset',
      opacity: 0.5,
    },
    '& > svg': {
      width: 24,
      height: 29.5,
      paddingRight: 20,
    },
  })
);

export const HorizontalLine = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    borderTop: ({ theme }: WithTheme) => ` 3px solid ${theme.siteTemplatePalette.text.primary}`,
    marginTop: 30,
  })
);

export const LoadMoreContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    maxWidth: 1200,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '50px',
  })
);

export const LoadMoreButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ButtonProps) => <button {...otherProps} />)
    ({
      minWidth: 200,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
      border: 'none',
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'capitalize',
      cursor: 'pointer',
    })
);

export const IconWrapper = styled('svg')({
  fill: 'black',
  width: 22,
  height: 15,
  marginLeft: 15,
});


export const Line = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    borderTop: ({ theme }: WithTheme) => `1px solid ${theme.siteTemplatePalette.text.secondary}`,
  })
);

export const AboutUsContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    textAlign: 'center',
    maxWidth: 1200,
    width: '80%',
    margin: '0 auto',
    padding: '100px 0 100px 0',
    '@media (max-width: 768px)': {
      padding: '60px 0 60px 0',
    },
  })
);

export const AboutUsTitle = styled('div')({
  fontSize: 80,
  fontWeight: 800,
  '@media (max-width: 768px)': {
    fontSize: 50,
  },
});

export const AboutUsDescription = styled('div')({
  fontFamily: 'Open Sans',
  fontSize: 33,
  fontWeight: 400,
  paddingTop: 30,
  wordBreak: 'break-word',
  '@media (max-width: 768px)': {
    fontSize: 26,
  },
});

export const FooterContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 290,
    marginTop: 50,
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.dark1,
    textAlign: 'center',
  })
);

export const ContactUsButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ContactUsProps) => <input {...otherProps} />)({
    width: 300,
    height: 50,
    border: 'none',
    marginTop: 50,
    marginBottom: 40,
    padding: '10px 0 10px 0',
    fontSize: 24,
    fontWeight: 500,
    cursor: 'pointer',
    color: '#000000',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.header,
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtnHover,
      boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.whiteBtn,
    },
  })
);

export const LearnMore = styled('a')({
  fontSize: 24,
  color: '#FFFFFF',
});
