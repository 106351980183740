import { moduleName } from './auth';

export const APP_INITIALIZER = `${moduleName}/APP_INITIALIZER`;
export const APP_INITIALIZER_FAIL = `${moduleName}/APP_INITIALIZER_FAIL`;

export const GET_ACCESS_TOKEN = `${moduleName}/GET_ACCESS_TOKEN`;
export const GET_ACCESS_TOKEN_SUCCESS = `${moduleName}/GET_ACCESS_TOKEN_SUCCESS`;
export const GET_ACCESS_TOKEN_FAIL = `${moduleName}/GET_ACCESS_TOKEN_FAIL`;

export const SIGN_IN_AS_TEACHER = `${moduleName}/SIGN_IN_AS_TEACHER`;
export const SIGN_IN_AS_TEACHER_SUCCESS = `${moduleName}/SIGN_IN_AS_TEACHER_SUCCESS`;
export const SIGN_IN_AS_TEACHER_FAIL = `${moduleName}/SIGN_IN_AS_TEACHER_FAIL`;

export const FORGOT_PASSWORD = `${moduleName}/FORGOT_PASSWORD`;
export const FORGOT_PASSWORD_AS_TEACHER_SUCCESS = `${moduleName}/FORGOT_PASSWORD_AS_TEACHER_SUCCESS`;
export const FORGOT_PASSWORD_AS_TEACHER_FAIL = `${moduleName}/FORGOT_PASSWORD_AS_TEACHER_FAIL`;

export const CHECK_RECOVERY_TOKEN = `${moduleName}/CHECK_RECOVERY_TOKEN`;
export const CHECK_RECOVERY_TOKEN_SUCCESS = `${moduleName}/CHECK_RECOVERY_TOKEN_SUCCESS`;
export const CHECK_RECOVERY_TOKEN_FAIL = `${moduleName}/CHECK_RECOVERY_TOKEN_FAIL`;

export const RESET_PASSWORD = `${moduleName}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${moduleName}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${moduleName}/RESET_PASSWORD_FAIL`;

export const SET_ROLE_AS_STUDENT = `${moduleName}/SET_ROLE_AS_STUDENT`;
export const SET_ROLE_AS_TEACHER = `${moduleName}/SET_ROLE_AS_TEACHER`;

export const SWITCH_PAGES = `${moduleName}/SWITCH_PAGES`;

export const GET_TEACHER_EMAIL_FOR_SIGN_UP = `${moduleName}/GET_TEACHER_EMAIL_FOR_SIGN_UP`;
export const GET_TEACHER_EMAIL_FOR_SIGN_UP_SUCCESS = `${moduleName}/GET_TEACHER_EMAIL_FOR_SIGN_UP_SUCCESS`;
export const GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL = `${moduleName}/GET_TEACHER_EMAIL_FOR_SIGN_UP_FAIL`;
export const SIGN_UP_TEACHER = `${moduleName}/SIGN_UP_TEACHER`;
export const SIGN_UP_TEACHER_SUCCESS = `${moduleName}/SIGN_UP_TEACHER_SUCCESS`;
export const SIGN_UP_TEACHER_FAIL = `${moduleName}/SIGN_UP_TEACHER_FAIL`;

export const LOGOUT = `${moduleName}/LOGOUT`;
export const LOGOUT_FAIL = `${moduleName}/LOGOUT_FAIL`;
