export type OrderShippingMethodsType = {
  [key: string]: string
};

export const USPS_MULTIPLE = 'usps_multiple';
export const USPS_DELIVERY = 'usps_delivery';
export const USPS_PRIORITY_MAIL = 'usps_priority_mail_shipping_charged';
export const USPS_NON_PRIORITY_MAIL = 'usps_non_priority_mail_cost_included_in_price';
export const NON_USPS_SHIPPING = 'non_usps_shipping_cost_included_in_price';
export const SHIPPING_NOT_AVAILABLE = 'shipping_not_available';
export const LOCAL_DELIVERY = 'local_delivery';
export const LOCAL_PICKUP = 'local_pickup';
export const NOT_APPLICABLE = 'not_applicable';

export const orderShippingMethods: OrderShippingMethodsType = {
  [USPS_MULTIPLE]: 'USPS Multiple Delivery',
  [USPS_PRIORITY_MAIL]: 'USPS Priority Mail/Shipping Charged',
  [USPS_NON_PRIORITY_MAIL]: 'USPS Non-Priority Mail/Cost Included in Price',
  [NON_USPS_SHIPPING]: 'Non-USPS Shipping/Cost Included in Price',
  // [USPS_DELIVERY]: 'USPS Delivery',
  [SHIPPING_NOT_AVAILABLE]: 'Shipping Not Available',
  [LOCAL_DELIVERY]: 'Local Delivery',
  [LOCAL_PICKUP]: 'Local Pickup',
  [NOT_APPLICABLE]: 'The Product Does Not Require Shipping',
}