import { withTheme, WithTheme, styled } from '@material-ui/core/styles';

export const WarningWindowContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: 430,
  height: 392,
  backgroundColor: '#FFFFFF',
  margin: '0 auto',
  boxShadow: '0 5px 30px 0 rgba(0, 0, 0, 0.35)',
  borderRadius: 10,
});

export const WindowIcon = styled('div')({
  marginBottom: 32,
});

export const WindowContent = styled('div')({
  maxWidth: 300,
  fontSize: '20px',
  lineHeight: '29px',
  textAlign: 'center',
});

export const WindowButton = withTheme(
  styled('div')({
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '36px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
    '& > a': {
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
      textDecoration: 'none',
      '&:hover': {
        color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  })
);
