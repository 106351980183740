import {
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_PIN_FOR_STUDENT,
  GET_USER,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  UPDATE_TEACHER_PROFILE_INFO,
  UPDATE_TEACHER_PROFILE_INFO_SUCCESS,
  UPDATE_TEACHER_PROFILE_INFO_FAIL,
  CLEAR_TEACHER_PROFILE_INFO_ERROR,
  SET_AVATAR_IMAGE_DATA,
  GET_STUDENTS_LIST,
  GET_STUDENTS_LIST_SUCCESS,
  GET_STUDENTS_LIST_FAIL,
  UPDATE_STORE_NAME,
  UPDATE_STORE_NAME_FAIL,
  UPDATE_STORE_NAME_SUCCESS,
  CREATE_STUDENT,
  CREATE_STUDENT_SUCCESS,
  CREATE_STUDENT_FAIL,
  EDIT_STUDENT,
  EDIT_STUDENT_SUCCESS,
  EDIT_STUDENT_FAIL,
  GET_STUDENT,
  GET_STUDENT_SUCCESS,
  GET_STUDENT_FAIL,
  DELETE_STUDENT,
  DELETE_STUDENT_SUCCESS,
  DELETE_STUDENT_FAIL,
  CLEAR_STUDENTS_LIST,
  GET_LIST_WEBSITE_TEMPLATES,
  GET_LIST_WEBSITE_TEMPLATES_SUCCESS,
  GET_LIST_WEBSITE_TEMPLATES_FAIL,
  SET_SELECTED_WEBSITE_ID,
  CREATE_WEBSITE,
  CREATE_WEBSITE_SUCCESS,
  CREATE_WEBSITE_FAIL,
  EDIT_WEBSITE_SUCCESS,
  GET_WEBSITE,
  GET_WEBSITE_SUCCESS,
  GET_WEBSITE_FAIL,
  CLEAR_USER,
  GET_STORE_SUCCESS,
  GET_STORE_FAIL,
  GET_STORE_REQUEST,
  SSE_NOTIFY,
  SSE_FAILURE,
  SSE_NOTIFY_SUCCESS,
  CLEAR_NEW_NOTIFICATIONS,
} from './constants';
import { all, put, select, takeLatest, call } from 'redux-saga/effects';
import history from 'src/containers/history';
import { initialize } from 'redux-form';
import userApi from 'src/api/userApi';
import { setUserToStorage } from 'src/utils/helpers';
import {
  UpdateTeacherProfileInfo,
  UpdateStoreNameType,
  StudentForm,
  CreateStudentPayload,
  DeleteStudent,
  GetStudentsList,
} from './actions';
import { S3Image } from 'src/constants/commonTypes';

export const moduleName = 'user';

export type ActionType = {
  type: string;
  error: Error | null;
  payload?: any;
};

export type User = {
  id: number;
  email: string;
  displayName: string;
  enabled?: boolean;
  status?: string;
  firstName?: string;
  fullName?: string;
  lastName?: string;
  userImages: S3Image[];
  accountLogoPath?: [];
  accesses: Accesses[];
};

export type StateUserInterface = {
  user: User | null;
  error: Error | null;
  loading: boolean;
  pin: string;
  studentsList: {
    list: StudentList[];
    currentPage: number;
    totalPages: number;
  } | null;
  updateTeacherProfileInfoLoading: boolean;
  updateTeacherProfileInfoError: Error | null;
  teacherProfileAvatarImageData: string | null;
  userStore: any;
  notification: {
    eventsCount: Number;
  };
};

export type Error = {
  error: boolean;
  errors:
    | ErrorData[]
    | {
        title: string;
        message: string;
      };
};

export type ErrorData = {
  field?: string;
  invalid_value: string;
  message: string;
  code?: string;
};

export type StoreDescription = {
  id: number;
  cityAndState: string;
  schoolName: string;
  schoolAddress: string;
  schoolLevel: string;
  classroomConcentration: string[];
  zipCode: string;
};

export type Store = {
  id: number;
  name?: string;
  websites: Websites[];
  storeDescription: StoreDescription | null;
};

export type Websites = {
  id: number;
};

type Accesses = {
  id: number;
  name: string;
  store: Store;
};

export type StudentList = {
  id: number;
  username: string;
  displayName: string;
};

export const userState: StateUserInterface = {
  user: null,
  pin: '',
  studentsList: null,
  error: null,
  loading: false,
  updateTeacherProfileInfoLoading: false,
  updateTeacherProfileInfoError: null,
  teacherProfileAvatarImageData: null,
  userStore: null,
  notification: {
    eventsCount: 0,
  },
};

export default function reducer(
  state: StateUserInterface = userState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_USER:
    case CREATE_STUDENT:
    case RESET_PASSWORD:
    case GET_STUDENTS_LIST:
    case UPDATE_STORE_NAME:
    case GET_STUDENT:
    case DELETE_STUDENT:
    case GET_LIST_WEBSITE_TEMPLATES:
    case CREATE_WEBSITE:
    case GET_WEBSITE:
      return { ...state, loading: true, error: null };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pin: payload,
        loading: false,
        error: null,
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
        error: null,
      };

    case GET_STORE_SUCCESS:
      return {
        ...state,
        loading: false,
        userStore: payload,
        error: null,
      };

    case UPDATE_TEACHER_PROFILE_INFO: {
      return {
        ...state,
        updateTeacherProfileInfoLoading: true,
      };
    }

    case UPDATE_TEACHER_PROFILE_INFO_SUCCESS: {
      const { firstName, lastName, userImage } = payload;
      // Note: only certain values are updated - we don't touch e.g. email or
      // school information.  On the other hand we need to update the user in
      // order to display the teacherProfileAvatarImageDataupdate in the top bar immediately.

      // Note: userImage is not updated, because the store keeps S3Image which
      // we don't have available at this point.  It's not a problem, because
      // the Profile page will continue to show the correct image from
      // uploadedImageData.
      return {
        ...state,
        updateTeacherProfileInfoLoading: false,
        updateTeacherProfileInfoError: null,
        user: {
          ...state.user,
          firstName,
          lastName,
          displayName: `${firstName} ${lastName}`,
          userImages: userImage,
        },
      };
    }

    case SET_AVATAR_IMAGE_DATA: {
      return {
        ...state,
        teacherProfileAvatarImageData: payload,
      };
    }

    case UPDATE_STORE_NAME_SUCCESS: {
      return handleUpdateStoreName(state, payload);
    }

    case GET_STUDENTS_LIST_SUCCESS: {
      const list = state?.studentsList?.list;
      return {
        ...state,
        studentsList: {
          list: list ? [...list, ...payload.data] : payload.data,
          currentPage: payload._page,
          totalPages: payload._pages,
        },
        error: null,
        loading: false,
      };
    }

    case EDIT_STUDENT_SUCCESS:
    case GET_STUDENT_SUCCESS:
      return { ...state, loading: false, error: null };

    case DELETE_STUDENT_SUCCESS:
      return {
        ...state,
        studentsList: {
          ...state.studentsList,
          list: state.studentsList?.list.filter((student) => student.id !== payload),
        },
        loading: false,
        error: null,
      };

    case CREATE_STUDENT_SUCCESS:
      return {
        ...state,
        pin: payload,
        loading: false,
        error: null,
      };

    case CLEAR_TEACHER_PROFILE_INFO_ERROR: {
      return {
        ...state,
        updateTeacherProfileInfoError: null,
      };
    }

    case CLEAR_USER:
      return userState;

    case UPDATE_TEACHER_PROFILE_INFO_FAIL: {
      return {
        ...state,
        updateTeacherProfileInfoLoading: false,
        updateTeacherProfileInfoError: error,
      };
    }

    case RESET_PASSWORD_FAIL:
    case GET_USER_FAIL:
    case GET_STUDENTS_LIST_FAIL:
    case GET_WEBSITE_FAIL:
      return {
        ...state,
        loading: false,
        pin: '',
        user: null,
        studentsList: null,
        error: error,
      };
    case CLEAR_PIN_FOR_STUDENT: {
      return { ...state, pin: '' };
    }
    case UPDATE_STORE_NAME_FAIL:
    case CREATE_STUDENT_FAIL:
    case EDIT_STUDENT_FAIL:
    case DELETE_STUDENT_FAIL:
    case GET_LIST_WEBSITE_TEMPLATES_FAIL:
    case CREATE_WEBSITE_FAIL:
    case GET_STORE_FAIL:
      return { ...state, error: error, loading: false };

    case GET_STUDENT_FAIL:
      return { ...state, error: error, selectedStudentInfo: null };

    case CLEAR_STUDENTS_LIST:
      return { ...state, studentsList: null, error: null };

    case SET_SELECTED_WEBSITE_ID:
      return { ...state, templateID: payload };

    case GET_LIST_WEBSITE_TEMPLATES_SUCCESS:
      return { ...state, listTemplate: payload };

    case CREATE_WEBSITE_SUCCESS:
    case EDIT_WEBSITE_SUCCESS:
    case GET_WEBSITE_SUCCESS:
      return {
        ...state,
        website: payload,
        templateID: payload.template.id,
        arrayImages: payload.heroImages,
        loading: false,
        error: null,
      };

    case SSE_NOTIFY_SUCCESS:
      return {
        ...state,
        notification: {
          eventsCount: payload.newEventsCount,
        },
      };

    case CLEAR_NEW_NOTIFICATIONS:
      return {
        ...state,
        notification: {
          eventsCount: 0,
        },
      };

    default:
      return state;
  }
}

function handleUpdateStoreName(state: StateUserInterface, payload: Store) {
  const updatedAccesses = state.user?.accesses.reduce((acc: Accesses[], currVal: Accesses) => {
    if (currVal.store.id === payload.id) {
      currVal.store.name = payload.name;
      currVal.store.storeDescription = payload.storeDescription;
    }
    return [...acc, currVal];
  }, []);

  return {
    ...state,
    loading: false,
    user: {
      ...state.user,
      accesses: updatedAccesses,
    },
    error: null,
  };
}

function* resetPasswordStudentSaga({ payload }: ActionType) {
  try {
    const response = yield userApi.resetPasswordStudent(payload);

    yield put({ type: RESET_PASSWORD_SUCCESS, payload: response.plainPassword });
  } catch (err) {
    yield put({ type: RESET_PASSWORD_FAIL, error: err });
  }
}

function* getUserSaga() {
  try {
    const response = yield userApi.getCurrentUser();

    if (response) {
      yield put({ type: GET_USER_SUCCESS, payload: response.data || response });
      setUserToStorage('user', response.data || response);
      yield put({ type: SSE_NOTIFY });
      if (window.location.pathname === '/') {
        yield history.push('/home');
      }
    }
  } catch (err) {
    yield history.push('/');
    yield put({ type: GET_USER_FAIL, error: err });
  }
}

function* updateTeacherProfileInfoSaga({ payload }: UpdateTeacherProfileInfo) {
  const { user, teacherProfileAvatarImageData } = yield select((state) => state.user);
  const { userInfo, storeDescription } = payload;
  const { accesses } = user;
  const { id } = accesses[0].store;

  try {
    if (userInfo.userImage === '' && teacherProfileAvatarImageData !== '') {
      userInfo.userImage = undefined;
      yield userApi.updateTeacherProfileInfo(userInfo);
      userInfo.userImage = user?.userImages;
    } else {
      yield userApi.updateTeacherProfileInfo(userInfo);
    }

    yield call(updateStoreName, {
      type: UPDATE_STORE_NAME,
      payload: { storeDescription: storeDescription, id },
    });

    yield put({ type: UPDATE_TEACHER_PROFILE_INFO_SUCCESS, payload: userInfo });
  } catch (err) {
    yield put({ type: UPDATE_TEACHER_PROFILE_INFO_FAIL, error: err.data });
  }
}

function* getStudentsListSaga({ payload: page }: GetStudentsList) {
  const user = yield select((state) => state.user.user);

  try {
    if (user) {
      const {
        store: { id: storeId },
      } = user.accesses[0];
      const response = yield userApi.getStudentsList(storeId, page);
      yield put({ type: GET_STUDENTS_LIST_SUCCESS, payload: response });
    }
  } catch (err) {
    yield put({ type: GET_STUDENTS_LIST_FAIL, error: err });
  }
}

function* updateStoreName({ payload }: UpdateStoreNameType) {
  try {
    const response = yield userApi.updateUserStoreName(payload);
    yield put({ type: UPDATE_STORE_NAME_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: UPDATE_STORE_NAME_FAIL, error: err.data });
  }
}

function* createStudentSaga({ payload: { name, fullName, username } }: CreateStudentPayload) {
  const user = yield select((state) => state.user.user);
  const {
    store: { id },
  } = user.accesses[0];

  try {
    const accesses = [{ name, store: id }];
    const preparedData = { username, fullName, accesses: { ...accesses } };
    const response = yield userApi.createStudent(preparedData);

    yield put({ type: CREATE_STUDENT_SUCCESS, payload: response.plainPassword });
  } catch (err) {
    yield put({ type: CREATE_STUDENT_FAIL, error: err.data });
  }
}

function* getStudentSaga({ payload: studentId }: ActionType) {
  try {
    const response = yield userApi.getStudent(studentId);
    const currentAccess = response.accesses[0].name;

    yield put(
      initialize('editStudent', {
        username: response.username,
        fullName: response.fullName,
        name: currentAccess,
      })
    );

    yield put({ type: GET_STUDENT_SUCCESS });
  } catch (err) {
    yield put({ type: GET_STUDENT_FAIL, error: err });
  }
}

function* getStoreSaga({ payload: id }: ActionType) {
  const websitesIds = yield select((state) => state.user?.user?.accesses[0].store.websites);
  try {
    const response = yield userApi.getStore(id);
    yield put({ type: GET_STORE_SUCCESS, payload: response });
    if (!websitesIds.length) {
      yield put(
        initialize('websiteDescription', {
          name: response.name,
          websiteURL: `http://edcorps.org/shop/${response.path}`,
        })
      );
    }
  } catch (err) {
    yield put({ type: GET_STORE_FAIL, error: err.data });
  }
}

function* editStudentSaga({ payload: { name, fullName, username, studentId } }: StudentForm) {
  const user = yield select((state) => state.user.user);
  const {
    store: { id },
  } = user.accesses[0];

  try {
    const accesses = [{ name, store: id }];
    const preparedData = { username, fullName, accesses: { ...accesses } };
    yield userApi.editStudent(studentId, preparedData);

    yield put({ type: EDIT_STUDENT_SUCCESS });
    yield history.push('/students');
  } catch (err) {
    yield put({ type: EDIT_STUDENT_FAIL, error: err.data });
  }
}

function* deleteStudentSaga({ payload }: DeleteStudent) {
  try {
    yield userApi.deleteStudent(payload);

    yield put({ type: DELETE_STUDENT_SUCCESS, payload });
  } catch (err) {
    yield put({ type: DELETE_STUDENT_FAIL, error: err.data });
  }
}

function* getNewNotificationsSaga() {
  try {
    let id;
    const user = JSON.parse(localStorage.getItem('user') || '');
    if (user.accesses[0].store.websites.length) {
      id = user.accesses[0].store.websites[0].id;
    }
    const response = yield userApi.getNewNotifications(id);
    if (response) {
      yield put({ type: SSE_NOTIFY_SUCCESS, payload: response.data || response });
    }
  } catch (err) {
    yield put({ type: SSE_FAILURE, error: err });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(RESET_PASSWORD, resetPasswordStudentSaga),
    takeLatest(GET_USER, getUserSaga),
    takeLatest(UPDATE_TEACHER_PROFILE_INFO, updateTeacherProfileInfoSaga),
    takeLatest(GET_STUDENTS_LIST, getStudentsListSaga),
    takeLatest(UPDATE_STORE_NAME, updateStoreName),
    takeLatest(CREATE_STUDENT, createStudentSaga),
    takeLatest(EDIT_STUDENT, editStudentSaga),
    takeLatest(GET_STUDENT, getStudentSaga),
    takeLatest(DELETE_STUDENT, deleteStudentSaga),
    takeLatest(GET_STORE_REQUEST, getStoreSaga),
    takeLatest(SSE_NOTIFY, getNewNotificationsSaga),
  ]);
};
