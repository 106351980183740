import React from 'react';

// Chrome supports clip-path with a clipPath reference only to an inline svg,
// so the simplest place for it is in the DOM.  Corresponds to a path in
// src/assets/images/profile/avatarBackdrop.svg
const ClipPathSvg = () => {
  return (
    <svg
      width="220"
      height="220"
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <clipPath id="clipPath">
          <path
            d="M2.23501 39.2287C3.24941 19.1848 19.1848 3.24941 39.2287
2.23501C60.8855 1.13899 88.5512 0 110 0C131.449 0 159.114 1.13898
180.771 2.23501C200.815 3.24941 216.751 19.1848 217.765
39.2287C218.861 60.8855 220 88.5512 220 110C220 131.449 218.861
159.114 217.765 180.771C216.751 200.815 200.815 216.751 180.771
217.765C159.114 218.861 131.449 220 110 220C88.5512 220 60.8855
218.861 39.2287 217.765C19.1848 216.751 3.24941 200.815 2.23501
180.771C1.13899 159.114 0 131.449 0 110C0 88.5512 1.13898 60.8855
2.23501 39.2287Z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ClipPathSvg;
