import React from 'react';
import { USPS_NON_PRIORITY_MAIL, USPS_MULTIPLE } from 'src/constants/orderShippingMethods';
import { FullOrder, OrderedProduct } from 'src/ducks/orders/orders';
import { HorizontalLine } from '../styles';
import {
  ProductsContainer,
  ProductWrapper,
  ProductInfo,
  CalculationWrapper,
  ProductVersion,
} from './styles';

type ProductsInfo = {
  order: FullOrder;
};

const ProductsInfo = ({ order }: ProductsInfo) => {
  const calculateTotal = (price: number, quantity: number) => {
    return (price * quantity).toLocaleString('en-US', { minimumFractionDigits: 2 });
  };
  const isProductChoiceExists = order.orderedProducts.some(({ productChoiceNames }: OrderedProduct) =>
    Array.isArray(productChoiceNames) === true,
  );

  const getProductVersion = (productId: number) => {
    const defaultTextForProduct = 'This product is without versions';
    const foundProductWithChoices = order.orderedProducts.find(({ id, productChoiceNames }: OrderedProduct) =>
      productId === id && Array.isArray(productChoiceNames) === true,
    );

    if (foundProductWithChoices) {
      if (foundProductWithChoices.productChoiceNames?.[1]) {
        return `${foundProductWithChoices.productChoiceNames?.[0].valueName}, ${foundProductWithChoices.productChoiceNames?.[1].valueName}`;
      }
      return foundProductWithChoices.productChoiceNames?.[0].valueName;
    }
    return defaultTextForProduct;
  };

  const getProductNameWithMethod = (order: FullOrder, name: string, shippingType: string) => {
    if (
      (order.shippingMethod === USPS_NON_PRIORITY_MAIL || order.shippingMethod === USPS_MULTIPLE)
      && shippingType === USPS_NON_PRIORITY_MAIL) {
      return `${name} (USPS Non-Priority Mail/Cost Included in Price)`;
    }
    return name;
  };

  return (
    <ProductsContainer>
      <HorizontalLine />
      <ProductWrapper>
        <ProductVersion>
          <div>PRODUCT</div>
          {isProductChoiceExists && <div>VERSIONS OF PRODUCT</div>}
        </ProductVersion>
        <ProductInfo>
          <div>PRICE</div>
          <div>QUANTITY</div>
          <div>TOTAL</div>
        </ProductInfo>
      </ProductWrapper>
      <HorizontalLine />
      {order.orderedProducts.map((product: OrderedProduct) => (
        <React.Fragment key={product.id}>
          <ProductWrapper>
            <ProductVersion>
              <div>
                {getProductNameWithMethod(order, product.name, product.shippingType)}
              </div>
              <div>{isProductChoiceExists && getProductVersion(product.id)}</div>
            </ProductVersion>
            <ProductInfo>
              <div>${product.price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
              <div>{product.quantity}</div>
              <div>${calculateTotal(product.price, product.quantity)}</div>
            </ProductInfo>
          </ProductWrapper>
          <HorizontalLine />
        </React.Fragment>
      ))}
      <CalculationWrapper>
        <div>
          <div>SUBTOTAL:</div>
          <div>TAX:</div>
          <div>SHIPPING:</div>
        </div>
        <div>
          <div>${order.subtotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
          <div>${order.tax.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
          <div>${order.shipping.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
        </div>
      </CalculationWrapper>
    </ProductsContainer>
  );
};

export default ProductsInfo;
