import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import React from 'react';

export const Area = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <textarea {...otherProps} />)({
    background: '#F8FAFB',
    borderRadius: '5px',
    padding: '12px 18px',
    fontSize: '15px',
    border: 'none',
    minHeight: '40px',
    maxHeight: '200px',
    '&:hover': {
      background: '#EAEDF0',
    },
    '&:focus': {
      border: '1px solid #008DBA',
      outline: 'none',
    },
    '&:disabled': {
      border: 'none',
      opacity: 0.7,
      '&:hover': {
        background: '#F8FAFB',
      },
    },
  })
);
