import React from 'react';
import { Card, Typography, TypographyProps } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { Button, ButtonProps } from '@material-ui/core';
import { ResetPinButton } from 'src/components/pages/students/studenstList/styles';

const narrowScreenMediaQuery = '@media (max-width: 1850px)';

export const ProfileContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(248, 250, 251)',
});

export const OuterContainer = styled('div')({
  padding: '0 50px 50px',

  [narrowScreenMediaQuery]: {
    width: '70%',
    minWidth: 770,
  },
});

export const CardsContainer = styled('div')({
  display: 'flex',

  [narrowScreenMediaQuery]: {
    flexWrap: 'wrap',
  },
});

export const ButtonsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  '& button': {
    marginLeft: 25,
  },
});

export const BackButton = styled(ResetPinButton)({
  maxWidth: 130,
  height: 40,
});

const StyledCard = withTheme(
  styled(Card)({
    position: 'relative',
    minWidth: 350,
    boxSizing: 'border-box',
    overflow: 'visible',
    boxShadow: '0px 2px 4px rgba(169, 194, 209, 0.05), 0px 12px 16px rgba(169, 194, 209, 0.1)',

    padding: 30,
    borderRadius: 10,
    marginBottom: 30,

    '&:not(:last-child)': {
      marginRight: 30,
    },

    '&:nth-child(1)': {
      flex: 650,
      [narrowScreenMediaQuery]: {
        minWidth: '100%',
        flex: '0',
        marginRight: 0,
      },
    },

    '&:nth-child(2)': {
      flex: 500,
    },

    '&:nth-child(3)': {
      flex: 350,
    },
  })
);

const CardShadowCaster = styled('div')({
  position: 'absolute',
  height: 30,
  bottom: 0,
  width: '80%',
  left: '10%',
  boxShadow: '0px 4px 24px rgba(169, 194, 209, 0.03), 0px 2px 16px rgba(169, 194, 209, 0.03)',
});

export const ProfileCard = ({ children }: any) => {
  return (
    <StyledCard>
      <CardShadowCaster />
      {children}
    </StyledCard>
  );
};

export const PageTitle = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => (
    <Typography variant="h1" {...otherProps} />
  ))({
    paddingTop: 35,
    paddingBottom: 35,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: 30,
    fontWeight: 'bold',
  })
);

export const ProfileCardTitle = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => (
    <Typography variant="h2" {...otherProps} />
  ))({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 20,
  })
);

export const PrimaryButton = withTheme(
  styled((props: ButtonProps & WithTheme) => <Button variant="contained" {...props} />)({
    minWidth: 130,
    height: 40,
    borderRadius: 5,
    paddingLeft: 20,
    paddingRight: 24,

    textTransform: 'none',
    fontSize: 15,
    fontWeight: 500,

    color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeColor,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    boxShadow: '0px 8px 16px rgba(0, 141, 186, 0.19)',

    '&:hover': {
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeColor,
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
      boxShadow: '0px 15px 40px rgba(0, 141, 186, 0.2), 0px 8px 16px rgba(0, 141, 186, 0.12)',
    },

    '&:disabled': {
      color: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledColor,
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.buttons.disabledBg,
    },
  })
);
