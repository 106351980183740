import { moduleName } from './cashout';

export const GET_CASHOUT_LIST = `${moduleName}/GET_CASHOUT_LIST`;
export const GET_CASHOUT_LIST_SUCCESS = `${moduleName}/GET_CASHOUT_LIST_SUCCESS`;
export const GET_CASHOUT_LIST_FAIL = `${moduleName}/GET_CASHOUT_LIST_FAIL`;

export const CLEAR_CASHOUT_LIST = `${moduleName}/CLEAR_CASHOUT_LIST`;

export const VIEW_CASHOUT = `${moduleName}/VIEW_CASHOUT`;
export const VIEW_CASHOUT_SUCCESS = `${moduleName}/VIEW_CASHOUT_SUCCESS`;
export const VIEW_CASHOUT_FAIL = `${moduleName}/VIEW_CASHOUT_FAIL`;

export const EDIT_CASHOUT = `${moduleName}/EDIT_CASHOUT`;
export const EDIT_CASHOUT_SUCCESS = `${moduleName}/EDIT_CASHOUT_SUCCESS`;
export const EDIT_CASHOUT_FAIL = `${moduleName}/EDIT_CASHOUT_FAIL`;

export const CREATE_CASHOUT = `${moduleName}/CREATE_CASHOUT`;
export const CREATE_CASHOUT_SUCCESS = `${moduleName}/CREATE_CASHOUT_SUCCESS`;
export const CREATE_CASHOUT_FAIL = `${moduleName}/CREATE_CASHOUT_FAIL`;

export const DELETE_CASHOUT = `${moduleName}/DELETE_CASHOUT`;
export const DELETE_CASHOUT_SUCCESS = `${moduleName}/DELETE_CASHOUT_SUCCESS`;
export const DELETE_CASHOUT_FAIL = `${moduleName}/DELETE_CASHOUT_FAIL`;
