import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { reduxForm, InjectedFormProps, FormErrors } from 'redux-form';
import { Typography } from '@material-ui/core';
import { errorSelector, checkRecoveryTokenSelector } from 'src/ducks/auth/selectors';
import { FormInputField } from 'src/components/common/inputs';
import validations from 'src/components/common/inputs/validations';
import { Link } from 'react-router-dom';
import { PasswordIcon } from 'src/components/common/Icons';
import { checkRecoveryToken, switchPages } from 'src/ducks/auth/actions';
import { config } from 'src/config/config';
import {
  ErrorMessage,
  FormContainer,
  ContinueButton,
  AdditionalInfo,
  ContinueButtonContainer,
} from '../forgotPassword/styles';

export type FormNewPasswordProps = {
  first?: string;
  second?: string;
};

type Props = InjectedFormProps<FormNewPasswordProps, {}>;

const NewPassword = (props: Props) => {
  const { handleSubmit, valid } = props;
  const { id: recoveryToken } = useParams();
  const error = useSelector(errorSelector);
  const dispatch = useDispatch();
  const recoveryTokenIsChecked = useSelector(checkRecoveryTokenSelector);
  useEffect(() => {
    if (!recoveryTokenIsChecked) {
      dispatch(checkRecoveryToken(recoveryToken));
    }
  }, [recoveryTokenIsChecked]);
  const goBack = () => dispatch(switchPages());
  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <FormInputField
          whiteBg
          placeholder="Password"
          adornment={<PasswordIcon />}
          name="first"
          type="password"
          validate={validations.password}
        />
        <FormInputField
          whiteBg
          placeholder="Password (Confirm)"
          adornment={<PasswordIcon />}
          name="second"
          type="password"
          validate={validations.password}
        />
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            Reset Password
          </ContinueButton>
        </ContinueButtonContainer>
        <AdditionalInfo>
          <Link to="/" onClick={goBack}>
            <Typography variant="body2">Go back to Login</Typography>
          </Link>
        </AdditionalInfo>
      </FormContainer>
    </form>
  );
};

const validatePassword = (pw?: string, type?: string) => {
  if (!pw) return `Please provide the required field`;
  else if (!config.regexpValidatePass.test(pw)) {
    return `${type} must contain not less than 8 characters including letters, numbers and characters`;
  }
};

const validate = (values: FormNewPasswordProps) => {
  const errors: FormErrors<FormNewPasswordProps> = {};

  if (!values.first) errors.first = validatePassword(values.first, 'Password');
  else if (values.first) errors.first = validatePassword(values.first, 'Password');
  if (!values.second) errors.second = validatePassword(values.second, 'Confirm password');
  else if (values.second) errors.second = validatePassword(values.second, 'Confirm password');
  if (values.first !== values.second) errors.second = 'Passwords do not match. Please try again';

  return errors;
};

export default reduxForm<FormNewPasswordProps, {}>({
  form: 'newpassword',
  validate,
})(NewPassword);
