import React from 'react';
import { LogoIcon } from 'src/components/common/Icons';
import authBackground from 'src/assets/images/auth-background.svg';
import AuthCardForRecovery from './AuthCardForRecovery';
import {
  AuthContainer,
  LoginText,
  CardContainer,
  Logo,
  CardContent,
  ContactUs
} from './styles';

type Props = {
  text: string;
  children: React.ReactNode;
  style: boolean;
};

const Auth = ({ children, text, style }: Props) => {
  const email = 'contact@realworldscholars.org';

  return (
    <AuthContainer authBackground={authBackground}>
      <Logo>
        <LogoIcon />
      </Logo>
      {style ? (
        <AuthCardForRecovery text={text}>{children}</AuthCardForRecovery>
      ) : (
        <CardContainer>
          <CardContent>
            <LoginText>{text}</LoginText>
            {children}
          </CardContent>
        </CardContainer>
      )}
      <form action={`mailto:${email}`} method="post" encType="text/plain">
        <ContactUs type="submit" value="Contact Us" />
      </form>
    </AuthContainer>
  );
};

export default Auth;
