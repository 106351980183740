import { styled, Theme } from '@material-ui/core';
import { Label as LabelFormInput } from 'src/components/common/formInput/styles'

export const Label = styled(LabelFormInput)({});

export const inlineStyles = {
  listbox: {
    maxHeight: '360px'
  }
}

export const styles = ({
  customPalette: {
    text,
    background,
    borders,
  }
}: Theme) => ({
  autocomplete: {
    borderRadius: 5,
    padding: 0,
    backgroundColor: background.inputBg,
    '&:hover': {
      background: background.blue,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: 0,
    },
    '& *:not(:focus):not(.MuiInputBase-input)': {
      outline: 'none',
      border: 'none'
    },
  },
  input: {
    color: text.primary,
    height: 17,
    borderRadius: 5,
    border: `1px solid ${borders.select}`,
    padding: '12px 18px',
    '&:focus': {
      outline: 'none',
      borderRadius: 5,
      borderColor: text.highlight,
    },
  },
  option: {
    color: text.primary,
  },
});
