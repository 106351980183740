import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { Select, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { StylesTemplate } from 'src/constants/stylesTemplates';
import { styles } from './styles';

type Props = {
  name: string;
  validate?: ((value: string) => string | null)[];
  selectedValue?: string;
  handleChange: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  renderOptions?: any;
  styles: StylesTemplate;
};

const useStyles = makeStyles(styles);

const ShopSelectComponent = (props: Props & WrappedFieldProps) => {
  const {
    input,
    meta: { error, active, touched },
    selectedValue,
    handleChange,
    renderOptions,
    styles,
  } = props;
  const classes = useStyles(styles);
  const hasError = touched && !active && !!error;

  return (
    <>
      <Select
        {...input}
        error={hasError}
        className={classes.select}
        value={selectedValue}
        displayEmpty
        onChange={handleChange}
        inputProps={{
          className: classes.input,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          elevation: 0,
          PopoverClasses: { paper: classes.popover },
        }}
        variant="outlined"
        children={renderOptions}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </>
  );
};

const ShopSelectField = (props: Props) => <Field component={ShopSelectComponent} {...props} />;

export default ShopSelectField;
