import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Card } from '@material-ui/core';
import { StudentContainer } from 'src/components/pages/students/styles';
import { ResetPinButton } from 'src/components/pages/students/studenstList/styles';
import { ContinueButton } from 'src/components/pages/auth/signin/styles';

export const ViewOrderPage = StudentContainer;

export const OrderTitle = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontSize: 30,
    fontWeight: 500,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    paddingBottom: 25,
    '& > span': {
      color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
      paddingLeft: 5,
    },
  })
);

export const ViewOrderContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Card {...otherProps} />)({
    fontSize: 20,
    fontWeight: 500,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    boxShadow: '0px 4px 32px rgba(169, 194, 209, 0.05), 0px 2px 16px rgba(169, 194, 209, 0.05)',
  })
);

export const CustomerWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 40px 0 40px',
    '& > div:last-child': {
      color: ({ theme }: any) => theme.customPalette.text.grey,
      fontSize: 15,
      fontWeight: 400,
    },
  })
);

export const CustomerInfoWrapper = styled('div')({
  display: 'flex',
  padding: '24px 40px 0 40px',
  marginBottom: 30,
  alignItems: 'center',
  '& > svg:nth-of-type(2)': {
    paddingLeft: 30,
  },
});

export const InfoWrapper = styled('div')({
  paddingLeft: 4,
  fontSize: 15,
  '&  span': {
    fontWeight: 400,
    lineHeight: '24px',
  },
});

export const HorizontalLine = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    border: ({ theme }: WithTheme) => `1px solid ${theme.customPalette.borders.divider}`,
  })
);

export const BackButton = styled(ResetPinButton)({
  maxWidth: 130,
});

export const SaveButton = styled(ContinueButton)({
  maxWidth: 130,
  textTransform: 'capitalize',
  '& svg': {
    paddingRight: 7,
  },
});

export const ActionButtons = styled('div')({
  display: 'flex',
  flexBasis: 285,
  justifyContent: 'space-between',
});

export const ActionButtonsWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '40px 40px 30px 40px',
});

export const TotalWrapper = styled('div')({
  display: 'flex',
  fontSize: 20,
  fontWeight: 500,
  '& > div:last-child': {
    paddingLeft: 60,
  },
});
