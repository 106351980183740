import React from 'react';
import { WithTheme, withTheme, styled } from '@material-ui/core/styles';

type ProductsContainerProps = {
  id: string;
};

export enum ColumnLayout {
  TWO_COLUMNS = 'two-columns',
  THREE_COLUMNS = 'three-columns'
}

export const ProductsSectionTitle = styled('div')({
    fontSize: 42,
    marginTop: 50,
    color: '#303E4E',
    fontWeight: 'bold',
    textTransform: 'capitalize'
}); 

export const styles = () => ({
  columnLayout: {
    [`&[data-columns="${ColumnLayout.TWO_COLUMNS}"]`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(550px, 1fr))`,
    }, 
    [`&[data-columns="${ColumnLayout.THREE_COLUMNS}"]`]: {
      gridTemplateColumns: `repeat(auto-fill, minmax(300px, 1fr))`,
    }
  }
});

export const ProductsLayout = withTheme(
  styled(({ theme, columnLayout, ...otherProps }: WithTheme & { columnLayout: ColumnLayout }) => (
    <div {...otherProps} data-columns={columnLayout}/>
  ))({
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
    paddingTop: 60,
    display: 'grid', 
    gridGap: '60px 100px',
  })
);

export const ProductsSection = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ProductsContainerProps) => <div {...otherProps} />)(
    {
      '&#products': {
        scrollBehavior: 'smooth',
      },
      paddingTop: 60,
      maxWidth: 1200,
      width: '80%',
      margin: '0 auto',
    }
  )
);