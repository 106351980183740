import { moduleName } from './website';

export const SET_SELECTED_WEBSITE_ID = `${moduleName}/SET_SELECTED_WEBSITE_ID`;
export const SET_UPLOAD_WEBSITE_HERO_IMAGE = `${moduleName}/SET_UPLOAD_WEBSITE_HERO_IMAGE`;
export const CREATE_WEBSITE = `${moduleName}/CREATE_WEBSITE`;
export const CREATE_WEBSITE_SUCCESS = `${moduleName}/CREATE_WEBSITE_SUCCESS`;
export const CREATE_WEBSITE_FAIL = `${moduleName}/CREATE_WEBSITE_FAIL`;

export const GET_LIST_WEBSITE_TEMPLATES = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES`;
export const GET_LIST_WEBSITE_TEMPLATES_SUCCESS = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES_SUCCESS`;
export const GET_LIST_WEBSITE_TEMPLATES_FAIL = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES_FAIL`;
export const GET_WEBSITE = `${moduleName}/GET_WEBSITE`;
export const GET_WEBSITE_SUCCESS = `${moduleName}/GET_WEBSITE_SUCCESS`;
export const GET_WEBSITE_FAIL = `${moduleName}/GET_WEBSITE_FAIL`;

export const EDIT_WEBSITE_SUCCESS = `${moduleName}/EDIT_WEBSITE_SUCCESS`;

export const CHECK_BUSINESS_NAME_SUCCESS = `${moduleName}/CHECK_BUSINESS_NAME_SUCCESS`;
export const CHECK_BUSINESS_NAME_FAIL = `${moduleName}/CHECK_BUSINESS_NAME_FAIL`;
export const BUSINESS_NAME_CHANGED = `${moduleName}/BUSINESS_NAME_CHANGED`;

export const GET_WEBSITE_SHOP = `${moduleName}/GET_WEBSITE_SHOP`;
export const GET_WEBSITE_SHOP_SUCCESS = `${moduleName}/GET_WEBSITE_SHOP_SUCCESS`;
export const GET_WEBSITE_SHOP_FAIL = `${moduleName}/GET_WEBSITE_SHOP_FAIL`;

export const GET_WEBSITE_COLLECTIONS = `${moduleName}/GET_WEBSITE_COLLECTIONS`;
export const GET_WEBSITE_COLLECTIONS_SUCCESS = `${moduleName}/GET_WEBSITE_COLLECTIONS_SUCCESS`;
export const GET_WEBSITE_COLLECTIONS_FAIL = `${moduleName}/GET_WEBSITE_COLLECTIONS_FAIL`;

export const CLEAR_WEBSITE = `${moduleName}/CLEAR_WEBSITE`;
