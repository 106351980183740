import { moduleName } from './user';

export const RESET_PASSWORD = `${moduleName}/RESET_PASSWORD`;
export const RESET_PASSWORD_SUCCESS = `${moduleName}/RESET_PASSWORD_SUCCESS`;
export const RESET_PASSWORD_FAIL = `${moduleName}/RESET_PASSWORD_FAIL`;

export const CLEAR_PIN_FOR_STUDENT = `${moduleName}/CLEAR_PIN_FOR_STUDENT`;

export const GET_USER = `${moduleName}/GET_USER`;
export const GET_USER_SUCCESS = `${moduleName}/GET_USER_SUCCESS`;
export const GET_USER_FAIL = `${moduleName}/GET_USER_FAIL`;

export const UPDATE_TEACHER_PROFILE_INFO = `${moduleName}/UPDATE_TEACHER_PROFILE_INFO`;
export const UPDATE_TEACHER_PROFILE_INFO_SUCCESS = `${moduleName}/UPDATE_TEACHER_PROFILE_INFO_SUCCESS`;
export const UPDATE_TEACHER_PROFILE_INFO_FAIL = `${moduleName}/UPDATE_TEACHER_PROFILE_INFO_FAIL`;
export const CLEAR_TEACHER_PROFILE_INFO_ERROR = `${moduleName}/CLEAR_TEACHER_PROFILE_INFO_ERROR`;
export const SET_AVATAR_IMAGE_DATA = `${moduleName}/SET_AVATAR_IMAGE_DATA`;

export const GET_STUDENTS_LIST = `${moduleName}/GET_STUDENTS_LIST`;
export const GET_STUDENTS_LIST_SUCCESS = `${moduleName}/GET_STUDENTS_LIST_SUCCESS`;
export const GET_STUDENTS_LIST_FAIL = `${moduleName}/GET_STUDENTS_LIST_FAIL`;

export const UPDATE_STORE_NAME = `${moduleName}/UPDATE_STORE_NAME`;
export const UPDATE_STORE_NAME_SUCCESS = `${moduleName}/UPDATE_STORE_NAME_SUCCESS`;
export const UPDATE_STORE_NAME_FAIL = `${moduleName}/UPDATE_STORE_NAME_FAIL`;

export const CREATE_STUDENT = `${moduleName}/CREATE_STUDENT`;
export const CREATE_STUDENT_SUCCESS = `${moduleName}/CREATE_STUDENT_SUCCESS`;
export const CREATE_STUDENT_FAIL = `${moduleName}/CREATE_STUDENT_FAIL`;

export const EDIT_STUDENT = `${moduleName}/EDIT_STUDENT`;
export const EDIT_STUDENT_SUCCESS = `${moduleName}/EDIT_STUDENT_SUCCESS`;
export const EDIT_STUDENT_FAIL = `${moduleName}/EDIT_STUDENT_FAIL`;

export const DELETE_STUDENT = `${moduleName}/DELETE_STUDENT`;
export const DELETE_STUDENT_SUCCESS = `${moduleName}/DELETE_STUDENT_SUCCESS`;
export const DELETE_STUDENT_FAIL = `${moduleName}/DELETE_STUDENT_FAIL`;

export const GET_STUDENT = `${moduleName}/GET_STUDENT`;
export const GET_STUDENT_SUCCESS = `${moduleName}/GET_STUDENT_SUCCESS`;
export const GET_STUDENT_FAIL = `${moduleName}/GET_STUDENT_FAIL`;

export const CLEAR_STUDENTS_LIST = `${moduleName}/CLEAR_STUDENTS_LIST`;

export const SET_SELECTED_WEBSITE_ID = `${moduleName}/SET_SELECTED_WEBSITE_ID`;
export const CREATE_WEBSITE = `${moduleName}/CREATE_WEBSITE`;
export const CREATE_WEBSITE_SUCCESS = `${moduleName}/CREATE_WEBSITE_SUCCESS`;
export const CREATE_WEBSITE_FAIL = `${moduleName}/CREATE_WEBSITE_FAIL`;

export const GET_LIST_WEBSITE_TEMPLATES = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES`;
export const GET_LIST_WEBSITE_TEMPLATES_SUCCESS = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES_SUCCESS`;
export const GET_LIST_WEBSITE_TEMPLATES_FAIL = `${moduleName}/GET_LIST_WEBSITE_TEMPLATES_FAIL`;
export const GET_WEBSITE = `${moduleName}/GET_WEBSITE`;
export const GET_WEBSITE_SUCCESS = `${moduleName}/GET_WEBSITE_SUCCESS`;
export const GET_WEBSITE_FAIL = `${moduleName}/GET_WEBSITE_FAIL`;

export const EDIT_WEBSITE_SUCCESS = `${moduleName}/EDIT_WEBSITE_SUCCESS`;
// export const EDIT_WEBSITE_FAIL = `${moduleName}/EDIT_WEBSITE_FAIL`;
export const CLEAR_USER = `${moduleName}/CLEAR_USER`;

export const GET_STORE_REQUEST = `${moduleName}/GET_STORE_REQUEST`;
export const GET_STORE_SUCCESS = `${moduleName}/GET_STORE_SUCCESS`;
export const GET_STORE_FAIL = `${moduleName}/GET_STORE_FAIL`;

export const SSE_NOTIFY = `${moduleName}/SSE_NOTIFY`;
export const SSE_FAILURE = `${moduleName}/SSE_FAILURE`;
export const SSE_NOTIFY_SUCCESS = `${moduleName}/SSE_NOTIFY_SUCCESS`;

export const CLEAR_NEW_NOTIFICATIONS = `${moduleName}/CLEAR_NEW_NOTIFICATIONS`;
