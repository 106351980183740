import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Switch, SwitchProps } from '@material-ui/core';
import { InfoWrapper } from '../styles';

type ColoredDorPops = {
  color?: string | undefined;
};

export const InfoTitle = styled('div')({
  paddingBottom: 24,
});

export const TrackingWrapper = styled('div')({
  flexBasis: 250,
  paddingRight: 20,
});

export const StatusWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '& > div:first-child': {
    paddingRight: 10
  }
});

export const TrackingNumber = styled('div')({
  paddingBottom: 25,
})

export const StatusWrapperLabel = styled('div')({
  paddingBottom: 11,
});

export const ColoredDot = styled(({ color, ...otherProps }: ColoredDorPops) => (
  <span {...otherProps} />
))({
  height: 6,
  width: 6,
  marginRight: 7,
  verticalAlign: 'middle',
  backgroundColor: ({ color }: ColoredDorPops) => color,
  borderRadius: '50%',
  display: 'inline-block',
});

export const StyledSwitch = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & SwitchProps) => (
    <Switch
      disableRipple
      focusVisibleClassName="focusVisible"
      classes={{
        root: 'root',
        switchBase: 'switchBase',
        thumb: 'thumb',
        track: 'track',
        checked: 'checked',
        disabled: 'disabled',
      }}
      {...otherProps}
    />
  ))({
    '&&': {
      width: 42,
      height: 26,
      padding: 0,
    },
    '& .checked': {
      transform: 'translateX(16px)',
      color: '#FFFFFF',
      '& + .track': {
        backgroundColor: '#008DBA',
        opacity: 1,
        border: 'none',
      },
    },
    '& .switchBase': {
      padding: 4,
      '&:hover': {
        background: 'none',
      },
    },
    '& .thumb': {
      width: 18,
      height: 18,
      boxShadow: '0px 2px 2px rgba(68, 86, 108, 0.5)',
      color: '#FFFFFF',
    },
    '& .track': {
      borderRadius: 26 / 2,
      border: `1px solid #EFF4F8`,
      backgroundColor: '#EFF4F8',
      opacity: 1,
    },
    '& .disabled': {
      '& + .track': {
        backgroundColor: '#B3C0CE !important',
        opacity: 1,
      },
    },
  })
);

export const ViewOrderInfo = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0 40px 0 40px',
  marginTop: 30,
});

export const NoteOrderWrapper = styled('div')({
  flexBasis: 470,
  paddingLeft: 40,
});

export const ShippingAddressWrapper = styled('div')({
  paddingRight: 20,
  flexBasis: 200,
});

export const SuitableBox = styled('div')({
  paddingTop: 22,
  '& > div:first-child': {
    paddingBottom: 24,
  },
});

export const DescriptionWrapper = styled(InfoWrapper)({
  paddingLeft: 0,
});

export const DeliveryWrapper = styled('div')({
  maxWidth: 330,
});
