export type CashoutStatus = {
  status: string;
  shownStatus: string;
  color: string;
};

export default [
  {
    status: 'pending',
    shownStatus: 'Pending',
    color: '#0081FF',
  },
  {
    status: 'attention_changes',
    shownStatus: 'Attention Needed',
    color: '#FF3D57',
  },
  {
    status: 'approved',
    shownStatus: 'Approved',
    color: '#09B66D',
  },
];
