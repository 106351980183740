import serverKeys from './serverKeys';

const currentEnv = process.env.REACT_APP_ENV;
const secret = serverKeys.getSecret();

const environments = {
  local: {
    baseUrl: currentEnv,
    baseSSEUrl: '/api/event/get-updates',
    secret,
  },
};

export default environments['local'];
