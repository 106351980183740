import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { httpService, sessionService } from 'src/services';
import httpStatus from 'src/constants/httpStatus';
import oauthApi from 'src/api/oauthApi';
import { logout } from 'src/ducks/auth/actions';
import SSEventStream from './SSEventStream';

const { BAD_REQUEST, UNAUTHORIZED, INTERNAL_SERVER_ERROR } = httpStatus;

class Interceptors {
  store: any;

  constructor() {
    this.store = null;
  }

  private addInterceptors = () => {
    httpService.addResponseInterceptor(this.onResponseFulfilled, this.onRejected);
  };

  onResponseFulfilled = (response: AxiosResponse): AxiosRequestConfig => response.data;

  onRejected = (error: AxiosError) => {
    if (sessionService.isAuthenticated()) {
      if (error && error.response) {
        const {
          status,
          config: { url },
        } = error.response;

        if (status === INTERNAL_SERVER_ERROR) {
          // TODO: show message as notification for example
        }

        if (status === BAD_REQUEST && url && /oauth\/v2\/token/.test(url)) {
          this.logoutAndReset();
        }

        if (status === UNAUTHORIZED) {
          return this.updateToken();
        }
      }
    }

    return Promise.reject(error);
  };

  updateToken = async () => {
    try {
      httpService.removeAuthHeader();
      const currentRefreshToken = sessionService.getRefreshToken();

      if (currentRefreshToken) {
        const {
          access_token: accessToken,
          refresh_token: refreshToken,
          expires_in: expiresIn,
        }: any = await oauthApi.updateToken(currentRefreshToken);
        httpService.setAuthHeader(accessToken);
        sessionService.updateSession({
          accessToken,
          refreshToken,
          expiresIn,
        });
      }
      window.location.reload();
    } catch (error) {
      if (error.response?.status === UNAUTHORIZED) {
        this.logoutAndReset();
      }
    }
  };

  logoutAndReset = () => {
    this.store.dispatch(logout());
  };

  init = (store: any) => {
    this.store = store;
    this.addInterceptors();
  };
}

const interceptors = new Interceptors();

export default interceptors;
