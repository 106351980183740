import React from 'react';
import { useSelector } from 'react-redux';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import { loadingSelector } from '../ducks/auth/selectors';

type Props = {
  children: React.ReactNode;
};

const ProtectedRoute = ({ children: Component }: Props) => {
  const loading = useSelector(loadingSelector);
  return <>{loading ? <FullScreenPreloader /> : Component}</>;
};

export default ProtectedRoute;
