import { createSelector } from 'reselect';
import { StateOrderInterface, Error, ErrorData } from './shopOrder';

export interface RootState {
  shopOrder: StateOrderInterface;
}

const errorGetter = (state: any) => state.shopOrder.error;
export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        return err.message;
      });
    } else {
      return error.errors.message;
    }
  } else if (error?.code === 500) {
    return error?.message;
  }

  return null;
});

const orderCalculationGetter = (state: RootState) => state.shopOrder.calculation;
const shippingAddressFormGetter = (state: any) => state.form?.shippingAddress;
const orderGetter = (state: RootState) => state.shopOrder;
const loadingGetter = (state: RootState) => state.shopOrder.loading;

export const calculationSelector = createSelector(
  orderCalculationGetter,
  (calculation) => calculation,
);

export const isShippingAddressFormExistSelector = createSelector(
  shippingAddressFormGetter,
  (form) => !!form,
);

export const shippingAddressFormSelector = createSelector(
  shippingAddressFormGetter,
  (shippingAddress) => shippingAddress?.values,
);

export const stripeSessionIdSelector = createSelector(
  orderGetter,
  (state) => state.stripeSessionId,
);

export const orderStatusSelector = createSelector(orderGetter, (state) => state.status);

export const orderProductsAmountSelector = createSelector(
  orderGetter,
  (state) => state.productsAmount,
);

export const loadingOrderSelector = createSelector(loadingGetter, (loading) => loading);
