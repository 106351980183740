import React, { MouseEvent } from 'react';
//ignore @types for lib
// @ts-ignore
import Slider from 'react-slick';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { WebsiteContainer as TemplateContainer } from 'src/components/pages/websiteTemplates/websiteTemplate1/styles';
import {
  ContactUsProps,
  AddToCartButtonProps,
  ImageContainerProps,
} from 'src/components/pages/websiteTemplates/websiteTemplate1/styles';

type ShopNowButtonProps = {
  onClick?: any;
};

type ButtonProps = {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
};

export const WebsiteContainer = TemplateContainer;

export const HeroImageContainer = styled('div')({
  width: '100%',
  background: '-webkit-linear-gradient(right, rgba(0, 141, 186, 0.19) 35%, #FFFFFF 0%)',
});

export const WebsiteNameContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    maxWidth: 1200,
    width: '80%',
    margin: '0 auto',
    padding: '80px 0 30px 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 38,
    fontWeight: 800,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    '& > a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '@media (max-width: 768px)': {
      padding: '40px 0 20px 0',
    },
  })
);

export const CartContainer = styled('div')({
  display: 'flex',
  fontSize: 24,
  fontWeight: 300,
  '& svg:first-child': {
    paddingRight: 15,
  },
  '& svg:nth-of-type(2)': {
    paddingRight: 5,
  },
});

export const WebsiteName = styled('div')({
  fontSize: 35,
  textTransform: 'capitalize',
  wordBreak: 'break-word',
  '@media (max-width: 840px)': {
    textAlign: 'center',
  },
});

export const ImageWithTaglineContainer = styled('div')({
  maxWidth: 'auto',
  width: '80%',
  margin: '0 auto',
  '& > img': {
    maxWidth: '100%',
    height: 498,
    width: 1280,
    objectFit: 'cover',
  },
  '& > a': {
    paddingLeft: 0,
  },
  '@media (max-width: 768px)': {
    padding: 0,
    marginTop: 20,
    '& > img': {
      maxWidth: '100%',
      height: 273,
      width: 700,
      objectFit: 'cover',
    },
  },
});

export const Tagline = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    wordBreak: 'break-word',
    fontSize: 42,
    fontWeight: 800,
    paddingTop: 20,
    textTransform: 'capitalize',
    '@media (max-width: 768px)': {
      fontSize: 36,
    },
  })
);

export const ShopNowButton = withTheme(
  styled(({ theme, onClick, ...otherProps }: WithTheme & ShopNowButtonProps) => (
    <button onClick={onClick} {...otherProps} />
  ))({
    fontWeight: 500,
    fontSize: 29,
    border: ({ onClick }: ShopNowButtonProps & WithTheme) =>
      onClick ? '1px solid #44566C' : 'none',
    marginTop: 20,
    textAlign: 'left',
    padding: 0,
    cursor: 'pointer',
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
    '&:focus': {
      outline: 'none',
    },
    '& > svg': {
      width: 190,
      height: 18,
      paddingLeft: 8,
    },
    '@media (max-width: 768px)': {
      fontSize: 25,
    },
  })
);

export const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  '& > a': {
    marginTop: 30,
  }
});

export const OutOfStock = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    position: 'absolute',
    minWidth: '100%',
    textAlign: 'center',
    zIndex: 1,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: 40,
    fontWeight: 300,
  })
);

export const CardInfo = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.primary,
    flex: 1,
    wordBreak: 'break-word',
  })
);

export const CardName = styled('div')({
  fontWeight: 700,
  fontSize: 23,
  marginBottom: 10,
  textTransform: 'capitalize',
  wordBreak: 'break-word',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

export const ImageContainer = withTheme(
  styled(({ theme, isOutOfStock, ...otherProps }: WithTheme & ImageContainerProps) => (
    <div {...otherProps} />
  ))({
    position: 'relative',
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: ({ isOutOfStock }: ImageContainerProps) => (isOutOfStock ? 'default' : 'pointer'),
    '& > img': {
      width: '100%',
      height: 'auto',
      opacity: ({ isOutOfStock }: ImageContainerProps) => (isOutOfStock ? 0.4 : 1),
      borderRadius: 8,
    },
  })
);

export const Price = styled('div')({
  fontWeight: 700,
  fontSize: 23,  
  marginBottom: 10,
});

export const FreeShipping = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontWeight: 500,
    fontSize: 18,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.success,
    textTransform: 'capitalize',
    marginBottom: 25,
  })
);

export const ViewDetailsButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ButtonProps) => <button {...otherProps} />)
    ({
      border: 'none',
      width: '100%',
      borderRadius: 6,
      padding: '15px 30px',
      fontSize: 24,
      fontWeight: 500,
      textTransform: 'capitalize',
      cursor: 'pointer',
      color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.btn,
      '&:hover': {
        backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.btnHover,
        boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.btn,
      },
      '&:focus': {
        outline: 'none',
      },
    })
);

export const CarouselWrapper = withTheme(
  styled(({ theme, ...otherProps }: any) => <Slider {...otherProps} />)({
    borderRadius: 8,
    overflow: 'visible',
    filter:
      'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(-2px 4px 20px rgba(68, 86, 108, 0.16))',
    '& .slick-prev': {
      left: '-45px !important',
      width: 'auto',
      height: 'auto',
    },
    '& .slick-next': {
      right: '-45px !important',
      width: 'auto',
      height: 'auto',
    },
    '& button': {
      opacity: 0,
      color: '#44566C',
      zIndex: 100,
      '&::before': {
        fontSize: 68,
      },
      '&:hover': {
        opacity: '1 !important',
      },
    },
    '& .CarouselItem ~ div': {
      '&:nth-child(2)': {
        right: -32,
      },
      '&:nth-child(3)': {
        left: -32,
      },
      '&:hover': {
        '& button': {
          opacity: 1,
        },
      },
    },
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  })
);

export const AddToCartButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & AddToCartButtonProps) => (
    <button {...otherProps} />
  ))({
    border: ({ theme }: WithTheme) => `1px solid ${theme.customPalette.text.primary}`,
    padding: '10px 0',
    fontSize: 20,
    fontWeight: 300,
    cursor: 'pointer',
    width: 265,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.header,
    '&:focus': {
      outline: 'none',
    },
    '&:disabled': {
      cursor: 'unset',
      opacity: 0.5,
    },
  })
);

export const AboutUsContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.dark,
    color: ({ theme }: WithTheme) => theme.siteTemplatePalette.text.light,
    textAlign: 'center',
    margin: '100px auto 0 auto',
    padding: '100px 0 100px 0',
    '@media (max-width: 768px)': {
      padding: '60px 0 60px 0',
    },
  })
);

export const AboutUsTitle = styled('div')({
  fontSize: 80,
  fontWeight: 800,
  '@media (max-width: 768px)': {
    fontSize: 50,
  },
});

export const AboutUsDescription = styled('div')({
  fontFamily: 'Open Sans',
  fontSize: 33,
  fontWeight: 400,
  paddingTop: 30,
  maxWidth: 1200,
  margin: 'auto',
  width: '80%',
  wordBreak: 'break-word',
  '@media (max-width: 768px)': {
    fontSize: 26,
  },
});

export const FooterContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 320,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    textAlign: 'center',
  })
);

export const ContactUsButtonContainer = styled('div')({
  position: 'relative',
  marginTop: 35,
  '& > svg': {
    position: 'absolute',
    width: 22,
    top: 17,
    left: 25,
  },
});

export const ContactUsButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ContactUsProps) => <input {...otherProps} />)({
    width: 300,
    height: 50,
    border: ({ theme }: WithTheme) => `1px solid ${theme.customPalette.text.primary}`,
    fontSize: 24,
    fontWeight: 300,
    cursor: 'pointer',
    textTransform: 'capitalize',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.header,
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.whiteBtnHover,
      boxShadow: ({ theme }: WithTheme) => theme.siteTemplatePalette.shadow.whiteBtn,
    },
  })
);

export const LearnMore = styled('a')({
  fontSize: 18,
  color: 'inherit',
  display: 'block',
  padding: '30px 0 60px 0',
});

export const SocialNetworksContainer = styled('div')({});

export const LoadMoreContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    maxWidth: 1180,
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '50px',
  })
);

export const Line = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    borderTop: ({ theme }: WithTheme) => `1px solid ${theme.siteTemplatePalette.text.secondary}`,
  })
);

export const IconWrapper = styled('svg')({
  fill: 'black',
  width: 22,
  height: 15,
  marginLeft: 15,
});

export const LoadMoreButton = withTheme(
  styled(({ theme, ...otherProps }: WithTheme & ButtonProps) => <button {...otherProps} />)
    ({
      minWidth: 200,
      backgroundColor: ({ theme }: WithTheme) => theme.siteTemplatePalette.background.main,
      border: 'none',
      fontSize: 24,
      fontWeight: 700,
      textTransform: 'capitalize',
      cursor: 'pointer',
    })
);
