import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import App from 'src/App';
import store from './redux/store';
import generalStyles from 'src/theme/general';
import createMyTheme from './components/styles';

const theme = createMyTheme(generalStyles);

render(
  <MuiThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>,
  document.getElementById('root')
);
