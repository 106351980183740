import {
  GET_LIST_WEBSITE_TEMPLATES,
  GET_LIST_WEBSITE_TEMPLATES_SUCCESS,
  GET_LIST_WEBSITE_TEMPLATES_FAIL,
  SET_SELECTED_WEBSITE_ID,
  SET_UPLOAD_WEBSITE_HERO_IMAGE,
  CREATE_WEBSITE,
  CREATE_WEBSITE_SUCCESS,
  CREATE_WEBSITE_FAIL,
  EDIT_WEBSITE_SUCCESS,
  GET_WEBSITE,
  GET_WEBSITE_SUCCESS,
  GET_WEBSITE_FAIL,
  CHECK_BUSINESS_NAME_SUCCESS,
  CHECK_BUSINESS_NAME_FAIL,
  BUSINESS_NAME_CHANGED,
  GET_WEBSITE_SHOP,
  GET_WEBSITE_SHOP_SUCCESS,
  GET_WEBSITE_SHOP_FAIL,
  CLEAR_WEBSITE,
  GET_WEBSITE_COLLECTIONS,
  GET_WEBSITE_COLLECTIONS_FAIL,
  GET_WEBSITE_COLLECTIONS_SUCCESS,
} from './constants';
import { all, put, select, takeLatest, debounce, take } from 'redux-saga/effects';
import { initialize, change } from 'redux-form';
import websiteApi from 'src/api/websiteApi';
import oauthApi from 'src/api/oauthApi';
import { getShopProducts } from 'src/ducks/shopProducts/actions';
import { ProductChoice } from 'src/ducks/shopProducts/shopProducts';
import { WebsiteShop } from './actions';
import { removeExpirationShoppingCart } from 'src/utils/helpers';
import { S3Image } from 'src/constants/commonTypes';
import {
  UPDATE_STORE_NAME,
  UPDATE_STORE_NAME_SUCCESS,
  UPDATE_STORE_NAME_FAIL,
} from 'src/ducks/user/constants';
import { WebsiteCollection } from 'src/constants/productsTypes';
import { PaginatedHttpResponse } from 'src/interfaces/http';

export const moduleName = 'website';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

export type CreatedWebsite = {
  id?: number;
  description: string;
  email: string;
  name?: string;
  tagline: string;
  heroImage: string;
  store: { id: number };
  template: { id: number };
};

export type StateWebsiteInterface = {
  loading: boolean;
  error: Error | null;
  templateID: number;
  heroImage: string;
  websiteURL: string;
  arrayImages: S3Image[];
  website: CreatedWebsite | any;
  listTemplate: any;
  message: String;
  collections: WebsiteCollection[];
};

export type Error = {
  error: boolean;
  errors:
  | ErrorData[]
  | {
    message: string;
    title: string;
  };
};

export type ErrorData = {
  field?: string;
  code?: string;
  invalid_value: string;
  message: string;
};

export type ShoppingCartProps = {
  product: ProductProps;
  count: number;
  productChoice: ProductChoice;
  // price: number
  // total: number
};

export type ProductProps = {
  id: number;
  description: string;
  imagesGallery: any;
  inventory: number;
  isDigitalProduct?: boolean;
  isLocalDelivery?: boolean;
  isLocalPickup?: boolean;
  isPhysicalProduct?: boolean;
  shippingType: string;
  websiteCollection?: WebsiteCollection;
  note?: string;
  name: string;
  price: number;
};

export const websiteState: StateWebsiteInterface = {
  error: null,
  loading: false,
  templateID: 0,
  heroImage: '',
  websiteURL: '',
  arrayImages: [],
  website: {
    heroImages: [],
  },
  listTemplate: [],
  message: '',
  collections: []
};

export default function reducer(
  state: StateWebsiteInterface = websiteState,
  { type, payload, error }: ActionType,
) {
  switch (type) {
    case CREATE_WEBSITE:
    case GET_WEBSITE:
    case GET_WEBSITE_SHOP:
      return { ...state, loading: true, error: null };

    case SET_SELECTED_WEBSITE_ID:
      return {
        ...state,
        templateID: payload,
      };

    case SET_UPLOAD_WEBSITE_HERO_IMAGE:
      return {
        ...state,
        heroImage: payload === undefined ? state.heroImage : payload,
        arrayImages: payload === undefined ? state.arrayImages : payload,
        error: null,
      };

    case GET_LIST_WEBSITE_TEMPLATES_SUCCESS:
      return {
        ...state,
        listTemplate: payload,
        templateID: payload.data[0].id,
        error: null,
      };

    case CREATE_WEBSITE_SUCCESS:
    case EDIT_WEBSITE_SUCCESS:
    case GET_WEBSITE_SUCCESS:
    case GET_WEBSITE_SHOP_SUCCESS:
      return {
        ...state,
        website: payload,
        templateID: payload.template.id,
        arrayImages: payload.heroImages,
        websiteURL: payload.path,
        loading: false,
        error: null,
      };

    case CLEAR_WEBSITE:
      return websiteState;

    case GET_WEBSITE_FAIL:
    case GET_LIST_WEBSITE_TEMPLATES_FAIL:
    case CREATE_WEBSITE_FAIL:
    case GET_WEBSITE_SHOP_FAIL:
    case CHECK_BUSINESS_NAME_FAIL:
      return {
        ...state,
        loading: false,
        error,
      };

    case CHECK_BUSINESS_NAME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        website: {
          ...state.website,
          path: payload.websiteURL,
        },
      };

    case UPDATE_STORE_NAME_SUCCESS:
      return {
        ...state,
        message:
          'Wait a minute! Changing your EdCorp Name has also updated your Website URL. If this was a mistake, you can always change it back!',
      };

    case UPDATE_STORE_NAME_FAIL:
    case UPDATE_STORE_NAME:
      return {
        ...state,
        message: '',
      };

    case GET_WEBSITE_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: payload
      };

    case GET_WEBSITE_COLLECTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error,
      }
    default:
      return state;
  }
}

function* getListWebsiteTemplatesSaga() {
  try {
    const response = yield websiteApi.getListTemplate();
    yield put({ type: GET_LIST_WEBSITE_TEMPLATES_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: GET_LIST_WEBSITE_TEMPLATES_FAIL, error: err });
  }
}

function* getWebsiteSaga({ payload }: ActionType) {
  let perPage = 10;

  try {
    if (payload) {
      const response = yield websiteApi.getWebsite(payload);
      yield put(
        initialize('websiteDescription', {
          name: response.name,
          email: response.email,
          tagline: response.tagline,
          aboutAs: response.description,
          websiteURL: `http://edcorps.org/shop/${response.path}`,
        }),
      );
      yield put({ type: GET_WEBSITE_SUCCESS, payload: response });
      if (window.location.pathname.includes('shop')) {
        if (response.template.id === 1) {
          perPage = 12;
        }
        yield put(getShopProducts({ count: perPage, path: response.path }));
      }
    }
  } catch (err) {
    yield put({ type: GET_WEBSITE_FAIL, error: err });
  }
}

function* createWebsiteSaga({ payload: { email, tagline, aboutAs } }: ActionType) {
  const { website, templateID, heroImage, arrayImages } = yield select((state) => state.website);
  const { user } = yield select((state) => state.user);
  const findStore = user.accesses.map((item: any) => item.store)[0];
  try {
    const data = {
      email,
      tagline,
      description: aboutAs,
      template: templateID,
      heroImage,
    };
    if (!website.id) {
      //TODO refactor code
      const response = yield websiteApi.createWebsite({ ...data, store: findStore.id }); //this is User store
      yield put({ type: CREATE_WEBSITE_SUCCESS, payload: response });
      document.location.reload();
    } else {
      const websiteData = { ...data } as any;
      delete websiteData?.name;

      if (Array.isArray(arrayImages) && !heroImage) {
        delete websiteData.heroImage;
      }

      const response = yield websiteApi.editWebsite(website.id, {
        ...websiteData,
        store: website.store.id,
      });

      yield put({ type: EDIT_WEBSITE_SUCCESS, payload: response });
      yield put({ type: GET_WEBSITE, payload: response.id });
    }
  } catch (err) {
    yield put({ type: CREATE_WEBSITE_FAIL, error: err.data });
  }
}

function* checkBusinessNameSaga({ payload: name }: ActionType) {
  const user = yield select((state) => state.user.user);
  const {
    store: { id, name: currentName },
  } = user.accesses[0];

  try {
    if (currentName !== name) {
      yield put({ type: UPDATE_STORE_NAME, payload: { name, id } });
    }

    const {
      payload: { path },
    } = yield take(UPDATE_STORE_NAME_SUCCESS);
    const websiteURL = `http://edcorps.org/shop/${path}`;

    yield put({
      type: CHECK_BUSINESS_NAME_SUCCESS,
      payload: { websiteURL },
    });
    yield put(change('websiteDescription', 'websiteURL', websiteURL));
  } catch (err) {
    yield put({ type: CHECK_BUSINESS_NAME_FAIL, error: err.data });
  }
}

function* getWebsiteShopSaga({ payload: path }: WebsiteShop) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    
    removeExpirationShoppingCart('shoppingCart');
    const response = yield websiteApi.getWebsiteShop(path, anonymousToken);

    yield put({ type: GET_WEBSITE_SHOP_SUCCESS, payload: { ...response, path } });
  } catch (err) {
    yield put({ type: GET_WEBSITE_SHOP_FAIL, error: err.data });
  }
}

/**
 * Fetches website collections by website ID.
 * @see {WebsiteCollection} for reference
 */
function* getWebsiteCollectionsSaga({
  payload: {
    websiteId
  }
}: ActionType): Generator {
  try {
    if (!websiteId) {
      throw new Error('getCollectionsSaga. No websiteId provided');
    }
    const oauthRes: any = yield oauthApi.getAccessToken();

    let response = (
      yield websiteApi.getCollections(websiteId, oauthRes?.access_token)
    ) as PaginatedHttpResponse<WebsiteCollection[]>;

    /**
     * @todo Refactor code below once BE fully implements pagination and live search algorythm. Pagination is not enough.
     * For now BA concerns we shall have a tiny amount of collections, so we re-fetch all collections
     * one time since they are small structures in size and it's more efficient to fetch them all once
     * than making a ton of requests to BE once user scrolls them infinitely in current implementation.
     */
    const { _total, _count } = response || {};
    if (_total && _count && _total > _count) {
      response = (yield websiteApi.getCollections(
        websiteId,
        oauthRes?.access_token,
        { perPage: _total },
      )
      ) as PaginatedHttpResponse<WebsiteCollection[]>;
    }

    const collections = response?.data as WebsiteCollection[] || [];
    yield put({ type: GET_WEBSITE_COLLECTIONS_SUCCESS, payload: collections })
  } catch (err) {
    yield put({ type: GET_WEBSITE_COLLECTIONS_FAIL, error: err.data })
  }
}
export const saga = function* () {
  yield all([
    takeLatest(GET_LIST_WEBSITE_TEMPLATES, getListWebsiteTemplatesSaga),
    takeLatest(CREATE_WEBSITE, createWebsiteSaga),
    takeLatest(GET_WEBSITE, getWebsiteSaga),
    debounce(500, BUSINESS_NAME_CHANGED, checkBusinessNameSaga),
    takeLatest(GET_WEBSITE_SHOP, getWebsiteShopSaga),
    /** @todo Add throttle to fetching collections as soon as BE implements live search algorythm */
    takeLatest(GET_WEBSITE_COLLECTIONS, getWebsiteCollectionsSaga),
  ]);
};
