import { styled } from '@material-ui/core';
import defaultImage from 'src/assets/images/default.png';

export const Container = styled('div')({
  background: `url(${defaultImage})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
});

export const PosterImageText = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  color: '#44566C',
  fontSize: 15,
});

export const ErrorMessage = styled('div')({
  color: 'red',
});

export const Remove = styled('span')({
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  right: 0,
  width: 24,
  height: 24,
  background: '#EAEDF0',
  borderRadius: '50%',
});

export const CurrentImg = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  borderRadius: 10,
  height: 633,
  width: 633,
  position: 'relative',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
  zIndex: 2,
  '@media (max-width: 1540px)': {
    width: 500,
    height: 500,
  },
});

export const UploadProductImageText = styled('p')({
  fontWeight: 500,
  lineHeight: '24px',
  paddingBottom: 20,
})
