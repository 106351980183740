import React from 'react';
import { Link } from 'react-router-dom';
import { TableBody, TableRow, Paper, TableContainer, IconButton } from '@material-ui/core';
import { StudentList } from 'src/ducks/user/user';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { ResetPin } from './resetPin';
import { DeleteStudent } from './deleteStudent';
import { EditIcon } from 'src/components/common/Icons';
import {
  TableWrapper,
  Head,
  StyledBodyTableRow,
  StyledTableCell,
  StyledTableCellInfo,
  ActionContainer,
} from './styles';

type StudentsListProps = {
  isTeacher: boolean;
  studentsList: any;
  handleClearPin: () => void;
  handleResetPassword: (id: number) => void;
  handleRemoveStudent: (studentId: number) => void;
  loadMore: () => void;
  error: string | (string | undefined)[] | null;
};

const StudentsList = (props: StudentsListProps) => {
  const {
    isTeacher,
    studentsList,
    handleClearPin,
    handleResetPassword,
    error,
    handleRemoveStudent,
    loadMore,
  } = props;

  useInfiniteScroll({ loadMore });

  const renderTableActions = (id: number) => (
    <>
      <StyledTableCell align="left">
        <ResetPin
          userId={id}
          handleClearPin={handleClearPin}
          handleResetPassword={handleResetPassword}
        />
      </StyledTableCell>
      <ActionContainer align="left">
        <IconButton>
          <Link to={`/students/${id}`}>
            <div>
              <EditIcon />
            </div>
          </Link>
        </IconButton>
      </ActionContainer>
      <ActionContainer align="left">
        <IconButton>
          <DeleteStudent studentId={id} error={error} handleRemoveStudent={handleRemoveStudent} />
        </IconButton>
      </ActionContainer>
    </>
  );

  const renderUsersTable = () => (
    <TableContainer component={Paper}>
      <TableWrapper>
        <Head>
          <TableRow>
            <StyledTableCell style={{ width: '35%' }}>Student Name</StyledTableCell>
            <StyledTableCell align="left" style={{ width: '40%' }}>
              Student Username
            </StyledTableCell>
            {isTeacher && (
              <>
                <StyledTableCell align="left">Management</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
              </>
            )}
          </TableRow>
        </Head>
        <TableBody>
          {studentsList.map(({ id, displayName, username }: StudentList) => (
            <StyledBodyTableRow key={id}>
              <StyledTableCellInfo align="left">{displayName}</StyledTableCellInfo>
              <StyledTableCellInfo align="left">{username}</StyledTableCellInfo>
              {isTeacher && renderTableActions(id)}
            </StyledBodyTableRow>
          ))}
        </TableBody>
      </TableWrapper>
    </TableContainer>
  );

  return renderUsersTable();
};

export default StudentsList;
