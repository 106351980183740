import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { Backdrop, InnerElement } from './styles';

const FullScreenPreloader = () => {
  return (
    <Backdrop>
      <InnerElement>
        <CircularProgress />
      </InnerElement>
    </Backdrop>
  );
};

export default FullScreenPreloader;
