import React from 'react';
import { MenuItem } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';
import { ShoppingCartContainer, Price } from '../shoppingCart/styles';
import { AddToCartButtonProps } from '../websiteTemplates/websiteTemplate1/styles';

export const ProductDetailsContainer = styled(ShoppingCartContainer)({
  width: '100%',
});

export const ProductCardContainer = styled('div')({
  display: 'flex',
  margin: '60px',
  '@media (max-width: 768px)': {
    margin: '30px 40px',
  },
});

export const ShopProductContainer = styled('div')({
  display: 'flex',
  '@media (max-width: 1024px)': {
    flexDirection: 'column',
  },
});

export type StylesProps = {
  styles: any;
};

type CounterContainerProps = {
  isOutOfStock?: boolean,
  styles: StylesProps,
}

export const CardInfoContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 450,
    paddingLeft: 64,
    paddingRight: 15,
    wordBreak: 'break-word',
    '@media (max-width: 1024px)': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }),
);

export const CardInfo = styled('div')({
  flexGrow: 1,
});

export const CardContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 886px)': {
    flexDirection: 'column-reverse',
  },
});

export const ProductImageContainer = styled('div')({
  width: 600,
  '@media (max-width: 1440px)': {
    width: 400,
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
});

export const ProductPrice = styled(Price)({
  fontWeight: 500,
});

export const CardName = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />))({
  fontWeight: ({ styles }: StylesProps) => styles?.fontWeight,
  fontSize: 30,
  lineHeight: '35px',
  '& > span': {
    verticalAlign: 2,
    fontSize: 18,
    fontWeight: 500,
    color: ({ styles }: StylesProps) => styles?.successColor,
    textTransform: 'capitalize',
  },
  '@media (max-width: 1440px)': {
    fontSize: 24,
  },
});

export const CardDescription = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />))({
  paddingTop: 24,
  fontSize: 18,
  lineHeight: '24px',
  fontWeight: ({ styles }: StylesProps) => styles?.fontWeight,
  '@media (max-width: 1440px)': {
    fontSize: 14,
  },
});

export const AddToCartButton = styled(({ styles, ...otherProps }: AddToCartButtonProps & StylesProps) => (
  <button {...otherProps} />))({
  width: '100%',
  marginTop: 10,
  border: ({ styles }: StylesProps) => styles?.checkoutButton.border,
  borderRadius: ({ styles }: StylesProps) => styles?.checkoutButton.borderRadius,
  textAlign: 'center',
  padding: '16px 0',
  fontSize: 24,
  fontWeight: ({ styles }: StylesProps) => styles?.checkoutButton.fontWeight,
  cursor: 'pointer',
  color: ({ styles }: StylesProps) => styles?.orderColor,
  backgroundColor: ({ styles }: StylesProps) => styles?.confirmAddressBackground,
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    cursor: 'default',
    color: 'rgba(68,86,108,0.4)',
  },
});

export const SelectorContainer = styled('div')({
  flexGrow: 2,
  paddingTop: 13,
  paddingBottom: 120,
  width: '100%',
});

export const SelectorLabel = styled('div')({
  color: '#8697A8',
  fontSize: 18,
  fontWeight: 300,
  paddingBottom: 8,
  paddingTop: 15,
  textTransform: 'capitalize',
});

export const CounterContainer = styled(({ styles, isOutOfStock, ...otherProps }: any) => (
  <div {...otherProps} />))({
  fontSize: 24,
  color: ({ styles }: any) => styles?.orderColor,
  textAlign: 'start',
  paddingTop: 44,
  '& > span': {
    cursor: 'pointer',
    '&:last-child': {
      pointerEvents: ({ isOutOfStock }: any) => (isOutOfStock ? 'none' : 'auto'),
      opacity: ({ isOutOfStock }: any) => (isOutOfStock ? 0.5 : 1),
    },
    '&:nth-of-type(2)': {
      cursor: 'default',
      padding: '0 20px',
    },
  },
});
