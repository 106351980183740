import { createSelector } from 'reselect';
import { StateShopProductsInterface, ErrorData, Error } from './shopProducts';

export interface RootState {
  shopProducts: StateShopProductsInterface;
}

const shopProductsGetter = (state: RootState) => state.shopProducts;
const errorGetter = (state: any) => state.shopProducts.error;

export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => err.message);
    } else {
      return error.errors.message;
    }
  }

  return null;
});

export const shopProductsSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.shopProductsList,
);

export const shopOtherProductsSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.shopProductsOthers,
);

export const shopProductsByIdsSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.shopProductsByIdsList,
);

export const isValidProductsSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.isValidProducts,
);

export const invalidProductsSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.invalidProductsIds,
);

export const shopProductSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.shopProduct,
);

export const loadingShopProductSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.loading,
);

export const getShippingMethodSelector = createSelector(
  shopProductsGetter,
  (shopProducts) => shopProducts.shippingMethod,
);


