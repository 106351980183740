import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getWebsiteShop } from 'src/ducks/website/actions';
import { websiteSelector, websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import { orderProductsAmountSelector } from 'src/ducks/shopOrder/selectors';
import ShopProductDetails from './ShopProductDetails';
import WebsiteWrapper1
  from 'src/components/pages/websiteTemplates/websiteTemplate1/WebsiteWrapper1';
import WebsiteWrapper2
  from 'src/components/pages/websiteTemplates/websiteTemplate2/WebsiteWrapper2';
import WebsiteWrapper3
  from 'src/components/pages/websiteTemplates/websiteTemplate3/WebsiteWrapper3';
import { getItemByKeyFromStorage, LocalStorageType } from 'src/utils/helpers';
import { BackToCollection } from '../products/backToCollection';

const ShopProductDetailsContainer = () => {
  const dispatch = useDispatch();
  const { websitePath, productId } = useParams();
  const templateId = useSelector(websiteTemplateIDSelector);
  const { email, name } = useSelector(websiteSelector);
  const productsAmount = useSelector(orderProductsAmountSelector);
  const products = getItemByKeyFromStorage('shoppingCart');
  const allProductsAmount =
    products?.reduce((acc: number, currVal: LocalStorageType) => acc + currVal.count, 0) || 0;

  useEffect(() => {
    window.document.title = websitePath;
    dispatch(getWebsiteShop(websitePath));
  }, [dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  const chooseWebsiteWrapper = () => {
    switch (templateId) {
      case 1:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <BackToCollection />
            <ShopProductDetails websitePath={websitePath} productId={productId} />
          </WebsiteWrapper1>
        );
      case 2:
        return (
          <WebsiteWrapper2
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <BackToCollection />
            <ShopProductDetails websitePath={websitePath} productId={productId} />
          </WebsiteWrapper2>
        );
      case 3:
        return (
          <WebsiteWrapper3
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <BackToCollection />
            <ShopProductDetails websitePath={websitePath} productId={productId} />
          </WebsiteWrapper3>
        );
      default:
        return (
          <WebsiteWrapper1
            email={email}
            websitePath={websitePath}
            name={name}
            productsAmount={productsAmount || allProductsAmount}
          >
            <BackToCollection />
            <ShopProductDetails websitePath={websitePath} productId={productId} />
          </WebsiteWrapper1>
        );
    }
  };

  if (email && templateId) {
    return chooseWebsiteWrapper();
  }
  return null;
};


export default ShopProductDetailsContainer;
