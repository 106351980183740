import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

const popupBorderRadius = 10;

export const PopupDefault = styled('div')({
  backgroundColor: 'white',
  boxShadow: '0px 4px 8px rgba(169, 194, 209, 0.25), 0px 11px 32px rgba(169, 194, 209, 0.25)',
  borderRadius: popupBorderRadius,
  paddingTop: 20,
  paddingBottom: 60,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  height: 'auto',
});

export const Main = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: '100%',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.greyBg,
  })
);

export const PopupMainContainer = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    padding: '10px 70px',
    textAlign: 'center',

    '& span': {},
  })
);

export const PopupText = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <p {...otherProps} />)({
    fontSize: 20,
    lineHeight: '23px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
  })
);

export const PopupHeader = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <h3 {...otherProps} />)({
    margin: '0 auto',
    fontSize: 28,
    lineHeight: '33px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.highlight,
  })
);

export const PopupHint = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <span {...otherProps} />)({
    fontSize: 14,
    lineHeight: '16px',
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
  })
);

export const PopupButtonsContainer = styled('div')({
  position: 'absolute',
  bottom: '0',
  height: 56,
  width: '100%',
  display: 'flex',
});

export const PopupButton = withTheme(
  styled(({ theme, isSecondary, ...otherProps }: any) => <button {...otherProps} />)({
    flex: 1,
    outline: 'none',
    cursor: 'pointer',
    height: '100%',
    border: 'none',
    background: ({ theme }: WithTheme) => theme.customPalette.modalButtons.bg,
    color: ({ isSecondary, theme }: any) =>
      isSecondary
        ? theme.customPalette.modalButtons.secondaryColor
        : theme.customPalette.modalButtons.color,
    '&:hover': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.modalButtons.hoverBg,
    },
    '&:active': {
      backgroundColor: ({ theme }: WithTheme) => theme.customPalette.modalButtons.activeBg,
      color: 'black',
    },
    '&:first-child': {
      borderBottomLeftRadius: popupBorderRadius,
    },
    '&:last-child': {
      borderBottomRightRadius: popupBorderRadius,
    },
    '&:not(:last-child)': {
      marginRight: 2,
    },
  })
);
