export default {
  months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
  tooltips: {
    toolkit:
      '“Total” is how much your customer paid for their purchase. It includes the sale, sales tax, and any shipping charges associated with the order.',
  },
  sliderSettings: {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  },
  defaultObjForProductChoices: {
    price: null,
    inventory: null,
    width: null,
    height: null,
    length: null,
  },
  notificationsTypes: {
    order: 'new_ecommerce_order',
    announcement: 'announcement',
    toolkit: 'toolkit_bonus_approved',
    cashOut: 'cash_out_approved',
    cashOutNeeded: 'cash_out_attention_needed',
  },
};
