import { createSelector } from 'reselect';
import { StateCashoutInterface, Error, ErrorData } from './cashout';

export interface RootState {
  cashout: StateCashoutInterface;
}

const cashoutGetter = (state: RootState) => state.cashout;
const loadingGetter = (state: RootState) => state.cashout.loading;
const errorGetter = (state: RootState) => state.cashout.error;

export const cashoutListSelector = createSelector(
  cashoutGetter,
  (cashout: StateCashoutInterface) => cashout.cashoutList
);
export const cashoutSelector = createSelector(
  cashoutGetter,
  (cashout: StateCashoutInterface) => cashout.cashout
);
export const loadingCashoutSelector = createSelector(loadingGetter, (loading) => loading);

export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        return err.message;
      });
    } else {
      return error.errors.message;
    }
  }

  return null;
});
