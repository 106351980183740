import { GET_HOME_LIST, CLEAR_HOME_LIST } from './constants';

export type GetHomeList = {
  type: string;
  payload: {
    page: number;
    perPage: number;
  };
};

export function getHomeList(page: number = 1, perPage: number = 10) {
  return {
    type: GET_HOME_LIST,
    payload: { page, perPage },
  };
}

export function clearHomeList() {
  return { type: CLEAR_HOME_LIST };
}