import { styled } from '@material-ui/core/styles';

export const FullNameContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

export const NameInput = styled('div')({
  width: 170,
});

export const ClassroomConcentrationLabelContainer = styled('div')({
  margin: '24px 0 20px',
});

export const BusinessNameWrapper = styled('div')({
  position: 'relative',
  '& input': {
    paddingRight: 40,
  },
});

export const HintWrapper = styled('div')({
  position: 'absolute',
  right: 35,
  top: 47,
});
