export type StylesTemplate = {
  id: number;
  orderBackground: string;
  orderColor: string;
  cartColor: string;
  boxShadow: string;
  fontWeight: number;
  titleFontWeight: number;
  horizontalLineColor: string;
  confirmAddressBackground: string;
  changeAddressBackground: string;
  changeAddressColor: string;
  checkoutButton: {
    border: string;
    borderRadius: string;
    color: string;
    fontWeight: number;
    backgroundColor: string;
  };
};

export default [
  {
    id: 1,
    orderBackground: '#44566C',
    orderColor: '#FFFFFF',
    cartColor: '#44566C',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25), -2px 4px 20px rgba(68, 86, 108, 0.16)',
    fontWeight: 400,
    titleFontWeight: 500,
    horizontalLineColor: '#FFFFFF',
    confirmAddressBackground: '#44566C',
    changeAddressBackground: '#FFFFFF',
    changeAddressColor: '#44566C',
    successColor: 'rgb(0 132 76)',
    checkoutButton: {
      border: '2px solid #44566C',
      borderRadius: '15px',
      color: '#44566C',
      fontWeight: 600,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    id: 2,
    orderBackground: '#FFFFFF',
    orderColor: '#44566C',
    cartColor: '#44566C',
    boxShadow: 'none',
    fontWeight: 300,
    titleFontWeight: 300,
    horizontalLineColor: '#C4C4C4',
    confirmAddressBackground: '#FFFFFF',
    changeAddressBackground: '#FFFFFF',
    changeAddressColor: '#44566C',
    successColor: 'rgb(0 132 76)',
    checkoutButton: {
      border: '1px solid #44566C',
      borderRadius: 'none',
      color: '#44566C',
      fontWeight: 300,
      backgroundColor: '#FFFFFF',
    },
  },
  {
    id: 3,
    orderBackground: '#15232E',
    orderColor: '#FFFFFF',
    cartColor: '#15232E',
    boxShadow: 'none',
    fontWeight: 400,
    titleFontWeight: 400,
    horizontalLineColor: '#C4C4C4',
    confirmAddressBackground: '#15232E',
    changeAddressBackground: '#15232E',
    changeAddressColor: '#FFFFFF',
    successColor: 'rgb(0 132 76)',
    checkoutButton: {
      border: '1px solid #15232E',
      borderRadius: 'none',
      color: '#15232E',
      fontWeight: 500,
      backgroundColor: '#FFFFFF',
    },
  },
];
