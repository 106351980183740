import React from 'react';
import { Paper, Tabs, Tab, TabProps } from '@material-ui/core';
import { styled, withTheme } from '@material-ui/core/styles';

export const PaperRoot = withTheme(
  styled(Paper)({
    boxShadow: 'none',
  })
);

export const TabsWrapper = styled(({ ...otherProps }: any) => (
  <Tabs classes={{ indicator: 'indicator', flexContainer: 'flexContainer' }} {...otherProps} />
))({
  backgroundColor: '#F8FAFB',
  '& .flexContainer': {
    width: 290,
    justifyContent: 'flex-end',
  },
  '& .indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 97,
      width: '100%',
      backgroundColor: '#008DBA',
    },
  },
});

export const CustomTab = withTheme(
  styled(({ ...otherProps }: TabProps) => (
    <Tab classes={{ wrapper: 'template-icon' }} {...otherProps} />
  ))({
    color: '#44566C',
    textTransform: 'capitalize',
    fontSize: 15,
    '& .template-icon': {
      flexDirection: 'row',
      '& > svg': {
        margin: '0 6px 0 0 !important',
      },
    },
  })
);
