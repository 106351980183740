import { styled } from '@material-ui/core/styles';

export const Backdrop = styled('div')({
  position: 'fixed',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: 10,
});

export const InnerElement = styled('div')({
  position: 'absolute',
  textAlign: 'center',
  top: '50%',
  left: '50%',
});
