const formValidations = {
  FIRST_NAME_REQUIRED: 'Please provide your first name',
  LAST_NAME_REQUIRED: 'Please provide your last name',
  EMAIL_REQUIRED: 'Please provide your email',
  EMAIL_INCORRECT: 'Invalid email address',
  PASSWORD_REQUIRED: 'Please provide your password',
  PASSWORD_INCORRECT: 'Password needs at least 8 letters, numbers and characters',
  PIN_REQUIRED: 'Please provide the required field',
  PIN_INCORRECT: 'Pin needs contain at least 4 numbers',
  LOGIN_REQUIRED: 'Please provide the required field',
  CITY_REQUIRED: 'Please provide your city, state',
  ZIP_CODE_REQUIRED: 'Please provide the ZIP code',
  ZIP_CODE_INCORRECT: 'This field must contain 5 digits',
  SCHOOL_REQUIRED: 'Please provide your school address',
  STORE_REQUIRED: 'Please provide your business name',
  SCHOOL_LEVEL_REQUIRED: 'Please provide your school level',
  FIELD_REQUIRED: 'Please provide the required field',
  STUDENT_NAME_REQUIRED: 'Please provide your student name',
  STUDENT_USERNAME_REQUIRED: 'Please provide your student user name',
  WEBSITE_REQUIRED: 'Please provide the required field',
  WEBSITE_URL_INCORRECT: `This field must contain only lowercase letters, numbers, and special
   characters "-" and "_" and must be between 3 and 25 characters`,
  INVALID_IMAGE_TYPE: 'You can upload images only with these types: JPG, JPEG, PNG',
  INVALID_IMAGE_SIZE: 'File size is too big, please use file not bigger than 3 MB',
  STATE_INCORRECT: 'This field must contain from 2 letters',
  ZIP_INCORRECT: 'This field must contain from 5 digits',
  PHONE_INCORRECT: 'This field must contain from 10 digits',
  BUSINESS_NAME_USED: (name: string) => `The business name “${name}” is already used.`,
  // CASHOUT_AMOUNT_INCORRECT: ''
};

export const CROP_LOADING_ERROR = 'Cannot process file, please try again later';
export const CROP_FILE_FORMAT_ERROR = 'Please select a PNG or JPG image';
export const INCORRECT_MIN_HEIGHT = 'The minimal height should be 400 px';
export const INCORRECT_MIN_WIDTH = 'The minimal width should be 400 px';
export const INCORRECT_SITE_TEMPLATE_MIN_HEIGHT = 'The minimal height should be 720 px';

export default formValidations;
