import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getHomeList, clearHomeList } from 'src/ducks/home/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import { errorSelector, homeListSelector } from 'src/ducks/home/selectors';
import { Home as HomeType } from 'src/ducks/home/home';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { isInternalDomain } from 'src/utils/helpers';
import { SmartLinkWrapper } from 'src/components/common/smartLinkWrapper';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { FooterHomePage } from 'src/containers/footerHomePage';
import {
  HomeContainer,
  HomeTitle,
  CardsContainer,
  CardImage,
  HomeCard,
  CardTitle,
  CardsText,
  CardWrapper,
} from './styles';

const Home = () => {
  const dispatch = useDispatch();
  const homeList = useSelector(homeListSelector);
  const errors = useSelector(errorSelector);
  const currentPage = homeList?.currentPage;
  const totalPages = homeList?.totalPages;

  useEffect(() => {
    dispatch(getNewNotifications());
    dispatch(getHomeList());
    return () => {
      dispatch(clearHomeList());
    }
  }, [dispatch]);

  const loadMore = () => {
    const perPage = 10;
    if (currentPage && totalPages && currentPage < totalPages) {
      dispatch(getHomeList(Number(currentPage) + 1, perPage));
    }
  };

  useInfiniteScroll({ loadMore });

  const getLinkUrl = (url: string) => {
    const isExternalLink = !isInternalDomain(url);
    const domainName = 'https:\/\/edcorps.org\/';

    if (isExternalLink) {
      return { url, isExternalLink };
    }

    const replacedLink = url.replace(domainName, '/');
    return { url: replacedLink, isExternalLink };
  };

  const renderCards = () => {
    return homeList?.list.map((home: HomeType) => {
      const { isExternalLink, url } = getLinkUrl(home.url);

      return (
        <HomeCard key={home.id}>
          <SmartLinkWrapper link={url} external={isExternalLink}>
            <CardWrapper>
              <div>
                <CardTitle>{home.name}</CardTitle>
                <CardsText>
                  {home.description}
                </CardsText>
              </div>
              {!!home.blockIcons.length && <CardImage src={home.blockIcons[0].url} alt='' />}
            </CardWrapper>
          </SmartLinkWrapper>
        </HomeCard>
      );
    });
  };

  return (
    <HomeContainer>
      {errors && Array.isArray(errors) ? (
        errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
      ) : (
        <ErrorMessage>{errors}</ErrorMessage>
      )}
      <HomeTitle>
        {homeList?.bannerText}
      </HomeTitle>
      <CardsContainer>
        {homeList?.list?.length && renderCards()}
      </CardsContainer>
      <FooterHomePage />
    </HomeContainer>
  );
};

export default Home;
