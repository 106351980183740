import React from 'react';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { FormArea } from 'src/components/common/textarea';
import {
  CashoutDescription,
  TextAreaContainer,
  FormBlock,
  NeededChangesPrompt,
  LabelBlock,
  Label,
  LabelDescription,
} from '../styles';

type ClassWalletProps = {
  isAllowEditCashout?: boolean;
  validateAmount: (value: number) => string | null;
  needChangesText: string | null;
};

const ClassWallet = ({ isAllowEditCashout, validateAmount, needChangesText }: ClassWalletProps) => {
  return (
    <CashoutDescription>
      <FormBlock id='form-block-cashout'>
        <FormInput
          type="text"
          name="amount"
          label="Amount:"
          customBg="#F8FAFB"
          validate={[...validations.cashout.amount, validateAmount]}
          disabled={!isAllowEditCashout}
        />
        <TextAreaContainer>
          <FormArea
            name="note"
            label="Note:"
            validate={validations.cashout.note}
            disabled={!isAllowEditCashout}
          />
        </TextAreaContainer>
      </FormBlock>
      {
        needChangesText && (
          <NeededChangesPrompt>
            <LabelBlock>
              <Label>What Changes Are Needed?</Label>
              <LabelDescription>{needChangesText}</LabelDescription>
            </LabelBlock>
          </NeededChangesPrompt>
        )
      }
    </CashoutDescription>
  );
};

export default ClassWallet;
