import React from 'react';
import { LogoIcon } from 'src/components/common/Icons';
import { SmartLinkWrapper } from 'src/components/common/smartLinkWrapper';
import {
  dataForNavLinks,
  dataForSocialLinks,
  linkForTerms,
} from 'src/constants/dataForHomePageFooter';
import {
  FooterContainer,
  FooterSection,
  LinkBlock,
  Logo,
  CopyrightText,
  DividingStrip,
  TermsText,
  LinkText,
  SocialIcons,
} from './styles';

const FooteHomePage = () => {
  return (
    <FooterContainer>
      <FooterSection>
        <CopyrightText>Copyright © 2020 EdCorps.</CopyrightText>
        <Logo>
          <LogoIcon />
        </Logo>
        <SmartLinkWrapper link={linkForTerms} external={false}>
          <TermsText>{'Terms of use & Privacy Policy'}</TermsText>
        </SmartLinkWrapper>
      </FooterSection>
      <DividingStrip />
      <FooterSection>
        <LinkBlock>
          {dataForNavLinks.map(({ link, text, external }, i) => {
            return (
              <SmartLinkWrapper key={i} group link={link} external={external}>
                <LinkText>{text}</LinkText>
              </SmartLinkWrapper>
            );
          })}
        </LinkBlock>
        <LinkBlock>
          {dataForSocialLinks.map(({ link, icon: Icon, external }, i) => {
            return (
              <SmartLinkWrapper key={i} group link={link} external={external}>
                <SocialIcons>
                  <Icon />
                </SocialIcons>
              </SmartLinkWrapper>
            );
          })}
        </LinkBlock>
      </FooterSection>
    </FooterContainer>
  );
};

export default FooteHomePage;
