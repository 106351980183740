import React, { useEffect, useState } from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { styles } from './styles';

type Props = {
  name: string;
  label?: string;
  style?: any;
  checkValue?: any;
  disabled?: boolean;
};

const CheckboxComponent = (props: Props & WrappedFieldProps & WithStyles) => {
  const {
    classes,
    input,
    meta: { error, active, touched },
    name,
    label,
    style,
    checkValue,
    disabled = false,
  } = props;
  const hasError = touched && !active && !!error;
  const [state, setState] = useState({
    checked: checkValue || false,
  });

  useEffect(() => {
    if (checkValue !== state.checked) {
      setState({ checked: checkValue });
    }
  }, [checkValue]);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          key={name}
          label={label}
          style={style}
          control={
            <Checkbox
              disableRipple
              disabled={disabled}
              {...input}
              classes={{
                root: classes.checkboxField,
                checked: classes.checked,
              }}
              color="default"
              checked={state.checked}
              onClick={(e: any) => {
                setState({
                  ...state,
                  checked: e.target.checked,
                });
              }}
              value={!!checkValue ? 'on' : 'off'}
            />
          }
        />
      </FormGroup>
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </>
  );
};

const CheckboxField = (props: Props) => <Field component={CheckboxComponent} {...props} />;

export default withStyles(styles)(CheckboxField);
