import { moduleName } from './products';

export const GET_PRODUCTS_LIST = `${moduleName}/GET_PRODUCTS_LIST`;
export const GET_PRODUCTS_LIST_SUCCESS = `${moduleName}/GET_PRODUCTS_LIST_SUCCESS`;
export const GET_PRODUCTS_LIST_FAIL = `${moduleName}/GET_PRODUCTS_LIST_FAIL`;

export const ADD_PRODUCT = `${moduleName}/ADD_PRODUCT`;
export const ADD_PRODUCT_SUCCESS = `${moduleName}/ADD_PRODUCT_SUCCESS`;
export const ADD_PRODUCT_FAIL = `${moduleName}/ADD_PRODUCT_FAIL`;

export const EDIT_PRODUCT = `${moduleName}/EDIT_PRODUCT`;
export const EDIT_PRODUCT_SUCCESS = `${moduleName}/EDIT_PRODUCT_SUCCESS`;
export const EDIT_PRODUCT_FAIL = `${moduleName}/EDIT_PRODUCT_FAIL`;

export const DELETE_PRODUCT = `${moduleName}/DELETE_PRODUCT`;
export const DELETE_PRODUCT_SUCCESS = `${moduleName}/DELETE_PRODUCT_SUCCESS`;
export const DELETE_PRODUCT_FAIL = `${moduleName}/DELETE_PRODUCT_FAIL`;

export const GET_PRODUCT = `${moduleName}/GET_PRODUCT`;
export const GET_PRODUCT_SUCCESS = `${moduleName}/GET_PRODUCT_SUCCESS`;
export const GET_PRODUCT_FAIL = `${moduleName}/GET_PRODUCT_FAIL`;

export const ADD_PRODUCT_WITH_CUSTOM_FIELDS = `${moduleName}/ADD_PRODUCT_WITH_CUSTOM_FIELDS`;
export const ADD_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS = `${moduleName}/ADD_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS`;
export const ADD_PRODUCT_WITH_CUSTOM_FIELDS_FAIL = `${moduleName}/ADD_PRODUCT_WITH_CUSTOM_FIELDS_FAIL`;

export const EDIT_PRODUCT_WITH_CUSTOM_FIELDS = `${moduleName}/EDIT_PRODUCT_WITH_CUSTOM_FIELDS`;
export const EDIT_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS = `${moduleName}/EDIT_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS`;
export const EDIT_PRODUCT_WITH_CUSTOM_FIELDS_FAIL = `${moduleName}/EDIT_PRODUCT_WITH_CUSTOM_FIELDS_FAIL`;

export const SET_IMAGES = `${moduleName}/SET_IMAGES`;

export const CLEAR_PRODUCTS_LIST = `${moduleName}/CLEAR_PRODUCTS_LIST`;
