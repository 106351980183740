import {
  CREATE_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_ORDER_FAIL,
  PREVIEW_ORDER,
  PREVIEW_ORDER_SUCCESS,
  PREVIEW_ORDER_FAIL,
  VIEW_ORDER,
  VIEW_ORDER_SUCCESS,
  VIEW_ORDER_FAIL,
  UPDATE_PRODUCTS_AMOUNT,
  PREVIEW_ORDER_WITH_VALIDATION,
  CREATE_ORDER_WITH_VALIDATION,
  CANCEL_PAYMENT,
  CANCEL_PAYMENT_FAIL,
  CANCEL_PAYMENT_SUCCESS,
} from './constants';
import { all, put, takeLatest, select, take, call } from 'redux-saga/effects';
import history from 'src/containers/history';
import shopOrderApi from 'src/api/shopOrderApi';
import oauthApi from 'src/api/oauthApi';
import { getShippingMethodSelector } from 'src/ducks/shopProducts/selectors';
import { NormalizedProduct, normalizeProductsToOrder, getItemByKeyFromStorage } from 'src/utils/helpers';
import { GET_SHOP_PRODUCTS_BY_IDS_SUCCESS } from 'src/ducks/shopProducts/constants';
import { ViewOrder, CancelPayment, PreviewOrderWithValidation, CreateOrderWithValidationPayload } from './actions';

export const moduleName = 'shopOrder';

export type ActionType = {
  type: string;
  error: null;
  payload?: any;
};

type CalculationType = {
  subtotal: number;
  shipping: number;
  tax: number;
  grandTotal: number;
};

export type StateOrderInterface = {
  loading: boolean;
  error: Error | null;
  orderToken: string;
  stripeSessionId: string;
  status: string | null;
  productsAmount: number;
  calculation: CalculationType;
};

export type Error = {
  error: boolean;
  errors:
    | ErrorData[]
    | {
    title: string;
    message: string;
  };
  code?: number;
  message?: string;
};

export type ErrorData = {
  field?: string;
  invalid_value: string;
  message: string;
};

export const orderState: StateOrderInterface = {
  error: null,
  loading: false,
  orderToken: '',
  stripeSessionId: '',
  status: null,
  productsAmount: 0,
  calculation: {
    subtotal: 0,
    shipping: 0,
    tax: 0,
    grandTotal: 0,
  },
};

export default function reducer(
  state: StateOrderInterface = orderState,
  { type, payload, error }: ActionType,
) {
  switch (type) {
    case CREATE_ORDER:
    case PREVIEW_ORDER:
    case VIEW_ORDER:
    case CANCEL_PAYMENT:
      return { ...state, loading: true };

    case PREVIEW_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        calculation: {
          ...state.calculation,
          subtotal: payload.subtotal,
          shipping: payload.shipping,
          tax: payload.tax,
          grandTotal: payload.grandTotal,
        },
      };

    case VIEW_ORDER_SUCCESS:
      return {
        ...state,
        status: payload,
        loading: false,
        error: null,
      };

    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderToken: payload.token,
        stripeSessionId: payload.stripeSessionId,
        loading: false,
        error: null,
      };

    case CANCEL_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case UPDATE_PRODUCTS_AMOUNT:
      return { ...state, productsAmount: payload, error: null };

    case CREATE_ORDER_FAIL:
    case PREVIEW_ORDER_FAIL:
    case VIEW_ORDER_FAIL:
    case CANCEL_PAYMENT_FAIL:
      return { ...state, loading: false, error, status: null };

    default:
      return state;
  }
}
let countProceedToPayment = 1;

function* createOrderSaga({ payload: data }: CreateOrderWithValidationPayload) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const orderedProducts: NormalizedProduct[] = yield call(normalizeProductsToOrder);
    const currentShippingMethod: string = yield getItemByKeyFromStorage('shippingMethod');
    const shippingMethod: string = yield select(getShippingMethodSelector);

    const createOrderData = { ...data, orderedProducts, shippingMethod };

    if (currentShippingMethod === shippingMethod) {
      const response = yield shopOrderApi.createOrder(createOrderData, anonymousToken);
      yield put({ type: CREATE_ORDER_SUCCESS, payload: response });
    } else if (currentShippingMethod !== shippingMethod && countProceedToPayment === 1) {
      countProceedToPayment += 1;
      yield put({ type: CREATE_ORDER_SUCCESS, payload: {orderToken: '', stripeSessionId: ''} });
    } else if (currentShippingMethod !== shippingMethod && countProceedToPayment === 2) {
      countProceedToPayment = 1;
      const response = yield shopOrderApi.createOrder(createOrderData, anonymousToken);
      yield put({ type: CREATE_ORDER_SUCCESS, payload: response });
    }
  } catch (err) {
    yield put({ type: CREATE_ORDER_FAIL, error: err.data });
  }
}

function* createOrderWithValidationSaga({ payload }: CreateOrderWithValidationPayload) {
  yield take(GET_SHOP_PRODUCTS_BY_IDS_SUCCESS);
  const { isValidProducts } = yield select((state) => state.shopProducts);

  if (isValidProducts) {
    yield put({ type: CREATE_ORDER, payload });
  }
}

function* previewOrderSaga({ payload: data }: PreviewOrderWithValidation) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const orderedProducts: NormalizedProduct[] = yield call(normalizeProductsToOrder);
    const shippingMethod: string = yield select(getShippingMethodSelector);
    const previewOrderData = { ...data, orderedProducts, shippingMethod };
    const response = yield shopOrderApi.previewOrder(previewOrderData, anonymousToken);

    yield put({ type: PREVIEW_ORDER_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: PREVIEW_ORDER_FAIL, error: err.data });
  }
}

function* previewOrderWithValidationSaga({ payload }: PreviewOrderWithValidation) {
  yield take(GET_SHOP_PRODUCTS_BY_IDS_SUCCESS);
  const { isValidProducts } = yield select((state) => state.shopProducts);

  if (isValidProducts) {
    yield put({ type: PREVIEW_ORDER, payload });
  }
}

function* viewOrderSaga({ payload: { orderToken, websitePath } }: ViewOrder) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    const response = yield shopOrderApi.viewOrder(orderToken, anonymousToken);

    yield put({ type: VIEW_ORDER_SUCCESS, payload: response.status });
  } catch (err) {
    yield put({ type: VIEW_ORDER_FAIL, error: err.data });
    history.push(`/shop/${websitePath}`);
  }
}

function* cancelPaymentSaga({ payload: orderToken }: CancelPayment) {
  try {
    const { access_token: anonymousToken } = yield oauthApi.getAccessToken();
    yield shopOrderApi.cancelPayment(orderToken, anonymousToken);

    yield put({ type: CANCEL_PAYMENT_SUCCESS });
  } catch (err) {
    yield put({ type: CANCEL_PAYMENT_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(CREATE_ORDER, createOrderSaga),
    takeLatest(CREATE_ORDER_WITH_VALIDATION, createOrderWithValidationSaga),
    takeLatest(PREVIEW_ORDER, previewOrderSaga),
    takeLatest(PREVIEW_ORDER_WITH_VALIDATION, previewOrderWithValidationSaga),
    takeLatest(VIEW_ORDER, viewOrderSaga),
    takeLatest(CANCEL_PAYMENT, cancelPaymentSaga),
  ]);
};
