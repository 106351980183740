import React, { useState } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import { teacherProfileAvatarImageDataSelector } from 'src/ducks/user/selectors';
import { setAvatarImageData } from 'src/ducks/user/actions';
import { ProfileCameraIcon, DeleteIcon } from 'src/components/common/Icons';
import avatarStub from 'src/assets/images/profile/avatarStub.png';
import profileMessages from 'src/constants/profileMessages';
import { useImageUpload } from 'src/utils/useImageUpload';
import CropImageModal from './CropImageModal';
import ClipPathSvg from './ClipPathSvg';
import {
  Container,
  Backdrop,
  Clipper,
  AvatarPicture,
  ShadowCaster,
  AvatarStub,
  UploadCallToActionContainer,
  UploadCallToActionText,
  ErrorContainer,
  ButtonPanel,
  styles,
} from './styles';
import { PrimaryButton } from '../styles';
import { S3Image } from 'src/constants/commonTypes';

type AvatarEditorProps = {
  userImages: S3Image[];
};

type OnClickType = (e: React.MouseEvent<HTMLElement>) => void;

type UploadCallToActionProps = {
  hasPhoto: boolean;
  onClick: OnClickType;
};

const UploadCallToAction = ({ hasPhoto, onClick }: UploadCallToActionProps) => {
  return (
    <UploadCallToActionContainer onClick={onClick} className={hasPhoto ? undefined : 'noPhoto'}>
      <ProfileCameraIcon />
      <UploadCallToActionText>{profileMessages.clickToUpload}</UploadCallToActionText>
    </UploadCallToActionContainer>
  );
};

type UploadButtonProps = {
  onClick: OnClickType;
};

const UploadButton = ({ onClick }: UploadButtonProps) => {
  return (
    <PrimaryButton onClick={onClick}>
      <GetAppIcon fontSize="small" />
      &nbsp;{profileMessages.uploadYourPhotoButton}
    </PrimaryButton>
  );
};

type DeleteButtonProps = {
  onClick: OnClickType;
};

const DeleteButton = withStyles(styles)(({ onClick, classes }: DeleteButtonProps & WithStyles) => {
  return (
    <IconButton onClick={onClick} edge="end" className={classes.deleteButton}>
      <div>
        <DeleteIcon />
      </div>
    </IconButton>
  );
});

const DELETED_IMAGE_DATA = '';

const AvatarEditor = withStyles(styles)(
  ({ userImages, classes }: AvatarEditorProps & WithStyles) => {
  const userImage = (userImages && userImages[0]) || null;

  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const [uploadedImageData, setUploadedImageData] = useState<string | null>(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const croppedImageData = useSelector(teacherProfileAvatarImageDataSelector);

  const onImageDataAvailable = (imageData: string) => {   
    setUploadedImageData(imageData);
    setError(null);
    setModalOpen(true);
  };

  const onError = (error: string) => {
    setError(error);
  };

  const handleDelete = () => {
    dispatch(setAvatarImageData(DELETED_IMAGE_DATA));
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const { triggerFileUpload, LoadImageInput } = useImageUpload(onImageDataAvailable, onError);

  const canDelete = !!(croppedImageData || (userImage && croppedImageData !== DELETED_IMAGE_DATA));

  return (
    <Container>
      <CropImageModal 
        isOpen={isModalOpen} 
        closeModal={closeModal} 
        imageData={uploadedImageData}
      />
      <LoadImageInput />
      <div className={classes.avatarContainer}>
        <ClipPathSvg />
        <Backdrop />
        <ShadowCaster />
        <Clipper>
          {croppedImageData ? (
            <AvatarPicture src={croppedImageData} />
          ) : userImage && croppedImageData !== DELETED_IMAGE_DATA ? (
            <AvatarPicture src={userImage.url} />
          ) : (
            <AvatarStub src={avatarStub} />
          )}
          <UploadCallToAction onClick={triggerFileUpload} hasPhoto={canDelete} />
        </Clipper>
      </div>
      <ButtonPanel>
        <UploadButton onClick={triggerFileUpload} />
        {canDelete && <DeleteButton onClick={handleDelete} />}
      </ButtonPanel>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </Container>
  );
});

export default AvatarEditor;
