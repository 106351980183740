import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'src/ducks/user/selectors';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { FormArea } from 'src/components/common/textarea';
import {
  CashoutDescription,
  Label,
  LabelDescription,
  LabelBlock,
  FormBlock,
  TextAreaContainer,
  SchoolInfo,
  NeededChangesPrompt,
} from '../styles';

type MySchoolFormProps = {
  isAllowEditCashout?: boolean;
  validateAmount: (value: number) => string | null;
  needChangesText: string | null;
};

const MySchoolForm = ({ isAllowEditCashout, validateAmount, needChangesText }: MySchoolFormProps) => {
  const user = useSelector(userSelector);

  if (!user) {
    return null;
  }

  const { accesses } = user;
  const userStore = accesses[0].store;
  const storeDescription = userStore.storeDescription;

  return (
    <CashoutDescription>
      <FormBlock id='form-block-cashout'>
        <FormInput
          type="text"
          name="fullName"
          label="Who Should Mail Be Addressed To:"
          customBg="#F8FAFB"
          validate={validations.cashout.fullName}
          disabled={!isAllowEditCashout}
        />
        <FormInput
          name="amount"
          label="Amount:"
          customBg="#F8FAFB"
          validate={[...validations.cashout.amount, validateAmount]}
          disabled={!isAllowEditCashout}
        />
        <TextAreaContainer>
          <FormArea
            name="note"
            label="Note:"
            validate={validations.cashout.note}
            disabled={!isAllowEditCashout}
          />
        </TextAreaContainer>
      </FormBlock>
      <SchoolInfo>
        <LabelBlock>
          <Label>School name:</Label>
          <LabelDescription>{storeDescription?.schoolName}</LabelDescription>
        </LabelBlock>
        <LabelBlock>
          <Label>School Address:</Label>
          <LabelDescription>{storeDescription?.schoolAddress}</LabelDescription>
        </LabelBlock>
        <LabelBlock>
          <Label>City, State:</Label>
          <LabelDescription>{storeDescription?.cityAndState}</LabelDescription>
        </LabelBlock>
        <LabelBlock>
          <Label>Zip code:</Label>
          <LabelDescription>{storeDescription?.zipCode}</LabelDescription>
        </LabelBlock>
      </SchoolInfo>
      {
        needChangesText && (
          <NeededChangesPrompt>
            <LabelBlock>
              <Label>What Changes Are Needed?</Label>
              <LabelDescription>{needChangesText}</LabelDescription>
            </LabelBlock>
          </NeededChangesPrompt>
        )
      }
    </CashoutDescription>
  );
};

export default MySchoolForm;
