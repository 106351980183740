export const moduleName = 'notifications';

export const GET_NOTIFICATIONS_LIST_REQUEST = `${moduleName}/GET_NOTIFICATIONS_LIST_REQUEST`;
export const GET_NOTIFICATIONS_LIST_SUCCESS = `${moduleName}/GET_NOTIFICATIONS_LIST_SUCCESS`;
export const GET_NOTIFICATIONS_LIST_FAIL = `${moduleName}/GET_NOTIFICATIONS_LIST_FAIL`;

export const UPDATING_NOTIFICATIONS = `${moduleName}/UPDATING_NOTIFICATIONS`;
export const UPDATED_NOTIFICATIONS = `${moduleName}/UPDATED_NOTIFICATIONS`;

export const CLEAR_NOTIFICATIONS_LIST = `${moduleName}/CLEAR_NOTIFICATIONS_LIST`;

export const TOGGLE_MAIN_DRAWER = `${moduleName}/TOGGLE_MAIN_DRAWER`;
export const CLOSE_MAIN_DRAWER = `${moduleName}/CLOSE_MAIN_DRAWER`;
