import React from 'react';
import { Field, WrappedFieldProps } from 'redux-form';
import { FormControlLabel, FormHelperText } from '@material-ui/core';
import { RadioButton } from './styles';

type Props = {
  name: string;
  label: string;
  radioValue: string;
  icon: React.ReactNode;
  checkedIcon: React.ReactNode;
  disabled?: boolean;
};

const RadioComponent = (props: Props & WrappedFieldProps) => {
  const {
    input,
    meta: { error, active, touched },
    label,
    radioValue,
    icon,
    checkedIcon,
    disabled,
  } = props;
  const hasError = touched && !active && !!error;

  return (
    <>
      <FormControlLabel
        {...input}
        value={radioValue}
        label={label}
        control={<RadioButton icon={icon} checkedIcon={checkedIcon} disabled={disabled} disableRipple/>}
      />
      {hasError && <FormHelperText error={hasError}>{error}</FormHelperText>}
    </>
  );
};

const RadioField = (props: Props) => (
  <Field type="radio" value={props.radioValue} component={RadioComponent} {...props} />
);

export default RadioField;
