export default {
  pageTitle: 'Profile',
  failedToRetrieveErrorText: 'Failed to retrieve profile information. Please try again later.',
  uploadYourPhotoButton: 'Upload Photo',
  saveButton: 'Save',
  backButton: 'Back',
  classroomConcentration: 'Classroom Concentration:',
  schoolInformation: 'School Information',
  schoolAddress: 'School Address',
  cityAndState: 'City, State',
  schoolName: 'School Name',
  zipCode: 'Zip Code',
  schoolLevel: 'School Level',
  businessName: 'Business Name',
  email: 'Email:',
  firstName: 'First Name',
  lastName: 'Last Name',
  loading: 'Loading... ',
  failedToUpdateErrorText: 'There was an error saving your profile. Please try again later.',
  clickToUpload: 'Click to upload your photo',
};
