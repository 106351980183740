import { moduleName } from './shopOrder';

export const CREATE_ORDER = `${moduleName}/CREATE_ORDER`;
export const CREATE_ORDER_SUCCESS = `${moduleName}/CREATE_ORDER_SUCCESS`;
export const CREATE_ORDER_FAIL = `${moduleName}/CREATE_ORDER_FAIL`;

export const PREVIEW_ORDER = `${moduleName}/PREVIEW_ORDER`;
export const PREVIEW_ORDER_SUCCESS = `${moduleName}/PREVIEW_ORDER_SUCCESS`;
export const PREVIEW_ORDER_FAIL = `${moduleName}/PREVIEW_ORDER_FAIL`;

export const VIEW_ORDER = `${moduleName}/VIEW_ORDER`;
export const VIEW_ORDER_SUCCESS = `${moduleName}/VIEW_ORDER_SUCCESS`;
export const VIEW_ORDER_FAIL = `${moduleName}/VIEW_ORDER_FAIL`;

export const UPDATE_PRODUCTS_AMOUNT = `${moduleName}/UPDATE_PRODUCTS_AMOUNT`;

export const PREVIEW_ORDER_WITH_VALIDATION = `${moduleName}/PREVIEW_ORDER_WITH_VALIDATION`;
export const CREATE_ORDER_WITH_VALIDATION = `${moduleName}/CREATE_ORDER_WITH_VALIDATION`;

export const CANCEL_PAYMENT = `${moduleName}/CANCEL_PAYMENT`;
export const CANCEL_PAYMENT_FAIL = `${moduleName}/CANCEL_PAYMENT_FAIL`;
export const CANCEL_PAYMENT_SUCCESS = `${moduleName}/CANCEL_PAYMENT_SUCCESS`;
