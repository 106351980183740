import React from 'react';
import Router from 'src/components/Router';
import { AppContainer } from './styles';

const App = () => {
  return (
    <AppContainer>
      <Router />
    </AppContainer>
  );
};

export default App;
