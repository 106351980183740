const config = {
  root: '/',
  signin: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:id',
  signup: '/signup/:id',
  home: '/home',
  students: '/students',
  editStudent: '/students/:id',
  addStudent: '/students/add',
  products: '/products',
  addProduct: '/products/add',
  editProduct: '/products/:id',
  website: '/add-website',
  accounting: '/accounting',
  profile: '/profile',
  orders: '/orders',
  viewOrder: '/orders/:id',
  viewOrderMobile: '/orders-mobile/:id',
  viewWebsite: '/shop/:id',
  termsOfService: '/terms-of-service',
  shoppingCart: '/shop/:id/cart',
  shoppingOrder: '/shop/:id/cart/order',
  successBilling: '/shop/billing/:websitePath/:orderToken/success',
  cancelBilling: '/shop/billing/:websitePath/:orderToken/cancel',
  addCashout: '/accounting/cashout/add',
  editCashout: '/accounting/cashout/:id',
  productDetails: '/shop/:websitePath/:productId',
  notFound: '/not-found',
};

export default config;
