import { httpService } from 'src/services';

type CredentialsProps = {
  accessToken: string;
  refreshToken: string;
  expiresIn: string;
};

type AnonymousProps = {
  accessToken: string;
  expiresIn: string;
  scope: string;
  tokenType: string;
};

class SessionService {
  init = (credentials: CredentialsProps | null): void => {
    credentials && this.saveSession(credentials);
    httpService.setAuthHeader(this.getAccessToken());
  };

  saveAnonymous = (credentials: AnonymousProps): void => {
    localStorage.setItem('anonymous', JSON.stringify(credentials));
    if (credentials) {
      const { accessToken } = credentials;
      httpService.setAuthHeader(accessToken);
    }
  };

  saveSession = (credentials: CredentialsProps): void =>
    localStorage.setItem('auth', JSON.stringify(credentials));

  updateSession = (credentials: CredentialsProps): void =>
    credentials && this.saveSession(credentials);

  getAccessToken = (): string | undefined => this.loadSession()?.accessToken;

  getRefreshToken = (): string | undefined => this.loadSession()?.refreshToken;

  loadSession = (): CredentialsProps | null => {
    const credentials = localStorage.getItem('auth');

    if (credentials) {
      const { accessToken, refreshToken, expiresIn } = JSON.parse(credentials);
      return {
        accessToken,
        expiresIn,
        refreshToken,
      };
    }
    return null;
  };

  isAuthenticated = (): CredentialsProps | null => this.loadSession();

  destroySession = (): void => localStorage.removeItem('auth');

  initFromLocalStorage = (): void => {
    if (this.getAccessToken()) {
      return this.init(this.loadSession());
    }
    return this.destroySession();
  };
}

const sessionService = new SessionService();

export default sessionService;
