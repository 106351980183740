import React from 'react';
import { Card, FormLabel, FormLabelProps } from '@material-ui/core';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { ContinueButton } from 'src/components/pages/auth/signin/styles';

export const AddStudentButton = styled(ContinueButton)({
  '& svg': {
    paddingRight: 7,
  },
});

export const ButtonContainer = styled('div')({
  width: 150,
  paddingLeft: 20,
});

export const ActionsContainer = styled('div')({
  display: 'flex',
  padding: '50px 0 30px 0',
  justifyContent: 'flex-end',
  '& > a': {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
  },
});

export const FormContainer = styled('div')({
  width: 350,
});

export const ErrorWrapper = styled('div')({
  marginBottom: 30
});

export const HelperRadioText = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    width: 350,
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
  })
);

export const StudentFormLayout = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <Card {...otherProps} />)({
    width: 430,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0px 11px 32px rgba(169, 194, 209, 0.25), 0px 4px 8px rgba(169, 194, 209, 0.25)',
    '& > form': {
      paddingTop: 30,
    },
  })
);

export const FormLabelStyled = styled(({ ...otherProps }: FormLabelProps) => (
  <FormLabel {...otherProps} />
))({
  '&&': {
    color: '#8697A8',
  },
});

export const StudentFormContainer = styled('div')({
  padding: '30px 0 0 50px',
});

export const StudentFormLabel = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: 30,
    fontWeight: 500,
    paddingBottom: 30,
  })
);
