import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Overlay, MainDrawer, DrawerTitle, TitleWrapper } from './styles';
import { drawerSelector, notificationsListSelector } from 'src/ducks/notifications/selectors';
import { getNotificationsList, toggleMainDrawer } from 'src/ducks/notifications/actions';
import Notifications from './Notifications/Notifications';
import { useStyles } from './classes';
import clsx from 'clsx';
import { clearNewNotifications } from '../../../ducks/user/actions';

const NotificationsDrawer = () => {
  const drawerRef = useRef<any>(null);
  const dispatch = useDispatch();
  const drawerIsOpen = useSelector(drawerSelector);
  const notificationsList = useSelector(notificationsListSelector);
  const currentPage = Number(notificationsList?.currentPage);

  const classes = useStyles();
  const [exit, setExit] = React.useState(false);

  useEffect(() => {
    if (drawerIsOpen) {
      document.body.style.overflow = 'hidden';
    }

    document.addEventListener('mousedown', clickOutside);
    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, []);

  const clickOutside = (e: any) => {
    if (drawerRef.current && !drawerRef?.current?.contains(e.target)) {
      setExit(true);
      setTimeout(() => {
        dispatch(clearNewNotifications());
        dispatch(toggleMainDrawer());
      }, 1000);
      document.getElementsByTagName('body')[0].removeAttribute('style');
      localStorage.removeItem('firstLoad');
    }
  };

  const loadMore = () => {
    dispatch(getNotificationsList(currentPage + 1));
  };

  return (
    <Overlay style={drawerIsOpen ? {} : { display: 'none' }}>
      <MainDrawer
        ref={drawerRef}
        className={clsx({
          [classes.animatedItem]: drawerIsOpen && !localStorage.getItem('firstLoad'),
          [classes.animatedItemExiting]: exit,
        })}
      >
        <TitleWrapper>
          <DrawerTitle>Notifications</DrawerTitle>
        </TitleWrapper>
        <Notifications loadMore={loadMore} notificationsList={notificationsList} />
      </MainDrawer>
    </Overlay>
  );
};

export default NotificationsDrawer;
