import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '100%',
    minWidth: 400,
    marginTop: 20,
    boxShadow: 'none',
  },
  cardArea: {
    height: 547,
    overflow: 'hidden',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: 5,
      backgroundColor: '#C4C4C4',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#EAEDF0',
      borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#8697A8',
    },
  },
  media: {
    width: '100%',
  },
  cardAction: {
    margin: '5px auto',
    width: 340,
  },
  buttonField: {
    backgroundColor: theme.customPalette.buttons.activeColor,
    color: theme.customPalette.text.primary,
    '&:hover': {
      background: theme.customPalette.buttons.activeBg,
      color: theme.customPalette.text.active,
    },
    textTransform: 'capitalize',
    fontSize: 15,
    fontWeight: 'normal',
  },
  activeBtn: {
    background: theme.customPalette.buttons.activeBg,
    color: theme.customPalette.text.active,
  },
}));
