import { AxiosPromise, AxiosResponse } from 'axios';
import { prepareUrlParams } from 'src/ducks/cashout/urlHelper';
import { httpService } from 'src/services';

export default {
  getToolkitList: (
    storeId: number,
    urlParams: any,
  ): AxiosPromise<AxiosResponse> => {
    const newUrl = prepareUrlParams(`api/toolkit/list/${storeId}`, urlParams);

    return httpService.get({
      url: newUrl,
    })
  },

  getToolkitReport: (url: string): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: url,
      responseType: 'blob',
    }),

  getProfitOrder: (
    storeId: number,
    urlParams: any,
  ): AxiosPromise<AxiosResponse> => {
    const newUrl = prepareUrlParams(`api/order/profit/list/${storeId}`, urlParams);

    return httpService.get({
      url: newUrl,
    })
  },

  getProfitReportId: (url: string): AxiosPromise<AxiosResponse> => {
    return httpService.get({
      url: url,
    })
  },

  getProfitReportStatus: (
    storeId: number,
    reportId: string
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/order/profit/report-status/${storeId}/${reportId}`
    }),

  getProfitReport:(
    storeId: number,
    reportId: string
  ): AxiosPromise<AxiosResponse> =>
    httpService.get({
      url: `api/order/profit/report/${storeId}/${reportId}`,
      responseType: 'blob',
    }),
};
