import { createSelector } from 'reselect';
import { StateToolkitInterface } from './accounting';
import { Error, ErrorData } from '../user/user';

export interface RootState {
  accounting: StateToolkitInterface;
}

const toolkitStoreGetter = (state: RootState) => state.accounting;
export const toolkitListSelector = createSelector(
  toolkitStoreGetter,
  (toolkit) => toolkit?.toolkitList
);

export const profitsSelector = createSelector(toolkitStoreGetter, (toolkit) => toolkit.profitList);

export const loadingProfitSelector = createSelector(
  toolkitStoreGetter,
  (toolkit) => toolkit.loading
);

const errorGetter = (state: any) => state.accounting?.error;
export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => err.message);
    } else {
      return error.errors.message;
    }
  }

  return null;
});
