import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getCashoutList, clearCashoutList, deleteCashout } from 'src/ducks/cashout/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import {
  cashoutListSelector,
  loadingCashoutSelector,
  errorSelector,
} from 'src/ducks/cashout/selectors';
import { isUserTeacher, userWebsite } from 'src/ducks/user/selectors';
import Loader from 'src/components/common/error-pages/Loader';
import { Hint } from 'src/components/common/hint';
import { QuestionHintIcon } from 'src/components/common/Icons';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { ActionContainer, StudentContainer } from 'src/components/pages/students/styles';
import { CashoutList } from './cashoutList';
import { LabelWrapper } from 'src/components/pages/website/form-website/style';
import { AddProductButton, ButtonContainer } from '../products/styles';
import { CashoutTitle, CashoutTitlePrice, LinkWebsiteDesign } from './styles';
import { Report } from '../accounting/report';
import { ILoadMoreParams, prepareQueryParams } from '../accounting/report/queryHelper';
import cashoutApi from 'src/api/cashoutApi';
import { prepareOnDownload } from 'src/ducks/cashout/urlHelper';

const AvailableCashout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const cashoutList = useSelector(cashoutListSelector);
  const isLoading = useSelector(loadingCashoutSelector);
  const errors = useSelector(errorSelector);
  const isTeacher = useSelector(isUserTeacher);
  const websiteIds = useSelector(userWebsite);
  const currentPage = cashoutList?.currentPage;
  const totalPages = cashoutList?.totalPages;
  const totalCashout = cashoutList?.totalFundsCashOut;
  const titleHint =
    'This total includes profits and toolkit bonuses available for you to cash out.';
  const baseDownloadUrl = websiteIds?.length ? `api/cash-out/report/${websiteIds[0].id}` : '';

  const loadData = (params: any = {}) => {
    dispatch(getCashoutList(params));
  }

  const resetFilter = () => {
    dispatch(clearCashoutList());
  }

  useEffect(() => {
    dispatch(getNewNotifications());
    if (websiteIds?.length) {
      const queryParams = prepareQueryParams(location.search);
      loadData(queryParams);
      return resetFilter;
    }
  }, []);

  const loadMore = () => {
    if (currentPage && totalPages && currentPage < totalPages) {
      let queryParams: ILoadMoreParams = prepareQueryParams(location.search);
      queryParams.page = Number(currentPage) + 1;
      loadData(queryParams)
    }
  };

  const handleRemoveCashout = (cashoutId: number, amount: number) => {
    dispatch(deleteCashout(cashoutId, amount));
  };

  const disableAddCashoutButton = () => {
    const titleHintButton = 'You can\'t add new cash out because Funds Available for Cash Out is $0';

    return (
      <Hint placement="top" text={titleHintButton} position="unset">
        <AddProductButton variant="contained" color="primary" disabled={true}>
          + Add Cash Out
        </AddProductButton>
      </Hint>
    );
  };

  const handleAddCashout = () => {
    history.push('/accounting/cashout/add');
  };

  const onExportReport = (reportUrl: string, startDate: Date | null, endDate: Date | null) => {
    cashoutApi.getCashoutReport(reportUrl)
    .then(prepareOnDownload('Cash Outs', startDate, endDate));
  };
  
  return (
    <StudentContainer>
      {websiteIds?.length ? (
        isLoading && !cashoutList ? (
          <Loader />
        ) : (
          <>
            <ActionContainer>
              <CashoutTitle>
                <LabelWrapper>
                  <label>
                    Funds Available for Cash Out &nbsp;
                    <CashoutTitlePrice>${totalCashout?.toLocaleString('en-US', { minimumFractionDigits: 2 })}</CashoutTitlePrice>
                  </label>
                  <Hint placement="top" text={titleHint}>
                    <QuestionHintIcon />
                  </Hint>
                </LabelWrapper>
              </CashoutTitle>
              <ButtonContainer>
                {isTeacher &&
                  (totalCashout ? (
                    <AddProductButton variant="contained" color="primary" onClick={handleAddCashout}>
                      + Add Cash Out
                    </AddProductButton>
                  ) : (
                    disableAddCashoutButton()
                  ))}
              </ButtonContainer>
            </ActionContainer>
            <Report
              loadData={loadData}
              resetFilter={resetFilter}
              baseDownloadUrl={baseDownloadUrl}
              onExportReport={onExportReport}
            />
            {errors && Array.isArray(errors) ? (
              errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
            ) : (
              <ErrorMessage>{errors}</ErrorMessage>
            )}
            {cashoutList?.list?.length ?
              <CashoutList
                loadMore={loadMore}
                cashoutList={cashoutList.list}
                handleRemoveCashout={handleRemoveCashout}
                isTeacher={isTeacher}
              />
              :
              <div>You don't have any cash out</div>
            }
          </>
        )
      ) : (
        <div>Complete your account setup on the <LinkWebsiteDesign to="/add-website">Website Design</LinkWebsiteDesign> page before submitting your first cash out for approval</div>
      )}
    </StudentContainer>
  );
};

export default AvailableCashout;
