import React from 'react';
import { Theme } from '@material-ui/core';
import { withTheme, WithTheme, styled } from '@material-ui/core/styles';
import { Typography, TypographyProps } from '@material-ui/core';
import avatarBackdrop from 'src/assets/images/profile/avatarBackdrop.svg';

export const avatarSize = 220;
export const PRODUCT_IMAGE_SIZE = 400;
export const EDITOR_MARGIN_HOR = 16;
export const EDITOR_MARGIN_VER = 4;
export const EDITOR_BORDER_RADIUS = 50;
export const EDITOR_BORDER_COLOR = [255, 255, 255, 0.5];
export const SITE_TEMPLATE_IMAGE_HEIGHT = 498;                        
export const SITE_TEMPLATE_IMAGE_WIDTH = SITE_TEMPLATE_IMAGE_HEIGHT * 18 / 7;
const duration = '0.1s';

export const Container = styled('div')({
  flex: `0 0 ${avatarSize}px`,
  marginRight: 20,
  display: 'flex',
  flexDirection: 'column',
});

export const Backdrop = styled('div')({
  position: 'absolute',
  left: -16,
  top: 0,
  right: -16,
  bottom: -52,
  background: `url(${avatarBackdrop}) no-repeat`,
});

export const Clipper = styled('div')({
  clipPath: `url(#clipPath)`,
  // For Safari: https://caniuse.com/#search=clip-path
  '-webkit-clip-path': `url(#clipPath)`,
  backgroundColor: 'rgba(244, 248, 249, 0.5)',
  boxShadow: '0px 2px 4px rgba(169, 194, 209, 0.05), 0px 12px 16px rgba(169, 194, 209, 0.1)',
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
});

export const AvatarPicture = styled('img')({
  position: 'absolute',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
});

export const ShadowCaster = styled('div')({
  position: 'absolute',
  left: '15%',
  right: '15%',
  top: '89%',
  bottom: 0,
  boxShadow: '0px 16px 32px rgba(169, 194, 209, 0.15), 0px 8px 16px rgba(169, 194, 209, 0.1)',
});

export const AvatarStub = styled('img')({
  position: 'absolute',
  left: 54,
  top: 72,
});

export const UploadCallToActionContainer = styled('div')({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  transition: `background ${duration}, opacity ${duration}`,
  opacity: 0,
  '&:hover': {
    opacity: 1,
    backgroundColor: 'rgba(248, 250, 251, 0.8)',
  },
  '&.noPhoto:hover': {
    backgroundColor: 'rgba(248, 250, 251, 0.2)',
  },
});

export const UploadCallToActionText = styled('div')({
  width: 126,
  lineHeight: 1.6,
  fontSize: 15,
  fontWeight: 500,
  textAlign: 'center',
  marginTop: 10,
});

export const ErrorContainer = withTheme(
  styled('div')({
    color: ({ theme }: WithTheme) => theme.customPalette.text.error,
    marginTop: 20,
  })
);

export const ModalContent = styled('div')({
  width: '100%',
  boxSizing: 'border-box',
  display: 'flex',
});

export const ModalContentBlock = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: 20,
  paddingRight: 20,
  alignItems: 'center',
});

export const PreviewHeader = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => (
    <Typography variant="h2" {...otherProps} />
  ))({
    color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
    fontSize: 20,
    fontWeight: 'normal',
    marginTop: 24,
  })
);

export const styles: any = (theme: Theme) => ({
  deleteButton: {
    // Visually align with the right-hand side of the avatar; vertically
    // centered with the Upload button.

    // Note: `as 'relative'` is to suppress the TypeScript error "Types of
    // property 'position' are incompatible.".
    position: 'relative' as 'relative',
    top: -12,
    left: 12,
    // Prevent the container from changing size when the button toggles between
    // rendered/not rendered.
    marginBottom: -24,
    backgroundColor: 'transparent',

    // Make the clickable area square, otherwise the button looks squished.
    '& div': {
      width: 40,
      height: 40,
    },
  },

  productEditorWrapper: {
    position: 'relative' as 'relative',
    width: PRODUCT_IMAGE_SIZE,
    height: PRODUCT_IMAGE_SIZE,
  },
  productContainer: {
    position: 'relative',
    flex: `0 0 ${PRODUCT_IMAGE_SIZE} px`,
    width: PRODUCT_IMAGE_SIZE,
    height: PRODUCT_IMAGE_SIZE,
  },
  siteTemplateEditorWrapper: {
    position: 'relative' as 'relative',
    width: SITE_TEMPLATE_IMAGE_WIDTH,
    height: SITE_TEMPLATE_IMAGE_HEIGHT,
  },
  avatarEditorWrapper: {
    position: 'relative' as 'relative',
    width: avatarSize + EDITOR_MARGIN_HOR * 2,
    height: avatarSize + EDITOR_MARGIN_VER * 2,
  },
  avatarContainer: {
    position: 'relative',
    flex: `0 0 ${avatarSize}px`,
    width: avatarSize,
    height: avatarSize,
  },
});

export const ButtonPanel = styled('div')({
  marginTop: 30,
  display: 'flex',
});

export const ScaleEditorContainer = styled('div')({
  marginTop: 4,
  paddingLeft: 10,
  paddingRight: 10,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
});
