import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { previewOrderWithValidation } from 'src/ducks/shopOrder/actions';
import { getShopProductsByIds, setShippingMethod } from 'src/ducks/shopProducts/actions';
import { websiteSelector } from 'src/ducks/website/selectors';
import {
  invalidProductsSelector,
  isValidProductsSelector,
  shopProductsByIdsSelector,
  loadingShopProductSelector
} from 'src/ducks/shopProducts/selectors';
import ShoppingCart from '../ShoppingCart';
import { errorSelector, isShippingAddressFormExistSelector } from 'src/ducks/shopOrder/selectors';
import { CommentIcon, RightArrow } from 'src/components/common/Icons';
import { MaskedInputField } from 'src/components/common/formInput/maskedInput';
import { FormInput } from 'src/components/common/formInput';
import { FormArea } from 'src/components/common/textarea';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import validations from 'src/components/common/inputs/validations';
import { StylesTemplate } from 'src/constants/stylesTemplates';
import { cartShippingMethods, CartShippingMethodsType } from 'src/constants/cartShippingMethods';
import {
  availableShippingMethods,
  calculateSubtotalPrice,
  getItemByKeyFromStorage,
  LocalStorageType,
} from 'src/utils/helpers';
import history from 'src/containers/history';
import { Order } from '../order';
import { CartContainer, HorizontalLine, MisMatchDeliveryMessage, Wrapper } from '../styles';
import {
  ShippingContainer,
  ShippingName,
  ShippingPrompt,
  FormContainer,
  InputsWrapper,
  TextAreaContainer,
  ChangeAddressContainer,
  CommentTitleContainer,
  ConfirmAddressButton,
  CartWrapper,
  BackIcon,
} from './styles';

export type FormShippingAddressProps = {
  fullName?: string;
  address?: string;
  city?: string;
  state?: string;
  zipCode?: number;
  phone?: string;
  email?: string;
};

type ShippingAddressProps = {
  websitePath: string;
  styles: StylesTemplate;
};

type Props = InjectedFormProps<FormShippingAddressProps, ShippingAddressProps>;

const ShippingAddress = (props: Props & ShippingAddressProps) => {
  const { valid, handleSubmit, websitePath, styles } = props;
  const dispatch = useDispatch();
  const errors = useSelector(errorSelector);
  const isValidProducts = useSelector(isValidProductsSelector);
  const invalidProducts = useSelector(invalidProductsSelector);
  const isShippingAddressFormExist = useSelector(isShippingAddressFormExistSelector);
  const { id: website } = useSelector(websiteSelector);
  const productsList = useSelector(shopProductsByIdsSelector);
  const isLoading = useSelector(loadingShopProductSelector);
  const [isConfirmAddress, setConfirmAddress] = useState(false);
  const [deliveryAddress, setDeliveryAddress] = useState({});
  const [isValidExternalProducts, setIsValidExternalProducts] = useState(true);
  const products = getItemByKeyFromStorage('shoppingCart');
  const currentShippingMethod = getItemByKeyFromStorage('shippingMethod');
  const externalProducts = productsList?.length ? productsList : undefined;
  const cartSubtotalPrice = Number(calculateSubtotalPrice(products, externalProducts));
  const availableMethods = availableShippingMethods(externalProducts, true); 
  const cartShippingMethodsIndex = availableMethods[0];
  const newDeliveryMethod = cartShippingMethods[cartShippingMethodsIndex];
  const changedShippingMethodMessage = 'The delivery option has changed since adding products to your cart';
  const misMatchingShippingMethodCondition = (
    availableMethods.length &&
    currentShippingMethod &&
    !availableMethods.includes(currentShippingMethod)
  );

  useEffect(() => {
    if (errors) {
      setConfirmAddress(false);
    }
  }, [errors]);

  useEffect(() => {
    getCurrentProducts();
  }, [dispatch, websitePath]);

  useEffect(() => {
    if (!isLoading && !isValidProducts && !invalidProducts.length) {
      setIsValidExternalProducts(false);
      history.push(`/shop/${websitePath}/cart`);
    }
  }, [isValidProducts, invalidProducts, isLoading]);

  useEffect(() => {
    const methodsList = Object.keys(cartShippingMethods);
    const shippingMethod = methodsList.find((method: string) => {     
      if (misMatchingShippingMethodCondition) {
        return method === availableMethods[0];
      }
      return method === currentShippingMethod;
    });

    dispatch(setShippingMethod(shippingMethod || null));
  }, [misMatchingShippingMethodCondition]);

  const getCurrentProducts = () => {
    const productsIds = products.map(({ product }: LocalStorageType) => product.id);

    dispatch(getShopProductsByIds({ productsIds, websitePath, isCheckout: true }));
  };

  const handleConfirmAddress = (values: FormShippingAddressProps) => {
    getCurrentProducts();
    setConfirmAddress(true);

    const previewOrderData = {
      zipCode: values.zipCode,
      state: values.state?.toUpperCase(),
      website,
    };
    setDeliveryAddress({
      address: values.address,
      city: values.city,
      state: values.state?.toUpperCase(),
      zipCode: values.zipCode,
    });

    dispatch(previewOrderWithValidation(previewOrderData));
  };

  const handleChangeAddress = () => {
    setConfirmAddress(false);
  };

  const renderShippingForm = () => (
    <FormContainer>
      <FormInput
        name="fullName"
        type="text"
        label="Full Name"
        customBg="#F8FAFB"
        disabled={isConfirmAddress}
        validate={validations.shipping.fullName}
      />
      <FormInput
        name="address"
        label="Address"
        customBg="#F8FAFB"
        disabled={isConfirmAddress}
        validate={validations.shipping.address}
      />
      <InputsWrapper>
        <FormInput
          name="city"
          label="City"
          customBg="#F8FAFB"
          disabled={isConfirmAddress}
          validate={validations.shipping.city}
        />
        <FormInput
          name="state"
          label="State"
          customBg="#F8FAFB"
          disabled={isConfirmAddress}
          maxLength={inputRestrictions.MIN_LENGTH_NAME}
          validate={validations.shipping.state}
        />
        <FormInput
          name="zipCode"
          label="ZIP"
          customBg="#F8FAFB"
          disabled={isConfirmAddress}
          maxLength={inputRestrictions.MAX_LENGTH_ZIP_CODE}
          validate={validations.shipping.zip}
        />
      </InputsWrapper>
      <InputsWrapper>
        <MaskedInputField
          name="phone"
          label="Phone"
          customBg="#F8FAFB"
          mask="+1 (999) - 999 - 9999"
          disabled={isConfirmAddress}
          validate={validations.shipping.phone}
        />
        <FormInput
          name="email"
          label="Email"
          customBg="#F8FAFB"
          disabled={isConfirmAddress}
          validate={validations.email}
        />
        <div />
      </InputsWrapper>
    </FormContainer>
  );

  const renderCommentContainer = () => (
    <>
      <CommentTitleContainer>
        <CommentIcon />
        <span>Add a note about delivery instructions (optional)</span>
      </CommentTitleContainer>
      <TextAreaContainer>
        <FormArea
          name="note"
          disabled={isConfirmAddress}
          placeholder="Instructions? Special Requests? Add them here."
        />
      </TextAreaContainer>
    </>
  );

  const renderInvalidCartOrShipping = () => {
    if (isShippingAddressFormExist && isConfirmAddress && invalidProducts?.length) {
      return (
        <CartWrapper>
          <ShoppingCart websitePath={websitePath} isShowInvalidProducts />
          <Link to={`/shop/${websitePath}/cart`}>
            <ChangeAddressContainer>
              <ConfirmAddressButton styles={styles}>Update Shopping Cart</ConfirmAddressButton>
            </ChangeAddressContainer>
          </Link>
        </CartWrapper>
      );
    }

    return (
      <ShippingContainer styles={styles}>
        <BackIcon styles={styles} to={`/shop/${websitePath}/cart`}>
          <RightArrow />
          <div>Back to Shopping cart</div>
        </BackIcon>
        <ShippingName styles={styles}>Shipping Address</ShippingName>
        {!!misMatchingShippingMethodCondition &&
        <MisMatchDeliveryMessage isInfo>
          <div>{changedShippingMethodMessage}</div>
          <div>New delivery method: <span>{`${newDeliveryMethod}`}</span></div>
        </MisMatchDeliveryMessage>
        }
        <ShippingPrompt>Please enter a shipping address for this order. </ShippingPrompt>
        <HorizontalLine />
        {errors && Array.isArray(errors) ? (
          errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
        ) : (
          <ErrorMessage>{errors}</ErrorMessage>
        )}
        <form onSubmit={handleSubmit(handleConfirmAddress)}>
          {renderShippingForm()}
          {renderCommentContainer()}
          <ChangeAddressContainer>
            {!isConfirmAddress && (
              <ConfirmAddressButton
                styles={styles}
                disabled={!valid}
                isConfirmAddress={isConfirmAddress}
                type="submit"
              >
                Confirm Address
              </ConfirmAddressButton>
            )}
          </ChangeAddressContainer>
        </form>
        {isConfirmAddress && (
          <ChangeAddressContainer>
            <ConfirmAddressButton
              onClick={handleChangeAddress}
              styles={styles}
              disabled={!valid}
              isConfirmAddress={isConfirmAddress}
            >
              Change Address
            </ConfirmAddressButton>
          </ChangeAddressContainer>
        )}
      </ShippingContainer>
    );
  };

  return (
    <Wrapper>
      <CartContainer>
        {renderInvalidCartOrShipping()}
        <Order
          deliveryAddress={
            isConfirmAddress ? deliveryAddress : 'Please enter your shipping address'
          }
          valid={valid && isConfirmAddress && isValidProducts}
          styles={styles}
          cartSubtotalPrice={cartSubtotalPrice}
          website={website}
          getCurrentProducts={getCurrentProducts}
          isValidExternalProducts={isValidExternalProducts}
        />
      </CartContainer>
    </Wrapper>
  );
};

export default reduxForm<FormShippingAddressProps, ShippingAddressProps>({
  form: 'shippingAddress',
  destroyOnUnmount: false,
})(ShippingAddress);
