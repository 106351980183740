import { saga as authSaga } from 'src/ducks/auth/auth';
import { saga as userSaga } from 'src/ducks/user/user';
import { saga as productsSaga } from 'src/ducks/products/products';
import { saga as websiteSaga } from 'src/ducks/website/website';
import { saga as accountingSaga } from 'src/ducks/accounting/accounting';
import { saga as ordersSaga } from 'src/ducks/orders/orders';
import { saga as shopOrderSaga } from 'src/ducks/shopOrder/shopOrder';
import { saga as cashoutSaga } from 'src/ducks/cashout/cashout';
import { saga as shopProductsSaga } from 'src/ducks/shopProducts/shopProducts';
import { saga as notifications } from 'src/ducks/notifications/notifications';
import { saga as homeSaga } from 'src/ducks/home/home';
import { spawn } from 'redux-saga/effects';

export default function* rootSaga() {
  yield spawn(authSaga);
  yield spawn(userSaga);
  yield spawn(productsSaga);
  yield spawn(websiteSaga);
  yield spawn(accountingSaga);
  yield spawn(ordersSaga);
  yield spawn(shopOrderSaga);
  yield spawn(cashoutSaga);
  yield spawn(shopProductsSaga);
  yield spawn(notifications);
  yield spawn(homeSaga);
}
