import React from 'react';
import { TableBody, TableRow, Paper, TableContainer } from '@material-ui/core';
import { Profit } from 'src/ducks/accounting/accounting';
import { Hint } from 'src/components/common/hint';
import { QuestionHintIcon } from 'src/components/common/Icons';
import useInfiniteScroll from 'src/components/hooks/useInfiniteScroll';
import { formatDate } from 'src/utils/helpers';
import profitStatuses, { ProfitStatus } from 'src/constants/profitsStatuses';
import {
  TableWrapper,
  Head,
  StyledTableCell,
} from 'src/components/pages/students/studenstList/styles';
import { LabelWrapper } from 'src/components/pages/website/form-website/style';
import { StyledTableCellInfo, StyledDateCell, StyledTableRow } from '../orders/orderList/styles';

const tableColumnHeaders: string[] = [
  'Order ID',
  'Order Type',
  'Date',
  'Transaction number',
  'Zip code',
  'Total',
  'Profit',
];

type ProfitListProps = {
  loadMore: () => void;
  profitsList: Profit[];
};

const ProfitsList = (props: ProfitListProps) => {
  const {
    loadMore,
    profitsList,
  } = props;
  useInfiniteScroll({ loadMore });

  const getMappedOrderTypeStatus = (type: string) => {
    return profitStatuses.find((profitStatus: ProfitStatus) => profitStatus.status === type);
  };

  const getProfitsList = (profit: Profit) => {
    const profitStatus = getMappedOrderTypeStatus(profit.type);

    return (
      <StyledTableRow key={profit.id}>
        <StyledTableCellInfo>#{profit.id}</StyledTableCellInfo>
        <StyledTableCellInfo color={profitStatus?.color}>
          <span />
          {profitStatus?.shownStatus || profit.type}
        </StyledTableCellInfo>
        <StyledDateCell>{formatDate(profit.createdAt)}</StyledDateCell>
        {profit.type === 'offline' ? (
          <StyledTableCellInfo isDefaultValue={!!profit.transactionNumbers?.length}>
            -
          </StyledTableCellInfo>
        ) : (
          <StyledTableCellInfo isDefaultValue={!!profit.transactionNumbers?.length}>
            {profit.transactionNumbers?.map((transactionNumber: string) => (
              <>
                {transactionNumber}
                <br />
              </>
            ))}
          </StyledTableCellInfo>
        )}
        <StyledTableCellInfo>{profit.zipCode}</StyledTableCellInfo>
        <StyledTableCellInfo>
          ${profit.grandTotal?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
        </StyledTableCellInfo>
        <StyledTableCellInfo baseFontSize>
          {profit.profitTotal &&
            `$${profit.profitTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}`}
        </StyledTableCellInfo>
      </StyledTableRow>
    );
  };

  const totalHint = `"Total" is how much your customer paid for their purchase.
     It includes the sale, sales tax, and any shipping charges associated with the order.`;
  const profitHint = `"Profit" is the amount of profits available to cash out.
     It includes the sale and any shipping charges associated with the order, but has had credit card processing fees and sales tax removed. `;

  return (
    <TableContainer component={Paper}>
      <TableWrapper>
        <Head>
          <TableRow>
            {tableColumnHeaders.map((columnHeader: string) => {
              if (columnHeader === 'Total') {
                return (
                  <StyledTableCell key={columnHeader}>
                    <LabelWrapper>
                      <label>{columnHeader}</label>
                      {totalHint && (
                        <Hint placement="top" text={totalHint}>
                          <QuestionHintIcon />
                        </Hint>
                      )}
                    </LabelWrapper>
                  </StyledTableCell>
                );
              }
              if (columnHeader === 'Profit') {
                return (
                  <StyledTableCell key={columnHeader}>
                    <LabelWrapper>
                      <label>{columnHeader}</label>
                      {profitHint && (
                        <Hint placement="top" text={profitHint}>
                          <QuestionHintIcon />
                        </Hint>
                      )}
                    </LabelWrapper>
                  </StyledTableCell>
                );
              }
              return <StyledTableCell key={columnHeader}>{columnHeader}</StyledTableCell>;
            })}
          </TableRow>
        </Head>
        <TableBody>{profitsList.map((profit: Profit) => getProfitsList(profit))}</TableBody>
      </TableWrapper>
    </TableContainer>
  );
};

export default ProfitsList;
