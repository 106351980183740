import React from 'react';
import { Wrapper, StyledTooltip, TooltipWrapper } from './styles';

type Props = {
  children: any;
  text: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
  position?: string;
};

const Hint = ({ children, text, placement, position = 'absolute' }: Props) => {
  return (
    <Wrapper>
      <TooltipWrapper position={position}>
        <StyledTooltip arrow placement={placement} title={text}>
          <span>{children}</span>
        </StyledTooltip>
      </TooltipWrapper>
    </Wrapper>
  );
};

export default Hint;
