import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUploadWebsiteHeroImageAction } from 'src/ducks/website/actions';
import { websiteSelector } from 'src/ducks/website/selectors';
import { PreviewWebsiteImageIcon } from 'src/components/common/Icons';
import defaultHeroImage1 from 'src/assets/images/websites/website-1/hero-image.jpg';
import defaultHeroImage2 from 'src/assets/images/websites/website-2/hero-image.jpg';
import defaultHeroImage3 from 'src/assets/images/websites/website-3/hero-image.jpg';
import { BackButton as DeleteButton } from 'src/components/pages/orders/viewOrder/styles';
import { UploadImgStyles, ErrorMessage, Output, Delete, OverlayText, Overlay } from './styles';
import CropImageModal from 'src/components/pages/profile/avatarEditor/CropImageModal';
import { INCORRECT_SITE_TEMPLATE_MIN_HEIGHT } from 'src/constants/formValidations';
import { useImageUpload } from 'src/utils/useImageUpload';
import { IImageFile } from 'src/components/pages/products/posterImage/PosterImage';

type Props = {
  files?: File[] | any;
  onClearError?: () => void;
  templateId: number;
  setNotUploadedImageFile: (file: any) => void;
};

const UploadFiles = ({
  files,
  templateId,
  setNotUploadedImageFile,
}: Props) => {
  const dispatch = useDispatch();
  const websiteInfo = useSelector(websiteSelector);

  const [images, setImageState] = useState(files || []);
  const [uploadedImageData, setUploadedImageData] = useState<string | null>(null);
  const [isRemoveImage, setIsRemoveImage] = useState(false);
  const [error, setError] = useState('');
  const [isModalOpen, setModalOpen] = useState(false);

  const afterLoadValidation = (src: IImageFile['src']) => {
    return new Promise((resolve, reject) => {
      const img: any = new Image();
      img.src = src;
      
      img.onload = () => {
        if (img.height < 720) {
          setImageState([]);
          setError(INCORRECT_SITE_TEMPLATE_MIN_HEIGHT);
          return reject;
        }

        return resolve(true)
      }

      img.onerror = reject
    })
  };

  const chooseCurrentHeroImage = (): string => {
    if (templateId === 1) {
      return defaultHeroImage1;
    } else if (templateId === 2) {
      return defaultHeroImage2;
    } else if (templateId === 3) {
      return defaultHeroImage3;
    } else {
      return defaultHeroImage1;
    }
  };

  const removeImage = (id?: string) => () => {
    setNotUploadedImageFile([]);

    if (id) {
      setImageState(images.filter((image: File & { id?: string }) => image.id !== id));
    } else {
      setImageState([]);
    }
    dispatch(setUploadWebsiteHeroImageAction(''));
    setIsRemoveImage(true);
    setError('');
  };

  const onImageDataAvailable = (imageData: string) => {   
    setUploadedImageData(imageData);
    setError('');
  
    setModalOpen(true);
  };

  const onError = (error: string) => {
    setError(error);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCropImage = (file: any) => {
    setIsRemoveImage(false);
    setImageState([file]);
    setNotUploadedImageFile([file]);
    dispatch(setUploadWebsiteHeroImageAction(file.src));
  }

  const { triggerFileUpload, LoadImageInput } = useImageUpload(onImageDataAvailable, onError, afterLoadValidation);

  const getUploadedImage = (image: any, key: number) => {  
    const heroImages = websiteInfo?.heroImages;
    let src;

    if (heroImages.length && !image && !isRemoveImage) {
      src = heroImages[0].url;
    } else if (isRemoveImage || !image) {
      src = chooseCurrentHeroImage();
    } else {
      src = (image && image.url) || URL.createObjectURL(image);
    }

    return (
      <React.Fragment key={key}>
        <Output>
          <img src={src} alt={image?.name || ''} />
          <Overlay onClick={triggerFileUpload}>
            <PreviewWebsiteImageIcon />
            <OverlayText>
              Click to change
              <br />
              Site image <br /> <br />
              The minimal height should be 720px<br />
              File size cannot exceed 3MB
            </OverlayText>
          </Overlay>
        </Output>
        {!isRemoveImage && image && (
          <Delete>
            <DeleteButton onClick={removeImage(image?.id)}>Delete image</DeleteButton>
          </Delete>
        )}
      </React.Fragment>
    );
  };

  return (
    <UploadImgStyles>
      <CropImageModal 
        isOpen={isModalOpen}
        closeModal={closeModal}
        imageData={uploadedImageData}
        handleCropImage={handleCropImage}
        variant={'siteTemplate'}
      />
      {images.length ? images.map((image: any, i: number) => getUploadedImage(image, i)) : getUploadedImage(null, 999)}
      <LoadImageInput />
      <ErrorMessage>{error}</ErrorMessage>
    </UploadImgStyles>
  );
};

export default UploadFiles;
