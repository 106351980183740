import { createSelector } from 'reselect';
import { StateUserInterface, ErrorData, Error, User } from './user';
import userAccesses from 'src/constants/userAccesses';

export interface RootState {
  user: StateUserInterface;
}

const errorGetter = (state: any) => state.user.error;
export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        if (
          err.message === 'USERNAME_ALREADY_USED' ||
          err.message === 'Store name is already used'
        ) {
          return `${err.invalid_value} already used`;
        }
        return err.message;
      });
    } else {
      return error.errors.message;
    }
  }

  return null;
});

const pinGetter = (state: RootState) => state.user.pin;
export const pinSelector = createSelector(pinGetter, (pin) => pin);

export const userGetter = (state: RootState) => state.user.user;
export const userSelector = createSelector(userGetter, (user: User | null) => user);
export const userIdSelector = createSelector(userGetter, (user) => user?.id);
export const userWebsite = createSelector(userGetter, (user) => user?.accesses[0].store.websites);
export const userStoreId = createSelector(userGetter, (user) => user?.accesses[0].store.id);

export const fullNameSelector = createSelector(
  userGetter,
  (user) =>
    user && {
      firstName: user.firstName,
      lastName: user.lastName,
      displayName: user.displayName,
    }
);

export const storeSelector = createSelector(userGetter, (user: User | null) =>
  user?.accesses.map((item) => ({
    isTeacher: item.name === userAccesses.ACCESS_TEACHER,
    store: item.store,
  }))
);

export const firstStoreNameSelector = createSelector(storeSelector, (userStores) => {
  if (!userStores || userStores.length === 0) {
    return null;
  }
  const store = userStores[0].store;
  return store.name;
});

export const userAccessesSelector = createSelector(userGetter, (user) =>
  user?.accesses.map((item) => item.name)
);

const storeNameFormGetter = (state: any) => state.form?.storeName;
export const storeNameFormSelector = createSelector(storeNameFormGetter, (storeName) => storeName);

const userStoresGetter = (state: RootState) => state.user;
export const getStudentsListSelector = createSelector(
  userStoresGetter,
  (user) => user.studentsList
);

export const isStoreNameErrorsSelector = createSelector(
  storeNameFormGetter,
  (storeName) => !!storeName?.syncErrors
);

export const isAuthenticatedSelector = createSelector(userStoresGetter, (user) => !!user.user);

export const isUserTeacher = createSelector(userGetter, (user) =>
  user?.accesses.some((item) => item.name === userAccesses.ACCESS_TEACHER)
);

const editStudentFormGetter = (state: any) => state.form?.editStudent;
export const getStudentFormSelector = createSelector(editStudentFormGetter, (form) => form?.values);

const loadingGetter = (state: RootState) => state.user.loading;
export const loadingUserSelector = createSelector(loadingGetter, (loading) => loading);

export const canViewProfileSelector = isUserTeacher;

export const userImagesSelector = createSelector(
  userGetter,
  (user: User | null) => user?.userImages
);

export const updateTeacherProfileInfoLoadingSelector = createSelector(
  userStoresGetter,
  (user) => user?.updateTeacherProfileInfoLoading
);

export const updateTeacherProfileInfoErrorSelector = createSelector(
  userStoresGetter,
  (user) => user?.updateTeacherProfileInfoError
);

export const teacherProfileAvatarImageDataSelector = createSelector(
  userStoresGetter,
  (user) => user?.teacherProfileAvatarImageData
);

export const notifyCount = createSelector(
  userStoresGetter,
  (user) => user?.notification.eventsCount
);
