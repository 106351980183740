import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Link } from 'react-router-dom';
import { FormControl } from '@material-ui/core';
import { getStudentFormSelector, errorSelector } from 'src/ducks/user/selectors';
import { RadioField } from 'src/components/common/radio';
import userAccesses from 'src/constants/userAccesses';
import {
  createStudent,
  editStudent,
  clearStudentPin,
  getStudent,
  getNewNotifications,
} from 'src/ducks/user/actions';
import { AddStudent } from './addStudent';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { ErrorMessage } from 'src/components/pages/auth/signin/styles';
import { ResetPinButton } from 'src/components/pages/students/studenstList/styles';
import { CheckedIconForm, IconRadioForm } from 'src/components/common/Icons';
import {
  ActionsContainer,
  HelperRadioText,
  StudentFormLayout,
  StudentFormContainer,
  StudentFormLabel,
  FormContainer,
  FormLabelStyled,
  ErrorWrapper,
} from './styles';

type AddStudentProps = {
  studentId?: string;
};

export type FormProps = {
  username: string;
  fullName: string;
  name: string;
};

type Props = InjectedFormProps<FormProps, {}>;

const textForEmailHint =
  'Use any combination of characters!  We suggest creating a username that ' +
  'is easy for the student to remember. [first name] + [first initial of last name] + [#]. ' +
  'For example, Student Name: Jordan Smith, Username: jordans13';

const StudentForm = ({ valid }: Props) => {
  const { id: studentId } = useParams();
  const dispatch = useDispatch();
  const errors = useSelector(errorSelector);
  const studentFormValues = useSelector(getStudentFormSelector);

  useEffect(() => {
    dispatch(getNewNotifications());
    if (studentId) {
      dispatch(getStudent(studentId));
    }
  }, [dispatch, studentId]);

  const handleClearPin = () => {
    dispatch(clearStudentPin());
  };

  const handleCreate = () => {
    dispatch(createStudent(studentFormValues));
  };

  const handleUpdate = () => {
    dispatch(editStudent({ ...studentFormValues, studentId }));
  };

  const renderStudentFormContainer = () => (
    <FormContainer>
      <ErrorWrapper>
        {errors && Array.isArray(errors) ? (
          errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
        ) : (
          <ErrorMessage>{errors}</ErrorMessage>
        )}
      </ErrorWrapper>
      <FormInput
        type="text"
        name="fullName"
        label="Student Name *"
        title="Title about help"
        validate={validations.studentName}
      />
      <FormInput
        type="text"
        name="username"
        label="Student Username *"
        isNeedHelp
        title={textForEmailHint}
        validate={validations.studentUserName}
      />
    </FormContainer>
  );

  const renderStudentRoles = () => (
    <FormControl component="fieldset">
      <FormLabelStyled>Student Role (Select One) *</FormLabelStyled>
      <RadioField
        name="name"
        label="Editor"
        icon={<IconRadioForm />}
        checkedIcon={<CheckedIconForm />}
        radioValue={userAccesses.ACCESS_EDITOR}
      />
      <HelperRadioText>
        Editors can upload and publish products and can make changes to the website. Editors CANNOT
        fulfill orders.
      </HelperRadioText>
      <RadioField
        name="name"
        label="Manager"
        icon={<IconRadioForm />}
        checkedIcon={<CheckedIconForm />}
        radioValue={userAccesses.ACCESS_MANAGER}
      />
      <HelperRadioText>
        Managers can upload and publish products and can make changes to the website. Managers CAN
        fulfill orders.
      </HelperRadioText>
    </FormControl>
  );

  const renderActionsContainer = () => (
    <ActionsContainer>
      <Link to="/students">
        <ResetPinButton>Back</ResetPinButton>
      </Link>
      <AddStudent
        handleClearPin={handleClearPin}
        valid={valid}
        isStudentExist={!!studentId}
        handleCreateStudent={handleCreate}
        handleUpdateStudent={handleUpdate}
      />
    </ActionsContainer>
  );

  return (
    <StudentFormContainer>
      <StudentFormLabel>{studentId ? 'Edit A Student' : 'Add A Student'}</StudentFormLabel>
      <StudentFormLayout>
        <form>
          {renderStudentFormContainer()}
          {renderStudentRoles()}
          {renderActionsContainer()}
        </form>
      </StudentFormLayout>
    </StudentFormContainer>
  );
};

export default reduxForm<FormProps, AddStudentProps>({
  form: 'editStudent',
})(StudentForm);
