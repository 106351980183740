import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm, initialize, change, clearFields } from 'redux-form';
import {
  productShippingOptionsSelector,
  productTypeSelector,
  errorSelector,
} from 'src/ducks/products/selectors';
import { FormSelectField } from 'src/components/common/selects';
import { FormCheckboxField } from 'src/components/common/checkbox';
import { RadioField } from 'src/components/common/radio';
import { CheckedIconForm, IconRadioForm } from 'src/components/common/Icons';
import { FromShippingOptions } from 'src/constants/productsTypes';
import FormArea from 'src/components/common/textarea/FormArea';
import { FormInput } from 'src/components/common/formInput';
import validations from 'src/components/common/inputs/validations';
import { ProductErrorMessage } from '../styles';
import {
  FormControlWrapper,
  ShippingOptionLabel,
  LabelCategoryOption,
  ShippingBlock,
  DeliveryOptions,
  CheckBoxWrapper,
  LocalDeliveryInfo,
  ProductCategoryWrapper,
  ShippingOptionMessage,
  ShippingText,
  RadioWrapper,
} from './styles';
import { LOCAL_DELIVERY, LOCAL_PICKUP, NON_USPS_SHIPPING, orderShippingMethods, SHIPPING_NOT_AVAILABLE, USPS_NON_PRIORITY_MAIL, USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

type ShippingOptionsProps = {
  id?: number;
  getProductType: (productType: string) => void;
};

type ProductTypeOptionsType = {
  [key: string]: string
}

export type OptionListType = {
  value: string,
  name: string,
}

const PHYSICAL_PRODUCT = 'physical_product';
const DIGITAL_PRODUCT_OR_SERVICE = 'digital_product_or_service';

const DIGITAL_PRODUCT = 'isDigitalProduct';
const SERVICE = 'isService';

const productTypeOptions: ProductTypeOptionsType = {
  [PHYSICAL_PRODUCT]: 'This is a physical product',
  [DIGITAL_PRODUCT_OR_SERVICE]: 'This is a digital product or service',
};

const shippingMethods: ProductTypeOptionsType = {
  ...orderShippingMethods,
  [DIGITAL_PRODUCT]: 'Digital Product',
  [SERVICE]: 'Service',
};

const shippingInfo = `The dashboard uses USPS Priority Mail Flat-Rate boxes to calculate shipping costs. 
When using this option, you can also input tracking information and allow your customers to track their orders.`

export const prepareOptionList = (options: ProductTypeOptionsType): OptionListType[] => {
  const keyList = Object.keys(options);

  return keyList.map((key: string) => {
    return {
      value: key,
      name: options[key],
    }
  })
}

const ShippingOptionsForm = ({ id, getProductType }: ShippingOptionsProps) => {
  const dispatch = useDispatch();
  const productShippingOptions = useSelector(productShippingOptionsSelector);
  const productType = useSelector(productTypeSelector);
  const errors = useSelector(errorSelector);
  const [selectedProductType, setProductType] = useState<string>('');

  useEffect(() => {
    if (id) {
      setProductType(productType);
      getProductType(productType);
    } else {
      setProductType(PHYSICAL_PRODUCT);
      getProductType(PHYSICAL_PRODUCT);
      dispatch(
        initialize('shippingOptions', {
          isPhysicalProduct: true,
          shippingType: USPS_PRIORITY_MAIL,
          productType: PHYSICAL_PRODUCT,
          isLocalPickup: false,
          isLocalDelivery: false,
          productCategory: DIGITAL_PRODUCT,
          deliveryFee: null,
        })
      );
    }
  }, [id, productType]);

  useEffect(() => {
    if (productShippingOptions?.shippingType !== USPS_PRIORITY_MAIL) {
      dispatch(
        change('isVariousSizes', 'isVariousSizes', false)
      )

      const fieldsForChange = ['width', 'height', 'length'];
      if (id) {
        fieldsForChange.forEach((name: string) => {
          dispatch(change('product', name, null));
        });
      } else {
        dispatch(clearFields('product', false, false, ...fieldsForChange));
      }
    }
    
    if (!id && productShippingOptions?.shippingType === SHIPPING_NOT_AVAILABLE) {
      dispatch(change('shippingOptions', 'isLocalPickup', true));
      dispatch(change('shippingOptions', 'isLocalDelivery', true));
    }
  }, [productShippingOptions?.shippingType]);

  const handleChangeShippingOption = ({
    target: { value },
  }: React.ChangeEvent<{ value: unknown }>) => {
    setProductType(value as string);
    getProductType(value as string);
  };

  const renderSelector = () => (
    <FormControlWrapper>
      <ShippingOptionLabel>shipping option:</ShippingOptionLabel>
      {errors &&
        Array.isArray(errors) &&
        errors.map(
          (error, index) =>
            error.isShipping && (
              <ProductErrorMessage key={index}>{error.message}</ProductErrorMessage>
            )
        )}
      <FormSelectField
        name="productType"
        options={prepareOptionList(productTypeOptions)}
        selectedValue={selectedProductType}
        handleChange={handleChangeShippingOption}
      />
    </FormControlWrapper>
  );

  const renderLocalDeliveryInfo = () => (
    <LocalDeliveryInfo>
      <FormInput
        placeholder="Delivery Fee"
        type="number"
        name="deliveryFee"
        label="Delivery Fee: "
        validate={validations.deliveryFee}
        customBg="#F8FAFB"
      />
      <FormArea label="Note: " name="note" validate={validations.productNote} />
    </LocalDeliveryInfo>
    );

  const renderShippingInfo = () => {
    switch (productShippingOptions?.shippingType) {
      case USPS_PRIORITY_MAIL:
        return `You’re using a USPS Priority Mail box and the dashboard will 
        automatically charge shipping based on the current rates.`
      case USPS_NON_PRIORITY_MAIL:
        return `You’re using USPS (not a flat-rate Priority Mail Box), 
        the dashboard will not charge shipping, you’ve included the cost 
        of shipping into the price of your product.`
      case NON_USPS_SHIPPING:
        return `You’re using another shipping carrier (ex: UPS, Fedex, etc.),
                the dashboard will not charge shipping, and you’ve included the 
                cost of shipping into the price of your product.`
      default:
        return `You do not plan to ship this product at all.`
    }
  };

  const renderPhysicalProductOptions = () => {
    return (
      <ShippingBlock>
        <LabelCategoryOption>Shipping</LabelCategoryOption>
        <ShippingOptionMessage>{shippingInfo}</ShippingOptionMessage>
        <ShippingText>{renderShippingInfo()}</ShippingText>
        <RadioWrapper>
          <RadioField
            name="shippingType"
            label={shippingMethods[USPS_PRIORITY_MAIL]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={USPS_PRIORITY_MAIL}
          />
          <RadioField
            name="shippingType"
            label={shippingMethods[USPS_NON_PRIORITY_MAIL]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={USPS_NON_PRIORITY_MAIL}
          />
          <RadioField
            name="shippingType"
            label={shippingMethods[NON_USPS_SHIPPING]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={NON_USPS_SHIPPING}
          />
          <RadioField
            name="shippingType"
            label={shippingMethods[SHIPPING_NOT_AVAILABLE]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={SHIPPING_NOT_AVAILABLE}
          />
        </RadioWrapper>
        <DeliveryOptions>
          <LabelCategoryOption>Alternate Delivery Options</LabelCategoryOption>
          <CheckBoxWrapper>
            <FormCheckboxField
              style={{ color: '#8697A8', fontSize: 15 }}
              name="isLocalPickup"
              checkValue={!!productShippingOptions?.isLocalPickup}
              label={shippingMethods[LOCAL_PICKUP]}
            />
            <FormCheckboxField
              style={{ color: '#8697A8', fontSize: 15 }}
              name="isLocalDelivery"
              checkValue={!!productShippingOptions?.isLocalDelivery}
              label={shippingMethods[LOCAL_DELIVERY]}
            />
          </CheckBoxWrapper>
        </DeliveryOptions>
        <DeliveryOptions>
          {productShippingOptions?.isLocalDelivery && renderLocalDeliveryInfo()}
        </DeliveryOptions>
      </ShippingBlock>
    );
  };

  const renderDigitalProduct = () => {
    return (
      <ShippingBlock>
        <LabelCategoryOption>Product Category</LabelCategoryOption>
        <ProductCategoryWrapper>
          <RadioField
            name="productCategory"
            label={shippingMethods[DIGITAL_PRODUCT]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={DIGITAL_PRODUCT}
          />
          <RadioField
            name="productCategory"
            label={shippingMethods[SERVICE]}
            icon={<IconRadioForm />}
            checkedIcon={<CheckedIconForm />}
            radioValue={SERVICE}
          />
        </ProductCategoryWrapper>
        <FormArea label="Note: " name="note" validate={validations.productNote} />
      </ShippingBlock>
    );
  };

  return (
    <div>
      {renderSelector()}
      {selectedProductType === PHYSICAL_PRODUCT
        ? renderPhysicalProductOptions()
        : renderDigitalProduct()}
    </div>
  );
};

export default reduxForm<FromShippingOptions, ShippingOptionsProps, {}>({
  form: 'shippingOptions',
})(ShippingOptionsForm);
