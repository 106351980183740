import React from 'react';
import { FormControl, FormControlProps } from '@material-ui/core';
import { styled, withTheme, WithTheme,  } from '@material-ui/core/styles';
import { SmTitle } from '../styles';

export const ShippingOptionLabel = styled(SmTitle)({
  marginBottom: 20,
});

export const FormControlWrapper = styled(
  ({ ...otherProps }: FormControlProps) => (
    <FormControl {...otherProps} />
  ))({
  width: 350,
  paddingBottom: 30,
});

export const LabelCategoryOption = styled('div')({
  display: 'flex',
  fontSize: 15,
  lineHeight: '18px',
  paddingBottom: 14,
});

export const ShippingBlock = styled('div')({
  color: '#8697A8',
  '& textarea': {
    height: 65,
    border: '1px solid #EAEDF0',
    color: '#44566C',
    resize: 'none',
    fontSize: 15,
    fontFamily: 'Roboto',
    lineHeight: '24px',
  },
});

export const DeliveryOptions = styled('div')({
  paddingTop: 30,
});

export const CheckBoxWrapper = styled('div')({
  display: 'flex',
  flexFlow: 'wrap',
});

export const RadioWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexFlow: 'nowrap',
});


export const LocalDeliveryInfo = styled('div')({
  '& > div:first-child': {
    maxWidth: 350,
  },
});

export const ProductCategoryWrapper = styled('div')({
  paddingBottom: 33
});

export const ShippingOptionMessage = styled('div')({
  fontSize: 13,
  paddingBottom: 9,
})

export const ShippingText = withTheme(
  styled('div')({
    color: ({ theme }: WithTheme) => theme.customPalette.buttons.activeBg,
    fontSize: 13,
    paddingBottom: 9,
    width: '100%'
  })
);

