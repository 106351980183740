import React from 'react';
import { TableCell, TableCellProps, TableRow, TableRowProps } from '@material-ui/core';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

type StyledTableCellInfo = {
  color?: string | undefined;
  isDefaultValue?: boolean;
  baseFontSize?: boolean;
};

export const OrdersTotalTitle = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    fontSize: 30,
    fontWeight: 500,
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    paddingBottom: 25,
  })
);

export const StyledTableCellInfo = withTheme(
  styled(
    ({
      theme,
      color,
      isDefaultValue,
      baseFontSize,
      ...otherProps
    }: TableCellProps & WithTheme & StyledTableCellInfo) => <TableCell {...otherProps} />
  )({
    wordBreak: 'break-word',
    color: ({ theme, isDefaultValue }: WithTheme & StyledTableCellInfo) =>
      isDefaultValue ? '#8697A8' : theme.customPalette.text.primary,
    '&:first-child, &:last-child': {
      color: '#8697A8',
    },
    '&:last-child': {
      fontSize: ({ isDefaultValue, baseFontSize }: any) =>
        isDefaultValue ? (baseFontSize ? '' : '11px') : 'inherit',
    },
    '& > span': {
      height: 6,
      width: 6,
      marginRight: 7,
      verticalAlign: 'middle',
      backgroundColor: ({ color }: WithTheme & StyledTableCellInfo) => color,
      borderRadius: '50%',
      display: 'inline-block',
    },
  })
);

export const StyledDateCell = withTheme(
  styled(({ theme, ...otherProps }: TableCellProps & WithTheme) => <TableCell {...otherProps} />)({
    wordBreak: 'break-word',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
  })
);

export const StyledTableRow = withTheme(
  styled(({ theme, ...otherProps }: TableRowProps & WithTheme) => <TableRow {...otherProps} />)({
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: ({ theme }: any) => theme.customPalette.background.greyBg,
    },
  })
);

export const DefaultTrackingMessage = styled('div')({
  color: '#8697A8',
});
