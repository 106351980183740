import { StylesTemplate } from 'src/constants/stylesTemplates';

export const styles = () => ({
  select: {
    width: '100%',
    height: 45,
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    '& > svg':{
      color: (styles: StylesTemplate) => styles.cartColor,
      transform: 'scale(1.5)',
    },
    '& .MuiSelect-selectMenu':{
      backgroundColor: 'red'
    },
    '&.Mui-disabled': {
      opacity: 0.7,
      '& .MuiOutlinedInput-notchedOutline': {
        border: (styles: StylesTemplate) => `1px solid ${styles.cartColor}`
      },
    },
    '& .MuiOutlinedInput-input': {
      background: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: (styles: StylesTemplate) => `2px solid ${styles.cartColor}`
    },
    '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: (styles: StylesTemplate) => `2px solid ${styles.cartColor}`,
    },
  },
  input: {
    color: (styles: StylesTemplate) => styles.cartColor,
  },
  popover: {
    color: (styles: StylesTemplate) => styles.cartColor,
    border: (styles: StylesTemplate) => `2px solid ${styles.cartColor}`,
    borderRadius: 10,
  },
});
