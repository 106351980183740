import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm, InjectedFormProps, destroy } from 'redux-form';
import { errorSelector, isTeacherSelector } from 'src/ducks/auth/selectors';
import { switchPages } from 'src/ducks/auth/actions';
import { FormInputField } from 'src/components/common/inputs';
import inputRestrictions from 'src/constants/inputFieldRestrictions';
import validations from 'src/components/common/inputs/validations';
import { Link } from 'react-router-dom';
import { EmailIcon, PasswordIcon, ProfileNameIcon } from 'src/components/common/Icons';
import {
  ErrorMessage,
  FormContainer,
  ContinueButton,
  AdditionalInfo,
  ContinueButtonContainer,
  InputFieldContainer,
  RoleContainer,
  RoleButton,
} from './styles';

export type FormProps = {
  email: string;
  password: string;
};

type Props = InjectedFormProps<FormProps, {}>;

const Signin = (props: Props) => {
  const { handleSubmit, valid } = props;
  const error = useSelector(errorSelector);
  const isTeacher = useSelector(isTeacherSelector);
  const dispatch = useDispatch();
  const [isSignInTeacher, changeSignInStudentOrTeacher] = useState(isTeacher);
  const handleChangeRole = () => {
    changeSignInStudentOrTeacher(!isSignInTeacher);
    dispatch(destroy('signin'));
  };
  const linkToForgotPage = () => dispatch(switchPages());

  return (
    <form onSubmit={handleSubmit}>
      <RoleContainer>
        <RoleButton onClick={handleChangeRole} isActive={!isSignInTeacher}>Student</RoleButton>
        <RoleButton onClick={handleChangeRole} isActive={isSignInTeacher}>Teacher</RoleButton>
      </RoleContainer>
      <FormContainer>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {isSignInTeacher ? (
          <InputFieldContainer>
            <FormInputField
              placeholder="Email"
              adornment={<EmailIcon />}
              name="email"
              maxLength={inputRestrictions.MAX_LENGTH_EMAIL}
              validate={validations.email}
            />
            <FormInputField
              placeholder="Password"
              adornment={<PasswordIcon />}
              name="password"
              type="password"
              autoComplete="off"
              validate={validations.password}
              maxLength={inputRestrictions.MAX_LENGTH_PASSWORD}
            />
          </InputFieldContainer>
        ) : (
          <InputFieldContainer>
            <FormInputField
              placeholder="Login"
              adornment={<ProfileNameIcon />}
              name="email"
              validate={validations.login}
              maxLength={inputRestrictions.MAX_LENGTH}
            />
            <FormInputField
              placeholder="PIN"
              adornment={<PasswordIcon />}
              name="password"
              type="password"
              autoComplete="off"
              validate={validations.pin}
              maxLength={inputRestrictions.MAX_LENGTH_PIN}
            />
          </InputFieldContainer>
        )}
        <AdditionalInfo>
          {isSignInTeacher &&
          <Link to="/forgot-password" onClick={linkToForgotPage}>
            <p>Forgot password?</p>
          </Link>
          }
        </AdditionalInfo>
        <ContinueButtonContainer>
          <ContinueButton variant="contained" color="primary" disabled={!valid} type="submit">
            Continue
          </ContinueButton>
        </ContinueButtonContainer>
      </FormContainer>
    </form>
  );
};

export default reduxForm<FormProps, {}>({
  form: 'signin',
})(Signin);
