import React from 'react';
import { Wrapper, Label, InputError } from '../formInput/styles';
import { Area } from './styles';
import { Field } from 'redux-form';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

type Props = {
  adornment?: JSX.Element | string;
  name: string;
  disabled?: boolean;
  customClass?: string;
  maxLength?: number;
  type?: string;
  multiline?: boolean;
  placeholder?: string;
  validate?: ((value: string) => string | null)[];
  customRef?: React.Ref<HTMLButtonElement>;
  label?: string;
  required?: boolean;
  isNeedHelp?: boolean;
  title?: string;
  input: any;
  meta: any;
};

const FormAreaComponent = (props: Props) => {
  const { error, active, touched } = props.meta;
  const hasError = touched && !active && !!error;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      error: {
        border: `1px solid ${theme.customPalette.borders.errorInput}`,
      },
    })
  );

  const classes = useStyles();
  return (
    <Wrapper>
      <Label>
        {props.label}
        {props.required && '*'}
      </Label>
      <Area
        {...props}
        disabled={props.disabled}
        className={hasError ? classes.error : ''}
        inputref={props.customRef}
        {...props.input}
      />
      {hasError && <InputError>{error}</InputError>}
    </Wrapper>
  );
};

const FormArea = (props: any, ref: any) => (
  <Field component={FormAreaComponent} {...props} customref={ref} />
);

export default FormArea;
