import React, { SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { websiteSelector } from 'src/ducks/website/selectors';
import { ProductProps } from 'src/ducks/website/website';
import { calculateFullProductsAmount } from 'src/utils/helpers';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import heroImage from 'src/assets/images/websites/website-2/hero-image.jpg';
import productImage from 'src/assets/images/websites/product-image.png';
import vars from 'src/constants/vars';
import {
  SkewLinesIcon,
  ShopCartTemplate2Icon,
  RightArrow,
  LogoIconSmall,
  DownArrow,
  RightArrowBig,
} from 'src/components/common/Icons';
import {
  WebsiteContainer,
  HeroImageContainer,
  WebsiteNameContainer,
  WebsiteName,
  CartContainer,
  SocialNetworksContainer,
  ImageWithTaglineContainer,
  Tagline,
  ShopNowButton,
  CardContainer,
  ImageContainer,
  OutOfStock,
  CardName,
  Price,
  AboutUsContainer,
  AboutUsDescription,
  AboutUsTitle,
  ContactUsButtonContainer,
  FooterContainer,
  ContactUsButton,
  LoadMoreContainer,
  LoadMoreButton,
  FreeShipping,
  ViewDetailsButton,
  CardInfo,
  CarouselWrapper,
  Line,
  IconWrapper,
  LearnMore
} from './styles';
import { NON_USPS_SHIPPING, USPS_NON_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';
import { WebsiteTemplateProps } from '../../view-website/ViewWebsite';
import { ProductsSection, ProductsLayout, ProductsSectionTitle, ColumnLayout, styles } from '../styles';
import { CollectionSelect } from 'src/components/pages/products/collectionSelect';
import { NoProducts } from '../noProducts';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(styles);

export const renderFooter = (email: string) => (
  <FooterContainer>
    <div>
      <div>
        <LogoIconSmall />
      </div>
      <a href={`mailto:${email}`}>
        <ContactUsButtonContainer>
          <RightArrow />
          <ContactUsButton type="submit" value="Send us an email" />
        </ContactUsButtonContainer>
      </a>
      <LearnMore href="https://www.realworldscholars.org/edcorps">
        Learn More about EdCorps
      </LearnMore>
    </div>
  </FooterContainer>
);

const Template2 = ({
  products,
  otherProducts,
  loadMore,
  collections,
  selectedCollection,
  onCollectionChange,
  totalCount,
  totalCountOthers,
  isLoading,
  disableRedirectIfOutOfStock
}: WebsiteTemplateProps)=> {
  const { name, tagline, heroImages, email, description, path } = useSelector(websiteSelector);
  const isLoadMoreShow = products && totalCount !== products.length;
  const isLoadMoreOthersShow = otherProducts && totalCountOthers !== otherProducts.length;
  const productsAmount = calculateFullProductsAmount();
  const classes = useStyles();

  const renderProductList = (product: ProductProps) => {
    const { id, imagesGallery, name, shippingType, price, inventory, isPhysicalProduct } = product;
    const imageList = Object.values(imagesGallery).map((arr: any) => arr[1].url);
    const isOutOfStock = isPhysicalProduct && inventory === 0;
    const isShippingIncluded = shippingType === USPS_NON_PRIORITY_MAIL || shippingType === NON_USPS_SHIPPING;

    return (
      <CardContainer key={id}>
        <CardInfo>
          <CardName title={name}>{name.slice(0, 25)}</CardName>
          <Price>${price.toLocaleString('en-US', { minimumFractionDigits: 2 })}</Price>
          <FreeShipping>{isShippingIncluded && 'Shipping Included'}</FreeShipping>
        </CardInfo>
        {imageList.length ? (
          <CarouselWrapper {...vars.sliderSettings}>
            {imageList.map((url: string, i: number) => {
              return (
                <ImageContainer isOutOfStock={isOutOfStock} key={i}>
                  {isOutOfStock && (
                    <OutOfStock>This item is currently out of stock</OutOfStock>
                  )}
                  <img src={url} alt="" />
                </ImageContainer>
              );
            })}
          </CarouselWrapper>
        ) : (
          <ImageContainer isOutOfStock={isOutOfStock}>
            {isOutOfStock && <OutOfStock>This item is currently out of stock</OutOfStock>}
            <img src={productImage} alt="" />
          </ImageContainer>
        )}
        <Link
          to={`/shop/${path}/${id}`}
          onClick={(e: SyntheticEvent) => disableRedirectIfOutOfStock(e, inventory, isPhysicalProduct)}
        >
          <ViewDetailsButton>View Details</ViewDetailsButton>
        </Link>
      </CardContainer>
    );
  };

  return (
    <WebsiteContainer>
      {isLoading && <FullScreenPreloader />}
      <HeroImageContainer>
        <WebsiteNameContainer>
          <CartContainer>
            <SkewLinesIcon />
            <Link to={`/shop/${path}/cart`}>
              <ShopCartTemplate2Icon />
            </Link>
            <div>{productsAmount}</div>
          </CartContainer>
          <WebsiteName>{name}</WebsiteName>
          <SocialNetworksContainer />
        </WebsiteNameContainer>
        <ImageWithTaglineContainer>
          <img src={(heroImages[0] && heroImages[0].url) || heroImage} alt="heroImage" />
          <Tagline>{tagline}</Tagline>
          <a href="#products">
            <ShopNowButton>
              See Student-Made Products Here
              <RightArrowBig />
            </ShopNowButton>
          </a>
        </ImageWithTaglineContainer>
      </HeroImageContainer>
      <ProductsSection id="products">
        <CollectionSelect
          selectedCollection={selectedCollection}
          collections={collections}
          onCollectionChange={onCollectionChange}
        />
        {products?.length
          ? <ProductsLayout columnLayout={ColumnLayout.THREE_COLUMNS} className={classes.columnLayout}>
            { products.map((product: ProductProps) => renderProductList(product)) }
            </ProductsLayout>
          : <NoProducts />}
      </ProductsSection>
      {isLoadMoreShow && (
        <LoadMoreContainer>
          <Line />
          <LoadMoreButton onClick={() => loadMore()}>
            Show More
            <IconWrapper><DownArrow/></IconWrapper>
          </LoadMoreButton>
          <Line />
        </LoadMoreContainer>
      )}
      {/** Reuse code above and below to avoid DRY */}
      { otherProducts?.length && (
        <>
          <ProductsSection id="products-others">
            <ProductsSectionTitle>Other Products</ProductsSectionTitle>
            <ProductsLayout columnLayout={ColumnLayout.THREE_COLUMNS} className={classes.columnLayout}>
              { otherProducts.map((product: ProductProps) => renderProductList(product)) }
            </ProductsLayout>
          </ProductsSection>
          {isLoadMoreOthersShow && (
            <LoadMoreContainer>
              <Line />
              <LoadMoreButton onClick={() => loadMore(true)}>
                Show More
                <IconWrapper><DownArrow/></IconWrapper>
              </LoadMoreButton>
              <Line />
            </LoadMoreContainer>
          )}
        </>
      )}
      <AboutUsContainer>
        <AboutUsTitle>About Us</AboutUsTitle>
        <AboutUsDescription>{description}</AboutUsDescription>
      </AboutUsContainer>
      {renderFooter(email)}
    </WebsiteContainer>
  );
};

export default Template2;
