import { LOCAL_DELIVERY, LOCAL_PICKUP, NON_USPS_SHIPPING, NOT_APPLICABLE, SHIPPING_NOT_AVAILABLE, USPS_DELIVERY } from "./orderShippingMethods";

export type CartShippingMethodsType = {
  [key: string]: string
}

export const cartShippingMethods: CartShippingMethodsType = {
  [USPS_DELIVERY]: 'USPS Delivery',
  [NON_USPS_SHIPPING]: 'Non-USPS Shipping',
  [SHIPPING_NOT_AVAILABLE]: 'This Order Will Not Be Shipped',
  [LOCAL_DELIVERY]: 'Local Delivery',
  [LOCAL_PICKUP]: 'Local Pickup',
  [NOT_APPLICABLE]: 'Not Applicable',
}