import React, { useState } from 'react';
import { Popup } from 'src/components/common/modal';
import { DeleteIcon } from 'src/components/common/Icons';
import popupText from 'src/constants/popupMessages';

type DeleteStudentProps = {
  studentId: number;
  error: string | (string | undefined)[] | null;
  handleRemoveStudent: (studentId: number) => void;
};

const DeleteStudent = (props: DeleteStudentProps) => {
  const { studentId, error, handleRemoveStudent } = props;
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRemove = () => {
    handleRemoveStudent(studentId);
    setOpen(false);
  };

  return (
    <>
      <div onClick={handleOpen}>
        <DeleteIcon />
      </div>
      <Popup
        kind="danger"
        isOpen={isOpen}
        error={error}
        mainText={popupText.deleteStudent.mainText}
        closeModal={handleClose}
        handleYesAction={handleRemove}
        isRemove
      />
    </>
  );
};

export default DeleteStudent;
