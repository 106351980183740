import { all, put, select, takeLatest } from 'redux-saga/effects';
import productsApi from 'src/api/productsApi';
import {
  ADD_PRODUCT,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_FAIL,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCTS_LIST,
  GET_PRODUCTS_LIST_FAIL,
  GET_PRODUCTS_LIST_SUCCESS,
  EDIT_PRODUCT_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT,
  GET_PRODUCT_FAIL,
  EDIT_PRODUCT_FAIL,
  EDIT_PRODUCT,
  SET_IMAGES,
  CLEAR_PRODUCTS_LIST,
  ADD_PRODUCT_WITH_CUSTOM_FIELDS,
  ADD_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS,
  ADD_PRODUCT_WITH_CUSTOM_FIELDS_FAIL,
  EDIT_PRODUCT_WITH_CUSTOM_FIELDS,
  EDIT_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS,
  EDIT_PRODUCT_WITH_CUSTOM_FIELDS_FAIL,
} from './constants';
import { ActionType, Error } from '../user/user';
import { change, initialize } from 'redux-form';
import history from 'src/containers/history';
import {
  AddProductPayloadT,
  EditProductPayloadT,
  DeleteProduct,
  OneOptionT,
  TableDataT,
  WebsiteCollection,
} from 'src/constants/productsTypes';

export const moduleName = 'product';

export type StateProductsInterface = {
  error: Error | null;
  loading: boolean;
  productsList: {
    list: ProductsList[];
    currentPage: number;
    totalPages: number;
    total: any;
  } | null;
  productType: string;
  imagesGallery: [];
};

export type ProductsList = {
  id: number;
  name: string;
  price: number;
  inventory: number;
  imagesGallery: any;
  websiteCollection?: WebsiteCollection;
};

export const productsState: StateProductsInterface = {
  productsList: null,
  productType: '',
  error: null,
  loading: false,
  imagesGallery: [],
};

export default function reducer(
  state: StateProductsInterface = productsState,
  { type, payload, error }: ActionType
) {
  switch (type) {
    case GET_PRODUCTS_LIST:
    case ADD_PRODUCT:
    case ADD_PRODUCT_WITH_CUSTOM_FIELDS:
    case EDIT_PRODUCT_WITH_CUSTOM_FIELDS:
      return { ...state, loading: true, error: null };

    case GET_PRODUCTS_LIST_SUCCESS:
      const list = state?.productsList?.list;
      return {
        ...state,
        loading: false,
        productsList: {
          list: list ? [...list, ...payload.data] : payload.data,
          currentPage: payload._page,
          totalPages: payload._pages,
          total: payload._total,
        },
      };

    case GET_PRODUCTS_LIST_FAIL:
    case ADD_PRODUCT_FAIL:
    case ADD_PRODUCT_WITH_CUSTOM_FIELDS_FAIL:
    case EDIT_PRODUCT_WITH_CUSTOM_FIELDS_FAIL:
    case EDIT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        productsList: null,
        error: error,
      };

    case EDIT_PRODUCT_SUCCESS:
    case EDIT_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS:
    case ADD_PRODUCT_SUCCESS:
    case ADD_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS:
      return { ...state, loading: false, error: null };

    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productType: payload,
        loading: false,
        error: null
      }

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          list: state.productsList?.list.filter((item) => item.id !== payload),
          total: state.productsList?.total - 1,
        },
        loading: false,
      };

    case SET_IMAGES:
      return {
        ...state,
        imagesGallery: payload,
      };

    case CLEAR_PRODUCTS_LIST:
      return { ...state, productsList: null, error: null };

    default:
      return state;
  }
}

function* getProductsListSaga(payload: any) {
  try {
    const response = yield productsApi.getProductsList(
      payload.payload.websiteId || payload.payload,
      payload.payload.page
    );
    yield put({ type: GET_PRODUCTS_LIST_SUCCESS, payload: response });
  } catch (err) {
    yield put({ type: GET_PRODUCTS_LIST_FAIL, error: err });
  }
}

function* addProductSaga({
  payload: {
    description,
    price,
    name,
    width,
    height,
    length,
    inventory,
    imagesGallery,
    shippingOptions,
    websiteCollection,
  },
}: AddProductPayloadT) {
  const websitesIds = yield select((state) => state.user?.user?.accesses[0].store.websites);
  const websiteId = websitesIds[0].id;
  try {
    const preparedData = {
      description,
      price,
      name,
      width,
      height,
      length,
      inventory,
      website: websiteId,
      imagesGallery,
      collectionName: websiteCollection?.collectionName || null,
      ...shippingOptions,
    };

    const response = yield productsApi.addProduct(preparedData);

    yield put({
      type: ADD_PRODUCT_SUCCESS,
      payload: response.plainPassword,
    });
    history.push('/products');
  } catch (err) {
    yield put({ type: ADD_PRODUCT_FAIL, error: err.data });
  }
}

function* addProductWithCustomFieldsSaga({
  payload: {
    description,
    price,
    name,
    width,
    height,
    length,
    imagesGallery,
    websiteCollection,
    isVariousSizes,
    productChoices,
    productOptions,
    shippingOptions
  },
}: AddProductPayloadT) {
  const websitesIds = yield select((state) => state.user?.user?.accesses[0].store.websites);
  const websiteId = websitesIds[0].id;
  try {
    const preparedData = {
      description,
      price,
      name,
      width,
      height,
      length,
      isVariousSizes,
      productChoices,
      productOptions,
      website: websiteId,
      collectionName: websiteCollection?.collectionName || null,
      imagesGallery: imagesGallery || {},
      ...shippingOptions,
    };
    const response = yield productsApi.addProduct(preparedData);

    yield put({
      type: ADD_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS,
      payload: response.plainPassword,
    });
    history.push('/products');
  } catch (err) {
    yield put({ type: ADD_PRODUCT_WITH_CUSTOM_FIELDS_FAIL, error: err.data });
  }
}

function* deleteProductSaga({ payload }: DeleteProduct) {
  try {
    yield productsApi.removeProduct(payload);

    yield put({ type: DELETE_PRODUCT_SUCCESS, payload });
  } catch (err) {
    yield put({ type: DELETE_PRODUCT_FAIL, error: err.data });
  }
}

function* getProductSaga({ payload: id }: ActionType) {
  const response = yield productsApi.getProduct(id);

  const fistNamesArr = response?.productOptions[0]?.productOptionValues.map((el: OneOptionT) => el.name);
  const secondNamesArr = response?.productOptions[1]?.productOptionValues.map((el: OneOptionT) => el.name);
  const combinedNamesArr: string[] = [];
  fistNamesArr?.forEach((firstName: string) => {
    return secondNamesArr?.forEach((secondName: string) => {
      combinedNamesArr.push(`${firstName},${secondName}`);
    });
  });
  const namesArr = response?.productOptions?.length > 1 ? combinedNamesArr : fistNamesArr;
  try {
    yield put(
      initialize('product', {
        description: response.description,
        price: response.price,
        name: response.name,
        width: response.width,
        height: response.height,
        length: response.length,
        inventory: response.inventory,
        imagesGallery: response.imagesGallery,
        websiteCollection: response.websiteCollection,
      })
    );
    yield put(
      initialize('hasCustomFields', { hasCustomFields: response?.productChoices.length > 0 })
    );
    yield put(
      change(
        'ProductVersionsNames',
        'name1',
        response?.productOptions[0] ? response.productOptions[0].name : ''
      )
    );
    yield put(
      change(
        'ProductVersionsNames',
        'name2',
        response?.productOptions[1] ? response.productOptions[1].name : ''
      )
    );
    yield put(
      change(
        'ProductVersionsChips',
        'chip1',
        response?.productOptions[0]
          ? response.productOptions[0].productOptionValues.map(
              (el: { id: number; name: string }) => {
                return el.name;
              }
            )
          : []
      )
    );
    yield put(
      change(
        'ProductVersionsChips',
        'chip2',
        response?.productOptions[1]
          ? response.productOptions[1].productOptionValues.map(
              (el: { id: number; name: string }) => {
                return el.name;
              }
            )
          : []
      )
    );
    yield put(
      change(
        'isVariousSizes',
        'isVariousSizes',
        !response?.height || !response?.length || !response?.width
      )
    );
    yield put(
      initialize(
        'tableData',
        namesArr?.map((name: string, i: number) => {
            return {
              [name]: {
                height: response?.productChoices[i]?.height,
                length: response?.productChoices[i]?.length,
                width: response?.productChoices[i]?.width,
                price: response?.productChoices[i]?.price,
                inventory: response?.productChoices[i]?.inventory,
              },
            };
          })
          .reduce((result: TableDataT, item: TableDataT) => {
            const key = Object.keys(item)[0];
            result[key] = item[key];
            return result;
          }, {})
      )
    );
    yield put(
      initialize('shippingOptions', {
        isPhysicalProduct: response.isPhysicalProduct,
        productType: response.isPhysicalProduct ? 'physical_product' : 'digital_product_or_service',
        shippingType: response.shippingType,
        isLocalPickup: response.isLocalPickup,
        isLocalDelivery: response.isLocalDelivery,
        productCategory: response.isDigitalProduct ? 'isDigitalProduct' : 'isService',
        deliveryFee: response.deliveryFee,
        note: response.note
      })
    );
    const productType = response.isPhysicalProduct ? 'physical_product' : 'digital_product_or_service';

    yield put({ type: GET_PRODUCT_SUCCESS, payload: productType });
  } catch (err) {
    yield put({ type: GET_PRODUCT_FAIL, error: err });
  }
}

function* editProductSaga({
  payload: {
    description,
    price,
    name,
    width,
    height,
    length,
    inventory,
    websiteCollection,
    isVariousSizes,
    productOptions,
    productChoices,
    id,
    imagesGallery,
    shippingOptions,
  },
}: EditProductPayloadT) {
  const websitesIds = yield select((state) => state.user?.user?.accesses[0].store.websites);
  const websiteId = websitesIds[0].id;

  try {
    const preparedData = {
      description,
      price,
      name,
      width,
      height,
      length,
      inventory,
      isVariousSizes,
      productOptions,
      productChoices,
      website: websiteId,
      imagesGallery,
      collectionName: websiteCollection?.collectionName || null,
      ...shippingOptions,
    };
    yield productsApi.editProduct(id, preparedData);
    yield put({ type: EDIT_PRODUCT_SUCCESS });
    history.push('/products');
  } catch (err) {
    yield put({ type: EDIT_PRODUCT_FAIL, error: err.data });
  }
}

function* editProductWithCustomFieldsSaga({
  payload: {
    description,
    price,
    name,
    width,
    height,
    length,
    websiteCollection,
    isVariousSizes,
    productOptions,
    productChoices,
    id,
    imagesGallery,
    shippingOptions,
  },
}: EditProductPayloadT) {
  const websitesIds = yield select((state) => state.user?.user?.accesses[0].store.websites);
  const websiteId = websitesIds[0].id;
  
  try {
    const preparedData = {
      description,
      price,
      name,
      width,
      height,
      length,
      website: websiteId,
      imagesGallery,
      collectionName: websiteCollection?.collectionName || null,
      isVariousSizes,
      productOptions,
      productChoices,
      ...shippingOptions,
    };
    yield productsApi.editProduct(id, preparedData);
    yield put({ type: EDIT_PRODUCT_WITH_CUSTOM_FIELDS_SUCCESS });
    history.push('/products');
  } catch (err) {
    yield put({ type: EDIT_PRODUCT_WITH_CUSTOM_FIELDS_FAIL, error: err.data });
  }
}

export const saga = function* () {
  yield all([
    takeLatest(GET_PRODUCTS_LIST, getProductsListSaga),
    takeLatest(ADD_PRODUCT, addProductSaga),
    takeLatest(ADD_PRODUCT_WITH_CUSTOM_FIELDS, addProductWithCustomFieldsSaga),
    takeLatest(DELETE_PRODUCT, deleteProductSaga),
    takeLatest(GET_PRODUCT, getProductSaga),
    takeLatest(EDIT_PRODUCT, editProductSaga),
    takeLatest(EDIT_PRODUCT_WITH_CUSTOM_FIELDS, editProductWithCustomFieldsSaga),
  ]);
};
