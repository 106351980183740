import React from 'react';
import { useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';
import { ExclamationMarkIcon } from '../../../../common/Icons';
import { FormCheckboxField } from '../../../../common/checkbox';
import { productShippingOptionsSelector, productVersionsFormSelector } from '../../../../../ducks/products/selectors';
import { isVariousSizesT } from '../../../../../constants/productsTypes';
import { AdditionalElement, InfoWrapper } from './styles';
import { USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

type infoBlockT = {
  productType: string;
};

const InfoBlock = ({ productType }: infoBlockT) => {
  const productVersionsFormValues: isVariousSizesT = useSelector(productVersionsFormSelector);
  const productShippingOptions = useSelector(productShippingOptionsSelector);
  const isPhysicalProduct = productType === 'physical_product';
  const isUspsPriorityMail = productShippingOptions?.shippingType === USPS_PRIORITY_MAIL;

  return (
    <>
      <InfoWrapper>
        <AdditionalElement>
          <ExclamationMarkIcon />
        </AdditionalElement>
        <span>Does this product come in multiple variations?</span> You can add up to two variation
        categories with up to five options for each. Ex: By adding the category "Color", you can add
        up to 5 different color variations for your customers.
      </InfoWrapper>
      {(isPhysicalProduct && isUspsPriorityMail) && <InfoWrapper>
        <AdditionalElement>
          <FormCheckboxField
            style={{ color: '#008DBA' }}
            name="isVariousSizes"
            checkValue={!!productVersionsFormValues?.isVariousSizes}
          />
        </AdditionalElement>
        <span>Various sizes of Product.</span> Variants of this product come in various sizes and
        might require different shipping boxes than the original product listed above.
      </InfoWrapper>
      }
    </>
  );
};

export default reduxForm<isVariousSizesT, infoBlockT>({
  form: 'isVariousSizes',
})(InfoBlock);
