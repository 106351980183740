import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userWebsitesArraySelector, websiteSelector } from 'src/ducks/website/selectors';
import { isUserTeacher } from 'src/ducks/user/selectors';
import { getWebsiteAction } from 'src/ducks/website/actions';
import { getUserWebsiteID } from 'src/utils/helpers';
import { List, Divider } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { LogoIconSmall } from 'src/components/common/Icons';
import { StoreMenu } from './storeMenu';
import history from 'src/containers/history';
import { SmartLinkWrapper } from 'src/components/common/smartLinkWrapper';
import { sideBarListForStudent, sideBarListForTeacher } from 'src/constants/dataForSideBar';
import { DrawerContainer, Logo, ListItem, ItemText, ItemIcon, SidebarContainer } from './styles';

type SideBarList = {
  name: string;
  icon: () => JSX.Element;
  isDivider: boolean;
  link: string;
  external: boolean;
  fontWeightMedium?: boolean;
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const { path } = useSelector(websiteSelector);
  const dispatch = useDispatch();
  const websitesArray = useSelector(userWebsitesArraySelector);
  const isTeacher = useSelector(isUserTeacher);
  const sideBarList = isTeacher ? sideBarListForTeacher : sideBarListForStudent;
  const localStorageData = localStorage.getItem('myLiveSiteStatus');
  const data = localStorageData ? JSON.parse(localStorageData) : null;
  const websiteID = getUserWebsiteID(websitesArray);

  useEffect(() => {
    const websiteID = getUserWebsiteID(websitesArray);
    if (websiteID) {
      dispatch(getWebsiteAction(websiteID));
    }
  }, [websiteID, dispatch]);

  const goHome = () => history.push('/home');
  const renderList = () => {
    return sideBarList.map(
      ({ name, icon: Icon, isDivider, link, external, fontWeightMedium }: SideBarList) => {
        const currentLink = () => {
          if (link === '/shop/') {
            if (path) {
              return `/shop/${path}`;
            }
            return '';
          }
          return link;
        };
        const status = () => {
          if (link === '/shop/' && !data && path) {
            return 'notVisited';
          }
          if (currentLink()) {
            return pathname === link ? 'selected' : '';
          }
          return 'disabled';
        };
        if (name === 'Store') {
          return <StoreMenu name={name} Icon={Icon} key={name} />;
        }
        const visitMyLiveSite = () => {
          if (link === '/shop/') {
            const myLiveSiteStatus = [{ sitePath: `${path}`, status: 'visited' }];
            const json = JSON.stringify(myLiveSiteStatus);
            localStorage.setItem('myLiveSiteStatus', json);
          }
        };
        return (
          <SidebarContainer key={name}>
            <SmartLinkWrapper link={currentLink()} external={external}>
              <ListItem status={status()} onClick={visitMyLiveSite}>
                <ItemIcon status={status()}>
                  <Icon />
                </ItemIcon>
                <ItemText status={status()} fontWeightMedium={fontWeightMedium}>
                  {name}
                </ItemText>
              </ListItem>
            </SmartLinkWrapper>
            {isDivider && <Divider />}
          </SidebarContainer>
        );
      }
    );
  };

  return (
    <DrawerContainer anchor="left" variant="permanent">
      <Logo onClick={goHome}>
        <LogoIconSmall />
      </Logo>
      <List>{renderList()}</List>
    </DrawerContainer>
  );
};

export default Sidebar;
