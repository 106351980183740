import React from 'react';
import { useSelector } from 'react-redux';
import { CardContent } from '@material-ui/core';
import { PasswordRecoveryLockIcon } from 'src/components/common/Icons';
import {
  linkSuccessfullySentSelector,
  passwordSuccessfullyResetSelector,
  errorSelector,
} from 'src/ducks/auth/selectors';
import WarningWindow from './warningWindow';

import { CardContainer, CardHeader, HeaderIcon, HeaderText } from './styles';

type Props = {
  text: string;
  children: React.ReactNode;
};

const AuthCardForRecovery = ({ children, text }: Props) => {
  const error = useSelector(errorSelector);
  const linkSuccessfullySent = useSelector(linkSuccessfullySentSelector);
  const passwordSuccessfullyReset = useSelector(passwordSuccessfullyResetSelector);
  const successMessageStatus = linkSuccessfullySent || passwordSuccessfullyReset;
  const successMessageText = () => {
    if (linkSuccessfullySent) {
      return 'Link to change password successfully sent to your email!';
    } else if (passwordSuccessfullyReset) {
      return 'Your password has been successfully changed!';
    }
  };
  return error ? (
    <WarningWindow error={error} />
  ) : (
    <CardContainer passwordRecowery>
      <CardHeader>
        {successMessageStatus ? (
          <HeaderText success>{successMessageText()}</HeaderText>
        ) : (
          <>
            <HeaderIcon>
              <PasswordRecoveryLockIcon />
            </HeaderIcon>
            <HeaderText variant="h4">{text}</HeaderText>
          </>
        )}
      </CardHeader>
      <CardContent>{children}</CardContent>
    </CardContainer>
  );
};

export default AuthCardForRecovery;
