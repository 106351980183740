export type ProfitStatus = {
  status: string;
  shownStatus: string;
  color: string;
};

const profitStatuses = [
  {
    status: 'check',
    shownStatus: 'Check',
    color: '#A154F2',
  },
  {
    status: 'money',
    shownStatus: 'Money Order',
    color: '#0081FF',
  },
  {
    status: 'ecommerce',
    shownStatus: 'Ecommerce',
    color: '#09B66D',
  },
  {
    status: 'offline',
    shownStatus: 'Point of Sale',
    color: '#FDBF5E',
  },
];

export const prepareSelectOptions = () => {
  return profitStatuses.map(item => {
    return {
      type: item.status,
      shownType: item.shownStatus,
    }
  })
}

export default profitStatuses