import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getOrder, editOrder } from 'src/ducks/orders/actions';
import { getNewNotifications } from 'src/ducks/user/actions';
import history from 'src/containers/history';
import {
  selectedOrderSelector,
  loadingOrderSelector,
  trackingNumberSelector,
  isDirtyTrackingNumberSelector,
  orderAnyTouchedSelector,
} from 'src/ducks/orders/selectors';
import { userAccessesSelector } from 'src/ducks/user/selectors';
import { findMappedDeliveryStatus, formatDate } from 'src/utils/helpers';
import { ProfileNameIcon, LetterIcon } from 'src/components/common/Icons';
import Loader from 'src/components/common/error-pages/Loader';
import { Popup } from 'src/components/common/modal';
import popupText from 'src/constants/popupMessages';
import { SaveIcon } from 'src/components/common/Icons';
import userAccessesConstants from 'src/constants/userAccesses';
import { DeliveryInfo } from './deliveryInfo';
import { ProductsInfo } from './productsInfo';
import {
  ViewOrderPage,
  OrderTitle,
  CustomerWrapper,
  InfoWrapper,
  CustomerInfoWrapper,
  HorizontalLine,
  BackButton,
  SaveButton,
  ViewOrderContainer,
  ActionButtonsWrapper,
  ActionButtons,
  TotalWrapper,
} from './styles';
import { LOCAL_DELIVERY, LOCAL_PICKUP, NOT_APPLICABLE, USPS_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

const ViewOrder = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [checked, setChecked] = useState(false);
  const order = useSelector(selectedOrderSelector);
  const isLoading = useSelector(loadingOrderSelector);
  const trackingNumber = useSelector(trackingNumberSelector);
  const isAnyTouched = useSelector(orderAnyTouchedSelector);
  const isDirtyTrackingNumber = useSelector(isDirtyTrackingNumberSelector);
  const userAccesses = useSelector(userAccessesSelector);
  const currentStatus = findMappedDeliveryStatus(order?.status || '');
  const isAllowEditPage = !userAccesses?.includes(userAccessesConstants.ACCESS_EDITOR);
  const { mainText, warningText } = popupText.viewOrder;

  useEffect(() => {
    dispatch(getNewNotifications());
    dispatch(getOrder(id));
  }, [id, dispatch]);

  const handleEditOrder = () => {
    if (isAnyTouched || checked) {
      if (order) {
        let orderStatus = 'completed';

        if (order.status === 'paid') {
          orderStatus = 'shipping';
        }

        if (order.trackingNumber) {
          dispatch(editOrder(id, trackingNumber));
        } else {
          if(trackingNumber && order.status === 'shipping') {
            dispatch(editOrder(id, trackingNumber));
          } else {
            dispatch(editOrder(id, trackingNumber, orderStatus));
          }
        }
      }
    } else {
      history.push('/orders');
    }
  };

  const handleCheckIsAllowGoBack = () => {
    if (isDirtyTrackingNumber) {
      handleOpenPopup();
    } else {
      handleGoBack();
    }
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleGoBack = () => {
    history.push('/orders');
    handleClosePopup();
  };

  return !isLoading && order ? (
    <ViewOrderPage>
      <OrderTitle>
        Order <span>#{order.id}</span>
      </OrderTitle>
      <ViewOrderContainer>
        <CustomerWrapper>
          <div>Customer</div>
          <div>Placed on {formatDate(order.createdAt)}</div>
        </CustomerWrapper>
        <CustomerInfoWrapper>
          <ProfileNameIcon />
          <InfoWrapper>
            Name: <span>{order.fullName}</span>
          </InfoWrapper>
          <LetterIcon />
          <InfoWrapper>
            Email: <span>{order.email}</span>
          </InfoWrapper>
        </CustomerInfoWrapper>
        <HorizontalLine />
        <DeliveryInfo
          order={order}
          isAllowEditPage={isAllowEditPage}
          checked={checked}
          setChecked={setChecked}
        />
        <ProductsInfo order={order} />
        <ActionButtonsWrapper>
          <ActionButtons>
            <BackButton onClick={handleCheckIsAllowGoBack}>Back</BackButton>
            {isAllowEditPage && currentStatus?.shownStatus !== 'Delivered' && (
              <SaveButton
                variant="contained"
                color="primary"
                disabled={!`${trackingNumber}`}
                onClick={handleEditOrder}
              >
                <SaveIcon />
                Save
              </SaveButton>
            )}
          </ActionButtons>
          <TotalWrapper>
            <div>Total:</div>
            <div>${order.grandTotal.toLocaleString('en-US', { minimumFractionDigits: 2 })}</div>
          </TotalWrapper>
        </ActionButtonsWrapper>
      </ViewOrderContainer>
      <Popup
        isOpen={isOpenPopup}
        error={null}
        mainText={mainText}
        warningText={warningText}
        closeModal={handleClosePopup}
        handleYesAction={handleGoBack}
      />
    </ViewOrderPage>
  ) : (
    <Loader />
  );
};

export default ViewOrder;
