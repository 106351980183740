import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { websiteTemplateIDSelector } from 'src/ducks/website/selectors';
import { updateProductsAmount } from 'src/ducks/shopOrder/actions';
import { shopProductSelector, errorSelector, loadingShopProductSelector } from 'src/ducks/shopProducts/selectors';
import { addProductToShoppingCart, viewShopProduct } from 'src/ducks/shopProducts/actions';
import { FullShopProduct, ProductChoice } from 'src/ducks/shopProducts/shopProducts';
import { getItemByKeyFromStorage, LocalStorageType } from 'src/utils/helpers';
import { FullScreenPreloader } from 'src/components/common/fullScreenPreloader';
import productImage from 'src/assets/images/websites/product-image.png';
import stylesTemplates, { StylesTemplate } from 'src/constants/stylesTemplates';
import ProductChoices from './ProductChoices';
import vars from 'src/constants/vars';
import { Wrapper } from '../shoppingCart/styles';
import { ErrorMessage } from 'src/components/pages/products/styles';
import {
  CarouselWrapper,
  ImageContainer,
  OutOfStock,
} from '../websiteTemplates/websiteTemplate1/styles';
import {
  ProductDetailsContainer,
  ProductCardContainer,
  CardContainer,
  ProductImageContainer,
  CardDescription,
  CardInfoContainer,
  CardInfo,
  ShopProductContainer,
  ProductPrice,
  AddToCartButton,
  CardName,
  SelectorContainer,
  CounterContainer,
} from './styles';
import { NON_USPS_SHIPPING, USPS_NON_PRIORITY_MAIL } from 'src/constants/orderShippingMethods';

type WebsiteProductDetailsProps = {
  websitePath: string;
  productId: number;
};

const ShopProductDetails = ({ productId, websitePath }: WebsiteProductDetailsProps) => {
  const dispatch = useDispatch();
  const [productAmount, setProductAmount] = useState<number>(1);
  const [productChoiceId, setProductChoiceId] = useState<number | undefined>(undefined);
  const templateId = useSelector(websiteTemplateIDSelector);
  const shopProduct = useSelector(shopProductSelector);
  const isLoading = useSelector(loadingShopProductSelector);
  const errors = useSelector(errorSelector);
  const styles = stylesTemplates.find(({ id }: StylesTemplate) => templateId === id);
  const products = getItemByKeyFromStorage('shoppingCart');
  const productsAmount =
    products?.reduce((acc: number, currVal: LocalStorageType) => acc + currVal.count, 0) || 0;

  useEffect(() => {
    dispatch(viewShopProduct(productId, websitePath));
  }, [dispatch, productId, websitePath]);

  const handleAddProductToShoppingCart = () => {
    if (templateId) {
      const fullAmount = productsAmount + productAmount;

      dispatch(addProductToShoppingCart(productAmount, productChoiceId));
      dispatch(updateProductsAmount(fullAmount));
    }
  };

  const renderImage = (imageList: any, inventory: number, isPhysicalProduct?: boolean) => {
    const isOutOfStock = isPhysicalProduct && inventory === 0;
    return (
      <ProductImageContainer>
        {imageList.length ? (
          <CarouselWrapper {...vars.sliderSettings}>
            {imageList.map((url: string, i: number) => {
              return (
                <ImageContainer isOutOfStock={isOutOfStock} key={i}>
                  {isOutOfStock && (
                    <OutOfStock>This item is currently out of stock</OutOfStock>
                  )}
                  <img src={url} />
                </ImageContainer>
              );
            })}
          </CarouselWrapper>
        ) : (
          <ImageContainer isOutOfStock={isOutOfStock}>
            {isOutOfStock && <OutOfStock>This item is currently out of stock</OutOfStock>}
            <img src={productImage} />
          </ImageContainer>
        )}
      </ProductImageContainer>
    );
  };

  const calculateProductPrice = (price: number, currentProductChoice?: ProductChoice) => {
    if (currentProductChoice) {
      return (currentProductChoice.price * productAmount).toFixed(2);
    }
    return price.toFixed(2);
  };

  const handleDecreaseAmount = () => {
    if (shopProduct && productAmount !== 1) {
      setProductAmount(productAmount - 1);
    }
  };

  const handleIncreaseAmount = () => {
    if (shopProduct?.isPhysicalProduct) {
      if (shopProduct && productAmount < shopProduct.inventory) {
        setProductAmount(productAmount + 1);
      }
    } else {
      setProductAmount(productAmount + 1);
    }
  };

  const renderProduct = () => {
    if (!shopProduct) {
      return null;
    }

    const {
      imagesGallery,
      inventory,
      name,
      price,
      shippingType,
      description,
      websiteCollection,
      productChoices,
      note,
      isPhysicalProduct,
    }: FullShopProduct = shopProduct;
    const imageList = Object.values(imagesGallery).map((arr: any) => arr[1].url);
    const currentProductChoice = productChoices?.length ?
      productChoices.find(({ id }: ProductChoice) => id === productChoiceId)
      : undefined;
    const productPrice = calculateProductPrice(price, currentProductChoice);
    const isShippingIncluded = shippingType === USPS_NON_PRIORITY_MAIL || shippingType === NON_USPS_SHIPPING;

    return (
      <CardContainer>
        {isLoading && <FullScreenPreloader />}
        <ShopProductContainer>
          {renderImage(imageList, inventory, isPhysicalProduct)}
          <CardInfoContainer>
            <CardInfo>
              <CardName styles={styles}>
                {name}<br />
                {isShippingIncluded && <span>Shipping Included</span>}
              </CardName>
              {note &&
              <CardDescription styles={styles}>
                {`Product notes: ${note}`}
              </CardDescription>
              }
              <CardDescription styles={styles}>
                {`Product description: ${description}`}
              </CardDescription>
              { websiteCollection?.collectionName && (
                <CardDescription styles={styles}>
                  {`Product collection: ${websiteCollection.collectionName}`}
                </CardDescription>
              )}
            </CardInfo>
            {(!!shopProduct.productChoices.length && styles) ? (
              <SelectorContainer>
                <ProductChoices
                  setProductAmount={setProductAmount}
                  setProductChoiceId={setProductChoiceId}
                  productChoices={shopProduct.productChoices}
                  productId={shopProduct.id}
                  isPhysicalProduct={isPhysicalProduct}
                  styles={styles}
                />
              </SelectorContainer>
            ) : (
              <CounterContainer isOutOfStock={isPhysicalProduct && productAmount === shopProduct.inventory}>
                <span onClick={handleDecreaseAmount}>&#8722;</span>
                <span>{productAmount}</span>
                <span onClick={handleIncreaseAmount}>+</span>
              </CounterContainer>
            )}
            <AddToCartButton
              onClick={handleAddProductToShoppingCart}
              disabled={isPhysicalProduct && inventory === 0}
              styles={styles}
            >
              Add To Cart
            </AddToCartButton>
          </CardInfoContainer>
        </ShopProductContainer>
        {styles && <ProductPrice styles={styles}>$ {productPrice}</ProductPrice>}
      </CardContainer>
    );
  };

  return (
    <Wrapper>
      <ProductCardContainer>
        <ProductDetailsContainer styles={styles}>
          {errors && Array.isArray(errors) ? (
            errors.map((error, index) => <ErrorMessage key={index}>{error}</ErrorMessage>)
          ) : (
            <ErrorMessage>{errors}</ErrorMessage>
          )}
          {shopProduct?.id && renderProduct()}
        </ProductDetailsContainer>
      </ProductCardContainer>
    </Wrapper>
  );
};

export default ShopProductDetails;
