import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';
import { styled, WithTheme, withTheme } from '@material-ui/core/styles';

type FontSizeProps = {
  size?: number;
};

export const StyledRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: 20,
});

export const RowLabel = withTheme(
  styled(({ theme, ...otherProps }: TypographyProps & WithTheme) => <Typography {...otherProps} />)(
    {
      color: ({ theme }: WithTheme) => theme.customPalette.text.grey,
      flex: '0 0 140px',
    }
  )
);

export const RowValue = withTheme(
  styled(({ theme, size, ...otherProps }: TypographyProps & WithTheme & FontSizeProps) => (
    <Typography {...otherProps} />
  ))({
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    fontSize: ({ size }: TypographyProps & WithTheme & FontSizeProps) => size,
    fontWeight: 500,
    overflowWrap: 'anywhere',
    wordBreak: 'break-word',
  })
);
