import React from 'react';
import { styled } from '@material-ui/core/styles';
import { StylesTemplate } from 'src/constants/stylesTemplates';

export type ProceedToPaymentButtonProps = {
  disabled?: boolean;
};

type ButtonProps = {
  onClick?: any;
};

export type StylesProps = {
  styles: any;
};

export const OrderContainer = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />
))({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  width: '30%',
  maxWidth: 450,
  height: '100%',
  marginLeft: 50,
  backgroundColor: ({ styles }: StylesProps) => styles?.orderBackground,
  color: ({ styles }: StylesProps) => styles?.orderColor,
  boxShadow: ({ styles }: StylesProps) => styles?.boxShadow,
  fontWeight: ({ styles }: StylesProps) => styles?.fontWeight,
  borderRadius: 8,
  padding: '30px 24px 44px 24px',
  '@media (max-width: 1024px)': {
    marginLeft: 0,
    width: 'auto',
    maxWidth: 'unset',
  },
});

export const HorizontalLineOrder = styled(({ styles, ...otherProps }: StylesProps) => (
  <div {...otherProps} />
))({
  borderTop: ({ styles }: StylesProps) => `1px solid ${styles?.horizontalLineColor}`,
  marginTop: 16,
  width: '100%',
});

export const Title = styled(({ styles, ...otherProps }: StylesProps) => <div {...otherProps} />)({
  fontSize: 30,
  fontWeight: ({ styles }: StylesProps) => styles?.titleFontWeight,
});

export const SubTotalContainer = styled('div')({
  paddingTop: 34,
  paddingBottom: 24,
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 550px)': {
      flexDirection: 'column',
      marginBottom: 10,
    },
  },
  '& > div:first-child': {
    paddingBottom: 16,
  },
});

export const PriceOrder = Title;

export const SubTitle = styled('div')({
  fontSize: 24,
});

export const SubPrice = SubTitle;

export const DeliveryAddress = styled('div')({
  fontSize: 24,
  paddingTop: 8,
  wordBreak: 'break-word',
});

export const ProceedToPaymentButton = styled(
  ({ styles, ...otherProps }: ProceedToPaymentButtonProps & StylesProps & ButtonProps) => (
    <button {...otherProps} />
  )
)({
  alignSelf: 'center',
  width: '100%',
  maxWidth: 300,
  marginTop: 44,
  border: ({ styles }: StylesProps) => styles?.checkoutButton.border,
  borderRadius: ({ styles }: StylesProps) => styles?.checkoutButton.borderRadius,
  textAlign: 'center',
  padding: '16px 30px',
  fontSize: 24,
  fontWeight: ({ styles }: StylesProps) => styles?.checkoutButton.fontWeight,
  cursor: 'pointer',
  color: ({ styles }: StylesProps) => styles?.checkoutButton.color,
  backgroundColor: ({ styles }: StylesProps) => styles?.checkoutButton.backgroundColor,
  '&:focus': {
    outline: 'none',
  },
  '&:disabled': {
    cursor: 'default',
    color: 'rgba(68,86,108,0.4)',
  },
});
