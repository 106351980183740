import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import { OrdersTotalTitle } from 'src/components/pages/orders/orderList/styles';

export const CashoutTitle = styled(OrdersTotalTitle)({
  paddingBottom: 0,
});

export const CashoutTitlePrice = styled('span')({
  color: '#8697A8',
});

export const LinkWebsiteDesign = styled(Link)({
  color: '#008DBA',
});
