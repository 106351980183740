import React from 'react';
import { styled, withTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

export const ExternalLink = withTheme(
  styled(({ theme, group, ...otherProps }: any) => <a {...otherProps} />)({
    textDecoration: 'none',
    width: ({ group }: any) => (group ? 'auto' : 'inherit'),
    height: 'inherit',
  })
);

export const InternalLink = withTheme(
  styled(({ theme, group, ...otherProps }: any) => <Link {...otherProps} />)({
    textDecoration: 'none',
    width: ({ group }: any) => (group ? 'auto' : 'inherit'),
    height: 'inherit',
    color: '#44566C',
  })
);
