import { format } from 'date-fns';
import { defaultQueryParams, IQueryParams } from "src/components/pages/accounting/report/queryHelper";

export const STRIPE_MIN_DATE = '08/05/2020';
export const FILE_DATE_FORMAT = 'MM_dd_yyyy';

export interface IPageParams {
    page?: number,
    perPage?: number,
}
export interface IUrlParams extends IQueryParams, IPageParams { }

const defaultPageParams: IPageParams = {
    page: 1,
    perPage: 20,
}

const defaultUrlParams: IUrlParams = {
    ...defaultPageParams,
    ...defaultQueryParams,
}

export const prepareUrlParams = (baseUrl: string, urlParams: IUrlParams): string => {
    let newUrl = baseUrl;
    const newSearchParams = new URLSearchParams();
    const paramsList = Object.keys(urlParams) as Array<keyof IUrlParams>;

    const pageParam = paramsList.find((key: string) => key === 'page');
    if (!pageParam) {
        newSearchParams.append('page', `${defaultPageParams.page}`);
    }
    if (pageParam) {
        newSearchParams.append('page', `${urlParams.page}`);
    }

    if (JSON.stringify(defaultUrlParams) !== JSON.stringify(urlParams)) {
        newSearchParams.append('perPage', `${defaultPageParams.perPage}`);

        if (urlParams.cashoutType && urlParams.cashoutType !== defaultUrlParams.cashoutType) {
            newSearchParams.append('type', `${urlParams.cashoutType}`);
        }

        if (urlParams.from) {
            if (urlParams.to) {
                newSearchParams.append('approvedAt', `${urlParams.from}-${urlParams.to}`);
            } else {
                newSearchParams.append('approvedAt', `${urlParams.from}-${format(new Date(), 'MM/dd/yyyy')}`);
            }
        }

        newUrl += '?' + newSearchParams.toString()
    }

    return newUrl
}

export const prepareDownloadUrl = (
    baseUrl: string,
    urlParams: IUrlParams,
    minDate: Date | null = null
): string => {
    let newUrl = baseUrl;
    const newSearchParams = new URLSearchParams();

    if (JSON.stringify(defaultUrlParams) !== JSON.stringify(urlParams)) {
        if (urlParams.cashoutType && urlParams.cashoutType !== defaultUrlParams.cashoutType) {
            newSearchParams.append('type', `${urlParams.cashoutType}`);
        }

        if (urlParams.from) {
            if (urlParams.to) {
                newSearchParams.append('approvedAt', `${urlParams.from}-${urlParams.to}`);
            } else {
                newSearchParams.append('approvedAt', `${urlParams.from}-${format(new Date(), 'MM/dd/yyyy')}`);
            }
        } else {
            if (minDate) {
                const from = minDate;
                newSearchParams.append('approvedAt', `${format(from, 'MM/dd/yyyy')}-${format(new Date(), 'MM/dd/yyyy')}`);
            }
        }

        newUrl += '?' + newSearchParams.toString()
    }

    return newUrl
}

export type prepareOnDownloadReturnType = (a: any) => void;

export const prepareOnDownload = (
    filePrefix: string,
    startDate: Date | null = null,
    endDate: Date | null = null,
): prepareOnDownloadReturnType => {
    return (response: any): void => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;


        const from = startDate !== null ? format(startDate, FILE_DATE_FORMAT) : format(new Date(STRIPE_MIN_DATE), FILE_DATE_FORMAT);
        const to = endDate !== null ? format(endDate, FILE_DATE_FORMAT) : format(new Date(), FILE_DATE_FORMAT);

        let fileName = `${filePrefix}.csv`;
        if (startDate) {
            fileName = `${filePrefix} (${from} - ${to}).csv`;
        }
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
    }
}