import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';

export const ProductsContainer = styled('div')({
  marginTop: 24,
  fontSize: 15,
});

export const ProductWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 60,
    wordBreak: 'break-word',
    backgroundColor: ({ theme }: WithTheme) => theme.customPalette.background.greyBg,
    padding: '0 40px 0 40px',
    '&:not(:nth-of-type(2))': {
      fontWeight: 400,
    },
  }),
);

export const ProductVersion = styled('div')({
  display: 'flex',
  flexBasis: 600,
  '& > div': {
    width: '100%',
    paddingRight: 20,
  },
});

export const ProductInfo = styled('div')({
  display: 'flex',
  flexBasis: 300,
  '& > div': {
    width: '100%',
    textAlign: 'center',
  },
});

export const CalculationWrapper = withTheme(
  styled(({ theme, ...otherProps }: WithTheme) => <div {...otherProps} />)({
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 60,
    paddingTop: 20,
    fontWeight: 400,
    '& > div:first-child': {
      textAlign: 'right',
      color: ({ theme }: any) => theme.customPalette.text.grey,
      '& > div': {
        paddingTop: 20,
      },
    },
    '& > div:last-child': {
      paddingLeft: 57,
      '& > div': {
        paddingTop: 20,
      },
    },
  }),
);
