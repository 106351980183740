import React, { useState, useEffect } from 'react';
import { ApplyButton, ResetButton, ReportWrapper } from '../accounting/report/styles';
import { DatePickerInput } from 'src/components/common/DatePickerInput';
import { useHistory, useLocation } from 'react-router';
import { prepareQueryParams, prepareUrl } from '../accounting/report/queryHelper';
import { format, parse } from 'date-fns';
import { useDispatch } from 'react-redux';
import { STRIPE_MIN_DATE } from 'src/ducks/cashout/urlHelper';
import { clearOrderList } from 'src/ducks/orders/actions';

type OrderFiltersProps = {
  loadData: (params: string) => void,
  resetFilter: () => void,
};

const OrderFilters = (props: OrderFiltersProps) => {
  const {
    loadData,
    resetFilter,
  } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const minDate = new Date(STRIPE_MIN_DATE);

  useEffect(() => {
    const queryParams = prepareQueryParams(location.search);
    const from = queryParams.from ? parse(queryParams.from, 'MM/dd/yyyy', new Date()) : null;
    const to = queryParams.to ? parse(queryParams.to, 'MM/dd/yyyy', new Date()) : null;
    setStartDate(from);
    setEndDate(to);
  }, [location.search]);

  const handleChangeDates = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  let history = useHistory()
  const replaceUrl = (newQueryParams: any) => {
    let newUrl = prepareUrl(location, newQueryParams, false);
    dispatch(clearOrderList());
    resetFilter();
    loadData(newQueryParams);
    history.push(newUrl)
  };

  const handleApply = () => {
    const newQueryParams = {
      from: startDate !== null ? format(startDate, 'MM/dd/yyyy') : startDate,
      to: endDate !== null ? format(endDate, 'MM/dd/yyyy') : endDate,
    }
    replaceUrl(newQueryParams);
  }

  const handleReset = () => {
    const newQueryParams = {
      from: null,
      to: null,
    }
    replaceUrl(newQueryParams);
  }

  return (
    <ReportWrapper>
      <DatePickerInput
        selected={startDate}
        onChange={handleChangeDates}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        minDate={minDate}
        maxDate={new Date()}
      />
      <ApplyButton variant="contained" color="primary" onClick={handleApply}>
        Apply
      </ApplyButton>
      <ResetButton variant="contained" onClick={handleReset}>
        Reset
      </ResetButton>
    </ReportWrapper>
  )
};

export default OrderFilters;

