import {
  HomeIcon,
  StoreIcon,
  AccountingIcon,
  ViewLiveSiteIcon,
  ToolkitsIcon,
  WalletIcon,
  HelpIcon,
} from 'src/components/common/Icons';

export const sideBarListForTeacher = [
  {
    name: 'Home',
    icon: HomeIcon,
    isDivider: false,
    link: '/home',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'Store',
    icon: StoreIcon,
    isDivider: false,
    link: '',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'Accounting',
    icon: AccountingIcon,
    isDivider: true,
    link: '/accounting',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'View My Live Site',
    icon: ViewLiveSiteIcon,
    isDivider: false,
    link: '/shop/',
    external: true,
  },
  {
    name: 'EdCorps Toolkits',
    icon: ToolkitsIcon,
    isDivider: false,
    link: 'https://www.edcorpstoolkits.org',
    external: true,
  },
  {
    name: 'ClassWallet',
    icon: WalletIcon,
    isDivider: false,
    link: 'https://www.classwallet.com',
    external: true,
  },
  {
    name: 'Help Desk',
    icon: HelpIcon,
    isDivider: false,
    link:
      'https://docs.google.com/document/d/191O-f9NWa48Z3XWr_uFbCisgOA9xnT031vKu0qttw-I/edit?usp=sharing',
    external: true,
  },
];

export const sideBarListForStudent = [
  {
    name: 'Home',
    icon: HomeIcon,
    isDivider: false,
    link: '/home',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'Store',
    icon: StoreIcon,
    isDivider: false,
    link: '',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'Accounting',
    icon: AccountingIcon,
    isDivider: true,
    link: '/accounting',
    external: false,
    fontWeightMedium: true,
  },
  {
    name: 'View My Live Site',
    icon: ViewLiveSiteIcon,
    isDivider: false,
    link: '/shop/',
    external: true,
  },
  {
    name: 'EdCorps Toolkits',
    icon: ToolkitsIcon,
    isDivider: false,
    link: 'https://www.edcorpstoolkits.org',
    external: true,
  },
  {
    name: 'Help Desk',
    icon: HelpIcon,
    isDivider: false,
    link:
      'https://docs.google.com/document/d/191O-f9NWa48Z3XWr_uFbCisgOA9xnT031vKu0qttw-I/edit?usp=sharing',
    external: true,
  },
];
