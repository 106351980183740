import {
  RESET_PASSWORD,
  CLEAR_PIN_FOR_STUDENT,
  GET_USER,
  UPDATE_TEACHER_PROFILE_INFO,
  CLEAR_TEACHER_PROFILE_INFO_ERROR,
  SET_AVATAR_IMAGE_DATA,
  GET_STUDENTS_LIST,
  UPDATE_STORE_NAME,
  CREATE_STUDENT,
  EDIT_STUDENT,
  DELETE_STUDENT,
  GET_STUDENT,
  CLEAR_STUDENTS_LIST,
  GET_STORE_REQUEST,
  SSE_NOTIFY,
  CLEAR_NEW_NOTIFICATIONS,
} from './constants';

export function resetStudentPassword(id: number) {
  return {
    type: RESET_PASSWORD,
    payload: id,
  };
}

export function clearStudentPin() {
  return {
    type: CLEAR_PIN_FOR_STUDENT,
  };
}

export function getCurrentUser() {
  return { type: GET_USER };
}

export type UpdateTeacherProfileInfo = {
  type: string;
  payload: UpdateTeacherProfileInfoData;
};

export type UpdateTeacherProfileInfoData = {
  userInfo: UserInfo;
  storeDescription: {
    zipCode?: number;
    classroomConcentration: string[];
  };
};

export type UserInfo = {
  firstName: string;
  lastName: string;
  userImage?: string | null | '';
};

export function updateTeacherProfileInfo(values: UpdateTeacherProfileInfoData) {
  return { type: UPDATE_TEACHER_PROFILE_INFO, payload: values };
}

export function clearTeacherProfileInfoError() {
  return { type: CLEAR_TEACHER_PROFILE_INFO_ERROR };
}

export function setAvatarImageData(imageData: string) {
  return { type: SET_AVATAR_IMAGE_DATA, payload: imageData };
}

export type GetStudentsList = {
  type: string;
  payload: number;
};

export function getStudentsList(page: number = 1) {
  return {
    type: GET_STUDENTS_LIST,
    payload: page,
  };
}

export type UpdateStoreNamePayload = {
  id: number;
  name?: string;
  storeDescription?: {
    classroomConcentration: string[];
    zipCode?: number;
  };
};

export type UpdateStoreNameType = {
  type: string;
  payload: UpdateStoreNamePayload;
};

export function updateStoreName(name: string, id: number) {
  return {
    type: UPDATE_STORE_NAME,
    payload: { name, id },
  };
}

type CreateStudentForm = {
  username: string;
  fullName: string;
  name: string;
};

export type CreateStudentPayload = {
  type: string;
  payload: CreateStudentForm;
};

export function createStudent(createStudentForm: CreateStudentForm) {
  return {
    type: CREATE_STUDENT,
    payload: createStudentForm,
  };
}

export function getStore(id: number) {
  return {
    type: GET_STORE_REQUEST,
    payload: id,
  };
}

export type StudentForm = {
  type: string;
  payload: StudentFormPayload;
};

type StudentFormPayload = {
  username: string;
  fullName: string;
  name: string;
  studentId: string;
};

export function editStudent(studentForm: StudentFormPayload) {
  return {
    type: EDIT_STUDENT,
    payload: studentForm,
  };
}

export function getStudent(studentId: string) {
  return {
    type: GET_STUDENT,
    payload: studentId,
  };
}

export type DeleteStudent = {
  type: string;
  payload: number;
};

export function deleteStudent(studentId: number) {
  return {
    type: DELETE_STUDENT,
    payload: studentId,
  };
}

export function clearStudentsList() {
  return { type: CLEAR_STUDENTS_LIST };
}

export function getNewNotifications() {
  return { type: SSE_NOTIFY };
}

export function clearNewNotifications() {
  return { type: CLEAR_NEW_NOTIFICATIONS };
}
