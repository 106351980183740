import React from 'react';
import { Wrapper, Label, Input, InputError } from './styles';
import { Field } from 'redux-form';
import { QuestionHintIcon } from 'src/components/common/Icons';
import { Hint } from 'src/components/common/hint';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

type Props = {
  adornment?: JSX.Element | string;
  name: string;
  disabled?: boolean;
  customClass?: string;
  maxLength?: number;
  type?: string;
  multiline?: boolean;
  placeholder?: string;
  validate?: ((value: string) => string | null)[];
  customRef?: React.Ref<HTMLButtonElement>;
  label?: string;
  required?: boolean;
  isNeedHelp?: boolean;
  title?: string;
  input: any;
  meta: any;
  size?: string;
  step?: string | number;
  customBg?: boolean;
};

export const FormInputComponent = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      error: {
        border: `1px solid ${theme.customPalette.borders.errorInput}`,
      },
      smWrapper: {
        maxWidth: 170,
        '@media (max-width: 1820px)': {
          maxWidth: 'initial',
        },
      },
    })
  );

  const classes = useStyles();
  const { error, active, touched } = props.meta;
  const hasError = touched && !active && !!error;

  return (
    <Wrapper className={props.size === 'sm' ? classes.smWrapper : ''}>
      <Label>
        {props.label}
        {props.required && '*'}
        {props.isNeedHelp && props.title && (
          <Hint placement="top" text={props.title}>
            <QuestionHintIcon />
          </Hint>
        )}
      </Label>
      <Input
        placeholder={props.placeholder}
        disabled={props.disabled}
        className={hasError ? classes.error : ''}
        inputref={props.customRef}
        customBg={props.customBg}
        {...props.input}
      />
      {hasError && <InputError>{error}</InputError>}
    </Wrapper>
  );
};

const FormInput = (props: any, ref: any) => (
  <Field component={FormInputComponent} {...props} customref={ref} />
);

export default FormInput;
