import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import FormAutocompleteField from 'src/components/common/autocomplete/Autocomplete';
import { useCollectionsAsOptions } from 'src/components/hooks/useCollectionsAsOptions';
import { KeyboardKey, KeyboardCharCode } from 'src/constants/keyboard';
import { WebsiteCollection } from 'src/constants/productsTypes';
import { Wrapper } from './styles'; 

type CollectionAutocompleteProps = {
  collections: WebsiteCollection[];
  defaultCollection?: WebsiteCollection;
  onCollectionChange: (collection: string) => void;
}

const MAX_SEARCH_CHARS_LENGTH = 50;
const HINT_TEXT = `Collections allow you to showcase your products in different groups. 
Consider using collections for new or different product lines, seasonally-offered items, 
or to represent different groups of students and their work.`

const CollectionAutocomplete = ({
  collections = [],
  defaultCollection,
  onCollectionChange,
}: CollectionAutocompleteProps) => {
  const [collection, setCollection] = useState<string>('');
  const [isOpenPopup, setIsOpenPopup] = useState<boolean>(false);
  const options = useCollectionsAsOptions(collections);

  useEffect(() => {
    if (defaultCollection) {
      setCollection(defaultCollection.collectionName);
      onCollectionChange(defaultCollection.collectionName);
    }
  }, [defaultCollection]);

  const handleCollectionChange = (
    event: ChangeEvent<{}>,
    value: string
  ) => {
    if (!event) return false;

    event.preventDefault();
    event.stopPropagation();

    const {
      nativeEvent: { key, which, keyCode, charCode },
    } = event as KeyboardEvent<Element>;

    const letter: string = value?.[value.length - 1];
    const keyCharCode = keyCode || charCode || which;
    if (value?.length > MAX_SEARCH_CHARS_LENGTH
      || keyCharCode === KeyboardCharCode.ENTER
    ) {
      return false;
    }

    const allowedSymbols = /[A-Za-z0-9\s]/g;

    if (allowedSymbols.test(letter)
      || key === KeyboardKey.BACKSPACE
      || key === KeyboardKey.DELETE
      || keyCharCode === KeyboardCharCode.BACKSPACE
      || keyCharCode === KeyboardCharCode.DELETE
    ) {
      setCollection(value);
      onCollectionChange(value);
      return true;
    }

    return false;
  };

  return (
    <Wrapper>
      <FormAutocompleteField
        name="productCollection-autocomplete"
        options={options}
        openOnFocus={true}
        maxLength={MAX_SEARCH_CHARS_LENGTH}
        openPopup={isOpenPopup}
        label="Collection: "
        hint={HINT_TEXT}
        inputValue={collection}
        onInputChange={handleCollectionChange}
        onOpenPopup={() => setIsOpenPopup(true)}
        onClosePopup={() => setIsOpenPopup(false)}
      />
    </Wrapper>
  )
}

export default CollectionAutocomplete;