import { Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const styles = (theme: Theme) => ({
  textField: {
    width: '100%',
    padding: '10px 0',
  },
  input: {
    minHeight: 42,
    color: `${theme.customPalette.text.primary} !important`,
    backgroundColor: ({ isPasswordRecovery }: any) =>
      isPasswordRecovery
        ? theme.customPalette.background.inputWhiteBg
        : theme.customPalette.background.inputBg,
    '& $notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.select}`,
    },
    '&:hover $notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.select}`,
    },
    '&$focused $notchedOutline': {
      border: '1px solid #008DBA',
    },
    '&$error $notchedOutline': {
      border: `1px solid ${theme.customPalette.borders.errorInput}`,
    },
    '&$disabled $notchedOutline': {
      border: 'none',
    },
  },
  additionalInput: {
    '&::placeholder': {
      color: theme.customPalette.text.grey,
      opacity: 1,
    },
    height: 0,
    WebkitBoxShadow: ({ isPasswordRecovery }: any) =>
      `0 0 0 1000px ${
        isPasswordRecovery
          ? theme.customPalette.background.inputWhiteBg
          : theme.customPalette.background.inputBg
      } inset`,
  },
  notchedOutline: {},
  focused: {},
  error: {},
  disabled: {},
});

export const SuccessMessage = styled('div')({
  fontSize: 12,
  color: '#09B66D',
});
