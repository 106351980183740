import React from 'react';
import { styled, withTheme, WithTheme } from '@material-ui/core/styles';
import { Card, FormControl, FormControlProps } from '@material-ui/core';
import { ContinueButton } from 'src/components/pages/auth/signin/styles';

type MainBlockProps = {
  blockWidth?: number;
};

export const AddCashoutButton = styled(
  ({ isSaveButton, ...otherProps }: any) => (
    <ContinueButton {...otherProps} />
  ))({
  width: ({ isSaveButton }: any) => isSaveButton ? 130 : 165,
  '& svg': {
    paddingRight: 7,
  },
});

export const CashoutDescription = styled('div')({
  display: 'flex',
  paddingTop: 30,
});

export const FormBlock = styled('div')({
  flexBasis: 350,
  '&:first-child': {
    paddingRight: 24,
  },
  '& > input': {
    backgroundColor: '#F8FAFB',
  },
});

export const MainBlock = withTheme(
  styled(({ theme, blockWidth, ...otherProps }: WithTheme & MainBlockProps) => (
    <Card {...otherProps} />
  ))({
    display: 'flex',
    flexDirection: 'column',
    color: ({ theme }: WithTheme) => theme.customPalette.text.primary,
    justifyContent: 'center',
    boxShadow: '0px 11px 32px rgba(169, 194, 209, 0.25), 0px 4px 8px rgba(169, 194, 209, 0.25)',
    padding: '30px 40px',
    maxWidth: ({ blockWidth }: MainBlockProps & WithTheme) => blockWidth,
  }),
);

export const Label = styled('div')({
  fontSize: 15,
  color: '#8697A8',
  lineHeight: '18px',
  paddingBottom: 10,
});

export const LabelDescription = styled('div')({
  fontSize: 15,
  color: '#44566C',
  lineHeight: '18px',
  paddingTop: 7,
  wordBreak: 'break-word',
});

export const LabelBlock = styled('div')({
  paddingBottom: 26,
});

export const ActionButtonsContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '& > button:last-child': {
    marginLeft: 24,
  },
});

export const TextAreaContainer = styled('div')({
  '& textarea': {
    height: 150,
    border: '1px solid #EAEDF0',
    color: '#44566C',
    resize: 'none',
    fontFamily: 'Roboto',
    lineHeight: '24px',
  },
});

export const PromptText = styled('div')({
  fontSize: 13,
  fontWeight: 400,
  color: '#8697A8',
  lineHeight: '17px',
  paddingBottom: 24,
});

export const FormControlWrapper = styled(
  ({ blockWidth, ...otherProps }: MainBlockProps & FormControlProps) => (
    <FormControl {...otherProps} />
  ))({
  width: ({ blockWidth }: MainBlockProps) => blockWidth ? blockWidth - 24 : 350,
});

export const SchoolInfo = styled('div')({
  width: 220,
});

export const NeededChangesPrompt = styled('div')({
  width: 320,
  paddingLeft: 24,
  lineHeight: '24px',
  fontSize: 15,
});

export const CashoutFormContainer = styled('div')({
  padding: '30px 30px 30px 50px',
});
