import React from 'react';
import { useDispatch } from 'react-redux';
import { selectedWebsiteTemplateIDAction } from 'src/ducks/website/actions';
import { Card, CardActions, Button } from '@material-ui/core';
import { useStyles } from './styles';
import { TemplateProps } from '../tabs/Tabs';

type Props = {
  images: TemplateProps[];
  templateId: number;
};

const WebsiteTemplates = ({ images, templateId }: Props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const findTemplate = images.find((img: TemplateProps) => img.id === templateId);
  const image = findTemplate ? findTemplate : images[0];
  const [currentImage, setValue] = React.useState(image);

  const handleChange = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const imageIndex = parseInt(event.currentTarget.value, 10);
    dispatch(selectedWebsiteTemplateIDAction(imageIndex + 1));
    setValue(images[imageIndex]);
  };
  return (
    <>
      <Card className={classes.root}>
        <div className={classes.cardArea}>
          <img className={classes.media} src={currentImage.path} />
        </div>
        <CardActions className={classes.cardAction}>
          {images.map((item: TemplateProps, i: number) => (
            <Button
              key={i}
              className={`${classes.buttonField}
                 ${currentImage.id === item.id && classes.activeBtn}`}
              value={i}
              size="small"
              color="default"
              onClick={handleChange}
            >
              {item.name}
            </Button>
          ))}
        </CardActions>
      </Card>
    </>
  );
};

export default WebsiteTemplates;
