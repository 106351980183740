import { ArrowBackIos } from '@material-ui/icons';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { shopProductSelector } from 'src/ducks/shopProducts/selectors';
import { websiteUrlSelector } from 'src/ducks/website/selectors';
import config from 'src/routes/config';
import { Wrapper } from './styles';

const BackToCollection = () => {
  const [path, setPath] = useState<string>('');
  const websiteName = useSelector(websiteUrlSelector) || '';
  const { collectionName } = useSelector(shopProductSelector)?.websiteCollection || {};

  useEffect( () => {
    if(websiteName) {
      let newPath = `${config.viewWebsite.replace(':id', websiteName)}`; 
      const query = collectionName ? `?${new URLSearchParams({ collection: collectionName }).toString()}` : '';
      setPath(newPath + query);
    }
  }, [websiteName, collectionName])

  return ( path ? (
    <Wrapper>
      <ArrowBackIos fontSize='small'/>
      <Link to={path}>Back To The Collection</Link>
    </Wrapper>
    ) : null
  );
}

export default BackToCollection;