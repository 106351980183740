import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getNewNotifications } from 'src/ducks/user/actions';
import { CashoutTabIcon, ProfitsTabIcon, ToolkitsIcon } from 'src/components/common/Icons';
import { Toolkit } from '../toolkit';
import { Profits } from '../profits';
import { Cashout } from '../cashout';
import { CustomTab, TabsWrapper } from './styles';
import { CASHOUT_TAB, getCurrentTab, prepareTabUrl, PROFITS_TAB, TOOLKIT_TAB } from './tabHelper';
import { Location } from 'history';

const Accounting = () => {
  const location: any = useLocation<Location>();
  const activeTab = getCurrentTab(location);
  const [currentTab, setCurrentTab] = useState(activeTab);

  const history = useHistory();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (activeTab !== currentTab) {
      dispatch(getNewNotifications());
      const newUrl = prepareTabUrl(location, currentTab); 
      history.replace(newUrl);
    }
  }, [currentTab]);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <TabsWrapper value={activeTab} onChange={handleChange} TabIndicatorProps={{ children: <span /> }}>
        <CustomTab icon={<ProfitsTabIcon />} label="Profits" />
        <CustomTab icon={<ToolkitsIcon />} label="Toolkit Bonuses" />
        <CustomTab icon={<CashoutTabIcon />} label="Cash Out" />
      </TabsWrapper>
      {activeTab === TOOLKIT_TAB && <Toolkit />}
      {activeTab === PROFITS_TAB && <Profits />}
      {activeTab === CASHOUT_TAB && <Cashout />}
    </>
  );
};

export default Accounting;
