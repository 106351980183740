export default {
  MAX_LENGTH_EMAIL: 255,
  MAX_LENGTH_PASSWORD: 100,
  MAX_LENGTH_SCHOOL_NAME: 255,
  MAX_LENGTH_CITY: 255,
  MAX_LENGTH: 30,
  MAX_LENGTH_STUDENT_NAME: 60,
  MAX_LENGTH_STUDENT_LOGIN: 60,
  MIN_LENGTH: 8,
  MAX_LENGTH_PIN: 4,
  MIN_LENGTH_NAME: 2,
  MAX_LENGTH_ABOUT_AS: 500,
  MAX_LENGTH_WEB_SITE_URL: 40,
  MAX_LENGTH_PRICE: 12,
  MAX_LENGTH_INVENTORY: 6,
  MIN_VALUE_FOR_NUMBERS_INPUTS: 0.01,
  MIN_VALUE_FOR_NUMBERS_INVENTORY: 0,
  MAX_TRACKING_NUMBER: 45,
  MAX_LENGTH_ZIP_CODE: 5,
  BUSINESS_NAME_MAX_LENGTH: 40,
  MAX_LENGTH_CASHOUT_AMOUT: 12,
  MAX_LENGTH_CASHOUT_NOTE: 255,
  MAX_LENGTH_ORGANIZATION_NAME: 255,
  MAX_LENGTH_ORGANIZATION_ADDRESS: 50,
  MAX_LENGTH_ORGANIZATION_TAX_ID: 20,
  MAX_LENGTH_FULL_NAME: 50,
  MIN_VALUE_DELIVERY_FEE: 0.5,
  MAX_VALUE_DELIVERY_FEE: 999999.99,
  MAX_LENGTH_PRODUCT_NOTE: 255,
  MAX_LENGTH_PRODUCT_NAME: 25,
};
