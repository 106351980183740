import { createSelector } from 'reselect';
import { StateProductsInterface } from './products';
import { Error, ErrorData } from '../user/user';
import { FormInterface } from 'src/constants/productsTypes';

export interface RootState {
  product: StateProductsInterface;
  form: FormInterface;
}

const productsStoreGetter = (state: RootState) => state.product;
export const productsListSelector = createSelector(
  productsStoreGetter,
  (product) => product?.productsList
);

export const productTypeSelector = createSelector(
  productsStoreGetter,
  (product) => product?.productType,
);

const editProductFormGetter = (state: RootState) => state.form?.product;
export const productFormSelector = createSelector(editProductFormGetter, (form) => form?.values);

const editProductVersionsFormGetter = (state: RootState) => state.form?.isVariousSizes;
export const productVersionsFormSelector = createSelector(editProductVersionsFormGetter, (form) => form?.values);

const editProductVersionsNamesFormGetter = (state: RootState) => state.form?.ProductVersionsNames;
export const productVersionsNamesFormSelector = createSelector(editProductVersionsNamesFormGetter, (form) => form?.values);

const editProductVersionsChipsFormGetter = (state: RootState) => state.form?.ProductVersionsChips;
export const productVersionsChipsFormSelector = createSelector(editProductVersionsChipsFormGetter, (form) => form?.values);

const editTableDataFormGetter = (state: RootState) => state.form?.tableData;
export const tableFormFormSelector = createSelector(editTableDataFormGetter, (form) => form?.values);

const productHasCustomFieldsGetter = (state: RootState) => state.form?.hasCustomFields;
export const productHasCustomFieldsSelector = createSelector(productHasCustomFieldsGetter, (form) => form?.values);

const shippingOptionsFormGetter = (state: RootState) => state.form?.shippingOptions;
export const productShippingOptionsSelector = createSelector(
  shippingOptionsFormGetter,
  form => form?.values
);

const errorGetter = (state: any) => state.product.error;
export const errorSelector = createSelector(errorGetter, (error: Error | null) => {
  const shippingOptionsCodes = ['DIFFERENT_SHIPPING_METHODS', 'SHIPPING_NOT_SELECTED', 'TWO_WAYS_SELECTED'];

  if (error?.error) {
    if (Array.isArray(error.errors)) {
      return error.errors.map((err: ErrorData) => {
        if (err?.code && shippingOptionsCodes.includes(err.code)) {
          return {
            isShipping: true,
            message: err.message,
          }
        }
        return {
          isShipping: false,
          message: err.message,
        }
      });
    } else {
      return error.errors.message;
    }
  }

  return null;
});
