import { LIST_ORDER, CLEAR_ORDER_LIST, VIEW_ORDER, EDIT_ORDER } from './constants';

export type GetOrderList = {
  type: string;
  payload: any;
};

export function getOrderList(queryParams: any) {
  return {
    type: LIST_ORDER,
    payload: queryParams,
  };
}

export function clearOrderList() {
  return { type: CLEAR_ORDER_LIST };
}

export type GetOrder = {
  type: string;
  payload: number;
};

export function getOrder(orderId: number) {
  return {
    type: VIEW_ORDER,
    payload: orderId,
  };
}

export type EditOrder = {
  type: string;
  payload: EditOrderPayload;
};

export type EditOrderPayload = {
  orderId: number;
  trackingNumber: string;
  status?: string;
};

export function editOrder(orderId: number, trackingNumber: string, status?: string) {
  return {
    type: EDIT_ORDER,
    payload: { orderId, trackingNumber, status },
  };
}
