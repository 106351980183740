export default {
  maxLength: (max: number) => (value: string) =>
    value && value.length > max ? `This field must contain no more than ${max} characters` : null,
  minLength: (min: number) => (value: string) =>
    value && value.length < min ? `This field must contain not less than ${min} characters` : null,
  required: (message: string = 'Required') => (value: string | number) => {
    if (typeof value === 'string' && value && value.trim()) {
      return null;
    } else if (typeof value === 'number' && value >= 0){
      return null;
    } else {
      return message;
    }
  },

  validateEmail: (message: string) => (value: string) =>
    value && !/^[a-zA-Z0-9_.+-]+@(?:[a-zA-Z0-9\\-]+\.)+[a-zA-Z0-9\\-]{1,64}$/i.test(value)
      ? message
      : null,
  validatePassword: (message: string) => (value: string) => {
    return /^(?=.*\d)(?=.*[a-zA-z])(?=.*[!@#$%^&*()[\]\-,.?"':{}|<>~+=;_`/\\]).{8,}$/.test(value)
      ? null
      : message;
  },
  validatePin: (message: string) => (value: string) => {
    return /^[0-9]{4,}$/.test(value) ? null : message;
  },
  validateWebsiteURL: (message: string) => (value: string) => {
    return /^[a-z0-9_-]{3,}$/.test(value) ? null : message;
  },
  minValue: (min: number) => (value: number) =>
    value && value < min ? `The minimal value is ${min}` : null,
  maxValue: (max: number, message?: string) => (value: number) =>
    value && value > max ?
      (message || `The maximum value is ${max.toLocaleString('en-US', { minimumFractionDigits: 2 })}`) :
      null,
  isInteger: () => (value: number) =>
    !Number.isInteger(+value) ? 'This field must contain only digits' : null,
  isNumber: () => (value: number | string) =>
    isNaN(+value) ? 'This field must contain only digits' : null,
  validateZipCode: (message: string) => (value: string) => {
    const regexp = /^[0-9]{5}$/;
    return regexp.test(value) ? null : message;
  },
  validateState: (message: string) => (value: string) => {
    return /^[a-z]{2}$/i.test(value) ? null : message;
  },
  validateZIP: (message: string) => (value: string) => {
    return /^[0-9]{5}$/.test(value) ? null : message;
  },
  validatePhone: (message: string) => (value: string) => {
    const normalizedValue = value?.replace(/[^\d]/g, '');
    // 1 symbol goes from mask + pure number consisted 10 digits
    return /^[0-9]{11}$/.test(normalizedValue) ? null : message;
  },
  normalizePhone: (value: string) => value?.replace(/[^\d]/g, '').substr(1),
};
